const stateInventory = [
  {
    value: "0",
    label: "0-Unknown",
  },
  {
    value: "1",
    label: "1-Registered",
  },
  {
    value: "2",
    label: "2-Reserved",
  },
  {
    value: "3",
    label: "3-Disposed",
  },
  {
    value: "4",
    label: "4-Imported",
  },
  {
    value: "5",
    label: "5-Inbound",
  },
  {
    value: "6",
    label: "6-Stocking",
  },
  {
    value: "7",
    label: "7-In Transit",
  },
  {
    value: "8",
    label: "8-In Store",
  },
  {
    value: "9",
    label: "9-Dispatched",
  },
];

export default stateInventory;
