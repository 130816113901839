/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
// import React, { useState, useCallback } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { useTranslation } from "react-i18next";

// React Data Grid
const gridStyle = { minHeight: 410 };

const sum = (a, b) => a + b;

const TransoutProcessListData = ({ filteredData }) => {
  // eslint-disable-next-line no-shadow
  const { t } = useTranslation(["transferOut"]);
  const columns = [
    { name: "id", header: "Id", type: "number", defaultVisible: false },
    {
      name: "skuCode",
      header: t(`transferOut.DialogProcessTransout.column-skuCode`),
      defaultFlex: 1,
      type: "string",
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0].skuCode}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value;
      },
    },
    {
      name: "description",
      header: t(`transferOut.DialogProcessTransout.column-description`),
      defaultFlex: 2,
      type: "string",
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0].description}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value;
      },
    },
    {
      name: "productCode",
      header: t(`transferOut.DialogProcessTransout.column-productCode`),
      type: "number",
      defaultFlex: 2.5,
    },
    {
      name: "quantity",
      header: t(`transferOut.DialogProcessTransout.column-quantity`),
      defaultFlex: 1,
      type: "string",
      groupSummaryReducer: {
        initialValue: 0,
        reducer: sum,
      },
    },
    {
      name: "checkinQty",
      header: t(`transferOut.DialogProcessTransout.column-checkinQty`),
      defaultFlex: 1,
      type: "string",
      groupSummaryReducer: {
        initialValue: 0,
        reducer: sum,
      },
    },
  ];

  return (
    <div>
      <ReactDataGrid
        licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
        idProperty="id"
        // checkboxColumn
        // multiSelect={false}
        // selected={selected}
        // onSelectionChange={onSelectionChange}
        style={gridStyle}
        columns={columns}
        groupColumn={true}
        defaultGroupBy={["productCode"]}
        defaultCollapsedGroups={true}
        dataSource={filteredData}
        max_height={500}
        pagination={true}
      />
    </div>
  );
};

export default TransoutProcessListData;
