/* eslint-disable array-callback-return */
/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Menu,
  MenuItem,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UserService from "../../../service/KeyCloakService";

// eslint-disable-next-line arrow-body-style
const PrefixDialogAdd = ({ open, close, load }) => {
  const [locationArray, setLocationArray] = useState([]);
  const [prefix, setPrefix] = useState("");
  const [sequence, setSequence] = useState("");
  const [site, setSite] = useState("");
  const prefixRef = useRef("");
  const sequenceRef = useRef("");
  const [tempLocationArray, SetTempLocationArray] = useState([]);
  const storedSite = localStorage.getItem("siteCode");
  const { t } = useTranslation(["setting"]);

  // Data fetching functions
  const loadLocation = async () => {
    try {
      const data = await fetch(`/echoMe/loc/listLocSite`);
      if (!data.ok) {
        console.log("error message from loadPrefix");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      const temp = [];
      json?.map((r) => {
        return temp.push({
          id: r.id,
          siteCode: r.siteCode,
        });
      });
      SetTempLocationArray(temp);
    } catch (err) {
      console.error(err);
    }
  };

  const loadSite = async () => {
    try {
      const data = await fetch(`/echoMe/loc/listLocSite`);
      if (!data.ok) {
        console.log("error message from loadPrefix");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      const tempLoc = [];
      const temp = [];
      const temp2 = [];

      json?.map((r) => {
        tempLoc.push(r);
        temp.push(r.siteCode);
      });

      // console.log({ json, temp, tempLoc });

      // if user has role of superuser
      // console.log(UserService?.getClientRole());
      if (UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERUSER")) {
        setLocationArray(temp);
      } else {
        tempLoc
          ?.filter((r) => storedSite === r.siteCode)
          ?.map((r) => temp2.push(r.siteCode));
        setLocationArray(temp2);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Update data function
  const refreshData = () => {
    loadSite();
    loadLocation();
  };

  // Update data whenever storedSite is changed
  useEffect(() => {
    refreshData();
  }, [storedSite]);

  // Change input handlers
  const currentInputChangeHandler = (e) => {
    let a = e.target.value?.toUpperCase();
    setPrefix(a);
  };

  const currentInputChangeHandlerSequence = (e) => {
    let a = e.target.value;
    setSequence(a);
  };

  const handleChange = (event) => {
    let temp = event.target.value;
    let result = tempLocationArray
      ?.filter((r) => r.siteCode === temp)
      ?.map((r) => r.id)[0];
    setSite(result);
  };

  // Button functions
  const handleClose = () => {
    setPrefix("");
    setSite("");
    setSequence("");
    prefixRef.current.value = "";
    sequenceRef.current.value = "";
    close();
  };

  const addPrefix = async () => {
    // Make sure users enters the all fields
    if (!site || !prefix || !sequence) {
      alert(t("setting.PrefixPrefixDialogAddDialog.alert-addPrefix"));
    } else if (prefix.length > 2) {
      //  Make sure lenth of the prefix is more than 2
      alert(t("setting.PrefixPrefixDialogAddDialog.alert-addPrefix2"));
    } else if (sequence.length > 6) {
      //  Make sure lenth of the prefix is more than 2
      alert(t("setting.PrefixPrefixDialogAddDialog.alert-addPrefix3"));
    } else {
      // If they enters the all fields
      let tempSequence = +sequence;
      // console.log(tempSequence);
      try {
        const data = await fetch(
          `/echoMe/prefix/newPrefix?prefix=${prefix}&seq=${tempSequence}&site=${site}`,
          { method: "POST" }
        );
        if (!data.ok) {
          console.log("error message from addPrefix");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        load();
        handleClose();
      } catch (err) {
        console.error(err);
        alert(err);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="sm">
        <DialogTitle>{t("setting.PrefixDialogAdd.header-name")}</DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("setting.PrefixDialogAdd.body")}
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <form>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={1}>
                  {}
                </Grid>
                <Grid item xs={12} sm={10}>
                  <TextField
                    required
                    fullWidth={true}
                    id="prefix-input"
                    label={t("setting.PrefixDialogAdd.input-prefix")}
                    type="text"
                    placeholder={t(
                      "setting.PrefixDialogAdd.input-prefixPlaceHolder"
                    )}
                    inputRef={prefixRef}
                    onChange={currentInputChangeHandler}
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  {}
                </Grid>
                <Grid item xs={12} sm={1}>
                  {}
                </Grid>
                <Grid item xs={12} sm={10}>
                  <TextField
                    required
                    fullWidth={true}
                    id="sequence-input"
                    label={t("setting.PrefixDialogAdd.input-seq")}
                    type="number"
                    placeholder={t(
                      "setting.PrefixDialogAdd.input-seqPlaceHolder"
                    )}
                    inputRef={sequenceRef}
                    onChange={currentInputChangeHandlerSequence}
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  {}
                </Grid>
                <Grid item xs={12} sm={1}>
                  {}
                </Grid>
                <Grid item xs={12} sm={10} mt="15px">
                  <Box>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="simple-select-label">
                        {t("setting.PrefixDialogAdd.input-site")}
                      </InputLabel>
                      <Select
                        labelId="simple-select-label"
                        id="demo-simple-select"
                        label={t("setting.PrefixDialogAdd.input-site")}
                        onChange={handleChange}
                        defaultValue=""
                      >
                        {locationArray?.map((location) => (
                          <MenuItem key={location} value={location}>
                            {location}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={1}>
                  {}
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "blue " }}
            onClick={refreshData}
          >
            {t("setting.PrefixDialogAdd.button-refresh")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "blue " }}
            onClick={addPrefix}
          >
            {t("setting.PrefixDialogAdd.button-add")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red " }}
            onClick={close}
          >
            {t("setting.PrefixDialogAdd.button-close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PrefixDialogAdd;
