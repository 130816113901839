/* eslint-disable no-undef */
/* eslint-disable spaced-comment */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable prefer-const */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable no-return-assign */
import { useState, useEffect, useCallback, useLayoutEffect } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import moment from "moment";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Paper,
  MenuItem,
  Dialog,
} from "@mui/material";
import RegisterProcessListData2 from "./RegisterProcessListData2";
import RegisterProcessListData1 from "./RegisterProcessListData1";
import Equipmentdialog from "./DialogEquipmentRegistration";
import ManageDialog from "./DialogManageRegistration";
import RegisteredEquipmentDialog from "./DialogRegisteredEquipment";
import { printZpl } from "utility/printUtli";
import { genMultItemRfidTagZpl } from "utility/printUtli/itemLabelPrint";
import UserService from "../../service/KeyCloakService";
import PartialPrintAskDialog from "./DialogPartialPrintAskRegistration";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// // eslint-disable-next-line import/no-absolute-path
// eslint-disable-next-line arrow-body-style

const gridStyle = { minHeight: 300 };
const toArray = (selected) => Object.keys(selected)?.map((id) => id * 1);

const DialogProcess = ({
  open,
  close,
  selected2,
  selected3,
  setChecked,
  load,
}) => {
  const [openEquipment, setOpenEquipment] = useState(false);
  const [openRegisteredEquip, setoOpenRegisteredEquip] = useState(false);
  const [openPartialAskPrint, setOpenPartialAskPrint] = useState(false);
  const [isDeleteButtonPress, setIsDeleteButtonPress] = useState(false);
  const [openManage, setOpenManage] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const { t } = useTranslation(["assetRegistration"]);
  const [dataArray, setdataArray] = useState([]);
  const [registerData, setRegisterData] = useState([]);
  const [scannerArray, setScannerArray] = useState([]);
  const [regList, setRegList] = useState([]);
  const [containerRfidList, setContainerRfidList] = useState([]);
  const [containerCode, setContainerCode] = useState("");
  const [selectedContainerRFID, setSelectedContainerRFID] = useState([]);
  const [isM2Scanning, setIsM2Scanning] = useState(false);
  const [scannerIP, setScannerIP] = useState("");
  const [selected, setSelected] = useState({});
  const [equipmentRFID, setEquipmentRFID] = useState("");
  const [containerAssetCode, setContainerAssetCode] = useState("");
  const [isScanning, setIsScanning] = useState(false);
  const [isCleared, setIsCleared] = useState(true);
  const [isScanned, setIsScanned] = useState(false);
  const [isOneTimeReg, setIsOneTimeReg] = useState(false);
  const [timerId, setTimerId] = useState("");

  const columns = [
    {
      name: "rfid",
      header: t(`assetReg.Async.DialogProcessRegistration-rfid`),
      defaultFlex: 1,
      type: "string",
    },
    {
      name: "rsku",
      header: t(`assetReg.Async.DialogProcessRegistration-rsku`),
      defaultFlex: 1,
      type: "string",
    },
  ];

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  // Fetching functions
  // Fetching the product list whenever changes happened of selected2(regNum),  selected3(Seleted)
  const loadProductList = async () => {
    const data = await fetch(
      `/echoMe/reg/listRegisterLine?regNum=${encodeURIComponent(
        selected3?.regNum
      )}`
    );
    const json = await data.json();
    setdataArray(json);
    return json;
  };

  // Fetching scanner List with reg num
  const getScannerList = async () => {
    const data = await fetch(
      `/echoMe/param/findAllActiveParamByCode?paramName=READER_IP`,
      {
        mode: "cors",
      }
    );
    const json = await data.json();
    setScannerArray(json);
    return json;
  };

  // React query responses
  const onSuccess = (data) => {
    console.log("Sucess", data);
  };

  const onError = (error) => {
    console.log(error);
  };

  // Reat query -scanner list
  const {
    data: scannerListProcess,
    isFetching: isFetchingScannerListProcess,
    refetch: refetchScannerListProcess,
    isError: isErrorScannerListProcess,
  } = useQuery("assetReg-getScannerList", getScannerList, {
    onSuccess,
    onError,
    // refetchInterval: 1000,
    // refetchIntervalInBackground: true,
    // refetchOnWindowFocus: true,
    enabled: false,
  });

  // Reat query -load product list
  const {
    data: assetRegistrationProcess,
    isFetching: isFetchingAssetReg,
    refetch: refetchAssetRegProductList,
    isError: isErrorLoadProductList,
  } = useQuery("assetReg-loadProductList", loadProductList, {
    onSuccess,
    onError,
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
  });

  // Getting data array from child components
  const getData = (arrayData) => {
    setRegisterData(
      arrayData === null || arrayData === undefined ? {} : arrayData
    );
  };

  const getData2 = (arrayData) => {
    setSelectedContainerRFID(
      arrayData === null || arrayData === undefined ? {} : arrayData
    );
  };

  // Fetching scanner list whenever dialog is opened
  useEffect(() => {
    if (selected3?.regNum) {
      refetchScannerListProcess();
    }
  }, [open]);

  useEffect(() => {
    refetchAssetRegProductList();
  }, [selected2, selected3, open]);

  // Dialog handler functions
  const dialogEquipmentClickHandler = () => {
    setOpenEquipment(true);
  };

  const dialogEquipmentCloseHandler = () => {
    setOpenEquipment(false);
  };

  const dialogManageClickHandler = () => {
    setOpenManage(true);
  };

  const dialogManageCloseHandler = () => {
    setOpenManage(false);
  };

  const dialogRegisteredClickHandler = () => {
    setoOpenRegisteredEquip(true);
  };

  const dialogRegisteredCloseHandler = () => {
    setoOpenRegisteredEquip(false);
  };

  const dialogPartialAskClickHandler = () => {
    setOpenPartialAskPrint(true);
  };

  const dialogPartialAskCloseHandler = () => {
    setOpenPartialAskPrint(false);
  };

  const backDrophandleClose = () => {
    setOpenBackDrop(false);
  };

  const backDrophandleOpen = async () => {
    setOpenBackDrop(true);
  };

  const handleChange = async (event) => {
    setScannerIP(event.target.value);
  };

  // Button functions
  const filterContainerAssetCode = async (rfid) => {
    const data = await fetch(`/echoMe/rfid/getRfidTagContainer?rfids=${rfid}`);
    try {
      if (!data.ok) {
        console.error(
          "Container does not have Container Access code. Please try again."
        );
      }
      const json = await data.json();
      const a = json?.map((d) => d.containerAssetCode);
      setContainerAssetCode(a[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (containerRfidList?.length === 1) {
      setTimeout(() => {
        filterContainerAssetCode(equipmentRFID);
      }, 1000);
    }
  }, [equipmentRFID, containerRfidList]);

  const containerRfidDeleteButtonHandler = async () => {
    setIsDeleteButtonPress(true);
    const temp = _.difference(containerRfidList, selectedContainerRFID);
    setContainerRfidList(temp);

    // filtering container code rfid
    const filteredContainerCode = temp?.map((d) => d?.rfid)?.join(",");
    setEquipmentRFID(filteredContainerCode);
  };

  const itemRFIDDeleteButtonHandler = async () => {
    const tempDeletedArray = _.difference(regList, Object?.values(selected));
    setRegList(tempDeletedArray);
    setSelected({});
  };

  const clearButtonHandler = async () => {
    if (scannerIP) {
      setRegList([]);
      setContainerRfidList([]);
      setSelectedContainerRFID([]);
      setEquipmentRFID("");
      setContainerCode("");
      setIsScanned(false);
      setIsOneTimeReg(false);
      setIsScanning(false);
      setSelected({});
      setIsCleared(true);
      setIsM2Scanning(false);
      setIsDeleteButtonPress(false);
    }
  };

  const handleCancel = () => {
    setContainerCode("");
    setEquipmentRFID("");
    setTimerId("");
    setContainerAssetCode("");
    setScannerArray([]);
    setScannerIP("");
    setTimerId("");
    setRegList("");
    setContainerRfidList([]);
    setSelectedContainerRFID([]);
    setIsScanned(false);
    setIsOneTimeReg(false);
    setIsScanning(false);
    setIsM2Scanning(false);
    setSelected(null);
    setIsCleared(true);
    setChecked(null);
    setIsDeleteButtonPress(false);
    load();
    close();
  };

  const generateRfidItemButtonHandler = async () => {
    try {
      const data = await fetch(
        `echoMe/reg/genRfidItem?regNum=${encodeURIComponent(selected3?.regNum)}`
      );
      if (!data.ok) {
        console.log("error message from generateRfidItemButtonHandler");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      printZpl(
        genMultItemRfidTagZpl(
          json?.map((e) => {
            const {
              eanupc = "1111111111",
              description = "NA",
              style = "NA",
              color = "NA",
              size = "NA",
              skuCode = "NA",
              exp = "NA",
              serial = "NA",
              rfid = "SNoRfidFoundData",
              productCode = "NA",
            } = e;
            console.log(e);
            let ssc = `${style}/${size}/${color}`;
            return {
              barCode: eanupc,
              des: description,
              ssc: ssc,
              sku: skuCode,
              exp: exp,
              serial: serial,
              rfid: rfid,
              productCode: productCode,
            };
          })
        )
      );
      load();
      refetchAssetRegProductList();
    } catch (err) {
      alert(err);
    }
  };

  const startScanM2 = async () => {
    if (scannerIP) {
      setIsM2Scanning(true);
      backDrophandleOpen();
      try {
        const data = await fetch(
          // `http://192.168.1.141:8080/echoMe/rfid/getReaderData?ipAdd=${scannerIP}`,
          `echoMe/rfid/getReaderData?ipAdd=${scannerIP}`,
          // `/echoMe/rfid/getReaderData?ipAdd=dummyData`,
          {
            mode: "cors",
          }
        );
        if (!data.ok) {
          console.log("error message from submitHandler");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        setIsM2Scanning(false);
        const json = await data.json();

        // Filtering RFID for Display
        const filteredRegData = [];
        json
          ?.filter((d) => d?.rfid.includes("SAT"))
          .map((d) => filteredRegData.push(d));

        // If first time scanning
        if (_.isEmpty(regList)) {
          setRegList(filteredRegData);
        } else if (!_.isEmpty(regList)) {
          // If more than one time then accumulate result
          const temp = _.differenceBy(filteredRegData, regList, "rfid");
          const removeDupReg = _.uniqBy([...temp, ...regList], "rfid");
          setRegList(removeDupReg);
        }

        // Filtering Container RFID for Display
        const filteredContainerData = [];
        json
          ?.filter((d) => d?.rfid.includes("CAT"))
          .map((d) => filteredContainerData.push(d));
        // If first time scanning
        if (_.isEmpty(containerRfidList)) {
          setContainerRfidList(filteredContainerData);
        } else if (!_.isEmpty(containerRfidList)) {
          // If more than one time then accumulate result
          const temp = _.differenceBy(
            filteredContainerData,
            containerRfidList,
            "rfid"
          );
          const removeDupCon = _.uniqBy(
            [...temp, ...containerRfidList],
            "rfid"
          );
          setContainerRfidList(removeDupCon);
        }

        // filtering container code rfid (needed for the first case)
        const filteredContainerCode = filteredContainerData?.map(
          (d) => d?.rfid
        );

        //If container accesscode has one then filter container access code
        if (filteredContainerCode?.length === 1) {
          await filterContainerAssetCode(filteredContainerCode[0]);
          setEquipmentRFID(filteredContainerCode[0]);
        }

        setIsScanning(false);
        setIsScanned(true);
        setIsCleared(false);
        backDrophandleClose();
      } catch (err) {
        console.error(err);
        backDrophandleClose();
        alert(err);
      }
    }
  };

  const registerEquipmentHandler = async () => {
    backDrophandleOpen();
    if (equipmentRFID) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const rfids = containerRfidList?.map((d) => d?.rfid);
      try {
        const data = await fetch(`/echoMe/reg/checkInContainer`, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            regNum: encodeURIComponent(selected3?.regNum),
            rfids,
          }),
        });
        if (!data.ok) {
          console.log("error message from registerContainers");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        const json = await data.json();
        setContainerCode(json[0].containerCode);
        alert(
          t(
            `assetReg.DialogProcessRegistration.alert-registerEquipmentHandler2`
          )
        );
        refetchAssetRegProductList();
        backDrophandleClose();
      } catch (err) {
        alert(err);
        backDrophandleClose();
      }
    } else if (!equipmentRFID) {
      alert(
        t(`assetReg.DialogProcessRegistration.alert-registerEquipmentHandler`)
      );
      backDrophandleClose();
    }
  };

  const closeButtonHandler = async () => {
    backDrophandleOpen();
    if (!containerAssetCode) {
      alert(t(`assetReg.DialogProcessRegistration.alert-closeButtonHandler`));
      backDrophandleClose();
    } else if (containerAssetCode) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const rfids = regList.map((d) => d?.rfid);
      try {
        const data = await fetch(`/echoMe/reg/checkInItems`, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            containerAssetCode,
            regNum: encodeURIComponent(selected3?.regNum),
            rfids,
          }),
        });
        if (!data.ok) {
          console.log("error message from registerItems");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        const json = await data.json();
        // console.log(json);
        alert(
          t(`assetReg.DialogProcessRegistration.alert-closeButtonHandler2`)
        );
        refetchAssetRegProductList();
        setIsOneTimeReg(false);
        backDrophandleClose();
        clearButtonHandler();
        load();
      } catch (err) {
        alert(err);
        setIsOneTimeReg(false);
        backDrophandleClose();
      }
    }
  };

  const completeButtonHandler = async () => {
    try {
      const data = await fetch(
        `/echoMe/reg/registerComplete?regNum=${encodeURIComponent(
          selected3?.regNum
        )}`
      );
      if (!data.ok) {
        console.log("error message from registerComplete");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      // console.log(json);
      setChecked(null);
      handleCancel();
    } catch (err) {
      alert(err);
    }
  };

  const scanButtonHandler = async () => {
    startScanM2();
  };

  const oneButtonHandler = async () => {
    setIsOneTimeReg(true);
    await registerEquipmentHandler();
    await closeButtonHandler();
    setIsOneTimeReg(false);
  };

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth="xl" fullScreen={true}>
        <DialogTitle>
          {t("assetReg.DialogProcessRegistration.header-name")}{" "}
          <Button
            color="primary"
            size="medium"
            variant="contained"
            style={{ float: "right", color: "white", marginRight: "20px" }}
            onClick={dialogEquipmentClickHandler}
            disabled={isScanning || isM2Scanning}
          >
            {t("assetReg.DialogProcessRegistration.button-new")}
          </Button>
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("assetReg.DialogProcessRegistration.bodytext-documentNumber")}{" "}
            <b style={{ color: "red" }}>{selected3?.regNum}</b>
          </DialogContentText>
          <Box
            variant="div"
            sx={{
              padding: "20px",
              border: "1px solid",
              borderRadius: "8px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6">
                        {t(
                          "assetReg.DialogProcessRegistration.sectionHeader-equipment"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                      {}
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        sx={{ ml: "-8px" }}
                        style={{ color: "white", backgroundColor: "red" }}
                        onClick={containerRfidDeleteButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          isScanning ||
                          !isScanned ||
                          isOneTimeReg ||
                          !scannerIP ||
                          !containerRfidList?.length > 0 ||
                          selectedContainerRFID.length < 1 ||
                          isM2Scanning
                        }
                      >
                        {t("assetReg.DialogProcessRegistration.button-delete")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        sx={{ ml: "-8px" }}
                        style={{ color: "white", backgroundColor: "warn" }}
                        onClick={oneButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          isScanning ||
                          !isScanned ||
                          isOneTimeReg ||
                          !scannerIP ||
                          !equipmentRFID ||
                          !containerAssetCode ||
                          isM2Scanning ||
                          containerRfidList?.length > 1 ||
                          regList?.length <= 0
                        }
                      >
                        {t("assetReg.DialogProcessRegistration.button-one")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ ml: "-4px" }}
                        fullWidth={true}
                        style={{ color: "white" }}
                        onClick={registerEquipmentHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          isScanning ||
                          !isScanned ||
                          isOneTimeReg ||
                          !scannerIP ||
                          !equipmentRFID ||
                          isM2Scanning ||
                          containerRfidList?.length < 0
                        }
                      >
                        {t(
                          "assetReg.DialogProcessRegistration.button-regEquip"
                        )}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        fullWidth={true}
                        style={{ color: "white" }}
                        onClick={closeButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          isScanning ||
                          !isScanned ||
                          isOneTimeReg ||
                          !scannerIP ||
                          !containerAssetCode ||
                          isM2Scanning
                        }
                      >
                        {t("assetReg.DialogProcessRegistration.button-regItem")}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} sx={{ marginTop: "10px" }}>
                      <RegisterProcessListData1
                        filteredData={containerRfidList}
                        getData={getData2}
                        setIsDeleteButtonPress={setIsDeleteButtonPress}
                        isDeleteButtonPress={isDeleteButtonPress}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "row",
                    padding: "15px",
                  }}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">
                      {t(
                        "assetReg.DialogProcessRegistration.sectionHeader-docInfo"
                      )}
                    </Typography>
                    <TextField
                      margin="dense"
                      id="regNum-input"
                      name="regNum"
                      label={t(
                        "assetReg.DialogProcessRegistration.input-regNum"
                      )}
                      type="text"
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      value={selected3?.regNum ?? "None"}
                    />
                    <TextField
                      margin="dense"
                      id="regDate-input"
                      name="regDate"
                      label={t(
                        "assetReg.DialogProcessRegistration.input-regDate"
                      )}
                      type="text"
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      value={
                        selected3?.regDate === null
                          ? "None"
                          : moment(selected3?.regDate).format("YYYY-MM-DD")
                      }
                    />
                    <TextField
                      margin="dense"
                      id="supplier-input"
                      name="supplier"
                      label={t(
                        "assetReg.DialogProcessRegistration.input-supplier"
                      )}
                      type="text"
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      value={selected3?.shipperCode ?? "None"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} ml="20px">
                    <Typography variant="h6" align="right" color="orange">
                      {selected3?.status?.replaceAll("_", " ") ?? "Undefined"}
                    </Typography>
                    <TextField
                      margin="dense"
                      id="totalProduct-input"
                      name="totalProduct"
                      label={t(
                        "assetReg.DialogProcessRegistration.input-totalProduct"
                      )}
                      type="text"
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      value={selected3?.totalSkuCodeQty ?? "None"}
                    />
                    <TextField
                      margin="dense"
                      id="totalQuantity-input"
                      name="totalQuantity"
                      label={t(
                        "assetReg.DialogProcessRegistration.input-totalQuantity"
                      )}
                      type="text"
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      value={selected3?.totalOrderQty ?? "None"}
                    />
                    <TextField
                      margin="dense"
                      id="totalTracker-input"
                      name="totalTracker"
                      label={t(
                        "assetReg.DialogProcessRegistration.input-totalTracker"
                      )}
                      type="text"
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      value={`${selected3?.totalCheckinQty ?? "None"}/${
                        selected3?.totalOrderQty ?? "None"
                      }`}
                    />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "row",
                    padding: "20px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6">
                        {t(
                          "assetReg.DialogProcessRegistration.sectionHeader-regInfo"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <FormControl fullWidth variant="standard">
                          <InputLabel id="simple-select-label">
                            {t("assetReg.DialogProcessRegistration.ipDropDown")}
                            {/* {scannerIP} */}
                          </InputLabel>
                          <Select
                            labelId="simple-select-label"
                            id="demo-simple-select"
                            label="Site"
                            onChange={handleChange}
                            value={scannerIP}
                            disabled={
                              selected3?.status.toLowerCase() === "completed" ||
                              selected3?.status.toLowerCase() === "cancelled" ||
                              isScanning ||
                              isOneTimeReg ||
                              !isCleared ||
                              isM2Scanning
                            }
                          >
                            {scannerArray?.map((scanner) => (
                              <MenuItem key={scanner.id} value={scanner.value}>
                                {`${scanner.code} - ${scanner.value}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: "white" }}
                        fullWidth={true}
                        onClick={scanButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          isScanning ||
                          !scannerIP ||
                          isM2Scanning
                        }
                      >
                        {t("assetReg.DialogProcessRegistration.button-scan")}
                      </Button>
                    </Grid>
                    {/* <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: "white", backgroundColor: "red" }}
                        fullWidth={true}
                        onClick={stopScanButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          !isScanning ||
                          !scannerIP ||
                          isM2Scanning
                        }
                      >
                        {t("assetReg.DialogProcessRegistration.button-stop")}
                      </Button>
                    </Grid> */}
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: "white", backgroundColor: "red" }}
                        fullWidth={true}
                        onClick={clearButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          !scannerIP
                        }
                      >
                        {t("assetReg.DialogProcessRegistration.button-clear")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: "white", backgroundColor: "red" }}
                        fullWidth={true}
                        onClick={itemRFIDDeleteButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          isScanning ||
                          !isScanned ||
                          isOneTimeReg ||
                          !scannerIP ||
                          !regList?.length > 0 ||
                          Object?.values(selected).length < 1 ||
                          isM2Scanning
                        }
                      >
                        {t("assetReg.DialogProcessRegistration.button-delete")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ReactDataGrid
                        licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                        idProperty="rfid"
                        checkboxColumn
                        selected={selected}
                        onSelectionChange={onSelectionChange}
                        style={gridStyle}
                        columns={columns}
                        dataSource={regList}
                      />
                      <p>
                        {t(`assetReg.total`)}: {regList?.length}
                      </p>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "row",
                    padding: "20px",
                    marginTop: "-60px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4.5}>
                      <Typography variant="h6">
                        {t(
                          "assetReg.DialogProcessRegistration.sectionHeader-productList"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      {(UserService.getClientRole().includes(
                        "ROLE_ECHOME_ASSETREG"
                      ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_SUPERUSER"
                        ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_SUPERVISORS"
                        ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_TEAMREADER"
                        ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_PICKER1"
                        )) && (
                        <Button
                          variant="contained"
                          size="small"
                          fullWidth={true}
                          onClick={refetchAssetRegProductList}
                          disabled={isScanning || isM2Scanning}
                          style={{
                            color: "white",
                            backgroundColor: "orange",
                          }}
                        >
                          {t(
                            "assetReg.DialogEquipmentRegistration.button-refresh"
                          )}
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={1.5} sx={{ ml: "auto", mb: "10px" }}>
                      {(UserService.getClientRole().includes(
                        "ROLE_ECHOME_ASSETREG"
                      ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_SUPERUSER"
                        ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_SUPERVISORS"
                        ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_TEAMREADER"
                        ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_PICKER1"
                        )) && (
                        <Button
                          variant="contained"
                          size="small"
                          fullWidth={true}
                          onClick={() => {
                            if (registerData?.length === 1) {
                              dialogPartialAskClickHandler();
                            } else {
                              alert(
                                t(
                                  `assetReg.DialogProcessRegistration.alert-partialPrint`
                                )
                              );
                            }
                          }}
                          style={{
                            color: "white",
                            backgroundColor: "orange",
                          }}
                          disabled={
                            registerData.length < 1 ||
                            selected3?.status.toLowerCase() === "completed" ||
                            selected3?.status.toLowerCase() === "cancelled" ||
                            selected3?.status.toLowerCase() === "registering" ||
                            isScanning ||
                            isM2Scanning
                          }
                        >
                          {t(
                            "assetReg.DialogProcessRegistration.button-partprint"
                          )}
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      {(UserService.getClientRole().includes(
                        "ROLE_ECHOME_ASSETREG"
                      ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_SUPERUSER"
                        ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_SUPERVISORS"
                        ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_TEAMREADER"
                        ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_PICKER1"
                        )) && (
                        <Button
                          variant="contained"
                          size="small"
                          fullWidth={true}
                          onClick={dialogRegisteredClickHandler}
                          disabled={isScanning || isM2Scanning}
                          style={{
                            color: "white",
                            backgroundColor: "orange",
                          }}
                        >
                          {t(
                            "assetReg.DialogProcessRegistration.button-regEquip2"
                          )}
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        size="small"
                        color="warning"
                        fullWidth={true}
                        onClick={dialogManageClickHandler}
                        disabled={isScanning || isM2Scanning}
                        style={{ color: "white", backgroundColor: "orange" }}
                      >
                        {t(
                          "assetReg.DialogProcessRegistration.button-manageRFID"
                        )}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      {(UserService.getClientRole().includes(
                        "ROLE_ECHOME_ASSETREG"
                      ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_SUPERUSER"
                        ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_SUPERVISORS"
                        ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_TEAMREADER"
                        ) ||
                        UserService.getClientRole().includes(
                          "ROLE_ECHOME_PICKER1"
                        )) && (
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          fullWidth={true}
                          onClick={generateRfidItemButtonHandler}
                          disabled={
                            selected3?.status.toLowerCase() === "completed" ||
                            selected3?.status.toLowerCase() === "cancelled" ||
                            selected3?.status.toLowerCase() === "registering" ||
                            selected3?.status.toLowerCase() ===
                              "rfid_tag_printed" ||
                            isScanning ||
                            isM2Scanning
                          }
                          style={{ color: "white" }}
                        >
                          {t(
                            "assetReg.DialogProcessRegistration.button-genRFID"
                          )}
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <RegisterProcessListData2
                        filteredData={dataArray}
                        getData={getData}
                      >
                        {" "}
                      </RegisterProcessListData2>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={openBackDrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </DialogContent>
        <DialogActions>
          {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETREG") ||
            UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
            UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
            UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER") ||
            UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")) && (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={completeButtonHandler}
              disabled={
                selected3?.status.toLowerCase() === "completed" ||
                selected3?.status.toLowerCase() === "cancelled" ||
                isScanning ||
                isM2Scanning
              }
            >
              {t("assetReg.DialogProcessRegistration.button-complete")}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red" }}
            onClick={handleCancel}
            disabled={isScanning || isM2Scanning}
          >
            {t("assetReg.DialogProcessRegistration.button-close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Equipmentdialog
        // selected={JSON.stringify(selected)}
        open={openEquipment}
        close={dialogEquipmentCloseHandler}
      />
      <ManageDialog
        // selected={JSON.stringify(selected)}
        open={openManage}
        close={dialogManageCloseHandler}
        regNum={selected3?.regNum}
        selected3={selected3}
      />
      <RegisteredEquipmentDialog
        // selected={JSON.stringify(selected)}
        open={openRegisteredEquip}
        close={dialogRegisteredCloseHandler}
        regNum={selected3?.regNum}
        selected3={selected3}
      />
      <PartialPrintAskDialog
        // selected={JSON.stringify(selected)}
        open={openPartialAskPrint}
        close={dialogPartialAskCloseHandler}
        registerData={registerData}
      />
      {/* <StartScanAskDialog
        // selected={JSON.stringify(selected)}
        open={openStartScanAsk}
        close={dialogStartScanAskCloseHandler}
        setIsScanning={setIsScanning}
        stopScanButtonHandler={stopScanButtonHandler}
      /> */}
    </>
  );
};

export default DialogProcess;
