/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-object-spread */
import React, { useState, useCallback, useEffect } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import Toolbar from "@mui/material/Toolbar";
import { navbarContainer } from "examples/Navbars/DashboardNavbar/styles";
import { usePageVisibility } from "react-page-visibility";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MDButton from "../MDButton";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import DialogZoneAdd from "./DialogZoneAdd";

const gridStyle = { marginTop: 10, minHeight: 530 };

const defaultColumns = [
  { name: "id", header: "Zone Id", defaultVisible: false, type: "number" },
  { name: "zoneCode", header: "Code", defaultFlex: 3 },
  {
    name: "locZoneType",
    header: "Zone Type",
    defaultFlex: 3,
    render: ({ value }) => value && value.typeCode,
  },
  { name: "description", header: "Description", defaultFlex: 6 },
];

const STORE = {
  columns: defaultColumns,
  limit: 10,
  sortInfo: { name: "zoneCode", dir: 1, type: undefined },
  reservedViewportWidth: 0,
  columnOrder: ["zoneCode", "locZoneType.typeCode", "description"],
};

// eslint-disable-next-line arrow-body-style
const ZoneAsync = () => {
  const initialData = Object.assign({}, STORE, { dataSource: [] });
  const [gridState, setGridState] = useState(initialData);
  const [checked, setChecked] = useState(0);
  const [dialogLocationOpen, setDialogLocationOpen] = useState(false);
  const [newOrUpdate, setNewOrUpdate] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [site, setSite] = useState("");
  const storedSite = localStorage.getItem("siteCode");
  const navigate = useNavigate();
  const reduxSiteCode = useSelector((state) => state.siteCode);
  const isVisible = usePageVisibility();

  // Getting source
  const getDataSource =
    (entity) =>
    ({ skip, limit, sortInfo, groupBy, filterValue }) => {
      const queryParams = [
        skip ? `skip=${skip}` : null,
        limit ? `limit=${limit}` : null,
        groupBy && groupBy.length ? `groupBy=${groupBy}` : null,
        filterValue && filterValue.length
          ? `filterBy=${JSON.stringify(
              filterValue
                ?.filter((v) => v.active !== false)
                ?.map((v) => ({
                  value: v.value,
                  name: v.name,
                  operator: v.operator,
                  type: v.type,
                }))
            )}`
          : null,
        sortInfo ? `sortInfo=${JSON.stringify(sortInfo)}` : null,
      ]
        ?.filter((value) => value)
        .join("&");

      return fetch(`/echoMe/${entity}?${queryParams}`).then((response) => {
        const totalCount = response.headers.get("X-Total-Count");
        return response
          .json()
          .then((data) => ({ data, count: parseInt(totalCount, 10) }));
      });
    };

  const newDataSource = useCallback(getDataSource("/loc/listLocZone"), [
    dialogLocationOpen,
  ]);

  // Dialog handler
  const dialogAddClickHandler = () => {
    setOpenAddDialog(true);
  };

  const dialogAddCloseHandler = () => {
    setOpenAddDialog(false);
  };
  //  Button functions
  // Fetching the siteCode and matching with selected with siteId
  const siteHandler = async () => {
    try {
      const data = await fetch(`/echoMe/loc/listLocSite`);
      if (!data.ok) {
        console.log("error message from siteHandler");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      json?.filter((r) => r.siteCode === storedSite)?.map((r) => setSite(r.id));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    siteHandler();
  }, [storedSite, isVisible]);

  const clearData = () => {
    setGridState(Object.assign({}, gridState, { dataSource: [] }));
  };

  const loadData = () => {
    clearData();
    setTimeout(
      () =>
        setGridState(
          Object.assign({}, gridState, { dataSource: newDataSource })
        ),
      10
    );
  };

  useEffect(() => {
    if (storedSite) {
      loadData();
    } else {
      alert(`An error Occrued. Please select site again.`);
      navigate("/dashboard");
    }
  }, [storedSite, reduxSiteCode, isVisible]);

  const deleteLocation = async () => {
    try {
      const data = await fetch(`/echoMe/loc/removeLocZone?id=${checked}`, {
        method: "GET",
        mode: "no-cors",
      });
      if (!data.ok) {
        console.log("error message from RemoveLocation");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
    } catch (err) {
      console.error(err);
      alert(err);
    }
    loadData();
  };

  // Unused functions (Reference from Calson)
  const addNewLocation = () => {
    setNewOrUpdate("new");
    setDialogLocationOpen(true);
  };

  const adjustLocation = () => {
    setNewOrUpdate("update");
    setDialogLocationOpen(true);
  };
  const onDialogLocationClose = (event, reason, buttonStr) => {
    if (reason !== "backdropClick") setDialogLocationOpen(false);
    if (buttonStr === "submit") loadData();
  };

  const onSortInfoChange = (value) => {
    // const newSortInfo = value ? { type: value.type, name: value.name, dir: value.dir } : value;
    // setGridState(Object.assign({}, gridState, { sortInfo: newSortInfo }));
    setGridState(Object.assign({}, gridState, { sortInfo: value }));
  };

  const onFilterValueChange = (value) => {
    // const newFilterValue = JSON.parse(JSON.stringify(value));
    // const newFilterValue = value ? [...value] : value;

    setGridState(Object.assign({}, gridState, { filterValue: value }));
  };

  const removeRow = useCallback(({ rowProps }) => {
    fetch(`/echoMe/removeLocation?id=${rowProps.data.id}`, {
      method: "GET",
      mode: "no-cors",
    })
      .then(async (response) => {
        const responseData = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (responseData && responseData.message) || response.statusText;
          return Promise.reject(error);
        }
        loadData();
        return true;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("There was an error!", error);
      });
  }, []);

  // React Data grid functions
  const onSelectionChange = useCallback(({ selected }) => {
    setChecked(selected);
  }, []);

  const onColumnOrderChange = (columnOrder) => {
    setGridState(Object.assign({}, gridState, { columnOrder }));
  };

  const onEditComplete = useCallback(() => {});

  const adjustRow = useCallback(({ rowProps }) => {
    setChecked({ [rowProps.data.id]: true });
    adjustLocation();
  }, []);

  const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
    menuProps.autoDismiss = true;
    menuProps.items = [
      {
        label: `adjust: ${rowProps.data[cellProps.id]}`,
        onClick: () => {
          adjustRow({ rowProps, cellProps });
        },
      },
      {
        label: `remove: ${rowProps.data[cellProps.id]}`,
        onClick: () => {
          removeRow({ rowProps, cellProps });
        },
      },
    ];
  };

  const onLimitChange = (newLimit) => {
    setGridState(Object.assign({}, gridState, { limit: newLimit }));
  };

  return (
    <div>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Toolbar
          style={{ minHeight: "10px" }}
          sx={(theme) => navbarContainer(theme)}
        >
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }}>
            <MDTypography variant="h6" color="white">
              Zone
            </MDTypography>
          </MDBox>

          <MDBox>
            <MDButton
              style={{ marginRight: 10 }}
              size="small"
              onClick={loadData}
            >
              Load data
            </MDButton>
            <MDButton
              style={{ marginRight: 10 }}
              size="small"
              disabled={Array.isArray(gridState.dataSource)}
              onClick={clearData}
            >
              Clear data
            </MDButton>
            <MDButton
              style={{ marginRight: 10 }}
              size="small"
              onClick={dialogAddClickHandler}
              // disabled={true}
            >
              New
            </MDButton>
            <MDButton
              style={{ marginRight: 10 }}
              size="small"
              // disabled={!checked}
              onClick={deleteLocation}
              // disabled={true}
            >
              Delete
            </MDButton>
          </MDBox>
        </Toolbar>
      </MDBox>
      <MDBox pt={3}>
        <ReactDataGrid
          licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
          idProperty="id"
          style={gridStyle}
          columns={gridState.columns}
          dataSource={gridState.dataSource}
          defaultGroupBy={[]}
          enableSelection
          multiSelect={false}
          selected={checked}
          checkboxColumn
          pagination
          pageSizes={[10, 50, 100]}
          limit={gridState.limit}
          onLimitChange={onLimitChange}
          onSelectionChange={onSelectionChange}
          onSortInfoChange={onSortInfoChange}
          onFilterValueChange={onFilterValueChange}
          onColumnOrderChange={onColumnOrderChange}
          // // editable
          onEditComplete={onEditComplete}
          // renderRowContextMenu={renderRowContextMenu}
        />
        <DialogZoneAdd
          open={openAddDialog}
          close={dialogAddCloseHandler}
          load={loadData}
          site={site}
        />
      </MDBox>
    </div>
  );
};

export default ZoneAsync;
