/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
import Card from "@mui/material/Card";
import BasicLayout from "pages/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { useEffect } from "react";

//  Linked to keycloak user login page
function Basic() {
  useEffect(() => {
    window.location.href =
      "https://keycloak.protek-asia.tech/realms/Protek/account/#/";
  }, []);

  return (
    <>
      <p>redirecting...</p>
    </>
  );
}
export default Basic;
