/* eslint-disable object-shorthand */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { Grid, TextField, Box } from "@mui/material";
import { useState } from "react";
import { printZpl } from "utility/printUtli";
import { genMultItemRfidTagZpl } from "utility/printUtli/itemLabelPrint";

// eslint-disable-next-line arrow-body-style
const DialogPartialPrintWithoutSerialRegistration = ({
  open,
  close,
  registerData,
}) => {
  const [expDate, setexpDate] = useState();
  const [qty, setQty] = useState();
  const { t } = useTranslation(["assetRegistration"]);

  // Input change Handler
  const expDateChangeHandler = (event) => {
    setexpDate(event.target.value);
  };

  const qtyChangeHandler = (event) => {
    setQty(event.target.value);
  };

  const handleClose = () => {
    //  Resetting the values
    setexpDate("");
    setQty("");
    close();
  };

  //  Button functions
  const submitHandler = async (event) => {
    event.preventDefault();
    if (!qty || qty <= 0) {
      alert(
        t(`assetReg.DialogPartialPrintRegistration.alert-nextButtonHandler1`)
      );
    } else if (qty <= registerData[0].quantity && qty >= 0) {
      //  fetching data
      const urlString = expDate
        ? `/echoMe/reg/genPartialRfid?expDateString=${expDate}&lineId=${registerData[0].id}&qty=${qty}`
        : `/echoMe/reg/genPartialRfid?lineId=${registerData[0].id}&qty=${qty}`;
      try {
        const data = await fetch(urlString);
        if (!data.ok) {
          console.log("error message from partial print with serial num");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        const json = await data.json();
        printZpl(
          genMultItemRfidTagZpl(
            json?.map((e) => {
              const {
                eanupc = "1111111111",
                description = "NA",
                style = "NA",
                color = "NA",
                size = "NA",
                skuCode = "NA",
                exp = "NA",
                serial = "NA",
                rfid = "SNoRfidFoundData",
                productCode = "NA",
              } = e;
              console.log(e);
              let ssc = `${style}/${size}/${color}`;
              return {
                barCode: eanupc,
                des: description,
                ssc: ssc,
                sku: skuCode,
                exp: exp,
                serial: serial,
                rfid: rfid,
                productCode: productCode,
              };
            })
          )
        );
        //  Resetting the values and close
        handleClose();
      } catch (err) {
        console.error(err);
        alert(err);
      }
    } else if (qty > registerData[0].quantity) {
      alert(
        t(`assetReg.DialogPartialPrintRegistration.alert-nextButtonHandler2`)
      );
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          {t(
            "assetReg.DialogPartialPrintWithoutSerialRegistration.header-name"
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("assetReg.DialogPartialPrintWithoutSerialRegistration.body")}
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <Grid container spacing={2} mt="5px">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    id="expDate-input"
                    name="expDate"
                    label={t(
                      "assetReg.DialogPartialPrintWithoutSerialRegistration.input-expDate"
                    )}
                    type="date"
                    fullWidth={true}
                    focused
                    onChange={expDateChangeHandler}
                    sx={{
                      input: {
                        color: "blue",
                      },
                      "& label.Mui-focused": {
                        color: "#000000",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#d2d6da",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    id="qty-input"
                    name="qty"
                    label={t(
                      "assetReg.DialogPartialPrintWithoutSerialRegistration.input-qty"
                    )}
                    type="number"
                    fullWidth={true}
                    onChange={qtyChangeHandler}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={submitHandler}
              style={{ color: "white", backgroundColor: "blue" }}
            >
              {t(
                "assetReg.DialogPartialPrintWithoutSerialRegistration.button-generate"
              )}
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={handleClose}
              style={{ color: "white", backgroundColor: "red" }}
            >
              {t(
                "assetReg.DialogPartialPrintWithoutSerialRegistration.button-close"
              )}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogPartialPrintWithoutSerialRegistration;
