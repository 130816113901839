/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable arrow-body-style */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Autocomplete,
  TextField,
} from "@mui/material";
import TargetEquipData from "./RegisterManageListData";
import CurrentEquipData from "./RegisterManageListData2";
import GenEquipmentdialog from "./DialogEquipmentGenInv";

const DialogEquipment = ({ open, close, currentContainerAssetCodes }) => {
  const [openEquipment, setOpenEquipment] = useState(false);
  const [containersCodeArray, setContainersCodeArray] = useState([]);
  const [containerCodeArray, setContainerCodeArray] = useState([]);
  const [currentEquipArrays, setCurrentEquipArrays] = useState([]);
  const [targetEquipArray, setTargetEquipArray] = useState([]);
  const [targetContainerCode, setTargetContainerCode] = useState("");
  const [targetContainerAssetCode, setTargetContainerAssetCode] = useState("");
  const [targetAssetCode, setTargetAssetCode] = useState("");
  const [currentAssetCodes, setCurrentAssetCodes] = useState("");
  const [currentContainerCodes, setCurrentContainerCodes] = useState("");
  const [value, setValue] = useState(containerCodeArray[0]);
  const [inputValue, setInputValue] = useState("");
  const [isNew, setIsNew] = useState(false);
  const [isMoved, setIsMoved] = useState(false);
  const [isMovedCurrent, setisMovedCurrent] = useState(false);
  const { t } = useTranslation(["inventory"]);

  //  Dialog Handler
  const dialogEquipmentClickHandler = () => {
    setOpenEquipment(true);
  };

  const dialogEquipmentCloseHandler = () => {
    setOpenEquipment(false);
  };

  //  fetching functions
  const loadContainerCode = async () => {
    try {
      const data = await fetch(`/echoMe/rfid/listAllRfidContainer`);
      if (!data.ok) {
        console.log("error message from loadContainerCode");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      let removeDup = _.uniqBy(json, "containerCode");

      setContainersCodeArray(removeDup);

      let temp = removeDup
        ?.filter((d) => d.containerAssetCode === currentContainerAssetCodes)
        ?.map((d) => d.containerCode);
      setCurrentContainerCodes(temp[0]);

      // Filtering containercode array for auto complete
      let temp2 = removeDup?.map((d) => d.containerCode);
      setContainerCodeArray(temp2);
    } catch (err) {
      alert(err);
    }
  };

  const loadTargetEquipment = async () => {
    try {
      const data = await fetch(
        `/echoMe/inv/getInventoryByContainerAssetCode?containerAssetCode=${targetContainerAssetCode}`
      );
      if (!data.ok) {
        console.log("error message from loadTargetEquipment");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      setTargetEquipArray(json);
    } catch (err) {
      setTargetEquipArray([]);
      setIsNew(false);
      alert(err);
    }
  };

  const loadCurrentsEquipment = async () => {
    try {
      const data = await fetch(
        `/echoMe/inv/getInventoryByContainerAssetCode?containerAssetCode=${currentContainerAssetCodes}`
      );
      if (!data.ok) {
        console.log("error message from loadCurrentsEquipment");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      setCurrentEquipArrays(json);
    } catch (err) {
      setCurrentEquipArrays([]);
      alert(err);
    }
  };

  // Fetch the necessary data whenever dialog opens
  useEffect(() => {
    if (open) {
      loadContainerCode();
      loadCurrentsEquipment();
    }
  }, [open]);

  // Button functions
  const handleChange = (event, newValue) => {
    if (newValue === null) {
      newValue = "";
      setValue(newValue);
    }
    setValue(newValue);
    setTargetContainerCode(newValue);
    //  filtering  containerAssetCode for fetching current Equipment table
    let tempTargetContainerAssetCode = containersCodeArray
      ?.filter((d) => d.containerCode === newValue)
      ?.map((d) => d.containerAssetCode);
    setTargetContainerAssetCode(tempTargetContainerAssetCode[0]);
  };

  const filterContainerAccessCode = () => {
    //  filtering  containerAssetCode for fetching current Equipment table
    // console.log({ containersCodeArray, targetContainerCode });
    let tempTargetContainerAssetCode = containersCodeArray
      ?.filter((d) => d.containerCode === targetContainerCode)
      ?.map((d) => d.containerAssetCode);
    setTargetContainerAssetCode(tempTargetContainerAssetCode[0]);
  };

  // getting assetCode for moving table from child components by prop drilling
  const getTargetContainerAssetCode = (assetCode) => {
    setTargetAssetCode(assetCode);
  };

  const getCurrentContainerAssetCodes = (containerAssetCode) => {
    setCurrentAssetCodes(containerAssetCode);
  };

  const getTargetContainerCodes = (containerCode) => {
    setTargetContainerCode(containerCode);
    setInputValue(containerCode);
    let tempTargetContainerAssetCode = containersCodeArray
      ?.filter((d) => d.containerCode === containerCode)
      ?.map((d) => d.containerAssetCode);
    setTargetContainerAssetCode(tempTargetContainerAssetCode[0]);
  };

  const newButtonHandler = () => {
    setTargetEquipArray([]);
    setTargetContainerAssetCode("");
    setTargetContainerCode("");
    setTargetAssetCode("");
    setInputValue("");
    setValue("");
    setIsNew(false);
  };

  const searchButtonHandler = async (event) => {
    if (currentContainerCodes === targetContainerCode) {
      alert(t(`inventory.DialogEquipment.alert-searchButtonHandler`));
    } else if (
      currentContainerCodes !== targetContainerCode &&
      targetContainerAssetCode !== undefined
    ) {
      loadTargetEquipment();
      setIsNew(true);
    } else if (targetContainerAssetCode === undefined) {
      loadContainerCode();
      filterContainerAccessCode();
    }
  };

  // Reset all varaibles
  const handleClose = () => {
    setContainersCodeArray([]);
    setTargetEquipArray([]);
    setTargetContainerAssetCode("");
    setTargetContainerCode("");
    setTargetAssetCode("");
    setCurrentEquipArrays([]);
    setCurrentAssetCodes("");
    setInputValue("");
    setValue("");
    setIsNew(false);
    close();
  };

  const targetMoveButtonHandler = async () => {
    try {
      const data = await fetch(
        `/echoMe/inv/changeEquipment?containerAssetCode=${currentContainerAssetCodes}&assetCodes=${targetAssetCode}`
      );
      if (!data.ok) {
        console.log("error message from targetMoveButtonHandler");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      setIsMoved(true);
      loadCurrentsEquipment();
      loadTargetEquipment();
      setIsMoved(false);
    } catch (err) {
      alert(err);
    }
  };

  const currentsMoveButtonHandler = async () => {
    try {
      const data = await fetch(
        `/echoMe/inv/changeEquipment?containerAssetCode=${targetContainerAssetCode}&assetCodes=${currentAssetCodes}`
      );
      if (!data.ok) {
        console.log("error message from currentMoveButtonHandler");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      setisMovedCurrent(true);
      loadCurrentsEquipment();
      loadTargetEquipment();
      setisMovedCurrent(false);
    } catch (err) {
      alert(err);
    }
  };

  // console.log({
  //   currentContainerAssetCodes,
  //   currentAssetCodes,
  //   currentEquipArrays,
  //   targetContainerAssetCode,
  //   targetContainerCode,
  //   targetAssetCode,
  //   containersCodeArray,
  // });
  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        fullWidth={true}
        maxWidth="xl"
        fullScreen={true}
      >
        <DialogTitle>
          {" "}
          {t("inventory.DialogEquipment.header-name")}{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left"></DialogContentText>
          <Box
            variant="div"
            sx={{ padding: "15px", border: "1px solid", borderRadius: "8px" }}
          >
            <Grid item xs={12} md={12}>
              <Paper
                elevation={8}
                sx={{
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px",
                }}
              >
                <Grid container spacing={2} mt="2px">
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h6" mt="5px">
                      {t("inventory.DialogEquipment.bodyText")}
                      <b style={{ color: "red" }}>{currentContainerCodes}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={containerCodeArray}
                      sx={{ width: 300 }}
                      size="small"
                      onChange={handleChange}
                      disabled={isNew}
                      inputValue={
                        !targetContainerCode ? inputValue : targetContainerCode
                      }
                      // inputValue={inputValue}
                      clearOnEscape
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t(
                            "inventory.DialogEquipment.input-targetContainerCode"
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {}
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      type="submit"
                      style={{ color: "white", backgroundColor: "blue" }}
                      onClick={loadCurrentsEquipment}
                    >
                      {t("inventory.DialogEquipment.button-refresh")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {}
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      type="submit"
                      style={{ color: "white", backgroundColor: "blue" }}
                      onClick={loadContainerCode}
                      disabled={isNew}
                    >
                      {t("inventory.DialogEquipment.button-refresh")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      type="submit"
                      style={{ color: "white", backgroundColor: "blue" }}
                      onClick={dialogEquipmentClickHandler}
                      disabled={isNew}
                    >
                      {t("inventory.DialogEquipment.button-new")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      type="submit"
                      style={{ color: "white", backgroundColor: "blue" }}
                      onClick={searchButtonHandler}
                      disabled={isNew}
                    >
                      {t("inventory.DialogEquipment.button-search")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      type="submit"
                      style={{ color: "white", backgroundColor: "red" }}
                      onClick={newButtonHandler}
                    >
                      {t("inventory.DialogEquipment.button-clear")}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} mt="5px">
              <Paper
                elevation={8}
                sx={{
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <Typography variant="h6" mt="5px">
                      {t(
                        "inventory.DialogEquipment.sectionHeader-currentEquip"
                      )}
                    </Typography>
                    <TargetEquipData
                      currentEquipArrays={currentEquipArrays}
                      getCurrentContainerAssetCodes={
                        getCurrentContainerAssetCodes
                      }
                      isMovedCurrent={isMovedCurrent}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth={true}
                      style={{ color: "white", marginTop: 250 }}
                      disabled={!currentAssetCodes || !isNew}
                      onClick={currentsMoveButtonHandler}
                    >
                      <Icon fontSize="large">east</Icon>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth={true}
                      style={{ color: "white", marginTop: 10 }}
                      disabled={!targetAssetCode}
                      onClick={targetMoveButtonHandler}
                    >
                      <Icon fontSize="large">west</Icon>
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Typography variant="h6" mt="5px">
                      {t("inventory.DialogEquipment.sectionHeader-targetEquip")}
                    </Typography>
                    <CurrentEquipData
                      data={targetEquipArray}
                      getTargetContainerAssetCode={getTargetContainerAssetCode}
                      isNew={isNew}
                      isMoved={isMoved}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={1}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={handleClose}
              style={{ color: "white", backgroundColor: "red" }}
            >
              {t("inventory.DialogEquipmentGenInv.button-close")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <GenEquipmentdialog
        // selected={JSON.stringify(selected)}
        open={openEquipment}
        close={dialogEquipmentCloseHandler}
        getTargetContainerCodes={getTargetContainerCodes}
        loadContainerCode={loadContainerCode}
      />
    </>
  );
};

export default DialogEquipment;
