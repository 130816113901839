/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-object-spread */
import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import { usePageVisibility } from "react-page-visibility";
import "@inovua/reactdatagrid-enterprise/index.css";
import DateFilter from "@inovua/reactdatagrid-enterprise/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import Toolbar from "@mui/material/Toolbar";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { navbarContainer } from "examples/Navbars/DashboardNavbar/styles";
import MDButton from "../MDButton";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import DialogStock from "./DialogStockInventory";
import DialogEquipment from "./DialogEquipment";
import DialogMovement from "./DialogMovementInventory";
import DialogBatchUpdate from "./DialogBatchUpdateInv";
import DialogBatchRegen from "./DialogBatchRegeneInv";
import UserService from "../../service/KeyCloakService";
import DialogSearchbyRFIDInv from "./DialogSearchbyRFIDInv";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

//  React data grid fields
const gridStyle = { marginTop: 10, minHeight: 530 };

const defaultFilterValue = [
  { name: "id", type: "number", operator: "eq", value: "" },
  { name: "productCode", type: "string", operator: "contains", value: "" },
  { name: "description", type: "string", operator: "contains", value: "" },
  { name: "assetCode", type: "string", operator: "contains", value: "" },
  { name: "style", type: "string", operator: "contains", value: "" },
  { name: "color", type: "string", operator: "contains", value: "" },
  { name: "size", type: "string", operator: "contains", value: "" },
  { name: "rfid", type: "string", operator: "contains", value: "" },
  { name: "eanupc", type: "string", operator: "contains", value: "" },
  { name: "coo", type: "string", operator: "contains", value: "" },
  { name: "expiryDate", type: "date", operator: "inrange", value: "" },
  { name: "equipmentId", type: "string", operator: "contains", value: "" },
  { name: "serial", type: "string", operator: "contains", value: "" },
  { name: "inboundDate", type: "date", operator: "inrange", value: "" },
  { name: "regNum", type: "string", operator: "contains", value: "" },
  { name: "tiNum", type: "string", operator: "contains", value: "" },
  { name: "toNum", type: "string", operator: "contains", value: "" },
  { name: "locCode", type: "string", operator: "contains", value: "" },
  { name: "checkpointCode", type: "string", operator: "contains", value: "" },
  { name: "lastLocCode", type: "string", operator: "contains", value: "" },
  {
    name: "lastCheckpointCode",
    type: "string",
    operator: "contains",
    value: "",
  },
];

const STATUS_SELECTION = [
  "RESERVED",
  "AVAILABLE",
  "SHIP_OUT",
  "TRANSFER_OUT",
  "INTRANSIT",
  "CANCELLED",
  "DISPOSED",
];

const onRenderRow = (rowProps) => {
  if (rowProps.data.status === "COMPLETED") {
    rowProps.style.color = "#7986cb";
  }

  if (rowProps.data.stockTakeLock === true) {
    rowProps.style.color = "#FF3333";
  }
};

// eslint-disable-next-line arrow-body-style
const InventoryAsync2 = () => {
  const { t } = useTranslation(["inventory"]);
  const defaultColumns = [
    {
      name: "id",
      header: "Inventory Id",
      defaultVisible: false,
      type: "number",
    },
    {
      name: "regNum",
      header: t(`inventory.Async.column-regNum`),
      defaultWidth: 150,
    },
    {
      name: "rfid",
      header: t(`inventory.DialogBatchRegeneInv.column-rfid`),
      defaultWidth: 150,
    },
    {
      name: "productCode",
      header: t(`inventory.Async.column-productCode`),
      defaultWidth: 150,
    },
    {
      name: "description",
      header: t(`inventory.Async.column-description`),
      defaultWidth: 200,
    },
    {
      name: "assetCode",
      header: t(`inventory.Async.column-assetCode`),
      defaultWidth: 200,
    },
    {
      name: "style",
      header: t(`inventory.Async.column-style`),
      defaultWidth: 150,
    },
    {
      name: "color",
      header: t(`inventory.Async.column-color`),
      defaultWidth: 150,
    },
    {
      name: "size",
      header: t(`inventory.Async.column-size`),
      defaultWidth: 130,
    },
    {
      name: "eanupc",
      header: t(`inventory.Async.column-eanupc`),
      defaultWidth: 150,
    },
    { name: "coo", header: t(`inventory.Async.column-coo`), defaultWidth: 150 },
    {
      name: "expiryDate",
      header: t(`inventory.Async.column-expiryDate`),
      minWidth: 200,
      enableColumnFilterContextMenu: false,
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) =>
        // for range and notinrange operators, the index is 1 for the after field
        ({
          dateFormat: "YYYY-MM-DD",
          cancelButton: false,
          highlightWeekends: false,
          // placeholder: index === 1 ? "Expiry date is before..." : "Expiry date is after...",
        }),
      // eslint-disable-next-line no-unused-vars
      render: ({ value, cellPros }) =>
        value === null ? "None" : moment(value).format("YYYY-MM-DD"),
    },
    {
      name: "serial",
      header: t(`inventory.Async.column-serial`),
      defaultWidth: 200,
    },
    {
      name: "status",
      header: t(`inventory.Async.column-status`),
      minWidth: 150,
      filterEditor: SelectFilter,
      enableColumnFilterContextMenu: false,
      render: ({ value, cellPros }) => value.toString()?.replaceAll("_", " "),
      filterEditorProps: {
        placeholder: "All",
        multiple: true,
        wrapMultiple: false,
        dataSource: STATUS_SELECTION?.map((c) => ({ id: c, label: c })),
      },
    },
    {
      name: "inboundDate",
      header: t(`inventory.Async.column-inboundDate`),
      minWidth: 200,
      enableColumnFilterContextMenu: false,
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) =>
        // for range and notinrange operators, the index is 1 for the after field
        ({
          dateFormat: "YYYY-MM-DD",
          cancelButton: false,
          highlightWeekends: false,
          // placeholder: index === 1 ? "Inbound date is before..." : "Inbound date is after...",
        }),
      // eslint-disable-next-line no-unused-vars
      render: ({ value, cellPros }) =>
        value === null ? "None" : moment(value).format("YYYY-MM-DD"),
    },
    {
      name: "tiNum",
      header: t(`inventory.Async.column-tiNum`),
      defaultWidth: 150,
    },
    {
      name: "toNum",
      header: t(`inventory.Async.column-toNum`),
      defaultWidth: 150,
    },
    {
      name: "locCode",
      header: t(`inventory.Async.column-locCode`),
      defaultWidth: 150,
    },
    {
      name: "checkpointCode",
      header: t(`inventory.Async.column-checkpointCode`),
      defaultWidth: 150,
    },
    {
      name: "lastLocCode",
      header: t(`inventory.Async.column-lastLocCode`),
      defaultWidth: 150,
    },
    {
      name: "lastCheckpointCode",
      header: t(`inventory.Async.column-lastCheckpointCode`),
      defaultWidth: 150,
    },
  ];
  const STORE = {
    columns: defaultColumns,
    limit: 10,
    sortInfo: { name: "assetCode", dir: 1, type: undefined },
    filterValue: defaultFilterValue,
    reservedViewportWidth: 0,
    columnOrder: ["assetCode", "skuCode", "locCode", "inboundDate"],
  };
  const reduxSiteCode = useSelector((state) => state.siteCode);
  const initialData = Object.assign({}, STORE, { dataSource: [] });
  const [gridState, setGridState] = useState(initialData);
  const [selected, setSelected] = useState({});
  const [regNumArray, setRegNumArray] = useState({});
  const [openEquipment, setOpenEquipment] = useState(false);
  const [openMovement, setOpenMovement] = useState(false);
  const [openBatchUpdate, setOpenBatchUpdat] = useState(false);
  const [openBatchRegen, set0penBatchRegen] = useState(false);
  const [openSearchByRFID, setOpenSearchByRFID] = useState(false);
  const storedLan = localStorage.getItem("i18nextLng");
  const [dialogStockOpen, setDialogStockOpen] = useState(false);
  const [newOrUpdate, setNewOrUpdate] = useState("");
  const storedSite = localStorage.getItem("siteCode");
  const navigate = useNavigate();
  const [gridRef, setGridRef] = useState(null);
  const [rfid, setRFID] = useState("");
  const [equipment, setEquipment] = useState("");
  const isVisible = usePageVisibility();
  const [openBackDrop, setOpenBackDrop] = useState(false);

  //  Dialog Handler
  const dialogEquipmentClickHandler = () => {
    setOpenEquipment(true);
  };

  const dialogEquipmentCloseHandler = () => {
    setOpenEquipment(false);
  };

  const dialogMovementClickHandler = () => {
    setOpenMovement(true);
  };

  const dialogMovementCloseHandler = () => {
    setOpenMovement(false);
  };
  const dialogBatchUpdateClickHandler = () => {
    setOpenBatchUpdat(true);
  };

  const dialogBatchUpdateCloseHandler = () => {
    setOpenBatchUpdat(false);
  };
  const dialogBatchRegenClickHandler = () => {
    set0penBatchRegen(true);
  };

  const dialogBatchRegenCloseHandler = () => {
    set0penBatchRegen(false);
  };

  const dialogSearchRFIDClickHandler = () => {
    setOpenSearchByRFID(true);
  };

  const dialogSearchRFIDCloseHandler = () => {
    setOpenSearchByRFID(false);
  };

  const backDrophandleClose = () => {
    setOpenBackDrop(false);
  };

  const backDrophandleOpen = async () => {
    setOpenBackDrop(true);
  };

  // It will trigger refreshing of the web if site is changed from other tab
  useEffect(() => {
    if (isVisible) {
      if (storedSite !== reduxSiteCode && reduxSiteCode && storedSite) {
        window.location.reload();
      }
    }
  }, [isVisible, reduxSiteCode, storedSite]);

  // Button functions
  const clearData = () => {
    setGridState(Object.assign({}, gridState, { dataSource: [] }));
  };

  const loadData = () => {
    clearData();
    const newDataSource = ({ skip, limit, sortInfo, groupBy, filterValue }) =>
      // A proxy setting in package.json to divert traffic to whalespod:8080
      fetch(
        // "/echoMe/inv/listInventory" +
        "/echoMe/inv/listInventoryContainer" +
          `?skip=${skip}&limit=${limit}&groupBy=${groupBy}&sortInfo=${JSON.stringify(
            sortInfo
          )}&filterBy=${JSON.stringify(filterValue)}`,
        { method: "GET", mode: "no-cors" }
      ).then((response) => {
        const totalCount = response.headers.get("X-Total-Count");
        const resultObj = response
          .json()
          .then((data) => ({ data, count: Number(totalCount) }));
        return resultObj;
      });
    setGridState(Object.assign({}, gridState, { dataSource: newDataSource }));
  };

  const loadbDataByRfid = (rfid) => {
    clearData();
    const newDataSource = ({ skip, limit, sortInfo, groupBy, filterValue }) =>
      // A proxy setting in package.json to divert traffic to whalespod:8080
      fetch(
        // "/echoMe/inv/listInventory" +
        `/echoMe/inv/getInventoryByRFID?rfid=${rfid}`,
        { method: "GET", mode: "no-cors" }
      ).then((response) => {
        const totalCount = response.headers.get("X-Total-Count");
        const resultObj = response
          .json()
          .then((data) => ({ data, count: Number(totalCount) }));
        return resultObj;
      });
    setGridState(Object.assign({}, gridState, { dataSource: newDataSource }));
  };

  const loadbDataByEquipment = (equipment) => {
    clearData();
    const newDataSource = ({ skip, limit, sortInfo, groupBy, filterValue }) =>
      // A proxy setting in package.json to divert traffic to whalespod:8080
      fetch(
        // "/echoMe/inv/listInventory" +
        `/echoMe/inv/getInventoryByEquipmentID?equipmentId=${equipment}`,
        { method: "GET", mode: "no-cors" }
      ).then((response) => {
        const totalCount = response.headers.get("X-Total-Count");
        const resultObj = response
          .json()
          .then((data) => ({ data, count: Number(totalCount) }));
        return resultObj;
      });
    setGridState(Object.assign({}, gridState, { dataSource: newDataSource }));
  };

  //  Load data first whenever page is load
  useEffect(() => {
    if (!storedSite) {
      alert(t(`inventory.Async.alert-location`));
      navigate("/dashboard");
    }
  }, [storedSite]);

  const registerNewStockData = () => {
    setNewOrUpdate("new");
    setDialogStockOpen(true);
  };

  const adjustStockData = () => {
    setNewOrUpdate("update");
    setDialogStockOpen(true);
  };

  // for changing language
  useEffect(() => {
    gridState.columns = [];
    gridState.columns = defaultColumns;
  }, [storedLan]);

  const onDialogStockClose = (event, reason, buttonStr) => {
    if (reason !== "backdropClick") setDialogStockOpen(false);
    if (buttonStr === "submit") loadData();
  };

  const exportData = async () => {
    backDrophandleOpen();
    fetch(
      "/echoMe/inv/exportInventory" +
        `?sortInfo=${JSON.stringify(
          gridState.sortInfo
        )}&filterBy=${JSON.stringify(gridState.filterValue)}`
    ).then((response) => {
      response.blob().then((blob) => {
        if (response?.ok === true) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "inventory.xlsx";
          a.click();
          backDrophandleClose();
        } else if (response?.ok === false) {
          alert("There is something wrong during processing data.");
          backDrophandleClose();
        }
      });
    });
  };

  //  React Data grid functions
  const toArray = (selected) => Object?.values(selected)?.map((id) => id);

  const getId = (selected) => Object?.keys(selected)?.map((id) => id * 1);

  const scrollTo = useCallback(
    (index) => {
      return () => {
        gridRef.current.scrollToColumn(1, { duration: 300 });
      };
    },
    [gridRef]
  );

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  // filtering regnum and remove duplicate to check if user select more than one record
  const filterdupRegNum = () => {
    let temp = [];
    toArray(selected)?.map((r) => temp.push(r.regNum));
    temp = _.uniqBy(temp);
    setRegNumArray(temp);
  };

  useEffect(() => {
    filterdupRegNum();
  }, [selected]);

  const onColumnOrderChange = (columnOrder) => {
    setGridState(Object.assign({}, gridState, { columnOrder }));
  };

  const onLimitChange = (newLimit) => {
    setGridState(Object.assign({}, gridState, { limit: newLimit }));
  };

  const onSortInfoChange = (value) => {
    // const newSortInfo = value ? { type: value.type, name: value.name, dir: value.dir } : value;
    // setGridState(Object.assign({}, gridState, { sortInfo: newSortInfo }));
    setGridState(Object.assign({}, gridState, { sortInfo: value }));
  };

  const onFilterValueChange = (value) => {
    // const newFilterValue = JSON.parse(JSON.stringify(value));
    // const newFilterValue = value ? [...value] : value;
    setGridState(Object.assign({}, gridState, { filterValue: value }));
  };

  const onEditComplete = useCallback(
    // eslint-disable-next-line no-unused-vars
    ({ value, columnId, rowId, data }) => {
      // fetch(`/echoMe/updateInvStockInline?id=${rowId}&columnId=${columnId}&value=${value}`, {
      fetch(
        `/echoMe/inv/updateInventoryInline?id=${rowId}&columnId=${columnId}&value=${value}`,
        {
          method: "GET",
          mode: "no-cors",
        }
      )
        .then(async (response) => {
          const responseData = await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error =
              (responseData && responseData.message) || response.statusText;
            return Promise.reject(error);
          }
          loadData();
          return true;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error("There was an error!", error);
        });
    },
    []
  );

  const adjustRow = useCallback(({ rowProps }) => {
    setSelected({ [rowProps.data.id]: true });
    adjustStockData();
  }, []);

  const removeRow = useCallback(({ rowProps }) => {
    // fetch(`/echoMe/removeInvStock?id=${rowProps.data.id}`, {
    fetch(`/echoMe/inv/removeInventory?id=${rowProps.data.id}`, {
      method: "GET",
      mode: "no-cors",
    })
      .then(async (response) => {
        const responseData = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (responseData && responseData.message) || response.statusText;
          return Promise.reject(error);
        }
        loadData();
        return true;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("There was an error!", error);
      });
  }, []);

  const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
    menuProps.autoDismiss = true;
    menuProps.items = [
      {
        label: `adjust: ${rowProps.data[cellProps.id]}`,
        onClick: () => {
          console.log({ rowProps, cellProps });
          adjustRow({ rowProps, cellProps });
        },
      },
      {
        label: `remove: ${rowProps.data[cellProps.id]}`,
        onClick: () => {
          removeRow({ rowProps, cellProps });
        },
      },
    ];
  };

  const getRFIDData = ({ rfid, equipment }) => {
    setRFID(rfid === null || rfid === undefined ? "" : rfid);
    setEquipment(
      equipment === null || equipment === undefined ? "" : equipment
    );
  };

  return (
    <div>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Toolbar
          style={{ minHeight: "10px" }}
          sx={(theme) => navbarContainer(theme)}
        >
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }}>
            <MDTypography variant="h6" color="white">
              {t("inventory.Async.header-name")}
            </MDTypography>
          </MDBox>
          <MDBox>
            {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETIN") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_TEAMREADER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={scrollTo(10)}
              >
                {t("inventory.Async.button-scrollToFirst")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETIN") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_TEAMREADER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={loadData}
              >
                {t("inventory.Async.button-loadData")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETIN") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={dialogSearchRFIDClickHandler}
              >
                {t("inventory.Async.button-RFIDEQUPMENT")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETIN") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_TEAMREADER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                disabled={Array.isArray(gridState.dataSource)}
                onClick={clearData}
              >
                {t("inventory.Async.button-clearData")}
              </MDButton>
            )}
            {/* <MDButton style={{ marginRight: 10 }} size="small" onClick={registerNewStockData}>
              Register New
            </MDButton> */}
            {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETIN") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_TEAMREADER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                // disabled={!Object.keys(selected).length > 0}
                disabled={toArray(selected).length < 1}
                onClick={() => {
                  if (toArray(selected)?.length === 1) {
                    adjustStockData();
                  } else {
                    alert(t(`inventory.Async.alert-adjustmentButton`));
                  }
                }}
              >
                {t("inventory.Async.button-adjustment")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETIN") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                // disabled={!Object.keys(selected).length > 0}
                disabled={toArray(selected).length < 1}
                onClick={() => {
                  if (toArray(selected)?.length === 1) {
                    dialogMovementClickHandler();
                  } else {
                    alert(t(`inventory.Async.alert-movementButton`));
                  }
                }}
              >
                {t("inventory.Async.button-movement")}
              </MDButton>
            )}
            {/* <MDButton
              style={{ marginRight: 10 }}
              size="small"
              onClick={dialogEquipmentClickHandler}
            >
              {t("inventory.Async.button-equipment")}
            </MDButton> */}
            {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETIN") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_PICKER1") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_BATCH_UPDATE"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                disabled={toArray(selected)?.length < 1}
                onClick={dialogBatchUpdateClickHandler}
              >
                {t("inventory.Async.button-batchUpdate")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETIN") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_BATCH_REGEN") ||
              UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                disabled={toArray(selected)?.length < 1}
                onClick={dialogBatchRegenClickHandler}
              >
                {t("inventory.Async.button-batchRegen")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETIN") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                disabled={Array?.isArray(gridState.dataSource)}
                onClick={exportData}
              >
                {t("inventory.Async.button-export")}
              </MDButton>
            )}
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={openBackDrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </MDBox>
        </Toolbar>
      </MDBox>
      <MDBox pt={3}>
        <ReactDataGrid
          licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
          idProperty="id"
          style={gridStyle}
          columns={gridState.columns}
          dataSource={gridState.dataSource}
          defaultGroupBy={[]}
          defaultFilterValue={defaultFilterValue}
          enableSelection
          selected={selected}
          checkboxColumn
          pagination
          pageSizes={[10, 50, 100]}
          limit={gridState.limit}
          onLimitChange={onLimitChange}
          onSelectionChange={onSelectionChange}
          onSortInfoChange={onSortInfoChange}
          onFilterValueChange={onFilterValueChange}
          onColumnOrderChange={onColumnOrderChange}
          onEditComplete={onEditComplete}
          // renderRowContextMenu={renderRowContextMenu}
          onRenderRow={onRenderRow}
          onReady={setGridRef}
        />
        <DialogStock
          newOrUpdate={newOrUpdate}
          open={dialogStockOpen}
          selected={getId(selected)[0]}
          onDialogClose={onDialogStockClose}
          load={loadData}
          selectedObject={toArray(selected)}
          setSelected={setSelected}
        />
        <DialogEquipment
          open={openEquipment}
          close={dialogEquipmentCloseHandler}
        />
        <DialogMovement
          open={openMovement}
          close={dialogMovementCloseHandler}
          selected={toArray(selected)[0]}
          load={loadData}
        />
        <DialogBatchUpdate
          open={openBatchUpdate}
          close={dialogBatchUpdateCloseHandler}
          selected={toArray(selected)}
          load={loadData}
          setSelected={setSelected}
        />
        <DialogBatchRegen
          open={openBatchRegen}
          close={dialogBatchRegenCloseHandler}
          regNum={regNumArray[0]}
          selected={toArray(selected)}
          load={loadData}
          setSelected={setSelected}
        />
        <DialogSearchbyRFIDInv
          open={openSearchByRFID}
          close={dialogSearchRFIDCloseHandler}
          getData={getRFIDData}
          loadRFID={loadbDataByRfid}
          loadEquip={loadbDataByEquipment}
        />
      </MDBox>
    </div>
  );
};

export default InventoryAsync2;
