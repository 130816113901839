/* eslint-disable no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { uniqBy, uniqWith } from "lodash";
import { useTranslation } from "react-i18next";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { useSelector, useDispatch } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import {
  Grid,
  TextField,
  Box,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { usersActions } from "../../store/userSlice";
import { printZpl } from "../../utility/printUtli";
import { genMultItemRfidTagZpl } from "../../utility/printUtli/itemLabelPrint";

const gridStyle = { minHeight: 200, minWidth: 400 };
// const columns = [{ name: "assetCode", header: "Asset Id", defaultFlex: 1, type: "string" }];
// eslint-disable-next-line arrow-body-style
const DialogHandlingException = ({
  open,
  close,
  selected,
  setChecked,
  loadData,
}) => {
  const [remark, setRemark] = useState("");
  const { t } = useTranslation(["trackTrace"]);
  const columns = [
    { name: "id", header: "Id", type: "number", defaultVisible: false },
    {
      name: "itemRfid",
      header: t(`trackTrace.Async.column-itemRfid`),
      defaultFlex: 2,
    },
    {
      name: "containerCode",
      header: t(`trackTrace.Async.column-containerCode`),
      defaultFlex: 1.5,
    },
    {
      name: "type",
      header: t(`trackTrace.Async.column-type`),
      type: "string",
      defaultFlex: 1.5,
    },
  ];

  //  Input change Handler
  const inputChangeHandler = (event) => {
    setRemark(event.target.value);
  };

  //  Button functions
  const submitHandler = async () => {
    const idArray = selected?.map((r) => r.id).join(",");
    try {
      const data = await fetch(
        `/echoMe/rfidTrack/updateRfidTrackReason?offset=false&reason=${remark}&rfidTrackIds=${idArray}`
      );
      if (!data.ok) {
        console.log("error message from regenRfidItem");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      setChecked(0);
      loadData();
      close();
    } catch (err) {
      console.error(err);
      alert(err);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true}>
        <DialogTitle>
          {t("trackTrace.DialogHandlingException.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("trackTrace.DialogHandlingException.body")}
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <Grid container spacing={2} mt="5px">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box>
                    <ReactDataGrid
                      licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                      idProperty="id"
                      style={gridStyle}
                      columns={columns}
                      dataSource={selected}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    margin="dense"
                    id="reason-input"
                    name="reason"
                    label={t("trackTrace.DialogHandlingException.input-remark")}
                    multiline
                    onChange={inputChangeHandler}
                    rows={2}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              style={{ color: "white", backgroundColor: "blue" }}
              onClick={submitHandler}
            >
              {t("trackTrace.DialogHandlingException.button-confirm")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={close}
              style={{ color: "white", backgroundColor: "red" }}
            >
              {t("trackTrace.DialogHandlingException.button-close")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogHandlingException;
