/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-fragments */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import CheckBox from "@inovua/reactdatagrid-community/packages/CheckBox";
import "@inovua/reactdatagrid-enterprise/index.css";
import DateFilter from "@inovua/reactdatagrid-enterprise/DateFilter";
import { useTranslation } from "react-i18next";

const gridStyle = { minHeight: 160 };

const sum = (a, b) => a + b;

const TransOutProcessListData1 = ({
  filteredData,
  getData,
  isDeleteButtonPress,
  setIsDeleteButtonPress,
}) => {
  const [showGroupColumn, setShowGroupColumn] = useState(true);
  const [selected, setSelected] = useState({});
  const { t } = useTranslation(["assetReturn"]);
  const columns = [
    {
      name: "rfid",
      header: t(`assetReturn.Async.DialogProcessRegistration-container`),
      defaultFlex: 2,
    },
    {
      name: "containerCode",
      header: t(`assetReturn.Async.DialogProcessRegistration-containerCode`),
      defaultFlex: 2,
      minWidth: 150,
      enableColumnFilterContextMenu: false,
    },
  ];
  // React Data Grid Fields
  // // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const toArray = (selected) => Object?.values(selected)?.map((id) => id);

  useEffect(() => {
    getData(toArray(selected));
  }, [selected]);

  useEffect(() => {
    if (isDeleteButtonPress) {
      setSelected({});
      setIsDeleteButtonPress(false);
    }
  }, [isDeleteButtonPress]);

  return (
    <div>
      <ReactDataGrid
        licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
        idProperty="rfid"
        checkboxColumn
        selected={selected}
        onSelectionChange={onSelectionChange}
        style={gridStyle}
        groupColumn={true}
        dataSource={filteredData}
        columns={columns}
      />
      <p>
        {t(`assetReturn.total`)}: {filteredData.length}
      </p>
    </div>
  );
};

export default TransOutProcessListData1;
