/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable prefer-const */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable no-return-assign */

import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import moment from "moment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Paper,
  MenuItem,
} from "@mui/material";
import Equipmentdialog from "./DialogEquipmentTransout";
import DialogRegisteredEquipment from "./DialogRegisteredEquipmentTransout";
import ManageDialog from "./DialogManageTransout";
import TransoutProcessListData from "./TransoutProcessListData";
import UserService from "../../service/KeyCloakService";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { t } from "i18next";
// // eslint-disable-next-line import/no-absolute-path
// eslint-disable-next-line arrow-body-style

//  React data grid fields
const gridStyle = { minHeight: 317 };

const DialogProcessTransout = ({
  open,
  close,
  selected3,
  setChecked,
  load,
  filterDocumentObject,
}) => {
  // Dialog Equipment data logic
  const [openEquipment, setOpenEquipment] = useState(false);
  const [openRegEquipment, setOpenRegEquipment] = useState(false);
  const [openManage, setOpenManage] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [isCleared, setIsCleared] = useState(true);
  const [isScanned, setIsScanned] = useState(false);
  const [isOneTimeReg, setIsOneTimeReg] = useState(false);
  const [isM2Scanning, setIsM2Scanning] = useState(false);
  const [selected, setSelected] = useState({});
  const [containerSelected, setContainerSelected] = useState({});
  const [containerRfidList, setContainerRfidList] = useState([]);
  const [dataArray, setdataArray] = useState([]);
  const [scannerArray, setScannerArray] = useState([]);
  const [regList, setRegList] = useState([]);
  const [containerCode, setContainerCode] = useState("");
  const [equipmentRFID, setEquipmentRFID] = useState("");
  const [scannerIP, setScannerIP] = useState("");
  const [containerAssetCode, setContainerAssetCode] = useState("");
  const [timerId, setTimerId] = useState("");
  const [reason, setReason] = useState("");
  const [remark, setRemark] = useState("");
  const { t } = useTranslation(["transferOut"]);
  const itemColumns = [
    {
      name: "rfid",
      header: t(`transferOut.Async.DialogProcessRegistration-rfid`),
      defaultFlex: 1,
      type: "string",
    },
    {
      name: "rsku",
      header: t(`transferOut.Async.DialogProcessRegistration-rsku`),
      defaultFlex: 1,
      type: "string",
    },
  ];
  const containerColumns = [
    {
      name: "rfid",
      header: t(`transferOut.Async.DialogProcessRegistration-container`),
      defaultFlex: 1,
      type: "string",
    },
    {
      name: "containerCode",
      header: t(`transferOut.Async.DialogProcessRegistration-containerCode`),
      defaultFlex: 1,
      type: "string",
    },
  ];

  const loadProductList = async () => {
    if (selected3) {
      const data = await fetch(
        `/echoMe/to/listTransferOutLine?toNum=${encodeURIComponent(
          selected3?.toNum
        )}`
      );
      const json = await data.json();
      setdataArray(json);
    }
  };

  //  Fetching product list
  useEffect(() => {
    loadProductList();
  }, [selected3]);

  // Setting up initial values
  useEffect(() => {
    if (selected3) {
      setReason(selected3?.reason);
      setRemark(selected3?.remark);
    }
  }, [selected3]);

  // React query responses
  const onSuccess = (data) => {
    console.log("Sucess", data);
  };

  const onError = (error) => {
    console.log(error);
  };

  // Fetching scanner List with reg num
  const getScannerList = async () => {
    const data = await fetch(
      `/echoMe/param/findAllActiveParamByCode?paramName=READER_IP`,
      {
        mode: "cors",
      }
    );
    const json = await data.json();
    setScannerArray(json);
    return json;
  };

  // Reat query -scanner list
  const {
    data: scannerListProcessTransOut,
    isFetching: isFetchingScannerListProcessTransOut,
    refetch: refetchScannerListProcessTransOut,
    isError: isErrorScannerListProcessTransOut,
  } = useQuery("transOut-getScannerList", getScannerList, {
    onSuccess,
    onError,
    // refetchInterval: 1000,
    // refetchIntervalInBackground: true,
    // refetchOnWindowFocus: true,
    enabled: false,
  });

  const toArray = (selected) => Object?.values(selected)?.map((id) => id);

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  // eslint-disable-next-line no-shadow
  const onContainerSelectionChange = useCallback(({ selected }) => {
    setContainerSelected(selected);
  }, []);

  // Fetching scanner list whenever dialog is opened
  useEffect(() => {
    if (selected3?.toNum) {
      refetchScannerListProcessTransOut();
    }
  }, [open]);

  //  Dialog Handler
  const dialogEquipmentClickHandler = () => {
    setOpenEquipment(true);
  };

  const dialogEquipmentCloseHandler = () => {
    setOpenEquipment(false);
  };

  const dialogManageClickHandler = () => {
    setOpenManage(true);
  };

  const dialogManageCloseHandler = () => {
    setOpenManage(false);
  };

  const dialogRegEquipmentClickHandler = () => {
    setOpenRegEquipment(true);
  };

  const dialogRegEquipmentCloseHandler = () => {
    setOpenRegEquipment(false);
  };

  const backDrophandleClose = () => {
    setOpenBackDrop(false);
  };

  const backDrophandleOpen = () => {
    setOpenBackDrop(true);
  };

  const handleChange = async (event) => {
    setScannerIP(event.target.value);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  //  Button functions
  const containerRfidDeleteButtonHandler = async () => {
    const tempDeletedArray = _.difference(
      containerRfidList,
      toArray(containerSelected)
    );
    setContainerRfidList(tempDeletedArray);

    // filtering container code rfid
    const filteredContainerCode = tempDeletedArray?.map((d) => d?.rfid);
    setEquipmentRFID(filteredContainerCode);
    setContainerSelected({});
  };

  const itemRFIDDeleteButtonHandler = async () => {
    const tempDeletedArray = _.difference(regList, Object?.values(selected));
    setRegList(tempDeletedArray);
    setSelected({});
  };

  const clearButtonHandler = async () => {
    if (scannerIP) {
      setIsM2Scanning(false);
      setRegList("");
      setEquipmentRFID("");
      setIsScanned(false);
      setContainerCode("");
      setIsOneTimeReg(false);
      setIsScanning(false);
      setContainerRfidList("");
      setContainerSelected({});
      setSelected({});
      setIsCleared(true);
    }
  };

  const handleCancel = () => {
    setContainerCode("");
    setEquipmentRFID("");
    setTimerId("");
    setContainerAssetCode("");
    setIsScanned(false);
    setIsOneTimeReg(false);
    setIsScanning(false);
    setScannerArray([]);
    setScannerIP("");
    setContainerRfidList([]);
    setRegList([]);
    setContainerSelected({});
    setSelected({});
    setIsM2Scanning(false);
    load();
    setIsCleared(true);
    setChecked(null);
    setRemark("");
    setReason("");
    close();
  };

  const filterContainerAssetCode = async (rfid) => {
    const data = await fetch(`/echoMe/rfid/getRfidTagContainer?rfids=${rfid}`);
    try {
      if (!data.ok) {
        console.error(
          "Container does not have Container Access code. Please try again."
        );
        return;
      }
      const json = await data.json();
      const a = json?.map((d) => d.containerAssetCode);
      setContainerAssetCode(a[0]);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    if (containerRfidList?.length === 1) {
      setTimeout(() => {
        filterContainerAssetCode(equipmentRFID);
      }, 1000);
    }
  }, [equipmentRFID, containerRfidList]);

  const startScanM2 = async () => {
    if (scannerIP) {
      setIsM2Scanning(true);
      backDrophandleOpen();
      try {
        const data = await fetch(
          // `http://192.168.1.141:8080/echoMe/rfid/getReaderData?ipAdd=${scannerIP}`,
          `echoMe/rfid/getReaderData?ipAdd=${scannerIP}`,
          // `/echoMe/rfid/getReaderData?ipAdd=dummyData`,
          {
            mode: "cors",
          }
        );
        if (!data.ok) {
          console.log("error message from submitHandler");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        setIsM2Scanning(false);
        const json = await data.json();

        // Filtering RFID for Display
        const filteredRegData = [];
        json
          ?.filter((d) => d?.rfid.includes("SAT"))
          .map((d) => filteredRegData.push(d));
        // If first time scanning
        if (_.isEmpty(regList)) {
          setRegList(filteredRegData);
        } else if (!_.isEmpty(regList)) {
          // If more than one time then accumulate result
          const temp = _.differenceBy(filteredRegData, regList, "rfid");
          const removeDupReg = _.uniqBy([...temp, ...regList], "rfid");
          setRegList(removeDupReg);
        }

        // Filtering Container RFID for Display
        const filteredContainerData = [];
        json
          ?.filter((d) => d?.rfid.includes("CAT"))
          .map((d) => filteredContainerData.push(d));
        // If first time scanning
        if (_.isEmpty(containerRfidList)) {
          setContainerRfidList(filteredContainerData);
        } else if (!_.isEmpty(containerRfidList)) {
          // If more than one time then accumulate result
          const temp = _.differenceBy(
            filteredContainerData,
            containerRfidList,
            "rfid"
          );
          const removeDupCon = _.uniqBy(
            [...temp, ...containerRfidList],
            "rfid"
          );
          setContainerRfidList(removeDupCon);
        }

        // filtering container code rfid (needed for the first case)
        const filteredContainerCode = filteredContainerData?.map(
          (d) => d?.rfid
        );

        //If container accesscode has one then filter container access code
        if (filteredContainerCode?.length === 1) {
          await filterContainerAssetCode(filteredContainerCode[0]);
          setEquipmentRFID(filteredContainerCode[0]);
        }

        setEquipmentRFID(filteredContainerCode);
        setIsScanning(false);
        setIsScanned(true);
        setIsCleared(false);
        backDrophandleClose();
      } catch (err) {
        console.error(err);
        backDrophandleClose();
        alert(err);
      }
    }
  };

  const registerEquipmentHandler = async () => {
    backDrophandleOpen();
    if (equipmentRFID) {
      const rfids = containerRfidList.map((d) => d?.rfid);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      try {
        const data = await fetch(`/echoMe/to/checkInContainer`, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            rfids,
            toNum: encodeURIComponent(selected3?.toNum),
          }),
        });
        if (!data.ok) {
          console.log("error message from registerContainers");
          let error = await data;
          let errorText = await data.text();
          throw Error(errorText);
        }
        const json = await data.json();
        // console.log(json);
        setContainerCode(json[0].containerCode);
        alert(
          t(`transferOut.DialogProcessTransout.alert-registerEquipmentHandler2`)
        );
        load();
        filterDocumentObject();
        backDrophandleClose();
      } catch (err) {
        alert(err);
        console.log(err);
        backDrophandleClose();
      }
    } else if (!equipmentRFID) {
      alert(
        t(`transferOut.DialogProcessTransout.alert-registerEquipmentHandler`)
      );
      backDrophandleClose();
    }
  };

  const closeButtonHandler = async () => {
    backDrophandleOpen();
    if (!containerAssetCode) {
      alert(t(`transferOut.DialogProcessTransout.alert-closeButtonHandler`));
      backDrophandleClose();
    } else if (containerAssetCode) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const rfids = regList.map((d) => d?.rfid);
      try {
        const data = await fetch(`/echoMe/to/checkInItems`, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            containerAssetCode,
            rfids,
            toNum: encodeURIComponent(selected3?.toNum),
          }),
        });
        if (!data.ok) {
          console.log("error message from registerItems");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        alert(t(`transferOut.DialogProcessTransout.alert-closeButtonHandler2`));
        setIsOneTimeReg(false);
        load();
        filterDocumentObject();
        clearButtonHandler();
        backDrophandleClose();
      } catch (err) {
        alert(err);
        setIsOneTimeReg(false);
        backDrophandleClose();
      }
    }
  };

  const completeButtonHandler = async () => {
    try {
      const data = await fetch(
        `/echoMe/to/transferOutComplete?toNum=${encodeURIComponent(
          selected3?.toNum
        )}`
      );
      if (!data.ok) {
        console.log("error message from transferOutComplete");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      // console.log(json);
      setChecked(null);
      handleCancel();
    } catch (err) {
      alert(err);
    }
  };

  const saveButtonHandler = async () => {
    if (selected3?.toNum && selected3?.site) {
      try {
        const data = await fetch(
          `/echoMe/to/updateRemark?reason=${reason}&remark=${remark}&site=${
            selected3?.site
          }&toNum=${encodeURIComponent(selected3?.toNum)}`,
          { method: "POST" }
        );
        if (!data.ok) {
          console.log("error message from transferOutComplete");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        const json = await data.json();
        // console.log(json);
        setChecked(null);
        handleCancel();
      } catch (err) {
        alert(err);
      }
    } else {
      alert("TO number or site code is missing.");
    }
  };

  const scanButtonHandler = async () => {
    startScanM2();
  };

  const oneButtonHandler = async () => {
    setIsOneTimeReg(true);
    await registerEquipmentHandler();
    await closeButtonHandler();
    setIsOneTimeReg(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        fullWidth={true}
        maxWidth="xl"
        fullScreen={true}
      >
        <DialogTitle>
          {t("transferOut.DialogProcessTransout.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left"></DialogContentText>
          {t("transferOut.DialogProcessTransout.bodytext-documentNumber")}
          <b style={{ color: "red" }}>{selected3?.toNum}</b>
          <Typography variant="h6" sx={{ float: "right", color: "orange" }}>
            {selected3?.status?.replaceAll("_", " ")}
          </Typography>
          <Box
            variant="div"
            sx={{ padding: "20px", border: "1px solid", borderRadius: "8px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="h6">
                        {t("transferOut.DialogProcessTransout.button-equip")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        sx={{ ml: "-8px" }}
                        style={{ color: "white", backgroundColor: "red" }}
                        onClick={containerRfidDeleteButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          isScanning ||
                          !isScanned ||
                          isOneTimeReg ||
                          !scannerIP ||
                          !containerRfidList?.length > 0 ||
                          toArray(containerSelected).length < 1 ||
                          isM2Scanning
                        }
                      >
                        {t("transferOut.DialogProcessTransout.button-delete")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        sx={{ ml: "-8px" }}
                        style={{ color: "white", backgroundColor: "red" }}
                        onClick={oneButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          isScanning ||
                          !isScanned ||
                          isOneTimeReg ||
                          !scannerIP ||
                          !equipmentRFID ||
                          !containerAssetCode ||
                          isM2Scanning ||
                          containerRfidList?.length > 1 ||
                          regList?.length <= 0
                        }
                      >
                        {t("transferOut.DialogProcessTransout.button-one")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        sx={{ ml: "-4px" }}
                        style={{ color: "white" }}
                        onClick={registerEquipmentHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          isScanning ||
                          !isScanned ||
                          isOneTimeReg ||
                          !scannerIP ||
                          !equipmentRFID ||
                          isM2Scanning ||
                          containerRfidList?.length < 0
                        }
                      >
                        {t(
                          "transferOut.DialogProcessTransout.button-regEquipment"
                        )}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        variant="contained"
                        fullWidth={true}
                        style={{ color: "white" }}
                        onClick={closeButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          isScanning ||
                          !isScanned ||
                          isOneTimeReg ||
                          !scannerIP ||
                          !containerAssetCode ||
                          isM2Scanning
                        }
                      >
                        {t("transferOut.DialogProcessTransout.button-regItem")}
                      </Button>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{ marginTop: "10px", marginLeft: "15px" }}
                      >
                        <ReactDataGrid
                          licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                          idProperty="rfid"
                          checkboxColumn
                          selected={containerSelected}
                          onSelectionChange={onContainerSelectionChange}
                          style={{ minHeight: 160 }}
                          groupColumn={true}
                          dataSource={containerRfidList}
                          columns={containerColumns}
                        />
                        <p>
                          {t(`transferOut.total`)}: {containerRfidList.length}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "row",
                    padding: "15px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <Grid item xs={12} sm={12}>
                        <Typography variant="h6">
                          {t(
                            "transferOut.DialogProcessTransout.sectionHeader-header"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                      {}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      {}
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Grid item xs={12} sm={12}>
                        <Button
                          variant="contained"
                          color="warning"
                          fullWidth={true}
                          onClick={dialogManageClickHandler}
                          style={{ color: "white", backgroundColor: "green" }}
                          disabled={isScanning || isM2Scanning}
                        >
                          {t("transferOut.DialogProcessTransout.button-manage")}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Grid item xs={12} sm={12}>
                        {(UserService.getClientRole().includes(
                          "ROLE_ECHOME_TRANOUT"
                        ) ||
                          UserService.getClientRole().includes(
                            "ROLE_ECHOME_SUPERUSER"
                          ) ||
                          UserService.getClientRole().includes(
                            "ROLE_ECHOME_SUPERVISORS"
                          ) ||
                          UserService.getClientRole().includes(
                            "ROLE_ECHOME_TEAMREADER"
                          ) ||
                          UserService.getClientRole().includes(
                            "ROLE_ECHOME_PICKER1"
                          )) && (
                          <Button
                            variant="contained"
                            color="warning"
                            fullWidth={true}
                            disabled={
                              selected3?.status.toLowerCase() === "completed" ||
                              selected3?.status.toLowerCase() === "cancelled" ||
                              selected3?.status.toLowerCase() ===
                                "registering" ||
                              isScanning ||
                              isM2Scanning
                            }
                            onClick={dialogEquipmentClickHandler}
                            style={{
                              color: "white",
                              backgroundColor: "green",
                            }}
                          >
                            {t(
                              "transferOut.DialogProcessTransout.button-equip"
                            )}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Grid item xs={12} sm={12}>
                        <Button
                          variant="contained"
                          color="warning"
                          fullWidth={true}
                          onClick={dialogRegEquipmentClickHandler}
                          style={{ color: "white", backgroundColor: "green" }}
                          disabled={isScanning || isM2Scanning}
                        >
                          {t(
                            "transferOut.DialogProcessTransout.button-regEquip"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          margin="dense"
                          id="from-input"
                          name="from"
                          label={t(
                            "transferOut.DialogProcessTransout.input-from"
                          )}
                          type="text"
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={true}
                          value={
                            selected3?.shipFromLocation?.replaceAll("_", " ") ??
                            ""
                          }
                        />
                        <TextField
                          margin="dense"
                          id="to-input"
                          name="to"
                          label={t(
                            "transferOut.DialogProcessTransout.input-to"
                          )}
                          type="text"
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={true}
                          value={selected3?.shipToLocation ?? ""}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          margin="dense"
                          id="type-input"
                          name="type"
                          label={t(
                            "transferOut.DialogProcessTransout.input-type"
                          )}
                          type="text"
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={true}
                          value={selected3?.shipType ?? "None"}
                        />

                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Reason
                          </InputLabel>
                          <Select
                            value={reason ?? ""}
                            label="Reason"
                            onChange={handleReasonChange}
                            variant="outlined"
                            sx={{ minHeight: "52px" }}
                          >
                            <MenuItem value={"DIRECT_TRANSFER_ORDER"}>
                              DIRECT TRANSFER ORDER
                            </MenuItem>
                            <MenuItem value={"NORMAL_TRANSFER_ORDER"}>
                              NORMAL TRANSFER ORDER
                            </MenuItem>
                            <MenuItem value={""}>None</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          margin="dense"
                          id="date-input"
                          name="date"
                          label={t(
                            "transferOut.DialogProcessTransout.input-date"
                          )}
                          type="text"
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={true}
                          value={
                            selected3?.createdDate === null
                              ? ""
                              : moment(selected3?.createdDate).format(
                                  "YYYY-MM-DD"
                                )
                          }
                        />
                        <TextField
                          margin="dense"
                          id="remark-input"
                          name="remark"
                          label={t(
                            "transferOut.DialogProcessTransout.input-remark"
                          )}
                          type="text"
                          fullWidth={true}
                          value={remark ?? ""}
                          onChange={(e) => {
                            setRemark(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "row",
                    padding: "15px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3}>
                        <Typography variant="h6">
                          {t(
                            "transferOut.DialogProcessTransout.sectionHeader-regList"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <FormControl fullWidth variant="standard">
                            <InputLabel id="simple-select-label">
                              {t(
                                "transferOut.DialogProcessTransout.dropDown-IP"
                              )}
                              {/* {scannerIP} */}
                            </InputLabel>
                            <Select
                              labelId="simple-select-label"
                              id="demo-simple-select"
                              label="Scanner IP"
                              onChange={handleChange}
                              value={scannerIP}
                              disabled={
                                selected3?.status.toLowerCase() ===
                                  "completed" ||
                                selected3?.status.toLowerCase() ===
                                  "cancelled" ||
                                isScanning ||
                                isOneTimeReg ||
                                !isCleared ||
                                isM2Scanning
                              }
                              // disabled={true}
                            >
                              {scannerArray?.map((scanner) => (
                                <MenuItem
                                  key={scanner.id}
                                  value={scanner.value}
                                >
                                  {`${scanner.code} - ${scanner.value}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={1.5}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          disabled={
                            selected3?.status.toLowerCase() === "completed" ||
                            selected3?.status.toLowerCase() === "cancelled" ||
                            isScanning ||
                            !scannerIP ||
                            isM2Scanning
                          }
                          style={{ color: "white" }}
                          onClick={scanButtonHandler}
                        >
                          {t("transferOut.DialogProcessTransout.button-scan")}
                        </Button>
                      </Grid>
                      {/* <Grid item xs={12} sm={1.5}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          style={{ color: "white", backgroundColor: "red" }}
                          disabled={
                            selected3?.status.toLowerCase() === "completed" ||
                            selected3?.status.toLowerCase() === "cancelled" ||
                            !isScanning ||
                            !scannerIP ||
                            isM2Scanning
                          }
                          onClick={stopScanButtonHandler}
                        >
                          {t("transferOut.DialogProcessTransout.button-stop")}
                        </Button>
                      </Grid> */}
                      <Grid item xs={12} sm={1.5}>
                        <Button
                          variant="contained"
                          fullWidth={true}
                          onClick={clearButtonHandler}
                          style={{
                            color: "white",
                            backgroundColor: "red",
                            marginRight: "-100px",
                          }}
                          disabled={
                            selected3?.status.toLowerCase() === "completed" ||
                            selected3?.status.toLowerCase() === "cancelled" ||
                            !scannerIP
                          }
                        >
                          {t("transferOut.DialogProcessTransout.button-clear")}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={1.5}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ color: "white", backgroundColor: "red" }}
                          fullWidth={true}
                          onClick={itemRFIDDeleteButtonHandler}
                          disabled={
                            selected3?.status.toLowerCase() === "completed" ||
                            selected3?.status.toLowerCase() === "cancelled" ||
                            isScanning ||
                            !isScanned ||
                            isOneTimeReg ||
                            !scannerIP ||
                            !regList?.length > 0 ||
                            Object?.values(selected).length < 1 ||
                            isM2Scanning
                          }
                        >
                          {t("transferOut.DialogProcessTransout.button-delete")}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <ReactDataGrid
                          licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                          idProperty="rfid"
                          checkboxColumn
                          // multiSelect={false}
                          selected={selected}
                          onSelectionChange={onSelectionChange}
                          style={gridStyle}
                          columns={itemColumns}
                          dataSource={regList}
                        />
                        <p>
                          {t(`transferOut.total`)}: {regList.length}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "row",
                    padding: "15px",
                    marginTop: "-70px",
                  }}
                >
                  <Grid item xs={12} sm={12} container>
                    <Grid item xs={11} sm={11}>
                      <Typography variant="h6">
                        {t(
                          "transferOut.DialogProcessTransout.sectionHeader-productDetails"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <Button
                        variant="contained"
                        color="warning"
                        fullWidth={true}
                        onClick={loadProductList}
                        style={{ color: "white", backgroundColor: "green" }}
                        disabled={isScanning || isM2Scanning}
                      >
                        {t(
                          "transferOut.DialogRegisteredEquipmentTransout.button-refresh"
                        )}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ marginTop: "10px" }}>
                      <TransoutProcessListData
                        filteredData={dataArray}
                        totalqty={selected3?.totalOrderQty}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={openBackDrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </DialogContent>
        <DialogActions>
          {(UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
            UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
            UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER") ||
            UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")) && (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={completeButtonHandler}
              disabled={
                selected3?.status.toLowerCase() === "completed" ||
                selected3?.status.toLowerCase() === "cancelled" ||
                isScanning ||
                isM2Scanning
              }
            >
              {t("transferOut.DialogProcessTransout.button-complete")}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={saveButtonHandler}
            disabled={
              selected3?.status.toLowerCase() === "completed" ||
              selected3?.status.toLowerCase() === "cancelled" ||
              isScanning ||
              isM2Scanning
            }
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red" }}
            disabled={isScanning || isM2Scanning}
            onClick={handleCancel}
          >
            {t("transferOut.DialogProcessTransout.button-close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Equipmentdialog
        // selected={JSON.stringify(selected)}
        open={openEquipment}
        close={dialogEquipmentCloseHandler}
        toNum={selected3?.toNum}
      />
      <ManageDialog
        // selected={JSON.stringify(selected)}
        open={openManage}
        close={dialogManageCloseHandler}
        shipNum={selected3?.toNum}
        selected3={selected3}
      />
      <DialogRegisteredEquipment
        // selected={JSON.stringify(selected)}
        open={openRegEquipment}
        close={dialogRegEquipmentCloseHandler}
        shipNum={selected3?.toNum}
        selected3={selected3}
      />
    </>
  );
};

export default DialogProcessTransout;
