/* eslint-disable array-callback-return */
/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { isInteger } from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Menu,
  MenuItem,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UserService from "../../../service/KeyCloakService";

// eslint-disable-next-line arrow-body-style
const ContainerRuleConfigDialogAdd = ({ open, close, load }) => {
  const [configName, setconfigName] = useState();
  const [statusArray, SetStatusArray] = useState(["On", "Off"]);
  const [selectedStatus, SetselectedStatus] = useState("");
  const { t } = useTranslation(["setting"]);

  // Change input handlers
  const configNameInputChangeHandler = (e) => {
    setconfigName(e.target.value.toUpperCase());
  };

  const handleChange = (event) => {
    SetselectedStatus(event.target.value);
    // Reassigning the values
    if (event.target.value?.toUpperCase() === "ON") {
      SetselectedStatus(0);
    } else if (event.target.value?.toUpperCase() === "OFF") {
      SetselectedStatus(1);
    }
  };

  // Button functions
  const handleClose = () => {
    setconfigName("");
    SetselectedStatus("");
    close();
  };

  const addRules = async () => {
    if (!configName || !_.isInteger(selectedStatus)) {
      alert(t(`setting.ContainerRuleConfigDialogAdd.alert-addRules`));
    } else {
      // If they enters the all fields
      const temp = { configName: configName, status: selectedStatus };
      const obj = JSON.stringify(temp);
      try {
        const data = await fetch(
          `/echoMe/conf/newSystemConfig?systemConfig=${obj}`
        );
        if (!data.ok) {
          console.log("error message from addRules");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        handleClose();
        load();
      } catch (err) {
        console.error(err);
        alert(err);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          {t("setting.ContainerRuleConfigDialogAdd.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("setting.ContainerRuleConfigDialogAdd.body")}
          </DialogContentText>
          <Divider />
          <Box variant="div" sx={{ padding: "20px" }}>
            <form>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={1}>
                  {}
                </Grid>
                <Grid item xs={12} sm={10}>
                  <TextField
                    required
                    fullWidth={true}
                    id="configName-input"
                    label={t(
                      "setting.ContainerRuleConfigDialogAdd.input-configName"
                    )}
                    type="text"
                    focused={true}
                    onChange={configNameInputChangeHandler}
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  {}
                </Grid>
                <Grid item xs={12} sm={1}>
                  {}
                </Grid>
                <Grid item xs={12} sm={10} mt="15px">
                  <Box>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="simple-select-label">
                        {t("setting.ContainerRuleConfigDialogAdd.input-status")}
                      </InputLabel>
                      <Select
                        labelId="simple-select-label"
                        id="demo-simple-select"
                        label={t(
                          "setting.ContainerRuleConfigDialogAdd.input-Status"
                        )}
                        onChange={handleChange}
                        defaultValue=""
                      >
                        {statusArray?.map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={1}>
                  {}
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "blue " }}
            onClick={addRules}
          >
            {t("setting.ContainerRuleConfigDialogAdd.button-add")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red " }}
            onClick={handleClose}
          >
            {t("setting.ContainerRuleConfigDialogAdd.button-close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContainerRuleConfigDialogAdd;
