import { Card, Grid } from "@mui/material";
import StockOutAsync from "components/StockOut/StockOut";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import UserService from "../../service/KeyCloakService";

//  Getting arrays of roles from keycloak and if user does not have then it will not grant access
function StockOut() {
  let contents;
  const navigate = useNavigate();
  if (
    UserService?.getClientRole()?.includes("ROLE_ECHOME_STOCKTAKE") ||
    UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERUSER")
  ) {
    contents = (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <StockOutAsync />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  } else {
    navigate("/dashboard");
  }
  return <>{contents}</>;
}

export default StockOut;
