/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import { Card, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import TransferInAsync from "components/TransferIn/TransferInAsync";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import UserService from "../../service/KeyCloakService";

//  Getting arrays of roles from keycloak and if user does not have then it will not grant access
function TransferIn() {
  let contents;
  const navigate = useNavigate();
  if (
    UserService?.getClientRole()?.includes("ROLE_ECHOME_TRANIN") ||
    UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERUSER") ||
    UserService?.getClientRole()?.includes("ROLE_ECHOME_STORES")
  ) {
    contents = (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <TransferInAsync />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  } else {
    navigate("/dashboard");
  }
  return <>{contents}</>;
}

export default TransferIn;
