/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Divider from "@mui/material/Divider";
import { Grid, TextField, Box, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { usersActions } from "../../store/userSlice";

// eslint-disable-next-line arrow-body-style
const DialogEquipment = ({ open, close }) => {
  const reduxSiteCode = useSelector((state) => state.siteCode);
  const [numEquipment, setNumEquipment] = useState();
  const [rfidNumPerEquip, setRfidNumPerEquip] = useState();
  const [prefix, setPrefix] = useState();
  const [locationArray, SetLocationArray] = useState([]);
  const [tempLocationArray, SetTempLocationArray] = useState([]);
  const storedSite = localStorage.getItem("siteCode");
  const dispatch = useDispatch();
  const { t } = useTranslation(["assetRegistration"]);

  // Input change Handler
  const numEquipmentChangeHandler = (event) => {
    setNumEquipment(event.target.value);
  };

  const rfidNumPerEquipChangeHandler = (event) => {
    setRfidNumPerEquip(event.target.value);
  };

  //  Loading the prefix for generate the inbound label
  const loadPrefix = async () => {
    try {
      // Mapping between reduxSiteCode and siteCode
      let result = tempLocationArray
        ?.filter((r) => r.siteCode === reduxSiteCode)
        ?.map((r) => r.id)[0];
      const data = await fetch(`/echoMe/prefix/listAllPrefixSequence`);
      if (!data.ok) {
        console.log("error message from loadPrefix");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      const temp = [];
      json?.filter((r) => r.site === result)?.map((r) => temp.push(r.prefix));
      // let finalRoute = _.uniqBy(temp, "id");
      SetLocationArray(temp);
    } catch (err) {
      console.error(err);
    }
  };

  //  fetch loadprefix when dialog is opened
  useEffect(() => {
    loadPrefix();
  }, [open]);

  // Data fetching functions
  const loadLocation = async () => {
    loadPrefix();
    try {
      const data = await fetch(`/echoMe/loc/listLocSite`);
      if (!data.ok) {
        console.log("error message from loadPrefix");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      const temp = [];
      json?.map((r) => {
        return temp.push({
          id: r.id,
          siteCode: r.siteCode,
        });
      });
      SetTempLocationArray(temp);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    loadLocation();
  }, [reduxSiteCode, storedSite]);

  //  Fetching the location code from redux
  useEffect(() => {
    dispatch(usersActions.updateSiteCode(storedSite));
  }, [storedSite]);

  //  Button Functions
  const handleClose = () => {
    //  Resetting the values
    setPrefix("");
    setNumEquipment("");
    setRfidNumPerEquip("");
    close();
  };

  const handleChange = async (event) => {
    setPrefix(event.target.value);
    try {
      const data = await fetch(
        `/echoMe/prefix?containerPrefix=${event.target.value}`
      );
      if (!data.ok) {
        console.log("error message from fetch Prefix");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
    } catch (err) {
      alert(err);
    }
  };

  const genEquRfidTagZpl = (rfid, barCode) => {
    return `CT~~CD,~CC^~CT~ ^XA ^MD25 ~TA000 ~JSN ^LT0 ^MNW ^MTT ^PON ^PMN ^LH0,0 ^JMA ^PR6,6 ~SD15 ^JUS ^LRN ^CI27 ^PA0,1,1,0 ^RS8,,,3 ^XZ ^XA ^MMT ^PW945 ^LL472 ^LS0 ^FPH,3^FT11,395^A0N,150,150^FB934,1,38,C^FH\\^CI28^FD${barCode}^FS^CI27 ^BY5,3,200^FT139,236^BCN,,N,N ^FH\\^FD>:${barCode}^FS ^RFW,H,1,2,1^FD4000^FS ^RFW,A,2,6,1^FD${rfid}^FS ^PQ1,0,1,Y ^XZ`;
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    if (!prefix) {
      alert(t(`assetReg.DialogEquipmentRegistration.alert-submitHandler1`));
    } else if (!numEquipment || !rfidNumPerEquip) {
      alert(t(`assetReg.DialogEquipmentRegistration.alert-submitHandler2`));
    } else if (prefix && numEquipment && rfidNumPerEquip) {
      //  fetching data
      try {
        const data = await fetch(
          `/echoMe/reg/genRfidContainer?containerQty=${numEquipment}&rfidQty=${rfidNumPerEquip}`
        );
        if (!data.ok) {
          console.log("error message from genRfidContainer");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        const json = await data.json();

        //  get rfid and barcode from json
        let zpl = json
          ?.map((e) => {
            return genEquRfidTagZpl(e.rfid, e.containerCode);
          })
          .join("");
        console.log(zpl);

        // Print dialog
        setTimeout(() => {
          let printWindow = window.open();
          printWindow.document.open("text/plain");
          printWindow.document.write(zpl);
          printWindow.document.close();
          printWindow.focus();
          printWindow.print();
          printWindow.close();
        });

        //  Resetting the values and close
        handleClose();
      } catch (err) {
        console.error(err);
        alert(err);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          {t("assetReg.DialogEquipmentRegistration.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("assetReg.DialogEquipmentRegistration.body")} <br />
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <form onSubmit={submitHandler}>
              <Grid container spacing={2} mt="5px">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={1}>
                    {}
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Box>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="simple-select-label">
                          {t(
                            "assetReg.DialogEquipmentRegistration.input-prefix"
                          )}
                        </InputLabel>
                        <Select
                          labelId="simple-select-label"
                          id="demo-simple-select"
                          label="Site"
                          onChange={handleChange}
                          defaultValue=""
                        >
                          {locationArray?.map((location) => (
                            <MenuItem key={location} value={location}>
                              {location}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    {}
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    {}
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      margin="dense"
                      id="numEquipment-input"
                      name="numEquipment"
                      label={t(
                        "assetReg.DialogEquipmentRegistration.input-numEquipment"
                      )}
                      type="number"
                      fullWidth={true}
                      InputProps={{ inputProps: { min: 1, max: 999 } }}
                      onChange={numEquipmentChangeHandler}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      margin="dense"
                      id="rfidNumPerEquip-input"
                      name="rfidNumPerEquip"
                      label={t(
                        "assetReg.DialogEquipmentRegistration.input-rfidNumPerEquip"
                      )}
                      type="number"
                      fullWidth={true}
                      InputProps={{ inputProps: { min: 1, max: 9 } }}
                      onChange={rfidNumPerEquipChangeHandler}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      onClick={loadPrefix}
                      style={{ color: "white", backgroundColor: "blue" }}
                    >
                      {t("assetReg.DialogEquipmentRegistration.button-refresh")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      type="submit"
                      style={{ color: "white", backgroundColor: "blue" }}
                    >
                      {t(
                        "assetReg.DialogEquipmentRegistration.button-generate"
                      )}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth={true}
                      onClick={handleClose}
                      style={{ color: "white", backgroundColor: "red" }}
                    >
                      {t("assetReg.DialogRegisteredEquipment.button-close")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>{}</DialogActions>
      </Dialog>
    </>
  );
};

export default DialogEquipment;
