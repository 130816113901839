/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import { useState } from "react";
import PartialPrintDialog from "./DialogPartialPrintRegistration";
import DialogPartialPrintWithoutSerialRegistration from "./DialogPartialPrintWithoutSerialRegistration";

// eslint-disable-next-line arrow-body-style
const DialogPartialPrintAskRegistration = ({ open, close, registerData }) => {
  const [openPartialPrint, setOpenPartialPrint] = useState(false);
  const [openPartialNoSerialPrint, setOpenPartialNoSerialPrint] =
    useState(false);
  const { t } = useTranslation(["assetRegistration"]);

  // Dialog Handler
  const dialogPartialClickHandler = () => {
    setOpenPartialPrint(true);
    close();
  };

  const dialogPartialCloseHandler = () => {
    setOpenPartialPrint(false);
  };

  const dialogPartialWithoutSerialClickHandler = () => {
    setOpenPartialNoSerialPrint(true);
    close();
  };

  const dialogPartialWithoutSerialCloseHandler = () => {
    setOpenPartialNoSerialPrint(false);
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          {t("assetReg.DialogPartialPrintAskRegistration.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            {t("assetReg.DialogPartialPrintAskRegistration.body")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={dialogPartialClickHandler}
              style={{ color: "white" }}
            >
              {t("assetReg.DialogPartialPrintAskRegistration.button-yes")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={dialogPartialWithoutSerialClickHandler}
              style={{ color: "white", backgroundColor: "red" }}
            >
              {t("assetReg.DialogPartialPrintAskRegistration.button-no")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <PartialPrintDialog
        open={openPartialPrint}
        close={dialogPartialCloseHandler}
        registerData={registerData}
      />
      <DialogPartialPrintWithoutSerialRegistration
        open={openPartialNoSerialPrint}
        close={dialogPartialWithoutSerialCloseHandler}
        registerData={registerData}
      />
    </>
  );
};

export default DialogPartialPrintAskRegistration;
