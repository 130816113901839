/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Grid,
  TextField,
  Box,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";

const gridStyle = { minHeight: 200 };
// const columns = [{ name: "assetCode", header: "Asset Id", defaultFlex: 1, type: "string" }];

const defaultFilterValue = [
  { name: "id", type: "string", operator: "eq", value: "" },
  { name: "siteCode", type: "string", operator: "contains", value: "" },
  { name: "locationCode", type: "string", operator: "contains", value: "" },
  { name: "description", type: "string", operator: "contains", value: "" },
];

// eslint-disable-next-line arrow-body-style
const DialogCreateStockTake = ({ open, close, selected2 }) => {
  const [selected, setSelected] = useState({});
  const [locationArray, setLocationArray] = useState("");
  const [selectedObject, setSelectedObject] = useState("");
  const { t } = useTranslation(["stockTake"]);
  const columns = [
    {
      name: "siteCode",
      header: "Site Code",
      defaultFlex: 1,
    },
    {
      name: "locationCode",
      header: t(`stockTake.DialogProcessStockOut.column-locCode`),
      defaultFlex: 1,
    },
    {
      name: "description",
      header: t(`stockTake.DialogCreateStockTake.column-description`),
      defaultFlex: 1,
    },
    { name: "id", header: "Id", type: "number", defaultVisible: false },
  ];
  //  fetching functions
  const loadLocation = async () => {
    try {
      const data = await fetch(`/echoMe/loc/listLocation`);
      if (!data.ok) {
        console.log("error message from loadLocation");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      setLocationArray(json);
    } catch (err) {
      alert(err);
    }
  };

  // Fetch data whenever dialog is opened
  useEffect(() => {
    loadLocation();
  }, [open]);

  //  Button functions
  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  //  Filtering document regNum with selected
  const filterDocumentObject = async () => {
    Object.values(locationArray)
      ?.filter((d) => d.id === selected)
      ?.map((d) => setSelectedObject(d));
  };

  useEffect(() => {
    filterDocumentObject();
  }, [selected]);

  const reCountButtonHandler = async () => {
    try {
      const data = await fetch(
        `/echoMe/stocktake/stocktakeRecountByLoc?locCode=${
          selectedObject?.locationCode
        }&stNum=${encodeURIComponent(encodeURIComponent(selected2?.stNum))}`
      );
      if (!data.ok) {
        console.log("error message from reCountButtonHandler");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      setSelected(0);
      close();
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth="sm" fullWidth={true}>
        <DialogTitle>
          {t("stockTake.DialogStockTakeRecount.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("stockTake.DialogStockTakeRecount.body")}
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <Grid container spacing={2} mt="5px">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box>
                    <ReactDataGrid
                      licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                      idProperty="id"
                      multiSelect={false}
                      checkboxColumn
                      selected={selected}
                      onSelectionChange={onSelectionChange}
                      style={gridStyle}
                      columns={columns}
                      dataSource={locationArray}
                      defaultFilterValue={defaultFilterValue}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              style={{ color: "white", backgroundColor: "blue" }}
              onClick={reCountButtonHandler}
            >
              {t("stockTake.DialogCreateStockTake.button-submit")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={close}
              style={{ color: "white", backgroundColor: "red" }}
            >
              {t("stockTake.DialogProcessStockOut.button-close")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogCreateStockTake;
