/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, TextField, Box } from "@mui/material";
import { useState } from "react";

// eslint-disable-next-line arrow-body-style
const DialogEquipmentTransout = ({ open, close, toNum }) => {
  const [rfidNumPerEquip, setRfidNumPerEquip] = useState();
  const { t } = useTranslation(["transferOut"]);

  const rfidNumPerEquipChangeHandler = (event) => {
    setRfidNumPerEquip(event.target.value);
  };

  const genEquRfidTagZpl = (rfid, deptNo, loadId, orderNo) => {
    return `^XA ^MMT ^PW709 ^LL650 ^LS0 ^FT50,480^A0N,92,91^FH\^CI28^FD${deptNo}^FS^CI27 ^FT50,596^A0N,92,91^FH\^CI28^FD${loadId}^FS^CI27 ^FT50,364^A0N,92,91^FH\^CI28^FD${orderNo}^FS^CI27 ^FT156,130^A0N,92,91^FH\^CI28^FD${toNum}^FS^CI27 ^RFW,H,1,2,1^FD4000^FS ^RFW,A,2,6,1^FD${rfid}^FS ^PQ1,,,Y ^XZ `;
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    if (rfidNumPerEquip && +rfidNumPerEquip > 0) {
      try {
        const data = await fetch(
          `/echoMe/to/genRfidContainer?toNum=${encodeURIComponent(
            toNum
          )}&rfidQty=${rfidNumPerEquip}`
        );

        // console.log(data);

        if (!data.ok) {
          console.log("error message from genRfidContainer");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }

        const json = await data.json();

        //  get rfid and barcode from json
        let zpl = json
          ?.map((e) => {
            return genEquRfidTagZpl(
              e.rfid,
              e.department,
              e.containerCode,
              e.deliveryOrderNum
            );
          })
          .join("");
        console.log(zpl);

        // Print dialog
        // Print dialog
        setTimeout(() => {
          let printWindow = window.open();
          printWindow.document.open("text/plain");
          printWindow.document.write(zpl);
          printWindow.document.close();
          printWindow.focus();
          printWindow.print();
          printWindow.close();
        });
      } catch (err) {
        console.error(err);
        alert(err);
      }
    } else {
      alert(t(`transferOut.DialogEquipmentTransout.alert-submitHandler2`));
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          {t("transferOut.DialogEquipmentTransout.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("transferOut.DialogEquipmentTransout.body")}
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <Grid container spacing={2} mt="5px">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  {}
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    margin="dense"
                    id="rfidNumPerEquip-input"
                    name="rfidNumPerEquip"
                    label={t(
                      `transferOut.DialogEquipmentTransout.alert-submitHandler2`
                    )}
                    type="number"
                    fullWidth={true}
                    onChange={rfidNumPerEquipChangeHandler}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              style={{ color: "white", backgroundColor: "blue" }}
              onClick={submitHandler}
            >
              {t("transferOut.DialogEquipmentTransout.button-generate")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={close}
              style={{ color: "white", backgroundColor: "red" }}
            >
              {t("transferOut.DialogEquipmentTransout.button-close")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogEquipmentTransout;
