/* eslint-disable no-unneeded-ternary */
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "App";
import "typeface-roboto";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import store from "./store/userSlice";
import keycloakAuth from "./keycloakAuthInstance";
import "./i18n";

const queryClient = new QueryClient();
// let contents;

// if (process.env.REACT_APP_HOST_ENV === "DEV") {
//   contents = <ReactQueryDevtools InitialIsOpen={false} position="bottom-right" />;
// } else {
//   contents = null;
// }

ReactDOM.render(
  <ReactKeycloakProvider
    initOptions={{
      onLoad: "login-required",
      redirectUri: `${window.location.origin}/${window.location.hash}`,
      responseMode: "query",
    }}
    authClient={keycloakAuth}
    onTokens={({ token }) => {
      // dispatch(setToken(token));
      localStorage.setItem("keycloakToken", token);
    }}
  >
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <MaterialUIControllerProvider>
            <App />
          </MaterialUIControllerProvider>
        </HashRouter>
        <ReactQueryDevtools InitialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </Provider>
  </ReactKeycloakProvider>,
  document.getElementById("root")
);
