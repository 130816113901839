import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles({
  Me: {
    color: "red",
  },
});

export default function EchoMe() {
  const classes = useStyles();

  return (
    <>
      <span>echo</span>
      <span className={classes.Me}>Me</span>
    </>
  );
}
