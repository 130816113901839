/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
import Keycloak from "keycloak-js";

let url =
  process.env.REACT_APP_HOST_ENV === "DEV"
    ? "https://keycloak.protek-asia.tech"
    : "https://atlrfid.dfs.com/auth";

// Keycloak server setting
const keycloakAuth = new Keycloak({
  url: url,
  realm: "Protek",
  clientId: "echoMe",
});

export default keycloakAuth;
