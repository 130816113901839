/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Grid,
  TextField,
  Box,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";

// eslint-disable-next-line arrow-body-style
const DialogSearchbyRFIDInv = ({
  open,
  close,
  getData,
  loadRFID,
  loadEquip,
}) => {
  const [valueRFID, setValueRFID] = useState();
  const [valueEquip, setValueEquip] = useState();
  const { t } = useTranslation(["inventory"]);

  // Input change Handler
  const rfidChangeHandler = (event) => {
    setValueRFID(event.target.value);
  };

  const equipmentChangeHandler = (event) => {
    setValueEquip(event.target.value);
  };

  //  Button Functions
  const handleClose = () => {
    //  Resetting the values
    setValueRFID("");
    setValueEquip("");
    close();
  };

  //  Button functions
  const submitHandler = async (event) => {
    event.preventDefault();
    if (!valueRFID && !valueEquip) {
      alert(t(`inventory.DialogSearchbyRFIDInv.alert-submitHandler`));
    } else if (valueRFID || valueEquip) {
      getData({ rfid: valueRFID, equipment: valueEquip });
      if (valueEquip) {
        loadEquip(valueEquip);
      } else if (valueRFID) {
        loadRFID(valueRFID);
      }
      handleClose();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth="sm" fullWidth={true}>
        <DialogTitle>{t("inventory.Async.button-RFIDEQUPMENT")}</DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("inventory.DialogSearchbyRFIDInv.body")}
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <form onSubmit={submitHandler}>
              <Grid container spacing={2} mt="5px">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      id="value-input"
                      name="value"
                      label={t("inventory.DialogSearchbyRFIDInv.rfid")}
                      type="string"
                      fullWidth={true}
                      onChange={rfidChangeHandler}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      id="value-input"
                      name="value"
                      label={t(
                        "inventory.DialogStockInventory.bodyButton-equipment"
                      )}
                      type="string"
                      fullWidth={true}
                      onChange={equipmentChangeHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              type="submit"
              style={{ color: "white", backgroundColor: "blue" }}
              onClick={submitHandler}
            >
              {t("inventory.DialogBatchUpdateInv.button-confirm")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={handleClose}
              style={{ color: "white", backgroundColor: "red" }}
            >
              {t("inventory.DialogBatchUpdateInv.button-close")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogSearchbyRFIDInv;
