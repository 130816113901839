/* eslint-disable arrow-body-style */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-object-spread */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import ConstructionIcon from "@mui/icons-material/Construction";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import Timer10Icon from "@mui/icons-material/Timer10";
import "@inovua/reactdatagrid-enterprise/index.css";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";
import { navbarContainer } from "examples/Navbars/DashboardNavbar/styles";
import Typography from "@mui/material/Typography";
import { Grid, Button, useMediaQuery, Paper } from "@mui/material";
import ScannerIcon from "@mui/icons-material/Scanner";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import PrefixDialog from "./Prefix/PrefixDialog";
import UserService from "../../service/KeyCloakService";
import ChangeLog from "./ChangeLog";
import ContainerRuleConfigDialog from "./ContainerRuleConfig/ContainerRuleConfigDialog";
import ScanConfigDialog from "./Scanner/ScannerConfigDialog";

const SysSetting = () => {
  const [open, setOpen] = useState(false);
  const [openPrefix, setOpenPrefix] = useState(false);
  const [openLog, setOpenLog] = useState(false);
  const [openSysCon, setOpenSysCon] = useState(false);
  const [openStartScanAsk, setOpenStartScanAsk] = useState(false);
  const storedSite = localStorage.getItem("siteCode");
  const [msg, setMsg] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation(["setting"]);

  // Dialog Handler
  const dialogPrefixClickHandler = () => {
    setOpenPrefix(true);
  };

  const dialogPrefixCloseHandler = () => {
    setOpenPrefix(false);
  };

  const dialogLogClickHandler = () => {
    setOpenLog(true);
  };

  const dialogLogCloseHandler = () => {
    setOpenLog(false);
  };

  const dialogSysConClickHandler = () => {
    setOpenSysCon(true);
  };

  const dialogSysConCloseHandler = () => {
    setOpenSysCon(false);
  };

  const dialogStartScanAskClickHandler = () => {
    setOpenStartScanAsk(true);
  };

  const dialogStartScanAskCloseHandler = () => {
    setOpenStartScanAsk(false);
  };

  // When site and description is missign then it will redirect to dashboard page
  useEffect(() => {
    if (!storedSite) {
      alert(t(`setting.Async.alert-location`));
      navigate("/dashboard");
    }
  }, [storedSite]);

  return (
    <div>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Toolbar
          style={{ minHeight: "10px" }}
          sx={(theme) => navbarContainer(theme)}
        >
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }}>
            <MDTypography variant="h6" color="white">
              {t("setting.Async.header-name")}
            </MDTypography>
          </MDBox>
        </Toolbar>
      </MDBox>
      <Grid item xs={12} sm={12}>
        <Typography variant="subtitle1" ml="30px">
          {t("setting.Async.bodyText")}
        </Typography>
        <Typography variant="subtitle2" mt="30px" align="center">
          {t("setting.Async.bodyText2")}
        </Typography>
      </Grid>
      <Box
        variant="div"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <MDBox maxWidth={1300} mt={4.5}>
          <Grid
            container
            spacing={8}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Grid item xs={12} md={6} lg={4}>
              <Paper
                mb={3}
                elevation={7}
                sx={{
                  height: "240px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  minWidth: "300px",
                }}
                onClick={() => {
                  if (
                    UserService.getClientRole().includes("ROLE_ECHOME_DEV") ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_SUPERUSER"
                    )
                  ) {
                    dialogLogClickHandler();
                  } else {
                    setOpen(true);
                    setMsg(t("setting.Async.alert-access"));
                    // alert("You do not have right to access this function.");
                  }
                }}
              >
                <ContentPasteIcon fontSize="large" />
                {/* <Typography>Asset Registration</Typography> */}
                <Typography align="center">
                  {t("setting.Async.button-changeLog")}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper
                mb={3}
                elevation={7}
                sx={{
                  height: "240px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  minWidth: "300px",
                }}
                onClick={() => {
                  if (
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_CONTAINER_ADD"
                    ) ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_CONTAINER_MODIFY"
                    ) ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_CONTAINER_VIEW"
                    ) ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_CONTAINER_MASTER"
                    ) ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_SUPERUSER"
                    )
                  ) {
                    dialogSysConClickHandler();
                  } else {
                    setOpen(true);
                    setMsg(t("setting.Async.alert-access"));
                    // alert("You do not have right to access this function.");
                  }
                }}
              >
                <ConstructionIcon fontSize="large" />
                {/* <Typography>Asset Inventory</Typography> */}
                <Typography align="center">
                  {t("setting.Async.button-containerRule")}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper
                mb={3}
                elevation={7}
                sx={{
                  height: "240px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  minWidth: "300px",
                  // marginLeft: "-150px",
                }}
                //  Getting arrays of roles from keycloak and if user does not have then it will not grant access
                onClick={() => {
                  if (
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_TRANOUT"
                    ) ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_SUPERUSER"
                    ) ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_SUPERVISORS"
                    ) ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_TEAMREADER"
                    ) ||
                    UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")
                  ) {
                    dialogPrefixClickHandler();
                  } else {
                    setOpen(true);
                    setMsg(t("setting.Async.alert-access"));
                    // alert("You do not have right to access this function.");
                  }
                }}
              >
                <SpellcheckIcon fontSize="large" />
                {/* <Typography>Transfer Out</Typography> */}
                <Typography align="center">
                  {t("setting.Async.button-inboundPrefix")}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper
                mb={3}
                elevation={7}
                sx={{
                  height: "240px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  minWidth: "300px",
                  // marginLeft: "-150px",
                }}
                //  Getting arrays of roles from keycloak and if user does not have then it will not grant access
                onClick={() => {
                  if (
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_TRANOUT"
                    ) ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_SUPERUSER"
                    ) ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_SUPERVISORS"
                    ) ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_TEAMREADER"
                    ) ||
                    UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")
                  ) {
                    dialogStartScanAskClickHandler();
                  } else {
                    setOpen(true);
                    setMsg(t("setting.Async.alert-access"));
                    // alert("You do not have right to access this function.");
                  }
                }}
              >
                <ScannerIcon fontSize="large" />
                {/* <Typography>Transfer Out</Typography> */}
                <Typography align="center">
                  {t("setting.Async.button-scanner")}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </MDBox>
      </Box>
      <Box sx={{ width: "100%", justifyContent: "center", display: "flex" }}>
        <Collapse in={open}>
          <Alert
            severity="error"
            variant="filled"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                  navigate("/dashboard");
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            <AlertTitle sx={{ marginTop: "-6px" }}>Error</AlertTitle>
            {msg}
          </Alert>
        </Collapse>
      </Box>
      <PrefixDialog open={openPrefix} close={dialogPrefixCloseHandler} />
      <ChangeLog open={openLog} close={dialogLogCloseHandler} />
      <ContainerRuleConfigDialog
        open={openSysCon}
        close={dialogSysConCloseHandler}
      />
      <ScanConfigDialog
        open={openStartScanAsk}
        close={dialogStartScanAskCloseHandler}
      />
    </div>
  );
};

export default SysSetting;
