/* eslint-disable no-extra-boolean-cast */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toLength } from "lodash";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { Grid, Typography, Box } from "@mui/material";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import ContainerRuleConfigDialogAdd from "./ContainerRuleConfigDialogAdd";
import UserService from "../../../service/KeyCloakService";
import ContainerRuleConfigDialogModify from "./ContainerRuleConfigDialogModify";

const gridStyle = { minHeight: 160 };

// eslint-disable-next-line arrow-body-style
const ContainerRuleConfigDialog = ({ open, close }) => {
  const [selected, setSelected] = useState({});
  const [openConfigAdd, setOpenConfigAdd] = useState(false);
  const [openConfigModify, setOpenConfigModify] = useState(false);
  const [rulesArray, setRulesArray] = useState([]);
  const [selectedObject, setSelectedObject] = useState();
  const { t } = useTranslation(["setting"]);

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  //  Filtering selected object which is needed for process screen
  const filterDocumentObject = async () => {
    rulesArray
      ?.filter((d) => d.id === selected)
      ?.map((d) => setSelectedObject(d));
  };

  useEffect(() => {
    filterDocumentObject();
  }, [selected]);

  // Dialog Handler
  const dialogConfigAddClickHandler = () => {
    setOpenConfigAdd(true);
  };

  const dialogConfigAddCloseHandler = () => {
    setOpenConfigAdd(false);
  };

  const dialogConfigModifyClickHandler = () => {
    setOpenConfigModify(true);
  };

  const dialogConfigModifyCloseHandler = () => {
    setOpenConfigModify(false);
  };

  // Data fetching functions
  const loadData = async () => {
    const temp = [];
    //  Fetching data
    try {
      const data = await fetch(`/echoMe/conf/listSystemConfig`);
      if (!data.ok) {
        console.log("error message from loadPrefix");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      json?.map((r) => {
        temp.push(r);
      });
      setRulesArray(temp);
    } catch (err) {
      console.error(err);
    }
  };

  //  Load data whenever dialog is opened
  useEffect(() => {
    loadData();
  }, [open]);

  // React data grid field
  const columns = [
    {
      name: "site",
      header: t(`setting.PrefixDialogAdd.input-site`),
      defaultFlex: 1,
      type: "string",
    },
    {
      name: "configName",
      header: t(`setting.PrefixDialogAdd.input-configName`),
      type: "number",
      defaultFlex: 1.5,
    },
    {
      name: "status",
      header: t(`setting.ContainerRuleConfigDialogAdd.input-status`),
      defaultFlex: 1,
      type: "Number",
    },
    { name: "id", header: "Id", type: "number", defaultVisible: false },
  ];

  // Button functions
  const removeRules = async () => {
    try {
      const data = await fetch(
        `/echoMe/conf/removeSystemConfig?id=${selected}`
      );
      if (!data.ok) {
        console.log("error message from removeRules");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      setSelected(null);
      loadData();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          {t("setting.ContainerRuleConfigDialog.header-name")}
        </DialogTitle>
        <DialogContent>
          <Box variant="div" sx={{ padding: "20px" }}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <ReactDataGrid
                    licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                    idProperty="id"
                    checkboxColumn
                    selected={selected}
                    multiSelect={false}
                    onSelectionChange={onSelectionChange}
                    style={gridStyle}
                    columns={columns}
                    dataSource={rulesArray}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          {(UserService.getClientRole().includes(
            "ROLE_ECHOME_CONTAINER_MODIFY"
          ) ||
            UserService.getClientRole().includes(
              "ROLE_ECHOME_CONTAINER_MASTER"
            ) ||
            UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER")) && (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", backgroundColor: "red " }}
              // disabled={!selected || selected === null || Object.keys(selected).length === 0}
              onClick={() => {
                if (_.toLength(selected) === 0) {
                  alert(t(`setting.ContainerRuleConfigDialog.alert-delete`));
                } else if (_.toLength(selected) > 0) {
                  removeRules();
                }
              }}
            >
              {t("setting.ContainerRuleConfigDialog.button-delete")}
            </Button>
          )}
          {(UserService.getClientRole().includes(
            "ROLE_ECHOME_CONTAINER_MODIFY"
          ) ||
            UserService.getClientRole().includes(
              "ROLE_ECHOME_CONTAINER_MASTER"
            ) ||
            UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER")) && (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", backgroundColor: "red " }}
              onClick={() => {
                if (_.toLength(selected) === 0) {
                  alert(t(`setting.ContainerRuleConfigDialog.alert-modify`));
                } else if (_.toLength(selected) > 0) {
                  dialogConfigModifyClickHandler();
                }
              }}
            >
              {t("setting.ContainerRuleConfigDialog.button-modify")}
            </Button>
          )}
          {(UserService.getClientRole().includes("ROLE_ECHOME_CONTAINER_ADD") ||
            UserService.getClientRole().includes(
              "ROLE_ECHOME_CONTAINER_MASTER"
            ) ||
            UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER")) && (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", backgroundColor: "blue " }}
              onClick={dialogConfigAddClickHandler}
            >
              {t("setting.ContainerRuleConfigDialog.button-add")}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "blue " }}
            onClick={loadData}
          >
            {t("setting.ContainerRuleConfigDialog.button-refresh")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "blue " }}
            onClick={close}
          >
            {t("setting.ContainerRuleConfigDialog.button-close")}
          </Button>
        </DialogActions>
        <ContainerRuleConfigDialogAdd
          open={openConfigAdd}
          close={dialogConfigAddCloseHandler}
          load={loadData}
        />
        <ContainerRuleConfigDialogModify
          open={openConfigModify}
          close={dialogConfigModifyCloseHandler}
          load={loadData}
          selected={selectedObject}
        />
      </Dialog>
    </>
  );
};

export default ContainerRuleConfigDialog;
