/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import Dashboard from "pages/dashboard";
import TransferIn from "pages/TransferIn";
import TransferOut from "pages/TransferOut";
import Notifications from "pages/notifications";
import SignOutB from "pages/authentication/sign-outbeta";
import Inventory from "pages/Inventory";
import TrackAndTrace from "pages/TrackAndTrace";
import Location from "pages/Location";
import LocProfile from "pages/LocProfile";
import Registration2 from "pages/Registration2";
import StockOut from "pages/StockOut";
import AssetReturn from "pages/Return";
import Setting from "pages/SystemSetting";
import Icon from "@mui/material/Icon";
import Account from "../pages/Account";

//  Basic route for all functions in sidenav.
const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    key: "divider1",
    type: "divider",
  },
  {
    type: "collapse",
    name: "Asset Registration",
    key: "registration",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/assetRegistration",
    component: <Registration2 />,
  },
  {
    type: "collapse",
    name: "Asset Inventory",
    key: "assetInventory",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/assetInventory",
    component: <Inventory />,
  },
  {
    type: "collapse",
    name: "Stock Take",
    key: "stocktake",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/stocktake",
    component: <StockOut />,
  },
  {
    type: "collapse",
    name: "Transfer Out",
    key: "transferOut",
    icon: <Icon fontSize="small">zoom_out_map</Icon>,
    route: "/transferOut",
    component: <TransferOut />,
  },
  {
    type: "collapse",
    name: "Transfer In",
    key: "transferIn",
    icon: <Icon fontSize="small">zoom_in_map</Icon>,
    route: "/transferIn",
    component: <TransferIn />,
  },
  {
    type: "collapse",
    name: "Asset Return",
    key: "assetReturn",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/assetReturn",
    component: <AssetReturn />,
  },
  {
    type: "collapse",
    name: "Track and Trace",
    key: "trackAndTrace",
    icon: <Icon fontSize="small">add_circle</Icon>,
    route: "/trackandtrace",
    component: <TrackAndTrace />,
  },
  {
    key: "divider2",
    type: "divider",
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Account />,
  },
  // {
  //   type: "collapse",
  //   name: "Location",
  //   key: "location",
  //   icon: <Icon fontSize="small">warehouse</Icon>,
  //   route: "/location",
  //   component: <Location />,
  // },
  {
    type: "collapse",
    name: "Location",
    key: "Location",
    icon: <Icon fontSize="small">location_searching</Icon>,
    route: "/location",
    component: <LocProfile />,
  },
  {
    type: "collapse",
    name: "Setting",
    key: "Setting",
    icon: <Icon fontSize="small">settings_applications</Icon>,
    route: "/setting",
    component: <Setting />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Log out",
    key: "log-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/sign-outbeta",
    component: <SignOutB />,
  },
];

export default routes;
