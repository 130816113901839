/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-object-spread */
import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { useNavigate } from "react-router-dom";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import { usePageVisibility } from "react-page-visibility";
import "@inovua/reactdatagrid-enterprise/index.css";
import Toolbar from "@mui/material/Toolbar";
import { navbarContainer } from "examples/Navbars/DashboardNavbar/styles";
import MDButton from "../MDButton";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import DialogProcess from "./DialogProcessTranIn";
import DateFilter from "@inovua/reactdatagrid-enterprise/DateFilter";
import moment from "moment";
import UserService from "../../service/KeyCloakService";
import EquipmentTransIn from "./DialogEquipmentTransIn";

//  React Data grid fields
const gridStyle = { marginTop: 10, minHeight: 530 };

const toArray = (selected) => Object.keys(selected)?.map((id) => id * 1);

const STATUS_SELECTION = [
  "IMPORTED",
  "RFID_TAG_PRINTED",
  "TRANSFER_IN_WIP",
  "COMPLETED",
  "ONHOLD",
  "CANCELLED",
];

const defaultFilterValue = [
  { name: "id", type: "number", operator: "eq", value: "" },
  { name: "deliveryOrderNum", type: "string", operator: "contains", value: "" },
  { name: "shipFromLocation", type: "string", operator: "contains", value: "" },
  { name: "shipToLocation", type: "string", operator: "contains", value: "" },
  { name: "status", type: "select", operator: "in", value: "" },
  { name: "createdDate", type: "date", operator: "inrange", value: "" },
  { name: "modifiedDate", type: "date", operator: "inrange", value: "" },
  { name: "tiNum", type: "string", operator: "contains", value: "" },
];

const onRenderRow = (rowProps) => {
  if (rowProps.data.status === "COMPLETED") {
    rowProps.style.color = "#7986cb";
  }
};

// eslint-disable-next-line arrow-body-style
const TransferInAsync = () => {
  const { t } = useTranslation(["transferIn"]);
  const defaultColumns = [
    {
      name: "id",
      header: "Inventory Id",
      defaultVisible: false,
      type: "number",
    },
    {
      name: "deliveryOrderNum",
      header: t(`transferIn.Async.column-deliveryOrderNum`),
      defaultFlex: 1,
      type: "string",
    },
    {
      name: "shipFromLocation",
      header: t(`transferIn.Async.column-shipFromLocation`),
      defaultFlex: 1,
      type: "string",
      render: ({ value, cellPros }) => value?.replaceAll("_", " "),
    },
    {
      name: "shipToLocation",
      header: t(`transferIn.Async.column-shipToLocation`),
      defaultFlex: 1,
      type: "string",
    },
    {
      name: "createdDate",
      header: t(`transferIn.Async.column-createdDate`),
      enableColumnFilterContextMenu: false,
      defaultFlex: 1,
      minWidth: 200,
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) =>
        // for range and notinrange operators, the index is 1 for the after field
        ({
          dateFormat: "YYYY-MM-DD",
          cancelButton: false,
          highlightWeekends: false,
          // placeholder: index === 1 ? "Create date is before..." : "Create date is after...",
        }),
      // eslint-disable-next-line no-unused-vars
      render: ({ value, cellPros }) =>
        value === null ? "None" : moment(value).format("YYYY-MM-DD"),
    },
    {
      name: "modifiedDate",
      header: t(`transferIn.Async.column-modifiedDate`),
      enableColumnFilterContextMenu: false,
      defaultFlex: 1,
      minWidth: 200,
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) =>
        // for range and notinrange operators, the index is 1 for the after field
        ({
          dateFormat: "YYYY-MM-DD",
          cancelButton: false,
          highlightWeekends: false,
          // placeholder: index === 1 ? "Modified date is before..." : "Modified date is after...",
        }),
      // eslint-disable-next-line no-unused-vars
      render: ({ value, cellPros }) =>
        value === null ? "None" : moment(value).format("YYYY-MM-DD"),
    },
    {
      name: "status",
      header: t(`transferIn.Async.column-status`),
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
      filterEditor: SelectFilter,
      render: ({ value, cellPros }) => value?.replaceAll("_", " "),
      filterEditorProps: {
        placeholder: "All",
        multiple: true,
        wrapMultiple: false,
        dataSource: STATUS_SELECTION?.map((c) => ({ id: c, label: c })),
      },
    },
    {
      name: "tiNum",
      header: t(`transferIn.Async.column-tiNum`),
      type: "string",
      defaultFlex: 1,
      render: ({ value, cellPros }) => value?.replaceAll("_", " "),
    },
  ];

  const STORE = {
    columns: defaultColumns,
    limit: 10,
    sortInfo: { name: "assetCode", dir: 1, type: undefined },
    filterValue: defaultFilterValue,
    reservedViewportWidth: 0,
    columnOrder: ["assetCode", "productCode", "currLocCode", "prevLocCode"],
  };
  const initialData = Object.assign({}, STORE, { dataSource: [] });
  const [gridState, setGridState] = useState(initialData);
  const [selected, setSelected] = useState(0);
  const [openProcess, setOpenProcess] = useState(false);
  const [openEquipment, setOpenEquipment] = useState(false);
  const [selectedDocumentNum, setSelectedDocumentNum] = useState();
  const [selectedObject, setSelectedObject] = useState();
  const storedSite = localStorage.getItem("siteCode");
  const navigate = useNavigate();
  const reduxSiteCode = useSelector((state) => state.siteCode);
  const isVisible = usePageVisibility();
  const storedLan = localStorage.getItem("i18nextLng");
  const defaultSortInfo = { name: "createdDate", dir: -1 };

  //  Dialog Handler
  const dialogProcessClickHandler = () => {
    setOpenProcess(true);
  };

  const dialogProcessCloseHandler = () => {
    setOpenProcess(false);
  };

  const dialogEquipClickHandler = () => {
    setOpenEquipment(true);
  };

  const dialogEquipCloseHandler = () => {
    setOpenEquipment(false);
  };

  // Load the data
  const clearData = () => {
    setGridState(Object.assign({}, gridState, { dataSource: [] }));
  };

  const loadData = () => {
    clearData();
    const newDataSource = ({ skip, limit, sortInfo, groupBy, filterValue }) =>
      // A proxy setting in package.json to divert traffic to whalespod:8080
      fetch(
        // "/echoMe/listInvStock" +
        "/echoMe/ti/listTransferInHeader" +
          `?skip=${skip}&limit=${limit}&groupBy=${groupBy}&sortInfo=${JSON.stringify(
            sortInfo
          )}&filterBy=${JSON.stringify(filterValue)}`,
        { method: "GET", mode: "no-cors" }
      ).then((response) => {
        const totalCount = response.headers.get("X-Total-Count");
        const resultObj = response
          .json()
          .then((data) => ({ data, count: Number(totalCount) }));

        return resultObj;
      });
    setGridState(Object.assign({}, gridState, { dataSource: newDataSource }));
  };

  useEffect(() => {
    if (storedSite) {
      loadData();
    } else {
      alert(t(`transferIn.Async.alert-location`));
      navigate("/dashboard");
    }
  }, [storedSite, reduxSiteCode, isVisible]);

  // for changing language
  useEffect(() => {
    gridState.columns = [];
    gridState.columns = defaultColumns;
  }, [storedLan]);

  // Filtering tiNum
  const filterDocumentId = async () => {
    // const data = await fetch(`/echoMe/listDocument`);
    const data = await fetch(`/echoMe/ti/listTransferInHeader`);
    const json = await data.json();
    // console.log(json);
    json
      ?.filter((d) => d.id === selected)
      ?.map((d) => setSelectedDocumentNum(d.tiNum));
  };

  // Filtering selected object for process screen
  const filterDocumentObject = async () => {
    // const data = await fetch(`/echoMe/listDocument`);
    const data = await fetch(`/echoMe/ti/listTransferInHeader`);
    const json = await data.json();
    json?.filter((d) => d.id === selected)?.map((d) => setSelectedObject(d));
  };

  useEffect(() => {
    filterDocumentId();
    filterDocumentObject();
  }, [selected]);

  // Button functions
  const myFunc = () => {
    // eslint-disable-next-line no-console
    console.log("Print the state here");
  };

  const transfer = () => {
    // eslint-disable-next-line no-console
    console.log(`${JSON.stringify(toArray(selected))}`);
    fetch(`/echoMe/transferIn?ids=${JSON.stringify(toArray(selected))}`, {
      method: "GET",
      mode: "no-cors",
    })
      .then(async (response) => {
        const responseData = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (responseData && responseData.message) || response.statusText;
          return Promise.reject(error);
        }
        loadData();
        return true;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("There was an error!", error);
      });
  };

  //  React data grid functions
  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const onColumnOrderChange = (columnOrder) => {
    setGridState(Object.assign({}, gridState, { columnOrder }));
  };

  const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
    menuProps.autoDismiss = true;
    menuProps.items = [
      {
        label: `myFunc: ${rowProps.data[cellProps.id]}`,
        onClick: () => {
          myFunc({ rowProps, cellProps });
        },
      },
    ];
  };

  const onLimitChange = (newLimit) => {
    setGridState(Object.assign({}, gridState, { limit: newLimit }));
  };

  const onSortInfoChange = (value) => {
    // const newSortInfo = value ? { type: value.type, name: value.name, dir: value.dir } : value;
    // setGridState(Object.assign({}, gridState, { sortInfo: newSortInfo }));
    setGridState(Object.assign({}, gridState, { sortInfo: value }));
  };

  const onFilterValueChange = (value) => {
    // const newFilterValue = JSON.parse(JSON.stringify(value));
    // const newFilterValue = value ? [...value] : value;
    setGridState(Object.assign({}, gridState, { filterValue: value }));
  };

  return (
    <div>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Toolbar
          style={{ minHeight: "10px" }}
          sx={(theme) => navbarContainer(theme)}
        >
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }}>
            <MDTypography variant="h6" color="white">
              {t("transferIn.Async.header-name")}
            </MDTypography>
          </MDBox>

          <MDBox>
            <MDButton
              style={{ marginRight: 10 }}
              size="small"
              onClick={loadData}
            >
              {t("transferIn.Async.button-loadData")}
            </MDButton>
            <MDButton
              style={{ marginRight: 10 }}
              size="small"
              disabled={Array.isArray(gridState.dataSource)}
              onClick={clearData}
            >
              {t("transferIn.Async.button-clearData")}
            </MDButton>
            {(UserService.getClientRole().includes("ROLE_ECHOME_TRANIN") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes("ROLE_ECHOME_STORES") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_SUPERUSER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                disabled={
                  selected === null || selected === true || selected === 0
                }
                onClick={dialogProcessClickHandler}
              >
                {t("transferIn.Async.button-process")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_TRANIN") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes("ROLE_ECHOME_STORES") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_SUPERUSER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                // disabled={selected === null || selected === true || selected === 0}
                // disabled={true}
                onClick={dialogEquipClickHandler}
              >
                {t("transferIn.Async.button-equipment")}
              </MDButton>
            )}
            {/* <MDButton style={{ marginRight: 10 }} size="small" onClick={debugMe}>
              Debug
            </MDButton> */}
          </MDBox>
        </Toolbar>
      </MDBox>
      <MDBox pt={3}>
        <ReactDataGrid
          licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
          idProperty="id"
          style={gridStyle}
          columns={gridState.columns}
          dataSource={gridState.dataSource}
          defaultGroupBy={[]}
          defaultFilterValue={defaultFilterValue}
          enableSelection
          selected={selected}
          multiSelect={false}
          checkboxColumn
          pagination
          pageSizes={[10, 50, 100]}
          limit={gridState.limit}
          onLimitChange={onLimitChange}
          onSelectionChange={onSelectionChange}
          onSortInfoChange={onSortInfoChange}
          onFilterValueChange={onFilterValueChange}
          onColumnOrderChange={onColumnOrderChange}
          // // editable
          // onEditComplete={onEditComplete}
          // renderRowContextMenu={renderRowContextMenu}
          onRenderRow={onRenderRow}
          defaultSortInfo={defaultSortInfo}
        />
      </MDBox>
      <DialogProcess
        // selected={JSON.stringify(selected)}
        open={openProcess}
        close={dialogProcessCloseHandler}
        selected2={selectedDocumentNum}
        selected3={selectedObject}
        setSelect={setSelected}
        load={loadData}
      />
      <EquipmentTransIn
        // selected={JSON.stringify(selected)}
        open={openEquipment}
        close={dialogEquipCloseHandler}
      />
    </div>
  );
};
export default TransferInAsync;
