/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
// @mui icons
import Icon from "@mui/material/Icon";
import { makeStyles } from "@mui/styles";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MDButton from "./MDButton";
import stateInventory from "./Registration/StateInventory";

const useStyles = makeStyles({
  customizedButton: {
    position: "absolute",
    right: 8,
    // top: "-9%",
    backgroundColor: "lightgray",
    color: "gray",
  },
});

const defaultValuesInvTrans = {
  transId: 0,
  id: 0,
  skuCode: "",
  assetCode: "",
  productCode: "",
  description: "",
  quantity: 0,
  currLocCode: "",
  prevLocCode: "",
  status: 0,
  createdDate: "",
  modifiedDate: "",
  reason: "",
};

const DialogTransit = ({ open, onDialogClose, selected }) => {
  const classes = useStyles();
  const [invTrans, setInvTrans] = useState(defaultValuesInvTrans);

  const onDiaLogTransitClose = (event, reason) => {
    onDialogClose(event, reason, undefined);
  };

  const handleCancel = () => {
    onDialogClose(undefined, undefined, "cancel");
  };
  const handleSubmit = () => {
    fetch(`/echoMe/updateInvTrans?invTrans=${JSON.stringify(invTrans)}`, {
      method: "GET",
      mode: "no-cors",
    })
      .then(async (response) => {
        const responseData = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (responseData && responseData.message) || response.statusText;
          return Promise.reject(error);
        }
        return true;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("There was an error!", error);
      });

    onDialogClose(undefined, undefined, "submit");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInvTrans({
      ...invTrans,
      [name]: value,
    });
  };

  // equivalent to componentDidMount()
  useEffect(() => {
    // eslint-disable-next-line no-console, no-unused-expressions
    open
      ? console.log("component did mount: open")
      : console.log("component did mount: close");

    const selectedKeys = selected ? Object.keys(selected) : null;
    const firstSelectedKey = selectedKeys ? selectedKeys[0] : null;

    if (open && firstSelectedKey) {
      fetch(`/echoMe/getInvTrans?transId=${firstSelectedKey}`, {
        method: "GET",
        mode: "no-cors",
      })
        .then((response) => {
          const promise = response
            .json()
            .then((data) => ({ data, calson: "here" }));
          return promise;
        })
        .then((data) => {
          // eslint-disable-next-line no-console
          console.log(`Editing transId ${data.data.transId}`);
          // setInvTrans({ ...invTrans, ...data.data });
          // eslint-disable-next-line prefer-object-spread
          setInvTrans(Object.assign({}, invTrans, data.data));
        });
    }
  }, [open]);

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={onDiaLogTransitClose}
      >
        <DialogTitle>
          Adjustment{" "}
          <IconButton
            className={classes.customizedButton}
            onClick={handleCancel}
          >
            <Icon fontSize="small">close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Change the inventory inTransit</DialogContentText>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              justify="center"
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            >
              <Grid item xs={12} md={4}>
                <TextField
                  // autoFocus
                  margin="dense"
                  id="skuCode-input"
                  name="skuCode"
                  label="SKU Code"
                  type="text"
                  // variant="standard"
                  fullWidth={true}
                  onChange={handleInputChange}
                  value={invTrans.skuCode}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  margin="dense"
                  id="assetCode-input"
                  name="assetCode"
                  label="Asset Code"
                  type="text"
                  fullWidth={true}
                  onChange={handleInputChange}
                  value={invTrans.assetCode}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  margin="dense"
                  id="productCode-input"
                  name="productCode"
                  label="Product Code"
                  type="text"
                  fullWidth={true}
                  onChange={handleInputChange}
                  value={invTrans.productCode}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  margin="dense"
                  id="description-input"
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth={true}
                  onChange={handleInputChange}
                  value={invTrans.description}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  margin="dense"
                  id="quantity-input"
                  name="quantity"
                  label="Quantity"
                  type="number"
                  onChange={handleInputChange}
                  value={invTrans.quantity}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  // style={{ padding: 10 }}
                  margin="dense"
                  id="status-input"
                  name="status"
                  select
                  label="Status"
                  variant="standard"
                  onChange={handleInputChange}
                  value={invTrans.status}
                >
                  {stateInventory?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Box width="100%" />
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  style={{ marginRight: 10 }}
                  id="currLocCode-input"
                  name="currLocCode"
                  label="Current Loc"
                  type="text"
                  fullWidth={true}
                  onChange={handleInputChange}
                  value={invTrans.currLocCode}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  id="prevLocCode-input"
                  name="prevLocCode"
                  label="Previous Loc"
                  type="text"
                  fullWidth={true}
                  onChange={handleInputChange}
                  value={invTrans.prevLocCode}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  margin="dense"
                  id="reason-input"
                  name="reason"
                  label="Reasons for Adjustment"
                  multiline
                  rows={4}
                  fullWidth={true}
                  onChange={handleInputChange}
                  value={invTrans.remark}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancel}>Cancel</MDButton>
          <MDButton onClick={handleSubmit}>Submit</MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogTransit;
