/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toLength } from "lodash";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { Grid, Typography, Box } from "@mui/material";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import PrefixDialogAdd from "./PrefixDialogAdd";
import UserService from "../../../service/KeyCloakService";

const gridStyle = { minHeight: 160 };

// eslint-disable-next-line arrow-body-style
const PrefixDialog = ({ open, close }) => {
  const [selected, setSelected] = useState({});
  const [openPrefixAdd, setOpenPrefixAdd] = useState(false);
  const [locationArray, setLocationArray] = useState([]);
  const [tempLocationArray, setTempLocationArray] = useState([]);
  const [keyCloakSite, setKeyCloakSite] = useState([]);
  const { t } = useTranslation(["setting"]);
  const storedSite = localStorage.getItem("siteCode");

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  // Dialog Handler
  const dialogPrefixAddClickHandler = () => {
    setOpenPrefixAdd(true);
  };

  const dialogPrefixAddCloseHandler = () => {
    setOpenPrefixAdd(false);
  };

  // Data fetching functions
  const loadPrefix = async () => {
    const tempLoc = [];
    //  Fetching location
    try {
      const data = await fetch(`/echoMe/loc/listLocSite`);
      if (!data.ok) {
        console.log("error message from loadPrefix");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      json?.map((r) => {
        tempLoc.push(r);
      });
      setTempLocationArray(tempLoc);
    } catch (err) {
      console.error(err);
    }

    //  Fetching prefix
    try {
      const data = await fetch(`/echoMe/prefix/listAllPrefixSequence`);
      if (!data.ok) {
        console.log("error message from loadPrefix");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      console.log(json);

      // if user has role of superuser
      if (UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERUSER")) {
        // displaying all prefix of sites
        setLocationArray(json);
      } else {
        // showing default client site
        let tempSite;
        // mapping sitecode with site
        tempLocationArray
          ?.filter((r) => storedSite === r.siteCode)
          ?.map((r) => (tempSite = r.id));
        console.log(tempSite);
        // mapping prefix with site
        let tempPrefixArray = [];
        json
          ?.filter((d) => tempSite === d.site)
          ?.map((d) => tempPrefixArray.push(d));
        setLocationArray(tempPrefixArray);
        // let finalRoute = _.uniqBy(temp4, "id");
      }
    } catch (err) {
      console.error(err);
    }
  };

  //  Load data whenever dialog is opened
  useEffect(() => {
    loadPrefix();
  }, [open]);

  // Matching siteid with site code
  const matchValue = (v) => {
    let result = "";
    result = tempLocationArray
      ?.filter((r) => r.id === v)
      ?.map((r) => r.siteCode);
    return result;
  };

  // React data grid field
  const columns = [
    {
      name: "prefix",
      header: t(`setting.PrefixDialogAdd.input-prefix`),
      defaultFlex: 1,
      type: "string",
    },
    {
      name: "sequence",
      header: t(`setting.PrefixDialogAdd.input-seq`),
      defaultFlex: 1.3,
      type: "string",
    },
    {
      name: "site",
      header: t(`setting.PrefixDialogAdd.input-site`),
      defaultFlex: 1,
      type: "string",
      render: ({ value, cellPros }) =>
        value?.toString()?.replace(value, matchValue(value)),
    },
    { name: "id", header: "Id", type: "number", defaultVisible: false },
  ];

  useEffect(() => {
    matchValue();
  }, [columns]);

  // Button functions
  const removePrefix = async () => {
    try {
      const data = await fetch(`/echoMe/prefix/removePrefix?id=${selected}`);
      if (!data.ok) {
        console.log("error message from removePrefix");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      setSelected(null);
      loadPrefix();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="sm">
        <DialogTitle>{t("setting.PrefixDialog.header-name")}</DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("setting.PrefixDialog.body")}
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <ReactDataGrid
                    licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                    idProperty="id"
                    checkboxColumn
                    selected={selected}
                    multiSelect={false}
                    onSelectionChange={onSelectionChange}
                    style={gridStyle}
                    columns={columns}
                    dataSource={locationArray}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red " }}
            onClick={() => {
              if (_.toLength(selected) === 0) {
                alert(t("setting.PrefixDialog.alert-delete"));
              } else if (_.toLength(selected) > "0") {
                removePrefix();
              }
            }}
          >
            {t("setting.PrefixDialog.button-delete")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "blue " }}
            onClick={loadPrefix}
          >
            {t("setting.PrefixDialog.button-refresh")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "blue " }}
            onClick={dialogPrefixAddClickHandler}
          >
            {t("setting.PrefixDialog.button-add")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "blue " }}
            onClick={close}
          >
            {t("setting.PrefixDialog.button-close")}
          </Button>
        </DialogActions>
        <PrefixDialogAdd
          open={openPrefixAdd}
          close={dialogPrefixAddCloseHandler}
          load={loadPrefix}
        />
      </Dialog>
    </>
  );
};

export default PrefixDialog;
