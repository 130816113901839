/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable arrow-body-style */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { Box, Grid, Typography, Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";

const DialogMovementInventory = ({ open, close, selected, load }) => {
  const [location, setLocation] = useState("");
  const [locCode, setLocCode] = useState("");
  const [site, setSite] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [locationArray, setLocationArray] = useState([]);
  const [locationObjectArray, setlocationObjectArray] = useState([]);
  const [locCodeArray, setLocCodeArray] = useState([]);
  const [isLocationSet, setIsLocationSet] = useState(false);
  const { t } = useTranslation(["inventory"]);

  //  fetching functions
  const loadLocation = async () => {
    try {
      const data = await fetch(`/echoMe/loc/listLocSite`);
      if (!data.ok) {
        console.log("error message from loadLocation");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      setlocationObjectArray(json);
      // console.log(json);

      // Filtering location array for auto complete
      let temp = json?.map((d) => d.siteCode);
      // console.log(temp);
      setLocationArray(temp);
    } catch (err) {
      alert(err);
    }
  };

  const loadListLocation = async (siteCode) => {
    try {
      const data = await fetch(
        `/echoMe/loc/listLocation?filterBy=[{"value":"${siteCode}","name":"siteCode","operator":"eq","type":"string"}]`
      );
      if (!data.ok) {
        console.log("error message from loadLocation");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }

      const json = await data.json();

      // console.log(json);

      // Filtering locCode array for auto complete
      let temp = [];
      json
        ?.filter((d) => siteCode === d.siteCode)
        ?.map((r) => temp.push(r.locationCode));
      // let removeDupLocCode = _.uniq(temp);
      setLocCodeArray(temp);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    loadLocation();
  }, [open]);

  const handleChange = (event, newValue) => {
    if (newValue === null) {
      newValue = "";
      setLocation(newValue);
    }
    setLocation(newValue);

    // Filtering corresponding site that user chose
    let tempSite = locationObjectArray
      ?.filter((d) => newValue === d.siteCode)
      ?.map((r) => r.id);
    setSite(tempSite[0]);
    loadListLocation(newValue);
    setIsLocationSet(true);
  };

  const handleChange2 = (event, newValue) => {
    if (newValue === null) {
      newValue = "";
      setLocCode(newValue);
    }
    // console.log(newValue);
    setLocCode(newValue);
  };

  const handleCancel = () => {
    setLocation("");
    setInputValue("");
    setInputValue2("");
    setLocationArray([]);
    setLocCodeArray([]);
    setIsLocationSet(false);
    load();
    close();
  };

  const submitHandler = async () => {
    if (locCode && site) {
      try {
        const data = await fetch(
          `/echoMe/inv/updateInventoryLocation?id=${selected.id}&newLocCode=${locCode}&site=${site}`,
          { method: "POST" }
        );
        if (!data.ok) {
          console.log("error message from submitHandler");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        handleCancel();
      } catch (err) {
        alert(err);
      }
    } else {
      alert(t(`inventory.DialogBatchUpdateInv.alert-submitHandler2`));
    }
  };

  // console.log({ selected, location, locCode, site });
  return (
    <>
      <Dialog open={open} onClose={close}>
        <DialogTitle>
          {t("inventory.DialogMovementInventory.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left"></DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6">Current Location</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="dense"
                  id="site-input"
                  name="site"
                  label={t("inventory.DialogMovementInventory.input-site2")}
                  type="text"
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={selected?.siteName ?? "None"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="dense"
                  id="locaion-input"
                  name="location"
                  label={t("inventory.DialogMovementInventory.input-location")}
                  type="text"
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={selected?.locCode ?? "None"}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6">
                  {t("inventory.DialogMovementInventory.input-newLocation")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={locationArray}
                  onChange={handleChange}
                  inputValue={inputValue}
                  clearOnEscape
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("inventory.DialogMovementInventory.input-site2")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={locCodeArray}
                  onChange={handleChange2}
                  disabled={!isLocationSet}
                  inputValue={inputValue2}
                  clearOnEscape
                  onInputChange={(event, newInputValue) => {
                    setInputValue2(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(
                        "inventory.DialogMovementInventory.input-location2"
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              style={{ color: "white", backgroundColor: "blue" }}
              onClick={submitHandler}
            >
              {t("inventory.DialogMovementInventory.button-submit")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              style={{ color: "white", backgroundColor: "red" }}
              onClick={handleCancel}
            >
              {t("inventory.DialogMovementInventory.button-close")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogMovementInventory;
