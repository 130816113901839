/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
// @mui icons
import Icon from "@mui/material/Icon";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "assets/theme/components/form/autocomplete";

import PropTypes from "prop-types";
import MDButton from "../MDButton";
import UserService from "../../service/KeyCloakService";

const useStyles = makeStyles({
  customizedButton: {
    position: "absolute",
    right: 8,
    // top: "-9%",
    backgroundColor: "lightgray",
    color: "gray",
  },
});

const Input = styled("input")({
  display: "none",
});

// eslint-disable-next-line no-unused-vars
const DialogFileUpload = ({
  newOrUpdate,
  open,
  onDialogClose,
  selected,
  load,
}) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = React.useState("HoiSen");
  const { keycloak } = useKeycloak();
  const { t } = useTranslation(["assetRegistration"]);

  const onDialogFileUploadClose = (event, reason) => {
    onDialogClose(event, reason, undefined);
  };

  // eslint-disable-next-line no-unused-vars
  const onDocTypeChange = (event, newValue) => {
    console.log(event);
    // setFileUpload({ ...fileUpload, []docType: newValue.docType });
  };

  const changeFileHandler = (e) => {
    // const { name, value } = e.target;
    console.log(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setIsSelected(true);

    // setFileUpload({
    //   ...fileUpload,
    //   [name]: value,
    // });
  };

  const handleCancel = () => {
    onDialogClose(undefined, undefined, "cancel");
    setIsClicked(false);
  };

  const handleSubmission = () => {
    setIsClicked(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    let token = keycloak.token;
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token, {
      "Content-Type": "text/csv",
    });
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      mode: "no-cors",
      redirect: "follow",
    };
    const userName = UserService.getUsername();
    // console.log(token);
    // console.log(userName);
    // fetch(`/echoMe/uploadM1`, {
    fetch(`/echoMe/reg/uploadM1?userName=${userName}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            setErrorMessage(text);
            throw new Error(text);
          });
          // throw Error("Cannot import file");
        }
        return response.json();
      })
      .then((result) => {
        console.log("Success:", result);
        onDialogClose(undefined, undefined, "submit");
        setIsClicked(false);
        load();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // equivalent to componentDidMount()
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    // open ? console.log("component did mount: open") : console.log("component did mount: close");
    setIsSelected(false);
    setSelectedFile(null);
    setErrorMessage("");
  }, [open]);

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={onDialogFileUploadClose}
      >
        <DialogTitle>
          {newOrUpdate === "new"
            ? t("assetReg.DialogFileUpload.header-name")
            : "Edit File Upload"}
          <IconButton
            className={classes.customizedButton}
            onClick={handleCancel}
          >
            <Icon fontSize="small">close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("assetReg.DialogFileUpload.header2-name")}
          </DialogContentText>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            justify="center"
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid item xs={12} md={4}>
              {isSelected ? (
                <div>
                  <p>Filename: {selectedFile?.name}</p>
                  <p>Filetype: {selectedFile?.type}</p>
                  <p>Size in bytes: {selectedFile?.size}</p>
                  <p>
                    lastModifiedDate:{" "}
                    {selectedFile?.lastModifiedDate?.toLocaleDateString()}
                  </p>
                </div>
              ) : (
                <p>{t("assetReg.DialogFileUpload.body")}</p>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <label htmlFor="contained-button-file">
            <Input
              accept="*.*"
              name="file"
              id="contained-button-file"
              // multiple
              onChange={changeFileHandler}
              type="file"
            />
            <MDButton
              variant="contained"
              color="primary"
              style={{ color: "white", marginRight: "10px" }}
              component="span"
              submit="true"
            >
              {t("assetReg.DialogFileUpload.button-select")}
            </MDButton>
          </label>
          <MDButton
            color="primary"
            style={{ color: "white", backgroundColor: "blue " }}
            disabled={!isSelected || isClicked}
            onClick={handleSubmission}
          >
            {t("assetReg.DialogFileUpload.button-submit")}
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// // Setting default values for the props of DialogFileUpload
// DialogFileUpload.defaultProps = {
//   open: false,
//   selected: 0,
// };

// // Typechecking props for the MDButton
// DialogFileUpload.propTypes = {
//   newOrUpdate: PropTypes.string.isRequired,
//   open: PropTypes.bool,
//   onDialogClose: PropTypes.func.isRequired,
//   selected: PropTypes.objectOf(PropTypes.any),
// };

export default DialogFileUpload;
