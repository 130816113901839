/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Box } from "@mui/material";
import { printZpl } from "../../utility/printUtli";
import { genMultItemRfidTagZpl } from "../../utility/printUtli/itemLabelPrint";
import PropTypes from "prop-types";

const gridStyle = { minHeight: 160 };
// const columns = [{ name: "assetCode", header: "Asset Id", defaultFlex: 1, type: "string" }];

// eslint-disable-next-line arrow-body-style
const DialogBatchRegeneInv = ({
  open,
  close,
  selected,
  regNum,
  load,
  setSelected,
}) => {
  const { t } = useTranslation(["inventory"]);
  const columns = [
    {
      name: "productCode",
      header: t(`inventory.DialogBatchRegeneInv.column-productCode`),
      type: "number",
      defaultFlex: 1,
    },
    {
      name: "rfid",
      header: t(`inventory.DialogBatchRegeneInv.column-rfid`),
      defaultFlex: 1,
    },
    { name: "id", header: "Id", type: "number", defaultVisible: false },
  ];

  //  Button functions
  const submitHandler = async () => {
    const rfidArray = selected?.map((r) => r.rfid).join(",");
    // console.log(regNum, rfidArray);
    try {
      const data = await fetch(
        `/echoMe/reg/regenRfidItem?regNum=NA&rfids=${rfidArray}`
      );
      if (!data.ok) {
        console.log("error message from regenRfidItem");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }

      const json = await data.json();
      printZpl(
        genMultItemRfidTagZpl(
          json?.map((e) => {
            const {
              eanupc = "1111111111",
              description = "NA",
              style = "NA",
              color = "NA",
              size = "NA",
              skuCode = "NA",
              exp = "NA",
              serial = "NA",
              rfid = "SNoRfidFoundData",
              productCode = "NA",
            } = e;
            console.log(e);
            let ssc = `${style}/${size}/${color}`;
            return {
              barCode: eanupc,
              des: description,
              ssc: ssc,
              sku: skuCode,
              exp: exp,
              serial: serial,
              rfid: rfid,
              productCode: productCode,
            };
          })
        )
      );
      load();
      setSelected("");
      close();
    } catch (err) {
      console.error(err);
      alert(err);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth="sm" fullWidth={true}>
        <DialogTitle>
          {t("inventory.DialogBatchRegeneInv.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("inventory.DialogBatchRegeneInv.body")}
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <Grid container spacing={2} mt="5px">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  {}
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box>
                    <ReactDataGrid
                      licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                      idProperty="id"
                      style={gridStyle}
                      columns={columns}
                      dataSource={selected}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                  {}
                </Grid>
                <Grid item xs={12} sm={2}>
                  {}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              style={{ color: "white", backgroundColor: "blue" }}
              onClick={submitHandler}
            >
              {t("inventory.DialogBatchRegeneInv.button-confirm")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={close}
              style={{ color: "white", backgroundColor: "red" }}
            >
              {t("inventory.DialogBatchRegeneInv.button-close")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogBatchRegeneInv;
