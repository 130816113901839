/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-duplicates */
/* eslint-disable import/newline-after-import */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import TransitEnterexitIcon from "@mui/icons-material/TransitEnterexit";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import DescriptionIcon from "@mui/icons-material/Description";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserService from "../../service/KeyCloakService";
import DialogLocationDashboard from "./DialogLocationDashboard";

function Dashboard() {
  const navigate = useNavigate();
  const [openLocation, setOpenLocation] = useState(false);
  const storedSite = localStorage.getItem("siteCode");

  const { t } = useTranslation(["common"]);

  //  Dialog open,close option handler
  const dialogLocationClickHandler = () => {
    setOpenLocation(true);
  };

  const dialogLocationCloseHandler = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      // Set 'open' to false, however you would do that with your particular code.
      setOpenLocation(false);
    }
  };

  // Open the site selection dialog if either of storedSite or storedSiteDescription are missing
  useEffect(() => {
    if (!storedSite) {
      dialogLocationClickHandler();
    }
  }, [storedSite]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box
        variant="div"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MDBox maxWidth={1300} mt={4.5}>
          <Grid
            container
            spacing={8}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Grid item xs={12} md={6} lg={4}>
              <Paper
                mb={3}
                elevation={7}
                sx={{
                  height: "240px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  // marginLeft: "150px",
                }}
                onClick={() => {
                  if (
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_ASSETREG"
                    ) ||
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_SUPERUSER"
                    ) ||
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_SUPERVISORS"
                    ) ||
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_TEAMREADER"
                    ) ||
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_PICKER1"
                    )
                  ) {
                    navigate("/assetRegistration");
                  } else {
                    alert(t(`common.DialogLocationDashboard.alert-access`));
                    navigate("/dashboard");
                  }
                }}
              >
                <AppRegistrationOutlinedIcon fontSize="large" />
                {/* <Typography>Asset Registration</Typography> */}
                <Typography> {t("common.App.assetReg")}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper
                mb={3}
                elevation={7}
                sx={{
                  height: "240px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                onClick={() => {
                  if (
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_ASSETIN"
                    ) ||
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_SUPERUSER"
                    ) ||
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_SUPERVISORS"
                    ) ||
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_TEAMREADER"
                    ) ||
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_PICKER1"
                    )
                  ) {
                    navigate("/assetInventory");
                  } else {
                    alert(t(`common.DialogLocationDashboard.alert-access`));
                    navigate("/dashboard");
                  }
                }}
              >
                <InventoryIcon fontSize="large" />
                {/* <Typography>Asset Inventory</Typography> */}
                <Typography> {t("common.App.assetInv")}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper
                mb={3}
                elevation={7}
                sx={{
                  height: "240px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  // marginLeft: "-150px",
                }}
                //  Getting arrays of roles from keycloak and if user does not have then it will not grant access
                onClick={() => {
                  if (
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_TRANOUT"
                    ) ||
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_SUPERUSER"
                    ) ||
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_SUPERVISORS"
                    ) ||
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_TEAMREADER"
                    ) ||
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_PICKER1"
                    )
                  ) {
                    navigate("/transferOut");
                  } else {
                    alert(t(`common.DialogLocationDashboard.alert-access`));
                    navigate("/dashboard");
                  }
                }}
              >
                <CallMissedOutgoingIcon fontSize="large" />
                {/* <Typography>Transfer Out</Typography> */}
                <Typography> {t("common.App.tranOut")}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper
                mb={3}
                elevation={7}
                sx={{
                  height: "240px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  // marginLeft: "150px",
                }}
                //  Getting arrays of roles from keycloak and if user does not have then it will not grant access
                onClick={() => {
                  if (
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_TRANIN"
                    ) ||
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_SUPERUSER"
                    ) ||
                    UserService?.getClientRole()?.includes("ROLE_ECHOME_STORES")
                  ) {
                    navigate("/transferIn");
                  } else {
                    alert(t(`common.DialogLocationDashboard.alert-access`));
                    navigate("/dashboard");
                  }
                }}
              >
                <TransitEnterexitIcon fontSize="large" />
                {/* <Typography>Transfer In</Typography> */}
                <Typography> {t("common.App.tranIn")}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper
                mb={3}
                elevation={7}
                sx={{
                  height: "240px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                //  Getting arrays of roles from keycloak and if user does not have then it will not grant access
                onClick={() => {
                  if (
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_ASSETRETURN" ||
                        UserService?.getClientRole()?.includes(
                          "ROLE_ECHOME_SUPERUSER"
                        )
                    )
                  ) {
                    navigate("/assetReturn");
                  } else {
                    alert(t(`common.DialogLocationDashboard.alert-access`));
                    navigate("/dashboard");
                  }
                }}
              >
                <KeyboardReturnIcon fontSize="large" />
                {/* <Typography>Asset Return</Typography> */}
                <Typography> {t("common.App.assetRe")}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper
                mb={3}
                elevation={7}
                sx={{
                  height: "240px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                //  Getting arrays of roles from keycloak and if user does not have then it will not grant access
                onClick={() => {
                  if (
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_STOCKTAKE" ||
                        UserService?.getClientRole()?.includes(
                          "ROLE_ECHOME_SUPERUSER"
                        )
                    )
                  ) {
                    navigate("/stocktake");
                  } else {
                    alert(t(`common.DialogLocationDashboard.alert-access`));
                    navigate("/dashboard");
                  }
                }}
              >
                <LocalGroceryStoreIcon fontSize="large" />
                {/* <Typography>Stock Take</Typography> */}
                <Typography> {t("common.App.stocktake")}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper
                mb={3}
                elevation={7}
                sx={{
                  height: "240px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  // marginLeft: "-150px",
                }}
                //  Getting arrays of roles from keycloak and if user does not have then it will not grant access
                onClick={() => {
                  if (
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_TRACKANDTRACE"
                    ) ||
                    UserService?.getClientRole()?.includes(
                      "ROLE_ECHOME_SUPERUSER"
                    )
                  ) {
                    navigate("/trackandtrace");
                  } else {
                    alert(t(`common.DialogLocationDashboard.alert-access`));
                    navigate("/dashboard");
                  }
                }}
              >
                <DescriptionIcon fontSize="large" />
                {/* <Typography>Track and Trace</Typography> */}
                <Typography> {t("common.App.trackandtrace")}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </MDBox>
      </Box>
      <DialogLocationDashboard
        open={openLocation}
        close={dialogLocationCloseHandler}
      />
    </DashboardLayout>
  );
}

export default Dashboard;
