/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-object-spread */
import React, { useEffect, useState, useCallback } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import { usePageVisibility } from "react-page-visibility";
import "@inovua/reactdatagrid-enterprise/index.css";
import Toolbar from "@mui/material/Toolbar";
import DateFilter from "@inovua/reactdatagrid-enterprise/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import moment from "moment";
import { navbarContainer } from "examples/Navbars/DashboardNavbar/styles";
import MDButton from "../MDButton";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import UserService from "../../service/KeyCloakService";
import DialogHandlingException from "./DialogHandlingException";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// React data grid fields
const gridStyle = { marginTop: 10, minHeight: 530 };

const defaultFilterValue = [
  { name: "id", type: "number", operator: "eq", value: "" },
  { name: "orderNum", type: "string", operator: "contains", value: "" },
  { name: "productCode", type: "string", operator: "contains", value: "" },
  { name: "createdDate", type: "date", operator: "inrange", value: "" },
  { name: "type", type: "select", operator: "in", value: "" },
  { name: "skuCode", type: "string", operator: "contains", value: "" },
  {
    name: "rfidEquipmentCode",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "containerCode", type: "string", operator: "contains", value: "" },
  { name: "containerRfid", type: "string", operator: "contains", value: "" },
  { name: "itemRfid", type: "string", operator: "contains", value: "" },
  { name: "rskuCode", type: "string", operator: "contains", value: "" },
  { name: "reason", type: "string", operator: "contains", value: "" },
  { name: "offset", type: "boolean", operator: "eq", value: "" },
  { name: "handledFlag", type: "boolean", operator: "eq", value: "FALSE" },
  { name: "exceptionFlag", type: "boolean", operator: "eq", value: "TRUE" },
  { name: "checkPoint", type: "string", operator: "contains", value: "" },
  { name: "exceptionDesc", type: "string", operator: "contains", value: "" },
  { name: "handledBy", type: "string", operator: "contains", value: "" },
  { name: "handledDate", type: "date", operator: "inrange", value: "" },
  {
    name: "originalCheckPoint",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "originalContainerCode",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "originalContainerRfid",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "originalItemCode", type: "string", operator: "contains", value: "" },
  {
    name: "originalLocationCode",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "scannedEquipment", type: "string", operator: "contains", value: "" },
  { name: "scannedMethod", type: "string", operator: "contains", value: "" },
  { name: "site", type: "number", operator: "eq", value: "" },
];

const STATUS_SELECTION = [
  "REGISTER",
  "ASSET_INVENTORY",
  "TRANSFER_OUT",
  "TRANSFER_IN",
  "STOCK_TAKE",
  "ADJUSTMENT",
  "MOVEMENT",
  "REGENERATE",
];

const BOOLEAN_SELECTION = ["TRUE", "FALSE"];

const onRenderRow = (rowProps) => {
  if (rowProps.data.status === "COMPLETED") {
    rowProps.style.color = "#7986cb";
  }

  if (
    rowProps.data.exceptionFlag === true &&
    rowProps.data.handledFlag === false
  ) {
    rowProps.style.color = "#FF0000";
  }
};

const toArray = (selected) => Object.values(selected)?.map((id) => id);

// eslint-disable-next-line arrow-body-style
const TrackAndTraceAsync = () => {
  const { t } = useTranslation(["trackTrace"]);
  const defaultColumns = [
    { name: "id", header: "Id", defaultVisible: false, type: "number" },
    {
      name: "orderNum",
      header: t(`trackTrace.Async.column-orderNum`),
      defaultWidth: 150,
    },
    {
      name: "productCode",
      header: t(`trackTrace.Async.column-productCode`),
      defaultWidth: 150,
    },
    {
      name: "rskuCode",
      header: t(`trackTrace.Async.column-rskuCode`),
      defaultWidth: 150,
    },
    {
      name: "skuCode",
      header: t(`trackTrace.Async.column-skuCode`),
      defaultWidth: 150,
    },
    {
      name: "containerCode",
      header: t(`trackTrace.Async.column-containerCode`),
      defaultWidth: 150,
      type: "string",
    },
    {
      name: "containerRfid",
      header: t(`trackTrace.Async.column-containerRfid`),
      defaultWidth: 150,
      type: "string",
    },
    {
      name: "itemRfid",
      header: t(`trackTrace.Async.column-itemRfid`),
      defaultWidth: 150,
      type: "string",
    },
    {
      name: "createdDate",
      header: t(`trackTrace.Async.column-createdDate`),
      defaultWidth: 200,
      filterEditor: DateFilter,
      enableColumnFilterContextMenu: false,
      filterEditorProps: (props, { index }) =>
        // for range and notinrange operators, the index is 1 for the after field
        ({
          dateFormat: "YYYY-MM-DD",
          cancelButton: false,
          highlightWeekends: false,
          // placeholder: index === 1 ? "Create date is before..." : "Create date is after...",
        }),
      // eslint-disable-next-line no-unused-vars
      render: ({ value, cellPros }) =>
        value === null ? "None" : moment(value).format("YYYY-MM-DD"),
    },
    {
      name: "type",
      header: t(`trackTrace.Async.column-type`),
      defaultWidth: 150,
      filterEditor: SelectFilter,
      enableColumnFilterContextMenu: false,
      render: ({ value, cellPros }) => value?.replaceAll("_", " "),
      filterEditorProps: {
        placeholder: "All",
        multiple: true,
        wrapMultiple: false,
        dataSource: STATUS_SELECTION?.map((c) => ({ id: c, label: c })),
      },
    },
    {
      name: "offset",
      header: t(`trackTrace.Async.column-offset`),
      filterEditor: SelectFilter,
      defaultWidth: 150,
      filterEditorProps: {
        placeholder: "All",
        multiple: false,
        wrapMultiple: false,
        dataSource: BOOLEAN_SELECTION?.map((c) => ({ id: c, label: c })),
      },
      render: ({ value, cellPros }) => (value === false ? "False" : "True"),
    },
    {
      name: "reason",
      header: t(`trackTrace.Async.column-reason`),
      defaultWidth: 150,
      type: "string",
    },
    {
      name: "checkPoint",
      header: t(`trackTrace.Async.column-checkPoint`),
      defaultWidth: 150,
      type: "string",
    },
    {
      name: "exceptionDesc",
      header: t(`trackTrace.Async.column-exceptionDesc`),
      defaultWidth: 200,
      type: "string",
    },
    {
      name: "exceptionFlag",
      header: t(`trackTrace.Async.column-exceptionFlag`),
      filterEditor: SelectFilter,
      defaultWidth: 150,
      filterEditorProps: {
        placeholder: "All",
        multiple: false,
        wrapMultiple: false,
        dataSource: BOOLEAN_SELECTION?.map((c) => ({ id: c, label: c })),
      },
      render: ({ value, cellPros }) => (value === false ? "False" : "True"),
    },
    {
      name: "handledBy",
      header: t(`trackTrace.Async.column-handledBy`),
      defaultWidth: 150,
      type: "string",
    },
    {
      name: "handledDate",
      header: t(`trackTrace.Async.column-handledDate`),
      defaultWidth: 200,
      filterEditor: DateFilter,
      enableColumnFilterContextMenu: false,
      filterEditorProps: (props, { index }) =>
        // for range and notinrange operators, the index is 1 for the after field
        ({
          dateFormat: "YYYY-MM-DD",
          cancelButton: false,
          highlightWeekends: false,
          // placeholder: index === 1 ? "Create date is before..." : "Create date is after...",
        }),
      // eslint-disable-next-line no-unused-vars
      render: ({ value, cellPros }) =>
        value === null ? "None" : moment(value).format("YYYY-MM-DD"),
    },
    {
      name: "handledFlag",
      header: t(`trackTrace.Async.column-handledFlag`),
      filterEditor: SelectFilter,
      defaultWidth: 150,
      filterEditorProps: {
        placeholder: "All",
        multiple: false,
        wrapMultiple: false,
        dataSource: BOOLEAN_SELECTION?.map((c) => ({ id: c, label: c })),
      },
      render: ({ value, cellPros }) => (value === false ? "False" : "True"),
    },
    {
      name: "originalCheckPoint",
      header: t(`trackTrace.Async.column-originalCheckPoint`),
      defaultWidth: 200,
      type: "string",
    },
    {
      name: "originalContainerCode",
      header: t(`trackTrace.Async.column-originalContainerCode`),
      defaultWidth: 200,
      type: "string",
    },
    {
      name: "originalContainerRfid",
      header: t(`trackTrace.Async.column-originalContainerRfid`),
      defaultWidth: 200,
      type: "string",
    },
    {
      name: "originalItemCode",
      header: t(`trackTrace.Async.column-originalItemCode`),
      defaultWidth: 200,
      type: "string",
    },
    {
      name: "originalLocationCode",
      header: t(`trackTrace.Async.column-originalLocationCode`),
      defaultWidth: 200,
      type: "string",
    },
    {
      name: "scannedEquipment",
      header: t(`trackTrace.Async.column-scannedEquipment`),
      defaultWidth: 180,
      type: "string",
    },
    {
      name: "scannedMethod",
      header: t(`trackTrace.Async.column-scannedMethod`),
      defaultWidth: 180,
      type: "string",
    },
    {
      name: "site",
      header: t(`trackTrace.Async.column-site`),
      defaultWidth: 180,
      type: "string",
    },
  ];

  const STORE = {
    columns: defaultColumns,
    limit: 10,
    sortInfo: [{ name: "createdDate", dir: 1, type: undefined }],
    filterValue: defaultFilterValue,
    reservedViewportWidth: 0,
    columnOrder: ["id", "createdDate", "orderNum", "type,"],
  };

  const initialData = Object.assign({}, STORE, { dataSource: [] });
  const [gridState, setGridState] = useState(initialData);
  const [checked, setChecked] = useState(0);
  const navigate = useNavigate();
  const reduxSiteCode = useSelector((state) => state.siteCode);
  let storedSite = localStorage.getItem("siteCode");
  const isVisible = usePageVisibility();
  const [gridRef, setGridRef] = useState(null);
  const [openException, setOpenException] = useState(false);
  const storedLan = localStorage.getItem("i18nextLng");
  const defaultSortInfo = { name: "createdDate", dir: -1 };
  const [openBackDrop, setOpenBackDrop] = useState(false);

  // Dialog Process data logic
  const dialogProcessClickHandler = () => {
    setOpenException(true);
  };

  const dialogProcessCloseHandler = () => {
    setOpenException(false);
  };

  const backDrophandleClose = () => {
    setOpenBackDrop(false);
  };

  const backDrophandleOpen = async () => {
    setOpenBackDrop(true);
  };

  // Fetching data
  const getDataSource =
    (entity) =>
    ({ skip, limit, sortInfo, groupBy, filterValue }) => {
      const queryParams = [
        skip ? `skip=${skip}` : null,
        limit ? `limit=${limit}` : null,
        groupBy && groupBy.length ? `groupBy=${groupBy}` : null,
        filterValue && filterValue.length
          ? `filterBy=${JSON.stringify(
              filterValue
                ?.filter((v) => v.active !== false)
                ?.map((v) => ({
                  value: v.value,
                  name: v.name,
                  operator: v.operator,
                  type: v.type,
                }))
            )}`
          : null,
        sortInfo ? `sortInfo=${JSON.stringify(sortInfo)}` : null,
      ]
        ?.filter((value) => value)
        .join("&");

      return fetch(`/echoMe/rfidTrack/${entity}?${queryParams}`).then(
        (response) => {
          const totalCount = response.headers.get("X-Total-Count");
          return response
            .json()
            .then((data) => ({ data, count: parseInt(totalCount, 10) }));
        }
      );
    };

  const newDataSource = useCallback(getDataSource("listRfidRecord"), []);

  //  Button functions
  const clearData = () => {
    setGridState(Object.assign({}, gridState, { dataSource: [] }));
  };

  const loadData = () => {
    clearData();
    setTimeout(
      () =>
        setGridState(
          Object.assign({}, gridState, { dataSource: newDataSource })
        ),
      10
    );
  };

  //  Load data first whenever page is loaded
  useEffect(() => {
    if (!storedSite) {
      alert(t(`trackTrace.Async.alert-location`));
      navigate("/dashboard");
    }
  }, [storedSite]);

  // for changing language
  useEffect(() => {
    gridState.columns = [];
    gridState.columns = defaultColumns;
  }, [storedLan]);

  const myFunc = () => {};

  const onLimitChange = (newLimit) => {
    setGridState(Object.assign({}, gridState, { limit: newLimit }));
  };

  const onSortInfoChange = (value) => {
    // const newSortInfo = value ? { type: value.type, name: value.name, dir: value.dir } : value;
    // setGridState(Object.assign({}, gridState, { sortInfo: newSortInfo }));
    setGridState(Object.assign({}, gridState, { sortInfo: value }));
  };

  const onFilterValueChange = (value) => {
    // const newFilterValue = JSON.parse(JSON.stringify(value));
    // const newFilterValue = value ? [...value] : value;
    setGridState(Object.assign({}, gridState, { filterValue: value }));
  };

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setChecked(selected);
  }, []);

  const scrollTo = useCallback(
    (index) => {
      return () => {
        gridRef.current.scrollToColumn(1, { duration: 300 });
      };
    },
    [gridRef]
  );

  const onColumnOrderChange = (columnOrder) => {
    setGridState(Object.assign({}, gridState, { columnOrder }));
  };

  const onEditComplete = useCallback(() => {});

  const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
    menuProps.autoDismiss = true;
    menuProps.items = [
      {
        label: `myFunc: ${rowProps.data[cellProps.id]}`,
        onClick: () => {
          myFunc({ rowProps, cellProps });
        },
      },
    ];
  };

  const exportData = () => {
    backDrophandleOpen();
    fetch(
      "/echoMe/rfidTrack/exportRfidTrack" +
        `?sortInfo=${JSON.stringify(
          gridState.sortInfo
        )}&filterBy=${JSON.stringify(gridState.filterValue)}`
    ).then((response) => {
      response.blob().then((blob) => {
        if (response?.ok === true) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Track And Trace Summary.xlsx";
          a.click();
          backDrophandleClose();
        } else if (response?.ok === false) {
          alert("There is something wrong during processing data.");
          backDrophandleClose();
        }
      });
    });
  };

  // const cancelOrder = async () => {
  //   if (selectedObject?.status.toLowerCase() === "completed") {
  //     alert(t("assetReturn.Async.alert-location"));
  //   } else {
  //     try {
  //       const data = await fetch(`/echoMe/assetReturn/cancelReturn?rtnNum=${selectedDocumentNum}`);
  //       if (!data.ok) {
  //         console.log("error message from cancelOrder");
  //         let error = await data;
  //         let errorText = await data.text();
  //         console.log(errorText);
  //         if (error.status === 500) {
  //           throw Error("Please try again.");
  //         }
  //         throw Error(errorText);
  //       }
  //       const json = await data.json();
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  //   setChecked(null);
  //   loadData();
  // };

  return (
    <div>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Toolbar
          style={{ minHeight: "10px" }}
          sx={(theme) => navbarContainer(theme)}
        >
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }}>
            <MDTypography variant="h6" color="white">
              {t("trackTrace.Async.header-name")}
            </MDTypography>
          </MDBox>

          <MDBox>
            {(UserService.getClientRole().includes(
              "ROLE_ECHOME_TRACKANDTRACE"
            ) ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_SUPERUSER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={scrollTo(10)}
              >
                {t("trackTrace.Async.button-scrollToFirst")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes(
              "ROLE_ECHOME_TRACKANDTRACE"
            ) ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_SUPERUSER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={loadData}
              >
                {t("trackTrace.Async.button-loadData")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes(
              "ROLE_ECHOME_TRACKANDTRACE"
            ) ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_SUPERUSER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                disabled={Array.isArray(gridState.dataSource)}
                onClick={clearData}
              >
                {t("trackTrace.Async.button-clearData")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes(
              "ROLE_ECHOME_TRACKANDTRACE"
            ) ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_SUPERUSER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                disabled={checked === null || checked === true || checked === 0}
                onClick={dialogProcessClickHandler}
              >
                {t("trackTrace.Async.button-batchException")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes(
              "ROLE_ECHOME_TRACKANDTRACE"
            ) ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_SUPERUSER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                disabled={Array.isArray(gridState.dataSource)}
                onClick={exportData}
              >
                {t("trackTrace.Async.button-export")}
              </MDButton>
            )}
          </MDBox>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={openBackDrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Toolbar>
      </MDBox>
      <MDBox pt={3}>
        <ReactDataGrid
          licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
          idProperty="id"
          style={gridStyle}
          columns={gridState.columns}
          dataSource={gridState.dataSource}
          defaultGroupBy={[]}
          defaultFilterValue={defaultFilterValue}
          enableSelection
          multiSelect
          selected={checked}
          checkboxColumn
          pagination
          pageSizes={[10, 50, 100]}
          limit={gridState.limit}
          onLimitChange={onLimitChange}
          onSelectionChange={onSelectionChange}
          onSortInfoChange={onSortInfoChange}
          onFilterValueChange={onFilterValueChange}
          onColumnOrderChange={onColumnOrderChange}
          onEditComplete={onEditComplete}
          // renderRowContextMenu={renderRowContextMenu}
          onRenderRow={onRenderRow}
          onReady={setGridRef}
          defaultSortInfo={defaultSortInfo}
        />
      </MDBox>
      <DialogHandlingException
        // selected={JSON.stringify(selected)}
        open={openException}
        close={dialogProcessCloseHandler}
        selected={toArray(checked)}
        setChecked={setChecked}
        loadData={loadData}
      />
    </div>
  );
};

export default TrackAndTraceAsync;
