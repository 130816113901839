/* eslint-disable prefer-const */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-object-spread */
import React, { useState, useCallback, useEffect } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "@inovua/reactdatagrid-enterprise/index.css";
import Toolbar from "@mui/material/Toolbar";
import moment from "moment";
import { usePageVisibility } from "react-page-visibility";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import DateFilter from "@inovua/reactdatagrid-enterprise/DateFilter";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { navbarContainer } from "examples/Navbars/DashboardNavbar/styles";
import MDButton from "../MDButton";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import DialogTransit from "../DialogTransit";
import DialogCreateStock from "./DialogCreateStockTake";
import DialogProcessStockOut from "./DialogProcessStockOut";
import UserService from "../../service/KeyCloakService";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// React data grid
const gridStyle = { marginTop: 10, minHeight: 530 };
const toArray = (selected) => Object.keys(selected)?.map((id) => id * 1);

// eslint-disable-next-line no-unused-vars
const defaultFilterValue = [
  { name: "id", type: "number", operator: "eq", value: "" },
  { name: "stNum", type: "string", operator: "contains", value: "" },
  { name: "ranges", type: "string", operator: "contains", value: "" },
  { name: "startDate", type: "date", operator: "inrange", value: "" },
  { name: "status", type: "select", operator: "in", value: "" },
  { name: "maker", type: "string", operator: "contains", value: "" },
  { name: "totalAssetQty", type: "number", operator: "eq", value: "" },
  { name: "totalContainerQty", type: "number", operator: "eq", value: "" },
  { name: "totalExceptionQty", type: "number", operator: "eq", value: "" },
  { name: "totalStocktakeQty", type: "number", operator: "eq", value: "" },
];

const STATUS_SELECTION = [
  "DRAFT",
  "INITIATED",
  "STOCKTAKING",
  "CANCEL",
  "COMPLETE",
];

const onRenderRow = (rowProps) => {
  if (rowProps.data.status === "COMPLETE") {
    rowProps.style.color = "#7986cb";
  }
};

const StockOut = () => {
  const { t } = useTranslation(["stockTake"]);
  // eslint-disable-next-line no-unused-vars
  const defaultColumns = [
    { name: "id", header: "Id", defaultVisible: false, type: "number" },
    {
      name: "stNum",
      header: t(`stockTake.Async.column-stNum`),
      defaultFlex: 1.2,
      type: "string",
    },
    {
      name: "ranges",
      header: t(`stockTake.Async.column-ranges`),
      defaultFlex: 1.5,
      type: "string",
    },
    {
      name: "startDate",
      header: t(`stockTake.Async.column-startDate`),
      defaultFlex: 1.5,
      minWidth: 200,
      enableColumnFilterContextMenu: false,
      type: "date",
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) =>
        // for range and notinrange operators, the index is 1 for the after field
        ({
          dateFormat: "YYYY-MM-DD",
          cancelButton: false,
          highlightWeekends: false,
        }),
      // eslint-disable-next-line no-unused-vars
      render: ({ value, cellPros }) =>
        value === null ? "None" : moment(value).format("YYYY-MM-DD"),
    },
    {
      name: "totalAssetQty",
      header: t(`stockTake.Async.column-totalAssetQty`),
      defaultFlex: 1.5,
      type: "number",
    },
    {
      name: "totalContainerQty",
      header: t(`stockTake.Async.column-totalContainerQty`),
      defaultFlex: 1.5,
      type: "number",
    },
    {
      name: "totalExceptionQty",
      header: t(`stockTake.Async.column-totalExceptionQty`),
      defaultFlex: 1.5,
      type: "number",
    },
    {
      name: "totalStocktakeQty",
      header: t(`stockTake.Async.column-totalStocktakeQty`),
      defaultFlex: 1.5,
      type: "number",
    },
    {
      name: "status",
      header: t(`stockTake.Async.column-status`),
      defaultFlex: 2,
      filterEditor: SelectFilter,
      enableColumnFilterContextMenu: false,
      render: ({ value, cellPros }) => value?.replaceAll("_", " "),
      filterEditorProps: {
        placeholder: "All",
        multiple: true,
        wrapMultiple: false,
        dataSource: STATUS_SELECTION?.map((c) => ({ id: c, label: c })),
      },
    },
    {
      name: "maker",
      header: t(`stockTake.Async.column-maker`),
      defaultFlex: 1.5,
      type: "string",
    },
  ];
  const STORE = {
    columns: defaultColumns,
    limit: 10,
    sortInfo: { name: "stNum", dir: 1, type: undefined },
    filterValue: defaultFilterValue,
    reservedViewportWidth: 0,
    columnOrder: ["stNum"],
  };
  const initialData = Object.assign({}, STORE, { dataSource: [] });
  const [stockTake, setStockTake] = useState();
  const [openStockCount, setOpenStockCount] = useState(false);
  const [dialogTransitOpen, setDialogTransitOpen] = useState(false);
  const [openStockProcess, setOpenStockProcess] = useState(false);
  const [gridState, setGridState] = useState(initialData);
  const [selected, setSelected] = useState(null);
  const [selectedObject, setSelectedObject] = useState();
  const reduxSiteCode = useSelector((state) => state.siteCode);
  const navigate = useNavigate();
  let storedSite = localStorage.getItem("siteCode");
  const isVisible = usePageVisibility();
  const storedLan = localStorage.getItem("i18nextLng");
  const [openBackDrop, setOpenBackDrop] = useState(false);

  // Dialog Handler
  const dialogCountClickHandler = () => {
    setOpenStockCount(true);
  };

  const dialogCountCloseHandler = () => {
    setOpenStockCount(false);
  };

  const dialogProcessClickHandler = () => {
    setOpenStockProcess(true);
  };

  const dialogProcessCloseHandler = () => {
    setOpenStockProcess(false);
  };

  const backDrophandleClose = () => {
    setOpenBackDrop(false);
  };

  const backDrophandleOpen = async () => {
    setOpenBackDrop(true);
  };

  // It will trigger refreshing of the web if site is changed from other tab
  useEffect(() => {
    if (storedSite !== reduxSiteCode && reduxSiteCode && storedSite) {
      window.location.reload();
    }
  }, [reduxSiteCode, storedSite]);

  // Button functions
  const clearData = () => {
    setGridState(Object.assign({}, gridState, { dataSource: [] }));
  };

  const loadData = () => {
    clearData();
    const newDataSource = ({ skip, limit, sortInfo, groupBy, filterValue }) =>
      // A proxy setting in package.json to divert traffic to whalespod:8080
      fetch(
        "/echoMe/stocktake/listStocktakeHeader" +
          `?skip=${skip}&limit=${limit}&groupBy=${groupBy}&sortInfo=${JSON.stringify(
            sortInfo
          )}&filterBy=${JSON.stringify(filterValue)}`,
        { method: "GET", mode: "no-cors" }
      ).then((response) => {
        const totalCount = response.headers.get("X-Total-Count");
        const resultObj = response
          .json()
          .then((data) => ({ data, count: Number(totalCount) }));
        resultObj.then((d) => setStockTake(d.data));
        return resultObj;
      });
    setGridState(Object.assign({}, gridState, { dataSource: newDataSource }));
  };

  //  Filtering selected object which is needed for process screen
  const filterDocumentObject = () => {
    stockTake
      ?.filter((d) => d.id === selected)
      ?.map((d) => setSelectedObject(d));
  };

  // updating the selectedObject whenever selected is changed
  useEffect(() => {
    filterDocumentObject();
    if (selected === null) {
      loadData();
      filterDocumentObject();
      // console.log({ stockTake, selectedObject });
    }
  }, [selected]);

  // for changing language
  useEffect(() => {
    gridState.columns = [];
    gridState.columns = defaultColumns;
  }, [storedLan]);

  // Load data first whenever page is loaded
  useEffect(() => {
    if (storedSite) {
      loadData();
    } else {
      alert(t(`stockTake.Async.alert-location`));
      navigate("/dashboard");
    }
  }, [storedSite, reduxSiteCode, isVisible]);

  // Adjustment dialog
  const adjustIntransitData = () => {
    setDialogTransitOpen(true);
  };

  const onDialogTransitClose = (event, reason, buttonStr) => {
    if (reason !== "backdropClick") setDialogTransitOpen(false);
    if (buttonStr === "submit") loadData();
  };

  const onLimitChange = (newLimit) => {
    setGridState(Object.assign({}, gridState, { limit: newLimit }));
  };

  const onSortInfoChange = (value) => {
    // const newSortInfo = value ? { type: value.type, name: value.name, dir: value.dir } : value;
    // setGridState(Object.assign({}, gridState, { sortInfo: newSortInfo }));
    setGridState(Object.assign({}, gridState, { sortInfo: value }));
  };

  const onFilterValueChange = (value) => {
    // const newFilterValue = JSON.parse(JSON.stringify(value));
    // const newFilterValue = value ? [...value] : value;
    setGridState(Object.assign({}, gridState, { filterValue: value }));
  };

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const onColumnOrderChange = (columnOrder) => {
    setGridState(Object.assign({}, gridState, { columnOrder }));
  };

  const onEditComplete = useCallback(
    // eslint-disable-next-line no-unused-vars
    ({ value, columnId, rowId, data }) => {
      fetch(
        `/echoMe/updateInvTransInline?transId=${rowId}&columnId=${columnId}&value=${value}`,
        {
          method: "GET",
          mode: "no-cors",
        }
      )
        .then(async (response) => {
          const responseData = await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error =
              (responseData && responseData.message) || response.statusText;
            return Promise.reject(error);
          }
          loadData();
          return true;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error("There was an error!", error);
        });
    },
    []
  );

  const adjustRow = useCallback(({ rowProps }) => {
    setSelected({ [rowProps.data.transId]: true });
    adjustIntransitData();
  }, []);

  const removeRow = useCallback(({ rowProps }) => {
    fetch(`/echoMe/removeInvTrans?transId=${rowProps.data.transId}`, {
      method: "GET",
      mode: "no-cors",
    })
      .then(async (response) => {
        const responseData = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (responseData && responseData.message) || response.statusText;
          return Promise.reject(error);
        }
        loadData();
        return true;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("There was an error!", error);
      });
  }, []);

  const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
    menuProps.autoDismiss = true;
    menuProps.items = [
      {
        label: `adjust: ${rowProps.data[cellProps.id]}`,
        onClick: () => {
          adjustRow({ rowProps, cellProps });
        },
      },
      {
        label: `remove: ${rowProps.data[cellProps.id]}`,
        onClick: () => {
          removeRow({ rowProps, cellProps });
        },
      },
    ];
  };

  const exportData = () => {
    backDrophandleOpen();
    fetch(`/echoMe/stocktake/exportStocktakeSummary`).then((response) => {
      response.blob().then((blob) => {
        if (response?.ok === true) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Stocktake Summary.xlsx";
          a.click();
          backDrophandleClose();
        } else if (response?.ok === false) {
          alert("There is something wrong during processing data.");
          backDrophandleClose();
        }
      });
    });
  };

  const cancelButtonHandler = async () => {
    try {
      const data = await fetch(
        `/echoMe/stocktake/stocktakeCancel?stNum=${encodeURIComponent(
          selectedObject?.stNum
        )}`
      );
      if (!data.ok) {
        console.log("error message from cancelButtonHandler");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      loadData();
      setSelected({});
    } catch (err) {
      alert(err);
      loadData();
    }
  };

  return (
    <div>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Toolbar
          style={{ minHeight: "10px" }}
          sx={(theme) => navbarContainer(theme)}
        >
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }}>
            <MDTypography variant="h6" color="white">
              {t("stockTake.Async.header-name")}
            </MDTypography>
          </MDBox>

          <MDBox>
            {(UserService.getClientRole().includes("ROLE_ECHOME_STOCKTAKE") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_SUPERUSER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={dialogCountClickHandler}
              >
                {t("stockTake.Async.button-create")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_STOCKTAKE") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_SUPERUSER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={dialogProcessClickHandler}
                disabled={
                  selected === null || selected === true || selected === 0
                }
              >
                {t("stockTake.Async.button-process")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_STOCKTAKE") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_SUPERUSER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={loadData}
              >
                {t("stockTake.Async.button-loadData")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_STOCKTAKE") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_SUPERUSER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={clearData}
              >
                {t("stockTake.Async.button-clearData")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_STOCKTAKE") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_SUPERUSER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={cancelButtonHandler}
                disabled={
                  selected === null ||
                  selected === true ||
                  selected === 0 ||
                  selectedObject?.status.toLowerCase() === "complete" ||
                  selectedObject?.status.toLowerCase() === "cancel" ||
                  selectedObject?.status.toLowerCase() === "stocktaking"
                }
              >
                {t("stockTake.Async.button-cancel")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_STOCKTAKE") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_SUPERUSER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                disabled={Array.isArray(gridState.dataSource)}
                onClick={exportData}
              >
                {t("stockTake.Async.button-export")}
              </MDButton>
            )}
          </MDBox>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={openBackDrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Toolbar>
      </MDBox>
      <MDBox pt={3}>
        <ReactDataGrid
          idProperty="id"
          licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
          style={gridStyle}
          columns={gridState.columns}
          dataSource={gridState.dataSource}
          defaultGroupBy={[]}
          defaultFilterValue={defaultFilterValue}
          enableSelection
          selected={selected}
          multiSelect={false}
          checkboxColumn
          pagination
          pageSizes={[10, 50, 100]}
          limit={gridState.limit}
          onLimitChange={onLimitChange}
          onSelectionChange={onSelectionChange}
          onSortInfoChange={onSortInfoChange}
          onFilterValueChange={onFilterValueChange}
          onColumnOrderChange={onColumnOrderChange}
          onRenderRow={onRenderRow}
          // editable
          onEditComplete={onEditComplete}
          // renderRowContextMenu={renderRowContextMenu}
        />
        <DialogTransit
          open={dialogTransitOpen}
          selected={selected}
          onDialogClose={onDialogTransitClose}
        />
      </MDBox>
      <DialogCreateStock
        open={openStockCount}
        close={dialogCountCloseHandler}
        load={loadData}
      />
      <DialogProcessStockOut
        open={openStockProcess}
        close={dialogProcessCloseHandler}
        selected2={selectedObject}
        setChecked={setSelected}
        load={loadData}
        filterDocumentObject={filterDocumentObject}
      />
    </div>
  );
};

export default StockOut;
