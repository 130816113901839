/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Box } from "@mui/material";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";

//  React Data Grid
const gridStyle = { minHeight: 200 };

const toArray = (selected) => Object.values(selected)?.map((id) => id);

// eslint-disable-next-line arrow-body-style
const DialogCreateStockTake = ({ open, close, load }) => {
  const [selected, setSelected] = useState({});
  const [locationArray, SetLocationArray] = useState([]);
  const { t } = useTranslation(["stockTake"]);
  const columns = [
    {
      name: "siteCode",
      header: "Site Code",
      defaultFlex: 1.5,
      type: "string",
    },
    {
      name: "locationCode",
      header: t(`stockTake.DialogCreateStockTake.column-locationCode`),
      defaultFlex: 2,
      type: "string",
    },
    {
      name: "description",
      header: t(`stockTake.DialogCreateStockTake.column-description`),
      defaultFlex: 2,
      type: "string",
    },
    { name: "id", header: "Id", type: "number", defaultVisible: false },
  ];

  // Button Functions
  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const loadLocation = async () => {
    try {
      const data = await fetch(`/echoMe/loc/listLocation`);
      if (!data.ok) {
        console.log("error message from loadLocation");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      const temp = [];
      json?.map((r) => {
        return temp.push(r);
      });
      SetLocationArray(temp);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    loadLocation();
  }, [open]);

  const closeHandler = () => {
    setSelected({});
    close();
  };

  const submitButtonHandler = async () => {
    let tempRanges = toArray(selected)
      ?.map((r) => r.locationCode)
      .join(",");
    let obj = JSON.stringify({ ranges: tempRanges });
    try {
      const data = await fetch(
        `/echoMe/stocktake/newStocktakeHeader?stocktakeHeader=${obj}`,
        {
          mode: "cors",
        }
      );
      if (!data.ok) {
        console.log("error message from stopScanButtonHandler");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      load();
      closeHandler();
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          {t("stockTake.DialogCreateStockTake.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("stockTake.DialogCreateStockTake.body")}
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <ReactDataGrid
                    licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                    idProperty="id"
                    checkboxColumn
                    selected={selected}
                    onSelectionChange={onSelectionChange}
                    style={gridStyle}
                    columns={columns}
                    dataSource={locationArray}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={loadLocation}
          >
            {t("stockTake.DialogCreateStockTake.button-refresh")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={submitButtonHandler}
            disabled={toArray(selected).length < 1}
          >
            {t("stockTake.DialogCreateStockTake.button-submit")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red" }}
            onClick={closeHandler}
          >
            {t("stockTake.DialogCreateStockTake.button-close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogCreateStockTake;
