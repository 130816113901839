/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-fragments */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { useTranslation } from "react-i18next";

const gridStyle = { minHeight: 375 };

const sum = (a, b) => a + b;

const RegisterProcessListData2 = ({ filteredData, getData }) => {
  const [selected, setSelected] = useState({});
  const { t } = useTranslation(["assetRegistration"]);

  const columns = [
    { name: "id", header: "Id", type: "number", defaultVisible: false },
    {
      name: "skuCode",
      header: t(`assetReg.DialogProcessRegistration.column-skuCode`),
      defaultFlex: 2.5,
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0]?.skuCode}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value;
      },
    },
    {
      name: "description",
      header: t(`assetReg.DialogProcessRegistration.column-description`),
      type: "string",
      defaultFlex: 4,
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0]?.description}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value;
      },
    },
    {
      name: "productCode",
      header: t(`assetReg.DialogProcessRegistration.column-productCode`),
      type: "number",
      defaultFlex: 1.5,
    },
    {
      name: "quantity",
      header: t(`assetReg.DialogProcessRegistration.column-quantity`),
      type: "number",
      defaultFlex: 1.4,
      groupSummaryReducer: {
        initialValue: 0,
        reducer: sum,
      },
    },
    {
      name: "checkinQty",
      header: t(`assetReg.DialogProcessRegistration.column-checkinQty`),
      type: "number",
      defaultFlex: 1.8,
      groupSummaryReducer: {
        initialValue: 0,
        reducer: sum,
      },
    },
    {
      name: "status",
      header: t(`assetReg.DialogProcessRegistration.column-status`),
      type: "string",
      defaultFlex: 3,
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0]?.status?.replaceAll("_", " ")}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value?.replaceAll("_", " ");
      },
    },
    {
      name: "serial",
      header: t(`assetReg.DialogProcessRegistration.column-serial`),
      type: "string",
      defaultFlex: 2,
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0]?.serial}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value;
      },
    },
    {
      name: "expiryDate",
      header: t(`assetReg.DialogProcessRegistration.column-expiryDate`),
      defaultFlex: 1.5,
      minWidth: 150,
      enableColumnFilterContextMenu: false,
      type: "date",
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>
                {data.array[0]?.expiryDate === null
                  ? "None"
                  : moment(data.array[0]?.expiryDate).format("YYYY-MM-DD")}
              </b>
            </>
            // console.log({ data, value })
          );
        }
        return value === null ? "None" : moment(value)?.format("YYYY-MM-DD");
      },
    },
  ];

  // React Data Grid Fields
  // // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const toArray = (selected) => Object?.values(selected)?.map((id) => id);

  useEffect(() => {
    getData(toArray(selected));
  }, [selected]);

  return (
    <div>
      <ReactDataGrid
        licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
        idProperty="id"
        checkboxColumn
        selected={selected}
        onSelectionChange={onSelectionChange}
        style={gridStyle}
        groupColumn={true}
        dataSource={filteredData}
        defaultGroupBy={["productCode"]}
        defaultCollapsedGroups={true}
        columns={columns}
        pagination
      />
    </div>
  );
};

export default RegisterProcessListData2;
