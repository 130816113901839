/* eslint-disable prefer-const */
/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Avatar from "@mui/material/Avatar";
import { blue } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { Typography } from "@mui/material";
import UserService from "../../../service/KeyCloakService";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    cursor: "pointer",
    color: "#44b700",
    transition: `${theme.transitions.create(["background-color", "transform"], {
      duration: theme.transitions.duration.standard,
    })}`,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["common"]);
  const storedLan = localStorage.getItem("i18nextLng");

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem
        icon={<Icon>podcasts</Icon>}
        title="Manage Podcast sessions"
      />
      <NotificationItem
        icon={<Icon>shopping_cart</Icon>}
        title="Payment successfully completed"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  //  Making titles for each functions
  const makeTitle = () => {
    let a = route[route.length - 1];
    if (storedLan === "en") {
      if (a === "assetRegistration") {
        a = "Asset Registration";
      } else if (a === "assetInventory") {
        a = "Asset Inventory";
      } else if (a === "transferOut") {
        a = "transfer Out";
      } else if (a === "transferIn") {
        a = "transfer In";
      } else if (a === "trackandtrace") {
        a = "Track and Trace";
      } else if (a === "location") {
        a = "Location";
      } else if (a === "locProfile") {
        a = "Location Profile";
      } else if (a === "Notifications") {
        a = "Notifications";
      } else if (a === "stockTake") {
        a = "Stock Take";
      } else if (a === "assetReturn") {
        a = "Asset Return";
      } else if (a === "dashboard") {
        a = "Dashboard";
      } else if (a === "setting") {
        a = "Setting";
      } else if (a === "notifications") {
        a = "Notification";
      }
    } else if (storedLan === "ch") {
      if (a === "assetRegistration") {
        a = "物件登記管理";
      } else if (a === "assetInventory") {
        a = "物件庫存管理";
      } else if (a === "transferOut") {
        a = "物件轉出管理";
      } else if (a === "transferIn") {
        a = "物件轉入管理";
      } else if (a === "trackandtrace") {
        a = "物件追踪";
      } else if (a === "location") {
        a = "位置管理";
      } else if (a === "locProfile") {
        a = "位置管理";
      } else if (a === "Notifications") {
        a = "通知設定";
      } else if (a === "stockTake") {
        a = "盤點";
      } else if (a === "assetReturn") {
        a = "物件退回管理";
      } else if (a === "dashboard") {
        a = "控制版面";
      } else if (a === "setting") {
        a = "設定";
      } else if (a === "notifications") {
        a = "通知設定";
      }
    } else if (a === "assetregistration") {
      navigate("/assetRegistration");
    }
    return a;
  };

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  const LanguageChangeHandler = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  const makeUserNameForAvatar = () => {
    const temp =
      UserService.getUsername().charAt(0) + UserService.getUsername().slice(-1);
    return temp;
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      {/* <Typography>Test: {t("companyname")}</Typography> */}
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={makeTitle()}
            route={route}
            light={light}
          />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                sx={navbarIconButton}
                size="small"
                disableRipple
                // onClick={() => {
                //   navigate("/profile");
                // }}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    sx={{
                      bgcolor: blue[500],
                      width: 24,
                      height: 24,
                      fontSize: 14,
                    }}
                    variant="dot"
                  >
                    {makeUserNameForAvatar()}
                  </Avatar>
                </StyledBadge>
                {/* <Icon sx={iconsStyle}>account_circle</Icon> */}
              </IconButton>
              {/* <select
                className="nav-link bg-dark border-0 ml-1 mr-2 mt-1"
                value={localStorage.getItem("i18nextLng")}
                onChange={LanguageChangeHandler}
              >
                <option value="en">English</option>
                <option value="ch">繁字</option>
              </select> */}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
