/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { uniqBy, uniqWith } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import {
  Grid,
  TextField,
  Box,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { usersActions } from "../../store/userSlice";

const gridStyle = { minHeight: 240 };

// eslint-disable-next-line arrow-body-style
const DialogSelectToReturn = ({ open, close, rtnNum, getSelectedToNum }) => {
  const [selected, setSelected] = useState({});
  const [upcField, setUpcField] = useState();
  const [locationArray, setLocationArray] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedto, setSelectedTO] = useState("");
  const [toArray, setToArray] = useState([]);
  const { t } = useTranslation(["assetReturn"]);
  const columns = [
    { name: "id", header: "Id", type: "number", defaultVisible: false },
    {
      name: "toNum",
      header: t(`assetReturn.DialogSelectToReturn.column-toNum`),
      defaultFlex: 1.6,
    },
    {
      name: "status",
      header: t(`assetReturn.DialogSelectToReturn.column-status`),
      type: "string",
      defaultFlex: 1.8,
      render: ({ value, cellPros }) => value?.replaceAll("_", " "),
    },
    {
      name: "shipToLocation",
      header: t(`assetReturn.DialogSelectToReturn.column-shipToLocation`),
      type: "string",
      defaultFlex: 1.5,
    },
    {
      name: "createdDate",
      header: t(`assetReturn.DialogSelectToReturn.column-createdDate`),
      enableColumnFilterContextMenu: false,
      defaultFlex: 1.6,
      render: ({ value, cellPros }) =>
        value === null ? "None" : moment(value).format("YYYY-MM-DD"),
    },
  ];

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  //  fetching functions
  const loadLocation = async () => {
    try {
      const data = await fetch(`/echoMe/loc/listLocSite`);
      if (!data.ok) {
        console.log("error message from loadLocation");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      let temp = [];
      json?.map((d) => temp.push(d.siteCode));
      setLocationArray(temp);
    } catch (err) {
      alert(err);
    }
  };

  const loadToNum = async (location) => {
    try {
      const data = await fetch(
        `/echoMe/to/listTransferOutHeaderBySite?site=${location}`
      );
      if (!data.ok) {
        console.log("error message from loadToNum");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      let temp = [];
      json?.map((d) => temp.push(d));
      setToArray(temp);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    loadLocation();
  }, [open]);

  //  Filtering selected object which is needed for process screen
  const filterDocumentObject = () => {
    toArray
      ?.filter((d) => d.id === selected)
      ?.map((d) => setSelectedTO(d.toNum));
  };

  useEffect(() => {
    if (selected) {
      filterDocumentObject();
    }
  }, [selected]);

  const handleChange = (event) => {
    loadToNum(event.target.value);
  };

  //  Button Functions
  const handleClose = () => {
    //  Resetting the values
    setSelectedTO("");
    setToArray("");
    setSelected(null);
    close();
  };

  //  Button functions
  const submitHandler = async (event) => {
    event.preventDefault();
    if (!selectedto) {
      alert(t(`assetReturn.DialogSelectToReturn.alert-submitHandler`));
    } else {
      getSelectedToNum(selectedto);
      handleClose();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth="md" fullWidth={true}>
        <DialogTitle>
          {t("assetReturn.DialogSelectToReturn.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            {t("assetReturn.DialogSelectToReturn.body")}
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <Grid container spacing={2} mt="5px">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  {}
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="simple-select-label">
                        {t("assetReturn.DialogSelectToReturn.input-site")}
                      </InputLabel>
                      <Select
                        labelId="simple-select-label"
                        id="demo-simple-select"
                        label="fields"
                        onChange={handleChange}
                        defaultValue=""
                      >
                        {locationArray?.map((sites) => (
                          <MenuItem key={sites} value={sites}>
                            {sites}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                  {}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ReactDataGrid
                    licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                    idProperty="id"
                    checkboxColumn
                    multiSelect={false}
                    selected={selected}
                    onSelectionChange={onSelectionChange}
                    style={gridStyle}
                    columns={columns}
                    dataSource={toArray}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={loadLocation}
              style={{ color: "white", backgroundColor: "blue" }}
            >
              {t("assetReturn.DialogSelectToReturn.button-refresh")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              type="submit"
              style={{ color: "white", backgroundColor: "blue" }}
              onClick={submitHandler}
            >
              {t("assetReturn.DialogSelectToReturn.button-confirm")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={handleClose}
              style={{ color: "white", backgroundColor: "red" }}
            >
              {t("assetReturn.DialogSelectToReturn.button-close")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogSelectToReturn;
