/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
// import React, { useState, useCallback } from "react";
import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import DateFilter from "@inovua/reactdatagrid-enterprise/DateFilter";
import { useTranslation } from "react-i18next";

const gridStyle = { minHeight: 378 };

const sum = (a, b) => a + b;

const ReturnProcessListData2 = ({ filteredData }) => {
  // const [selected, setSelected] = useState({});
  const { t } = useTranslation(["assetReturn"]);
  const columns = [
    { name: "id", header: "Id", type: "number", defaultVisible: false },
    {
      name: "skuCode",
      header: t(`assetReturn.DialogProcessReturn.column-skuCode`),
      defaultFlex: 2.2,
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0]?.skuCode}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value;
      },
    },
    {
      name: "description",
      header: t(`assetReturn.DialogProcessReturn.column-description`),
      type: "string",
      defaultFlex: 4,
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0]?.description}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value;
      },
    },
    {
      name: "productCode",
      header: t(`assetReturn.DialogProcessReturn.column-productCode`),
      type: "number",
      defaultFlex: 2.5,
    },
    {
      name: "quantity",
      header: t(`assetReturn.DialogProcessReturn.column-quantity`),
      type: "number",
      defaultFlex: 1.3,
      groupSummaryReducer: {
        initialValue: 0,
        reducer: sum,
      },
    },
    {
      name: "checkinQty",
      header: t(`assetReturn.DialogProcessReturn.column-checkinQty`),
      type: "number",
      defaultFlex: 1.8,
      groupSummaryReducer: {
        initialValue: 0,
        reducer: sum,
      },
    },
    {
      name: "status",
      header: t(`assetReturn.DialogProcessReturn.column-status`),
      type: "string",
      defaultFlex: 3,
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0]?.status?.replaceAll("_", " ")}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value?.replaceAll("_", " ");
      },
    },
    {
      name: "serial",
      header: t(`assetReturn.DialogProcessReturn.column-serial`),
      type: "string",
      defaultFlex: 2,
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0]?.serial}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value;
      },
    },
  ];
  // // eslint-disable-next-line no-shadow
  // const onSelectionChange = useCallback(({ selected }) => {
  //   setSelected(selected);
  // }, []);

  // const dataSource = [
  //   { id: 1, productCode: "23321231", productDesc: "Ring 1", qty: 30, regQty: 0, rfid: "N" },
  //   { id: 2, productCode: "67756565", productDesc: "Ring 2", qty: 3, regQty: 0, rfid: "N" },
  //   { id: 3, productCode: "53423423", productDesc: "Ring 3", qty: 1, regQty: 0, rfid: "N" },
  //   { id: 4, productCode: "98978977", productDesc: "Ring 4", qty: 8, regQty: 0, rfid: "N" },
  //   { id: 5, productCode: "97555234", productDesc: "Ring 5", qty: 2, regQty: 0, rfid: "N" },
  //   { id: 6, productCode: "43434232", productDesc: "Ring 6", qty: 5, regQty: 0, rfid: "N" },
  // ];

  // console.log(filteredData);

  return (
    <div>
      <ReactDataGrid
        licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
        idProperty="id"
        // checkboxColumn
        // selected={selected}
        // onSelectionChange={onSelectionChange}
        style={gridStyle}
        columns={columns}
        dataSource={filteredData}
        groupColumn={true}
        defaultGroupBy={["productCode"]}
        defaultCollapsedGroups={true}
        pagination
      />
    </div>
  );
};

export default ReturnProcessListData2;
