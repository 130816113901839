/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable object-shorthand */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable prefer-const */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable no-return-assign */
import { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { difference } from "lodash";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DialogActions from "@mui/material/DialogActions";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Paper,
  MenuItem,
} from "@mui/material";
import DateFilter from "@inovua/reactdatagrid-enterprise/DateFilter";
import { printZpl } from "utility/printUtli";
import { genMultItemRfidTagZpl } from "utility/printUtli/itemLabelPrint";
import UserService from "../../service/KeyCloakService";

// import { useSelector, useDispatch } from "react-redux";
// // eslint-disable-next-line import/no-absolute-path
// import { equipmentIdActions } from "/Users/sungjaejoo/Desktop/echome-web/src/store/userSlice";

// eslint-disable-next-line arrow-body-style

// React Data Grid
const gridStyle = { minHeight: 330 };
const gridStyle2 = { minHeight: 435 };

const sum = (a, b) => a + b;
const toArray = (selected) => Object?.values(selected)?.map((id) => id);

const DialogProcessTranIn = ({
  open,
  close,
  selected2,
  selected3,
  setSelect,
  load,
}) => {
  const [selected, setSelected] = useState({});
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [transferSelected, setTransferSelected] = useState({});
  const [containerSelected, setContainerSelected] = useState({});
  const [dataArray, setdataArray] = useState([]);
  const [containerCode, setContainerCode] = useState("");
  const [equipmentRFID, setEquipmentRFID] = useState("");
  const [containerAssetCode, setContainerAssetCode] = useState("");
  const [scannerIP, setScannerIP] = useState("");
  const [isScanning, setIsScanning] = useState(false);
  const [isCleared, setIsCleared] = useState(true);
  const [isScanned, setIsScanned] = useState(false);
  const [isOneTimeReg, setIsOneTimeReg] = useState(false);
  const [containerRfidList, setContainerRfidList] = useState([]);
  const [regList, setRegList] = useState([]);
  const [scannerArray, setScannerArray] = useState([]);
  const [timerId, setTimerId] = useState("");
  const [isM2Scanning, setIsM2Scanning] = useState(false);
  const { t } = useTranslation(["transferIn"]);
  const columns = [
    { name: "id", header: "Id", type: "number", defaultVisible: false },
    {
      name: "skuCode",
      header: t(`transferIn.DialogProcessTranIn.column-skuCode`),
      defaultFlex: 1,
      type: "string",
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0].skuCode}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value;
      },
    },
    {
      name: "containerCode",
      header: t(`transferIn.DialogProcessTranIn.column-containerCode`),
      defaultFlex: 1,
      type: "string",
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0].containerCode}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value;
      },
    },
    {
      name: "itemRfid",
      header: t(`transferIn.DialogProcessTranIn.column-itemRfid`),
      defaultFlex: 1,
      type: "string",
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0].itemRfid}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value;
      },
    },
    {
      name: "description",
      header: t(`transferIn.DialogProcessTranIn.column-description`),
      defaultFlex: 1.5,
      type: "string",
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0].description}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value;
      },
    },
    {
      name: "productCode",
      header: t(`transferIn.DialogProcessTranIn.column-productCode`),
      type: "number",
      defaultFlex: 2.5,
    },
    {
      name: "quantity",
      header: t(`transferIn.DialogProcessTranIn.column-quantity`),
      defaultFlex: 1,
      type: "string",
      groupSummaryReducer: {
        initialValue: 0,
        reducer: sum,
      },
    },
    {
      name: "checkinQty",
      header: t(`transferIn.DialogProcessTranIn.column-checkinQty`),
      defaultFlex: 1,
      type: "string",
      groupSummaryReducer: {
        initialValue: 0,
        reducer: sum,
      },
    },
    {
      name: "createdDate",
      header: t(`transferIn.DialogProcessTranIn.column-createdDate`),
      defaultFlex: 1,
      minWidth: 200,
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>
                {data.array[0]?.createdDate === null
                  ? "None"
                  : moment(data.array[0]?.createdDate).format("YYYY-MM-DD")}
              </b>
            </>
            // console.log({ data, value })
          );
        }
        return value === null ? "None" : moment(value)?.format("YYYY-MM-DD");
      },
    },
    {
      name: "remarks",
      header: t(`transferIn.DialogProcessTranIn.column-remark`),
      defaultFlex: 1.5,
      type: "string",
      render: ({ value, data }) => {
        if (data.__group) {
          return (
            <>
              <b>{data.array[0].remarks}</b>
            </>
            // console.log({ data, value })
          );
        }
        return value;
      },
    },
  ];
  const columns2 = [
    {
      name: "rfid",
      header: t(`transferIn.DialogProcessTranIn.column-RFID`),
      defaultFlex: 1,
      type: "string",
    },
    {
      name: "rsku",
      header: t(`transferIn.DialogProcessTranIn.column-RSKU`),
      defaultFlex: 1,
      type: "string",
    },
  ];
  const columns3 = [
    {
      name: "rfid",
      header: t(`transferIn.DialogProcessTranIn.container`),
      defaultFlex: 2,
    },
    {
      name: "rsku",
      header: "Container Code",
      defaultFlex: 2,
    },
  ];

  //  Dialog Handler
  const backDrophandleClose = () => {
    setOpenBackDrop(false);
  };

  const backDrophandleOpen = () => {
    setOpenBackDrop(true);
  };

  // React query responses
  const onSuccess = (data) => {
    console.log("Sucess", data);
  };

  const onError = (error) => {
    console.log(error);
  };

  // Fetching scanner List with reg num
  const getScannerList = async () => {
    const data = await fetch(
      `/echoMe/param/findAllActiveParamByCode?paramName=READER_IP`,
      {
        mode: "cors",
      }
    );
    const json = await data.json();
    setScannerArray(json);
    return json;
  };

  // Reat query -scanner list
  const {
    data: scannerListProcessTransIn,
    isFetching: isFetchingScannerListProcessTransIn,
    refetch: refetchScannerListTransIn,
    isError: isErrorScannerListTransIn,
  } = useQuery("TransIn-getScannerList", getScannerList, {
    onSuccess,
    onError,
    // refetchInterval: 1000,
    // refetchIntervalInBackground: true,
    // refetchOnWindowFocus: true,
    enabled: false,
  });

  useEffect(async () => {
    loadTransferList();
  }, [selected2, selected3]);

  const loadTransferList = async () => {
    if (selected3) {
      const data = await fetch(
        `/echoMe/ti/listTransferOutLine?tiNum=${encodeURIComponent(
          selected3?.tiNum
        )}`
      );
      const tempJson = await data.json();
      const filteredJson = _.uniqBy(tempJson, "itemRfid");
      // console.log(filteredJson);
      setdataArray(filteredJson);
    }
  };

  // Fetching scanner list whenever dialog is opened
  useEffect(() => {
    if (selected2) {
      refetchScannerListTransIn();
    }
  }, [open]);

  //  Button Functions
  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  // eslint-disable-next-line no-shadow
  const onTransferSelectionChange = useCallback(({ selected }) => {
    setTransferSelected(selected);
  }, []);

  // eslint-disable-next-line no-shadow
  const onContainerSelectionChange = useCallback(({ selected }) => {
    setContainerSelected(selected);
  }, []);

  //  Button functions
  const containerRfidDeleteButtonHandler = async () => {
    const tempDeletedArray = _.difference(
      containerRfidList,
      toArray(containerSelected)
    );
    console.log(
      containerRfidList,
      toArray(containerSelected),
      tempDeletedArray
    );
    setContainerRfidList(tempDeletedArray);

    // filtering container code rfid
    const filteredContainerCode = tempDeletedArray?.map((d) => d?.rfid);
    setEquipmentRFID(filteredContainerCode);
    setContainerSelected({});
  };

  const itemRFIDDeleteButtonHandler = async () => {
    const tempDeletedArray = _.difference(regList, Object?.values(selected));
    setRegList(tempDeletedArray);
    setSelected({});
  };

  const startScanM2 = async () => {
    if (scannerIP) {
      setIsM2Scanning(true);
      backDrophandleOpen();
      try {
        const data = await fetch(
          `echoMe/rfid/getReaderData?ipAdd=${scannerIP}`,
          // `http://192.168.1.141:8080/echoMe/rfid/getReaderData?ipAdd=${scannerIP}`,
          // `/echoMe/rfid/getReaderData?ipAdd=dummyData`,
          {
            mode: "cors",
          }
        );
        if (!data.ok) {
          console.log("error message from submitHandler");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        setIsM2Scanning(false);
        const json = await data.json();

        // Filtering RFID for Display
        const filteredRegData = [];
        json
          ?.filter((d) => d?.rfid.includes("SAT"))
          .map((d) => filteredRegData.push(d));
        // If first time scanning
        if (_.isEmpty(regList)) {
          setRegList(filteredRegData);
        } else if (!_.isEmpty(regList)) {
          // If more than one time then accumulate result
          const temp = _.differenceBy(filteredRegData, regList, "rfid");
          const removeDupReg = _.uniqBy([...temp, ...regList], "rfid");
          setRegList(removeDupReg);
        }

        // Filtering Container RFID for Display
        const filteredContainerData = [];
        json
          ?.filter((d) => d?.rfid.includes("CAT"))
          .map((d) => filteredContainerData.push(d));
        // If first time scanning
        if (_.isEmpty(containerRfidList)) {
          setContainerRfidList(filteredContainerData);
        } else if (!_.isEmpty(containerRfidList)) {
          // If more than one time then accumulate result
          const temp = _.differenceBy(
            filteredContainerData,
            containerRfidList,
            "rfid"
          );
          const removeDupCon = _.uniqBy(
            [...temp, ...containerRfidList],
            "rfid"
          );
          setContainerRfidList(removeDupCon);
        }

        // filtering container code rfid (needed for the first case)
        const filteredContainerCode = filteredContainerData?.map(
          (d) => d?.rfid
        );

        //If container accesscode has one then filter container access code
        if (filteredContainerCode?.length === 1) {
          await filterContainerAssetCode(filteredContainerCode[0]);
          setEquipmentRFID(filteredContainerCode[0]);
        }

        setIsScanning(false);
        setIsScanned(true);
        setIsCleared(false);
        backDrophandleClose();
      } catch (err) {
        console.error(err);
        backDrophandleClose();
        alert(err);
      }
    }
  };

  const clearButtonHandler = async () => {
    if (scannerIP) {
      setRegList([]);
      setEquipmentRFID("");
      setIsScanned(false);
      setContainerCode("");
      setIsOneTimeReg(false);
      setIsScanning(false);
      setContainerRfidList([]);
      setSelected(null);
      setIsM2Scanning(false);
      setIsCleared(true);
    }
  };

  const handleCancel = () => {
    load();
    setContainerCode("");
    setEquipmentRFID("");
    setTimerId("");
    setContainerAssetCode("");
    setIsScanned(false);
    setIsOneTimeReg(false);
    setIsScanning(false);
    setScannerArray([]);
    setScannerIP("");
    setContainerRfidList([]);
    setSelected(null);
    setIsCleared(true);
    setIsM2Scanning(false);
    close();
  };

  // Button functions
  const filterContainerAssetCode = async () => {
    const data = await fetch(
      `/echoMe/rfid/getRfidTagContainer?rfids=${equipmentRFID}`
    );
    try {
      if (!data.ok) {
        throw new Error(`Problem with ${data.status}`);
      }
      const json = await data.json();
      const a = json?.map((d) => d.containerAssetCode);
      setContainerAssetCode(a[0]);
    } catch (err) {
      alert("There is something wrong with server. Please try again.");
    }
  };

  useEffect(() => {
    if (containerRfidList?.length === 1) {
      setTimeout(() => {
        filterContainerAssetCode(equipmentRFID);
      }, 1000);
    }
  }, [equipmentRFID, containerRfidList]);

  const handleIPChange = async (event) => {
    setScannerIP(event.target.value);
    // let tempSelected = scannerArray.filter((d) => d.value === event.target.value).map((d) => d)[0];
    // if (tempSelected.code.toLowerCase().includes("m2")) {
    //   setIsM2(true);
    // } else {
    //   setIsM2(false);
    // }
  };

  const completeButtonHandler = async () => {
    try {
      const data = await fetch(
        `/echoMe/ti/transferInComplete?tiNum=${encodeURIComponent(
          selected3?.tiNum
        )}`
      );
      if (!data.ok) {
        console.log("error message from transferOutComplete");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      // console.log(json);
      setSelect(null);
      load();
      handleCancel();
    } catch (err) {
      alert(err);
    }
  };

  const closeButtonHandler = async () => {
    backDrophandleOpen();
    if (!containerAssetCode) {
      alert("There is something wrong with container rfid.");
      backDrophandleClose();
    } else if (containerAssetCode) {
      try {
        const rfids = regList.map((d) => d?.rfid);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const data = await fetch(`/echoMe/ti/checkInItems`, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            containerAssetCode: containerAssetCode,
            rfids,
            tiNum: encodeURIComponent(selected3?.tiNum),
          }),
        });
        if (!data.ok) {
          console.log("error message from registerItems");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);

          throw Error(errorText);
        }
        const json = await data.json();
        // console.log(json);
        alert(`Item RFID is successfully registered.`);
        setSelect(null);
        load();
        clearButtonHandler();
        handleCancel();
        backDrophandleClose();
      } catch (err) {
        alert(err);
        backDrophandleClose();
      }
    }
  };

  const regenerateButtonHandler = async () => {
    const rfidArray = Object.values(transferSelected)
      ?.map((r) => r.itemRfid)
      ?.join(",");
    try {
      const data = await fetch(
        `/echoMe/reg/regenRfidItem?regNum=&regCheck=false&rfids=${rfidArray}`
      );
      if (!data.ok) {
        console.log("error message from regenRfidItem");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);

        throw Error(errorText);
      }
      const json = await data.json();
      printZpl(
        genMultItemRfidTagZpl(
          json?.map((e) => {
            const {
              eanupc = "1111111111",
              description = "NA",
              style = "NA",
              color = "NA",
              size = "NA",
              skuCode = "NA",
              exp = "NA",
              serial = "NA",
              rfid = "SNoRfidFoundData",
              productCode = "NA",
            } = e;
            console.log(e);
            let ssc = `${style}/${size}/${color}`;
            return {
              barCode: eanupc,
              des: description,
              ssc: ssc,
              sku: skuCode,
              exp: exp,
              serial: serial,
              rfid: rfid,
              productCode: productCode,
            };
          })
        )
      );
      load();
    } catch (err) {
      alert(err);
    }
  };

  const scanButtonHandler = async () => {
    startScanM2();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        fullWidth={true}
        maxWidth="xl"
        fullScreen={true}
      >
        <DialogTitle>
          {t("transferIn.DialogProcessTranIn.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("transferIn.DialogProcessTranIn.bodytext-documentNumber")}{" "}
            <b style={{ color: "red" }}>{selected3?.tiNum}</b>
          </DialogContentText>
          <Box
            variant="div"
            sx={{ padding: "20px", border: "1px solid", borderRadius: "8px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6">
                        {t(`transferIn.DialogProcessTranIn.sectionHeader2`)}
                      </Typography>
                    </Grid>{" "}
                    <Grid item xs={12} sm={3}>
                      {}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      {}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        variant="contained"
                        fullWidth={true}
                        style={{ color: "white" }}
                        onClick={containerRfidDeleteButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "complete" ||
                          selected3?.status.toLowerCase() === "cancel" ||
                          isScanning ||
                          !isScanned ||
                          !scannerIP ||
                          isM2Scanning ||
                          !containerRfidList?.length > 0 ||
                          toArray(containerSelected).length < 1
                        }
                      >
                        {t(`transferIn.DialogProcessTranIn.button-delete`)}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        variant="contained"
                        fullWidth={true}
                        style={{ color: "white" }}
                        onClick={closeButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "complete" ||
                          selected3?.status.toLowerCase() === "cancel" ||
                          isScanning ||
                          !isScanned ||
                          !scannerIP ||
                          isM2Scanning ||
                          !containerAssetCode
                        }
                      >
                        {t(`transferIn.DialogProcessTranIn.button-regItem`)}
                      </Button>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{ marginTop: "10px", marginLeft: "15px" }}
                      >
                        <ReactDataGrid
                          licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                          idProperty="rfid"
                          checkboxColumn
                          selected={containerSelected}
                          onSelectionChange={onContainerSelectionChange}
                          style={{ minHeight: 160 }}
                          groupColumn={true}
                          dataSource={containerRfidList}
                          columns={columns3}
                        />
                        <p>
                          {t(`transferIn.total`)}: {containerRfidList.length}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "row",
                    padding: "15px",
                    marginBottom: "1rem",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={10}>
                      <Typography variant="h6">
                        {t("transferIn.DialogProcessTranIn.sectionHeader")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography variant="h6" align="right" color="orange">
                        {selected3?.status?.replaceAll("_", " ")}
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            margin="dense"
                            id="from-input"
                            name="from"
                            label={t(
                              "transferIn.DialogProcessTranIn.input-from"
                            )}
                            type="text"
                            readOnly
                            fullWidth={true}
                            value={selected3?.shipFromLocation?.replaceAll(
                              "_",
                              " "
                            )}
                          />
                          <TextField
                            margin="dense"
                            id="to-input"
                            name="to"
                            label={t("transferIn.DialogProcessTranIn.input-to")}
                            type="text"
                            readOnly
                            fullWidth={true}
                            value={selected3?.shipToLocation}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            margin="dense"
                            id="date-input"
                            name="date"
                            label={t(
                              "transferIn.DialogProcessTranIn.input-Date"
                            )}
                            type="text"
                            readOnly
                            fullWidth={true}
                            value={
                              selected3?.createdDate === null
                                ? ""
                                : moment(selected3?.createdDate).format(
                                    "YYYY-MM-DD"
                                  ) ?? "None"
                            }
                          />
                          <TextField
                            margin="dense"
                            id="remark-input"
                            name="remark"
                            label={t(
                              "transferIn.DialogProcessTranIn.input-remarks"
                            )}
                            type="text"
                            readOnly
                            fullWidth={true}
                            // value={selected3?.shipType ?? "None"}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            margin="dense"
                            id="totalAsset-input"
                            name="totalAsset"
                            label={t(
                              "transferIn.DialogProcessTranIn.input-totalAsset"
                            )}
                            type="text"
                            readOnly
                            fullWidth={true}
                            value={selected3?.totalSkuCodeQty ?? "None"}
                          />
                          <TextField
                            margin="dense"
                            id="totalEquip-input"
                            name="totalEquip"
                            label={t(
                              "transferIn.DialogProcessTranIn.input-totalEquip"
                            )}
                            type="text"
                            readOnly
                            fullWidth={true}
                            value={selected3?.totalContainerQty ?? "None"}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "row",
                    padding: "15px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3}>
                        <Typography variant="h6">Register</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <FormControl fullWidth variant="standard">
                            <InputLabel id="ip-select">
                              {t(`transferIn.DialogProcessTranIn.dropDown-IP`)}
                            </InputLabel>
                            <Select
                              labelId="ip-label"
                              id="ip-select"
                              label={t(
                                `transferIn.DialogProcessTranIn.dropDown-IP`
                              )}
                              onChange={handleIPChange}
                              value={scannerIP}
                              disabled={
                                selected3?.status.toLowerCase() ===
                                  "complete" ||
                                selected3?.status.toLowerCase() === "cancel" ||
                                isScanning ||
                                isOneTimeReg ||
                                isM2Scanning
                              }
                            >
                              {scannerArray?.map((scanner) => (
                                <MenuItem
                                  key={scanner.id}
                                  value={scanner.value}
                                >
                                  {`${scanner.code} - ${scanner.value}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={1.5}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          disabled={
                            selected3?.status.toLowerCase() === "complete" ||
                            selected3?.status.toLowerCase() === "cancel" ||
                            selected3?.status.toLowerCase() === "draft" ||
                            selected3?.status.toLowerCase() === "initiated" ||
                            isScanning ||
                            !scannerIP ||
                            isM2Scanning
                          }
                          style={{ color: "white" }}
                          onClick={scanButtonHandler}
                        >
                          {t(`transferIn.DialogProcessTranIn.button-scan`)}
                        </Button>
                      </Grid>
                      {/* <Grid item xs={12} sm={1.5}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          style={{ color: "white", backgroundColor: "red" }}
                          disabled={
                            selected3?.status.toLowerCase() === "complete" ||
                            selected3?.status.toLowerCase() === "cancel" ||
                            !isScanning ||
                            !scannerIP ||
                            isM2Scanning
                          }
                          onClick={stopScanButtonHandler}
                        >
                          {t(`transferIn.DialogProcessTranIn.button-stop`)}
                        </Button>
                      </Grid> */}
                      <Grid item xs={12} sm={1.5}>
                        <Button
                          variant="contained"
                          fullWidth={true}
                          onClick={clearButtonHandler}
                          style={{
                            color: "white",
                            backgroundColor: "red",
                            marginRight: "-100px",
                          }}
                          disabled={
                            selected3?.status.toLowerCase() === "complete" ||
                            selected3?.status.toLowerCase() === "cancel" ||
                            !scannerIP
                          }
                        >
                          {t(`transferIn.DialogProcessTranIn.button-clear`)}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={1.5}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ color: "white", backgroundColor: "red" }}
                          fullWidth={true}
                          onClick={itemRFIDDeleteButtonHandler}
                          disabled={
                            selected3?.status.toLowerCase() === "complete" ||
                            selected3?.status.toLowerCase() === "cancel" ||
                            isScanning ||
                            !isScanned ||
                            isOneTimeReg ||
                            !scannerIP ||
                            !regList?.length > 0 ||
                            Object?.values(selected).length < 1 ||
                            isM2Scanning
                          }
                        >
                          {t(`transferIn.DialogProcessTranIn.button-delete`)}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <ReactDataGrid
                          licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                          idProperty="rfid"
                          checkboxColumn
                          // multiSelect={false}
                          selected={selected}
                          onSelectionChange={onSelectionChange}
                          style={gridStyle}
                          columns={columns2}
                          dataSource={regList}
                        />
                        <p>
                          {t(`transferIn.total`)}: {regList.length}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={7} mt={2}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px",
                    marginTop: "-7rem",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <Typography variant="h6">
                        {t("transferIn.DialogProcessTranIn.sectionHeader2")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} mt="-12px">
                      <Grid item xs={12} sm={12}>
                        {/* <TextField
                            margin="dense"
                            id="equipmentId-input"
                            name="equipmentId"
                            label={t("transferIn.DialogProcessTranIn.input-equipmentId")}
                            type="text"
                            fullWidth={true}
                            inputRef={equipmentRFIDRef}
                            onChange={currentInputChangeHandler}
                          /> */}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={2} mt="-12px">
                      <Grid item xs={12} sm={12}>
                        {/* <TextField
                            margin="dense"
                            id="itemRfid-input"
                            name="itemRfid"
                            label={t("transferIn.DialogProcessTranIn.input-itemRfid")}
                            type="text"
                            fullWidth={true}
                            inputRef={itemRFIDRef}
                            onChange={itemRfidInputChangeHandler}
                          /> */}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <Grid item xs={12} sm={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          style={{ color: "white" }}
                          onClick={loadTransferList}
                          disabled={
                            selected3?.status.toLowerCase() === "completed" ||
                            selected3?.status.toLowerCase() === "cancelled"
                          }
                        >
                          {t(
                            "transferIn.DialogEquipmentRegistration.button-refresh"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Grid item xs={12} sm={12}>
                        <Button
                          variant="contained"
                          fullWidth={true}
                          style={{
                            color: "white",
                            backgroundColor: "orange",
                          }}
                          onClick={regenerateButtonHandler}
                          disabled={
                            selected3?.status.toLowerCase() === "completed" ||
                            selected3?.status.toLowerCase() === "cancelled" ||
                            isScanning ||
                            !isCleared ||
                            isM2Scanning
                          }
                        >
                          {t("transferIn.DialogProcessTranIn.button-regen")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} mt="10px">
                      <ReactDataGrid
                        licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                        idProperty="id"
                        checkboxColumn
                        // multiSelect={false}
                        selected={transferSelected}
                        onSelectionChange={onTransferSelectionChange}
                        style={gridStyle2}
                        groupColumn={true}
                        defaultGroupBy={["productCode"]}
                        defaultCollapsedGroups={true}
                        columns={columns}
                        dataSource={dataArray}
                        max_height={500}
                        pagination={true}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={openBackDrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </DialogContent>
        <DialogActions>
          {(UserService?.getClientRole()?.includes("ROLE_ECHOME_TRANIN") ||
            UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERVISORS") ||
            UserService?.getClientRole()?.includes("ROLE_ECHOME_STORES") ||
            UserService?.getClientRole()?.includes(
              "ROLE_ECHOME_SUPERUSER"
            )) && (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={completeButtonHandler}
              disabled={
                selected3?.status.toLowerCase() === "completed" ||
                selected3?.status.toLowerCase() === "cancelled" ||
                isScanning ||
                isM2Scanning
              }
            >
              {t("transferIn.DialogProcessTranIn.button-complete")}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red" }}
            onClick={close}
            disabled={isScanning || isM2Scanning}
          >
            {t("transferIn.DialogProcessTranIn.button-exit")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogProcessTranIn;
