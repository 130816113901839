/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable prefer-const */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable no-return-assign */
import { useState, useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import DialogActions from "@mui/material/DialogActions";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Paper,
  MenuItem,
} from "@mui/material";
import RegisterProcessListData1 from "./ReturnProcessListData1";
import RegisterProcessListData2 from "./ReturnProcessListData2";
import Equipmentdialog from "./DialogEquipmentReturn";
import ManageDialog from "./DialogManageReturn";
import { printZpl } from "utility/printUtli";
import { genMultItemRfidTagZpl } from "utility/printUtli/itemLabelPrint";
import moment from "moment";
import DialogRegisteredEquipment from "./DialogRegisteredEquipmentReturn";
import DialogSelectToReturn from "./DialogSelectToReturn";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { useSelector, useDispatch } from "react-redux";
// // eslint-disable-next-line import/no-absolute-path
// import { equipmentIdActions } from "/Users/sungjaejoo/Desktop/echome-web/src/store/userSlice";

// eslint-disable-next-line arrow-body-style

const gridStyle = { minHeight: 322 };

const DialogProcessReturn = ({
  open,
  close,
  selected3,
  setChecked,
  load,
  filterDocumentObject,
}) => {
  // Dialog Equipment data logic
  const [openEquipment, setOpenEquipment] = useState(false);
  const [openRegisteredEquip, setoOpenRegisteredEquip] = useState(false);
  const [openSelectTO, setOpenSelectTO] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [isDeleteButtonPress, setIsDeleteButtonPress] = useState(false);
  const [selected, setSelected] = useState({});
  const [dataArray, setdataArray] = useState([]);
  const [regList, setRegList] = useState([]);
  const [containerRfidList, setContainerRfidList] = useState([]);
  const [selectedContainerRFID, setSelectedContainerRFID] = useState([]);
  const [toNum, setToNum] = useState("");
  const [containerCode, setContainerCode] = useState("");
  const [openManage, setOpenManage] = useState(false);
  const [equipmentRFID, setEquipmentRFID] = useState("");
  const [containerAssetCode, setContainerAssetCode] = useState("");
  const [isScanning, setIsScanning] = useState(false);
  const [isScanned, setIsScanned] = useState(false);
  const [isOneTimeReg, setIsOneTimeReg] = useState(false);
  const [scannerArray, setScannerArray] = useState([]);
  // const [scannerIP, setScannerIP] = useState("10.36.110.103");
  // const [scannerIP, setScannerIP] = useState("localhost");
  const [scannerIP, setScannerIP] = useState("");
  const [timerId, setTimerId] = useState("");
  const [isCleared, setIsCleared] = useState(true);
  const [isM2Scanning, setIsM2Scanning] = useState(false);
  const { t } = useTranslation(["assetReturn"]);

  const columns = [
    {
      name: "rfid",
      header: t(`assetReturn.Async.DialogProcessRegistration-rfid`),
      defaultFlex: 1,
      type: "string",
    },
    {
      name: "rsku",
      header: t(`assetReturn.Async.DialogProcessRegistration-rsku`),
      defaultFlex: 1,
      type: "string",
    },
  ];

  // Fetching functions
  // Fetching the product list whenever changes happened of selected3(Seleted)
  const loadProductList = async () => {
    const data = await fetch(
      `/echoMe/assetReturn/listAssetReturnLine?rtnNum=${selected3?.rtnNum}`
    );
    const json = await data.json();
    setdataArray(json);
    return json;
  };

  // React query responses
  const onSuccess = (data) => {
    console.log("Sucess", data);
  };

  const onError = (error) => {
    console.log(error);
  };

  // Fetching scanner List with reg num
  const getScannerList = async () => {
    const data = await fetch(
      `/echoMe/param/findAllActiveParamByCode?paramName=READER_IP`,
      {
        mode: "cors",
      }
    );
    const json = await data.json();
    setScannerArray(json);
    // setScannerIP(json[0].value);
    return json;
  };

  // // Reat query -scanner list
  const {
    data: scannerListProcessReturn,
    isFetching: isFetchingScannerListProcessReturn,
    refetch: refetchScannerListProcessReturn,
    isError: isErrorScannerListProcessReturn,
  } = useQuery("assetReturn-getScannerList", getScannerList, {
    onSuccess,
    onError,
    // refetchInterval: 1000,
    // refetchIntervalInBackground: true,
    // refetchOnWindowFocus: true,
    enabled: false,
  });

  // Reat query -load product list
  const {
    data: assetReturnProcess,
    isFetching: isFetchingAssetReg,
    refetch: refetchAssetReturnProductList,
    isError: isErrorLoadProductList,
  } = useQuery("assetReturn-loadProductList", loadProductList, {
    onSuccess,
    onError,
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
  });

  const getData = (arrayData) => {
    setSelectedContainerRFID(
      arrayData === null || arrayData === undefined ? {} : arrayData
    );
  };

  // Fetching scanner list whenever dialog is opened
  useEffect(() => {
    if (selected3?.rtnNum) {
      refetchScannerListProcessReturn();
    }
  }, [open]);

  useEffect(() => {
    refetchAssetReturnProductList();
  }, [selected3, open]);

  // Dialog Manage data logic
  const dialogEquipmentClickHandler = () => {
    setOpenEquipment(true);
  };

  const dialogEquipmentCloseHandler = () => {
    setOpenEquipment(false);
  };

  const dialogManageClickHandler = () => {
    setOpenManage(true);
  };

  const dialogManageCloseHandler = () => {
    setOpenManage(false);
  };

  const dialogRegisteredClickHandler = () => {
    setoOpenRegisteredEquip(true);
  };

  const dialogRegisteredCloseHandler = () => {
    setoOpenRegisteredEquip(false);
  };

  const dialogSelectTOClickHandler = () => {
    setOpenSelectTO(true);
  };

  const dialogSelectTOCloseHandler = () => {
    setOpenSelectTO(false);
  };

  const backDrophandleClose = () => {
    setOpenBackDrop(false);
  };

  const backDrophandleOpen = () => {
    setOpenBackDrop(true);
  };

  const handleChange = async (event) => {
    setScannerIP(event.target.value);
    // let tempSelected = scannerArray.filter((d) => d.value === event.target.value).map((d) => d)[0];
    // if (tempSelected.code.toLowerCase().includes("m2")) {
    //   setIsM2(true);
    // } else {
    //   setIsM2(false);
    // }
  };

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const getSelectedToNum = (selectedToNum) => {
    setToNum(selectedToNum);
  };

  // Button functions
  const filterContainerAssetCode = async (rfid) => {
    const data = await fetch(`/echoMe/rfid/getRfidTagContainer?rfids=${rfid}`);
    try {
      if (!data.ok) {
        console.error(
          "Container does not have Container Access code. Please try again."
        );
      }
      const json = await data.json();
      // console.log(json);

      const a = json?.map((d) => d.containerAssetCode);
      setContainerAssetCode(a[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const clearButtonHandler = async () => {
    if (scannerIP) {
      setEquipmentRFID("");
      setContainerCode("");
      setIsScanned(false);
      setIsOneTimeReg(false);
      setIsScanning(false);
      setContainerRfidList([]);
      setSelectedContainerRFID("");
      setRegList([]);
      setSelected(null);
      setIsCleared(true);
      setIsM2Scanning(false);
      setIsDeleteButtonPress(false);
    }
  };

  const stopScanButtonHandler = async () => {
    if (scannerIP) {
      setIsScanning(false);
      clearTimeout(timerId); // clear the timeoutID
      setTimerId("");
    }
  };

  const containerRfidDeleteButtonHandler = async () => {
    setIsDeleteButtonPress(true);
    const temp = _.difference(containerRfidList, selectedContainerRFID);
    setContainerRfidList(temp);

    // filtering container code rfid
    const filteredContainerCode = temp
      ?.filter((d) => d.rfid.includes("CAT"))
      ?.map((d) => d.rfid)
      ?.join(",");
    setEquipmentRFID(filteredContainerCode);
  };

  const itemRFIDDeleteButtonHandler = async () => {
    const temp = _.difference(regList, Object?.values(selected));
    setRegList(temp);
    setSelected({});
  };

  useEffect(() => {
    if (containerRfidList?.length === 1) {
      setTimeout(() => {
        filterContainerAssetCode(equipmentRFID);
      }, 1000);
    }
  }, [equipmentRFID, containerRfidList]);

  const handleClose = () => {
    setToNum("");
    setTimerId("");
    setContainerCode("");
    setEquipmentRFID("");
    setContainerAssetCode("");
    setRegList([]);
    setContainerRfidList([]);
    stopScanButtonHandler();
    setSelectedContainerRFID("");
    clearButtonHandler();
    setScannerArray([]);
    setScannerIP("");
    load();
    setIsScanned(false);
    setIsOneTimeReg(false);
    setIsScanning(false);
    setSelected({});
    setChecked(null);
    setIsCleared(true);
    setIsM2Scanning(false);
    setIsDeleteButtonPress(false);
    close();
  };

  const generateRfidItem = async () => {
    if (toNum) {
      try {
        const data = await fetch(
          `/echoMe/assetReturn/genRfidItem?rtnNum=${encodeURIComponent(
            selected3?.rtnNum
          )}&toNum=${toNum}`
        );
        if (!data.ok) {
          console.log("error message from generateRfidItem");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        const json = await data.json();
        printZpl(
          genMultItemRfidTagZpl(
            json?.map((e) => {
              const {
                eanupc = "1111111111",
                description = "NA",
                style = "NA",
                color = "NA",
                size = "NA",
                skuCode = "NA",
                exp = "NA",
                serial = "NA",
                rfid = "SNoRfidFoundData",
                productCode = "NA",
              } = e;
              console.log(e);
              let scs = `${style}/${color}/${size}`;
              return {
                barCode: eanupc,
                des: description,
                scs: scs,
                sku: skuCode,
                exp: exp,
                serial: serial,
                rfid: rfid,
                productCode: productCode,
              };
            })
          )
        );
        load();
        filterDocumentObject();
        refetchAssetReturnProductList();
      } catch (err) {
        console.error(err);
        alert(err);
      }
    } else if (!toNum) {
      alert("Please select TO number to process generating RFID.");
    }
  };

  const startScanM2 = async () => {
    if (scannerIP) {
      setIsM2Scanning(true);
      backDrophandleOpen();
      try {
        const data = await fetch(
          // `http://192.168.1.141:8080/echoMe/rfid/getReaderData?ipAdd=${scannerIP}`,
          `echoMe/rfid/getReaderData?ipAdd=${scannerIP}`,
          // `/echoMe/rfid/getReaderData?ipAdd=dummyData`,
          {
            mode: "cors",
          }
        );
        if (!data.ok) {
          console.log("error message from submitHandler");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        setIsM2Scanning(false);
        const json = await data.json();

        // Filtering RFID for Display
        const filteredRegData = [];
        json
          ?.filter((d) => d?.rfid.includes("SAT"))
          .map((d) => filteredRegData.push(d));
        // If first time scanning
        if (_.isEmpty(regList)) {
          setRegList(filteredRegData);
        } else if (!_.isEmpty(regList)) {
          // If more than one time then accumulate result
          const temp = _.differenceBy(filteredRegData, regList, "rfid");
          const removeDupReg = _.uniqBy([...temp, ...regList], "rfid");
          setRegList(removeDupReg);
        }

        // Filtering Container RFID for Display
        const filteredContainerData = [];
        json
          ?.filter((d) => d?.rfid.includes("CAT"))
          .map((d) => filteredContainerData.push(d));
        // If first time scanning
        if (_.isEmpty(containerRfidList)) {
          setContainerRfidList(filteredContainerData);
        } else if (!_.isEmpty(containerRfidList)) {
          // If more than one time then accumulate result
          const temp = _.differenceBy(
            filteredContainerData,
            containerRfidList,
            "rfid"
          );
          const removeDupCon = _.uniqBy(
            [...temp, ...containerRfidList],
            "rfid"
          );
          setContainerRfidList(removeDupCon);
        }

        // filtering container code rfid (needed for the first case)
        const filteredContainerCode = filteredContainerData?.map(
          (d) => d?.rfid
        );

        //If container accesscode has one then filter container access code
        if (filteredContainerCode?.length === 1) {
          await filterContainerAssetCode(filteredContainerCode[0]);
          setEquipmentRFID(filteredContainerCode[0]);
        }

        setIsScanning(false);
        setIsScanned(true);
        setIsCleared(false);
        backDrophandleClose();
      } catch (err) {
        console.error(err);
        backDrophandleClose();
        alert(err);
      }
    }
  };

  const registerEquipmentHandler = async () => {
    backDrophandleOpen();
    if (equipmentRFID) {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const rfids = containerRfidList?.map((d) => d?.rfid);
        const data = await fetch(`/echoMe/assetReturn/checkInContainer`, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            rfids,
            rtnNum: encodeURIComponent(selected3?.rtnNum),
          }),
        });
        if (!data.ok) {
          console.log("error message from registerContainers");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        const json = await data.json();
        setContainerCode(json[0].containerCode);
        alert(
          t(
            `assetReturn.DialogProcessRegistration.alert-registerEquipmentHandler2`
          )
        );
        refetchAssetReturnProductList();
        backDrophandleClose();
      } catch (err) {
        alert(err);
        backDrophandleClose();
      }
    } else if (!equipmentRFID) {
      alert(
        t(`assetReturn.DialogProcessReturn.alert-registerEquipmentHandler`)
      );
      backDrophandleClose();
    }
  };

  const closeButtonHandler = async () => {
    backDrophandleOpen();
    if (!containerAssetCode) {
      alert(t(`assetReturn.DialogProcessReturn.alert-closeButtonHandler`));
      backDrophandleClose();
    } else if (containerAssetCode) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const rfids = regList.map((d) => d?.rfid);
      try {
        const data = await fetch(`/echoMe/assetReturn/checkInItems`, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            containerAssetCode,
            rfids,
            rtnNum: encodeURIComponent(selected3?.rtnNum),
          }),
        });
        if (!data.ok) {
          console.log("error message from registerItems");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);

          throw Error(errorText);
        }
        const json = await data.json();
        // console.log(json);
        alert(
          t(`assetReturn.DialogProcessRegistration.alert-closeButtonHandler2`)
        );
        refetchAssetReturnProductList();
        setIsOneTimeReg(false);
        load();
        filterDocumentObject();
        clearButtonHandler();
        backDrophandleClose();
      } catch (err) {
        alert(err);
        setIsOneTimeReg(false);
        backDrophandleClose();
      }
    }
  };

  const completeButtonHandler = async () => {
    try {
      const data = await fetch(
        `/echoMe/assetReturn/returnComplete?rtnNum=${encodeURIComponent(
          selected3?.rtnNum
        )}`
      );
      if (!data.ok) {
        console.log("error message from registerComplete");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      // console.log(json);
      setChecked(null);
      handleClose();
      load();
    } catch (err) {
      alert(err);
    }
  };

  const scanButtonHandler = async () => {
    startScanM2();
  };

  const oneButtonHandler = async () => {
    setIsOneTimeReg(true);
    await registerEquipmentHandler();
    await closeButtonHandler();
    setIsOneTimeReg(false);
  };

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth="xl" fullScreen={true}>
        <DialogTitle>
          {t("assetReturn.DialogProcessReturn.header-name")}
          <Button
            color="primary"
            size="medium"
            variant="contained"
            style={{ float: "right", color: "white", marginRight: "20px" }}
            onClick={dialogEquipmentClickHandler}
            disabled={isScanning || isM2Scanning}
          >
            {t("assetReturn.DialogProcessReturn.header-button")}
          </Button>
        </DialogTitle>
        <DialogContent sx={{ padding: "10px" }}>
          <DialogContentText align="left">
            {t("assetReturn.DialogProcessReturn.bodytext-documentNumber")}{" "}
            <b style={{ color: "red" }}>{selected3?.rtnNum}</b>
          </DialogContentText>
          <Box
            variant="div"
            sx={{ padding: "25px", border: "1px solid", borderRadius: "8px" }}
          >
            <Grid container spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                  <Paper
                    elevation={8}
                    sx={{
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      padding: "15px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6">
                          {t(
                            "assetReturn.DialogProcessReturn.sectionHeader-equipment"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          sx={{ ml: "-8px" }}
                          style={{ color: "white", backgroundColor: "red" }}
                          onClick={containerRfidDeleteButtonHandler}
                          disabled={
                            selected3?.status.toLowerCase() === "completed" ||
                            selected3?.status.toLowerCase() === "cancelled" ||
                            isScanning ||
                            !isScanned ||
                            isOneTimeReg ||
                            !scannerIP ||
                            !equipmentRFID ||
                            !containerRfidList?.length > 0 ||
                            selectedContainerRFID.length < 1 ||
                            isM2Scanning
                          }
                        >
                          {t("assetReturn.DialogProcessReturn.button-delete")}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          sx={{ ml: "-8px" }}
                          style={{ color: "white", backgroundColor: "red" }}
                          onClick={oneButtonHandler}
                          disabled={
                            selected3?.status.toLowerCase() === "completed" ||
                            selected3?.status.toLowerCase() === "cancelled" ||
                            isScanning ||
                            !isScanned ||
                            isOneTimeReg ||
                            !scannerIP ||
                            !equipmentRFID ||
                            !containerAssetCode ||
                            isM2Scanning ||
                            containerRfidList?.length > 1 ||
                            regList?.length <= 0
                          }
                        >
                          {t("assetReturn.DialogProcessReturn.button-one")}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          sx={{ ml: "-4px" }}
                          style={{ color: "white" }}
                          onClick={registerEquipmentHandler}
                          disabled={
                            selected3?.status.toLowerCase() === "completed" ||
                            selected3?.status.toLowerCase() === "cancelled" ||
                            isScanning ||
                            !isScanned ||
                            isOneTimeReg ||
                            !scannerIP ||
                            !equipmentRFID ||
                            isM2Scanning ||
                            containerRfidList?.length < 0
                          }
                        >
                          {t("assetReturn.DialogProcessReturn.button-regEquip")}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Button
                          variant="contained"
                          fullWidth={true}
                          style={{ color: "white" }}
                          onClick={closeButtonHandler}
                          disabled={
                            selected3?.status.toLowerCase() === "completed" ||
                            selected3?.status.toLowerCase() === "cancelled" ||
                            isScanning ||
                            !isScanned ||
                            isOneTimeReg ||
                            !scannerIP ||
                            !containerAssetCode ||
                            isM2Scanning
                          }
                        >
                          {t("assetReturn.DialogProcessReturn.button-regItem")}
                        </Button>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{ marginTop: "10px", marginLeft: "15px" }}
                        >
                          <RegisterProcessListData1
                            filteredData={containerRfidList}
                            getData={getData}
                            setIsDeleteButtonPress={setIsDeleteButtonPress}
                            isDeleteButtonPress={isDeleteButtonPress}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Paper
                    elevation={8}
                    sx={{
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "15px",
                    }}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography variant="h6">
                        {t(
                          "assetReturn.DialogProcessReturn.sectionHeader-docInfo"
                        )}
                      </Typography>
                      <TextField
                        margin="dense"
                        id="rtnNum-input"
                        name="rtnNum"
                        label={t(
                          "assetReturn.DialogProcessReturn.input-rtnNum"
                        )}
                        type="text"
                        fullWidth={true}
                        InputProps={{
                          readOnly: true,
                        }}
                        value={selected3?.rtnNum ?? "None"}
                      />
                      <TextField
                        margin="dense"
                        id="toNum-input"
                        name="toNum"
                        label={t("assetReturn.DialogProcessReturn.input-toNum")}
                        type="text"
                        fullWidth={true}
                        InputProps={{
                          readOnly: true,
                        }}
                        value={toNum}
                      />
                      <TextField
                        margin="dense"
                        id="rtnDate-input"
                        name="rtnDate"
                        label={t(
                          "assetReturn.DialogProcessReturn.input-rtnDate"
                        )}
                        type="text"
                        fullWidth={true}
                        InputProps={{
                          readOnly: true,
                        }}
                        value={
                          selected3?.rtnDate === null
                            ? ""
                            : moment(selected3?.rtnDate).format("YYYY-MM-DD")
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} ml="20px" mt="25px">
                      <TextField
                        margin="dense"
                        id="totalProduct-input"
                        name="totalProduct"
                        label={t(
                          "assetReturn.DialogProcessReturn.input-totalProduct"
                        )}
                        type="text"
                        fullWidth={true}
                        InputProps={{
                          readOnly: true,
                        }}
                        value={selected3?.totalSkuCodeQty ?? "None"}
                      />
                      <TextField
                        margin="dense"
                        id="totalQuantity-input"
                        name="totalQuantity"
                        label={t(
                          "assetReturn.DialogProcessReturn.input-totalQuantity"
                        )}
                        type="text"
                        fullWidth={true}
                        InputProps={{
                          readOnly: true,
                        }}
                        value={selected3?.totalOrderQty ?? "None"}
                      />
                      <TextField
                        margin="dense"
                        id="totalTracker-input"
                        name="totalTracker"
                        label={t(
                          "assetReturn.DialogProcessReturn.input-totalTracker"
                        )}
                        type="text"
                        fullWidth={true}
                        InputProps={{
                          readOnly: true,
                        }}
                        value={`${selected3?.totalCheckinQty ?? "None"}/${
                          selected3?.totalOrderQty ?? "None"
                        }`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} ml="20px">
                      <Typography variant="h6" align="right" color="orange">
                        {selected3?.status?.replaceAll("_", " ") ?? "Undefined"}
                      </Typography>
                      <TextField
                        margin="dense"
                        id="supplier-input"
                        name="supplier"
                        label={t(
                          "assetReturn.DialogProcessReturn.input-supplier"
                        )}
                        type="text"
                        fullWidth={true}
                        InputProps={{
                          readOnly: true,
                        }}
                        value={selected3?.shipperCode ?? "None"}
                      />
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4.95}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "row",
                    padding: "15px",
                    marginLeft: "-15px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6">
                        {t(
                          "assetReturn.DialogProcessReturn.sectionHeader-regInfo"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <FormControl fullWidth variant="standard">
                          <InputLabel id="simple-select-label">
                            {t("assetReturn.DialogProcessReturn.ipDropDown")}
                            {/* {scannerIP} */}
                          </InputLabel>
                          <Select
                            labelId="simple-select-label"
                            id="demo-simple-select"
                            label="Site"
                            onChange={handleChange}
                            value={scannerIP}
                            disabled={
                              selected3?.status.toLowerCase() === "completed" ||
                              selected3?.status.toLowerCase() === "cancelled" ||
                              isScanning ||
                              isOneTimeReg ||
                              !isCleared ||
                              isM2Scanning
                            }
                            // disabled={true}
                          >
                            {scannerArray?.map((scanner) => (
                              <MenuItem key={scanner.id} value={scanner.value}>
                                {`${scanner.code} - ${scanner.value}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: "white" }}
                        fullWidth={true}
                        onClick={scanButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          isScanning ||
                          !scannerIP ||
                          isM2Scanning
                        }
                      >
                        {t("assetReturn.DialogProcessReturn.button-scan")}
                      </Button>
                    </Grid>
                    {/* <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: "white", backgroundColor: "red" }}
                        fullWidth={true}
                        onClick={stopScanButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          !isScanning ||
                          !scannerIP ||
                          isM2Scanning
                        }
                      >
                        {t("assetReturn.DialogProcessReturn.button-stop")}
                      </Button>
                    </Grid> */}
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: "white", backgroundColor: "red" }}
                        fullWidth={true}
                        onClick={clearButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          !scannerIP
                        }
                      >
                        {t("assetReturn.DialogProcessReturn.button-clear")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: "white", backgroundColor: "red" }}
                        fullWidth={true}
                        onClick={itemRFIDDeleteButtonHandler}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          isScanning ||
                          !isScanned ||
                          isOneTimeReg ||
                          !scannerIP ||
                          !regList?.length > 0 ||
                          Object?.values(selected).length < 1 ||
                          isM2Scanning
                        }
                      >
                        {t("assetReturn.DialogProcessReturn.button-delete")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ReactDataGrid
                        licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                        idProperty="rfid"
                        checkboxColumn
                        // multiSelect={false}
                        selected={selected}
                        onSelectionChange={onSelectionChange}
                        style={gridStyle}
                        columns={columns}
                        dataSource={regList}
                      />
                      <p>
                        {t(`assetReturn.total`)}: {regList.length}
                      </p>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={7.05}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "row",
                    padding: "15px",
                    marginTop: "-3rem",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4.5}>
                      <Typography variant="h6">
                        {t(
                          "assetReturn.DialogProcessReturn.sectionHeader-productList"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        fullWidth={true}
                        onClick={generateRfidItem}
                        style={{ color: "white" }}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          selected3?.status.toLowerCase() === "registering" ||
                          selected3?.status.toLowerCase() ===
                            "rfid_tag_printed" ||
                          isScanning ||
                          isM2Scanning
                        }
                      >
                        {t("assetReturn.DialogProcessReturn.button-genRFID")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        size="small"
                        color="warning"
                        fullWidth={true}
                        onClick={refetchAssetReturnProductList}
                        style={{ color: "white", backgroundColor: "orange" }}
                        disabled={isScanning || isM2Scanning}
                      >
                        {t("assetReturn.DialogEquipmentReturn.button-refresh")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        size="small"
                        color="warning"
                        fullWidth={true}
                        onClick={dialogManageClickHandler}
                        style={{ color: "white", backgroundColor: "orange" }}
                        disabled={isScanning || isM2Scanning}
                      >
                        {t("assetReturn.DialogProcessReturn.button-manageRFID")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={1.5} sx={{ ml: "auto", mb: "10px" }}>
                      <Button
                        variant="contained"
                        size="small"
                        color="warning"
                        fullWidth={true}
                        onClick={dialogRegisteredClickHandler}
                        style={{ color: "white", backgroundColor: "orange" }}
                        disabled={isScanning || isM2Scanning}
                      >
                        {t("assetReturn.DialogProcessReturn.button-regEquip2")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        size="small"
                        fullWidth={true}
                        onClick={dialogSelectTOClickHandler}
                        style={{ color: "white", backgroundColor: "orange" }}
                        disabled={
                          selected3?.status.toLowerCase() === "completed" ||
                          selected3?.status.toLowerCase() === "cancelled" ||
                          isScanning ||
                          isM2Scanning
                        }
                      >
                        {t("assetReturn.DialogProcessReturn.button-selectTo")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <RegisterProcessListData2 filteredData={dataArray}>
                        {" "}
                      </RegisterProcessListData2>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={openBackDrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={completeButtonHandler}
            disabled={
              selected3?.status.toLowerCase() === "completed" ||
              selected3?.status.toLowerCase() === "cancelled" ||
              isScanning ||
              isM2Scanning
            }
          >
            {t("assetReturn.DialogSelectToReturn.button-complete")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red" }}
            onClick={handleClose}
            disabled={isScanning || isM2Scanning}
          >
            {t("assetReturn.DialogSelectToReturn.button-close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Equipmentdialog
        // selected={JSON.stringify(selected)}
        open={openEquipment}
        close={dialogEquipmentCloseHandler}
      />
      <ManageDialog
        // selected={JSON.stringify(selected)}
        open={openManage}
        close={dialogManageCloseHandler}
        rtnNum={selected3?.rtnNum}
        selected3={selected3}
      />
      <DialogRegisteredEquipment
        // selected={JSON.stringify(selected)}
        open={openRegisteredEquip}
        close={dialogRegisteredCloseHandler}
        rtnNum={selected3?.rtnNum}
        selected3={selected3}
      />
      <DialogSelectToReturn
        // selected={JSON.stringify(selected)}
        open={openSelectTO}
        close={dialogSelectTOCloseHandler}
        rtnNum={selected3?.rtnNum}
        getSelectedToNum={getSelectedToNum}
      />
    </>
  );
};

export default DialogProcessReturn;
