/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useSelector, useDispatch } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import {
  Grid,
  TextField,
  Box,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// eslint-disable-next-line arrow-body-style
const DialogZoneAdd = ({ open, close, load, site }) => {
  const [zoneCode, setZoneCode] = useState("");
  const [description, setDescription] = useState("");
  const [zoneTypeArray, setZoneTypeArray] = useState([]);
  const [zoneType, setZoneType] = useState();

  // Input fields change handler
  const zoneCodeChangeHandler = (event) => {
    setZoneCode(event.target.value);
  };

  const descriptionChangeHandler = (event) => {
    setDescription(event.target.value);
  };

  const handleChange = (event) => {
    let temp = event.target.value;
    setZoneType(zoneTypeArray?.filter((r) => temp === r.typeCode)[0]);
  };

  //  Button functions;
  const closeHandler = () => {
    setDescription("");
    setZoneCode("");
    setZoneType("");
    load();
    close();
  };

  //  Fetching zonetype list for drop down menu
  const zoneTypeHandler = async () => {
    const data = await fetch(`/echoMe/loc/listLocZoneType`);
    const json = await data.json();
    let temp = [];
    json?.map((d) => temp.push(d));
    setZoneTypeArray(temp);
  };

  useEffect(() => {
    zoneTypeHandler();
  }, [open]);

  const submitHandler = async () => {
    // Allow user to enter all fields
    if (!zoneCode || !description || !zoneType) {
      alert("Please input all the fields.");
    } else {
      const temp = {
        site: site,
        zoneCode: zoneCode,
        locZoneType: zoneType,
        description: description,
      };
      const obj = JSON.stringify(temp);
      console.log(obj);
      try {
        //  If all fields are entered.
        const data = await fetch(`/echoMe/loc/newLocZone?locZone=${obj}`, {
          method: "GET",
          mode: "no-cors",
        });
        if (!data.ok) {
          console.log("error message from addLocation");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        // update the object and clear all fields and load data.
        closeHandler();
      } catch (err) {
        console.error(err);
        alert(err);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="sm">
        <DialogTitle>Add New Zone</DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            Please Input the Zone information.
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  id="zoneCode-input"
                  name="zoneCode"
                  label="Zone Code"
                  type="text"
                  fullWidth={true}
                  onChange={zoneCodeChangeHandler}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  id="description-input"
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth={true}
                  onChange={descriptionChangeHandler}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ minWidth: 100 }}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="simple-select-label">Zone Type</InputLabel>
                    <Select
                      labelId="simple-select-label"
                      id="simple-select"
                      defaultValue=""
                      label="Zone Type"
                      onChange={handleChange}
                    >
                      {zoneTypeArray?.map((zone) => (
                        <MenuItem key={zone.typeCode} value={zone.typeCode}>
                          {zone.typeCode}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={submitHandler}
              style={{ color: "white", backgroundColor: "blue" }}
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={closeHandler}
              style={{ color: "white", backgroundColor: "red" }}
            >
              Close
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogZoneAdd;
