/* eslint-disable object-shorthand */
/* eslint-disable prefer-template */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, TextField, Box, Typography } from "@mui/material";
import { useState, useRef } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { printZpl } from "utility/printUtli";
import { genMultItemRfidTagZpl } from "utility/printUtli/itemLabelPrint";

// eslint-disable-next-line arrow-body-style
const DialogPartialPrintRegistration = ({ open, close, registerData }) => {
  const [expDate, setexpDate] = useState();
  const [serialNum, setSerialNum] = useState();
  const [qty, setQty] = useState();
  const [isNext, setIsNext] = useState(false);
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const serialRef = useRef("");
  const { t } = useTranslation(["assetRegistration"]);

  // Input change Handler
  const expDateChangeHandler = (event) => {
    setexpDate(event.target.value);
  };

  const serialNumChangeHandler = (event) => {
    setSerialNum(event.target.value);
  };

  const qtyChangeHandler = (event) => {
    setQty(event.target.value);
  };

  const handleClose = () => {
    //  Resetting the values
    setexpDate("");
    setSerialNum("");
    setQty("");
    setActiveStep(0);
    setIsNext(false);
    close();
  };

  //  Button functions
  const nextButtonHandler = async (event) => {
    event.preventDefault();
    // console.log(expDate, serialNum, qty);
    if (!qty || qty <= 0) {
      alert(
        t(`assetReg.DialogPartialPrintRegistration.alert-nextButtonHandler1`)
      );
    } else if (qty > registerData[0].quantity || qty > 16) {
      alert(
        t(`assetReg.DialogPartialPrintRegistration.alert-nextButtonHandler2`)
      );
    } else if (qty <= registerData[0].quantity && qty <= 16 && qty > 0) {
      setIsNext(true);
      // Dynamically create the array and fill with step
      let temp = [];
      temp.length = qty;
      setSteps(temp.fill()?.map((_, index) => index + 1));
      // console.log(steps);
    }
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

    if (serialNum) {
      //  fetching data
      const urlString = expDate
        ? `/echoMe/reg/genPartialRfid?expDateString=${expDate}&lineId=${registerData[0].id}&qty=${qty}&sertalNo=${serialNum}`
        : `/echoMe/reg/genPartialRfid?lineId=${registerData[0].id}&qty=${qty}&sertalNo=${serialNum}`;
      try {
        const data = await fetch(urlString);
        if (!data.ok) {
          console.log("error message from partial print with serial num");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }

        const json = await data.json();
        printZpl(
          genMultItemRfidTagZpl(
            json?.map((e) => {
              const {
                eanupc = "1111111111",
                description = "NA",
                style = "NA",
                color = "NA",
                size = "NA",
                skuCode = "NA",
                exp = "NA",
                serial = "NA",
                rfid = "SNoRfidFoundData",
                productCode = "NA",
              } = e;
              console.log(e);
              let ssc = `${style}/${size}/${color}`;
              return {
                barCode: eanupc,
                des: description,
                ssc: ssc,
                sku: skuCode,
                exp: exp,
                serial: serial,
                rfid: rfid,
                productCode: productCode,
              };
            })
          )
        );
        //  Resetting the values and close
        setSerialNum("");
        serialRef.current.value = "";
      } catch (err) {
        console.error(err);
        alert(err);
      }
    }
  };

  // Stepper
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="md">
        <DialogTitle>
          {t("assetReg.DialogPartialPrintRegistration.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {!isNext
              ? t("assetReg.DialogPartialPrintRegistration.body1")
              : t("assetReg.DialogPartialPrintRegistration.body2")}
            {!isNext ? (
              <>
                <br />
                <strong>
                  {t("assetReg.DialogPartialPrintRegistration.body3")}
                </strong>
              </>
            ) : null}
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <Grid container spacing={2} mt="5px">
              <Grid container spacing={2}>
                {!isNext ? (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      id="expDate-input"
                      name="expDate"
                      label={t(
                        "assetReg.DialogPartialPrintRegistration.input-expDate"
                      )}
                      type="date"
                      fullWidth={true}
                      focused
                      onChange={expDateChangeHandler}
                      sx={{
                        input: {
                          color: "blue",
                        },
                        "& label.Mui-focused": {
                          color: "#000000",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#d2d6da",
                          },
                        },
                      }}
                    />
                  </Grid>
                ) : null}
                {!isNext ? (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      id="qty-input"
                      name="qty"
                      label={t(
                        "assetReg.DialogPartialPrintRegistration.input-qty"
                      )}
                      type="number"
                      fullWidth={true}
                      onChange={qtyChangeHandler}
                    />
                  </Grid>
                ) : null}
                {isNext ? (
                  <Grid item xs={12} sm={12}>
                    <TextField
                      margin="dense"
                      id="serialNum-input"
                      focused
                      name="serialNum"
                      label={t(
                        "assetReg.DialogPartialPrintRegistration.input-serialNum"
                      )}
                      type="string"
                      fullWidth={true}
                      inputRef={serialRef}
                      onChange={serialNumChangeHandler}
                      disabled={activeStep === steps?.length}
                      autoFocus={true}
                    />
                  </Grid>
                ) : null}
                {isNext ? (
                  <Grid item xs={12} sm={12}>
                    <Box sx={{ width: "100%" }}>
                      <Stepper activeStep={activeStep}>
                        {steps?.map((label, index) => {
                          const stepProps = {};
                          const labelProps = {};
                          // if (isStepOptional(index)) {
                          //   labelProps.optional = (
                          //     <Typography variant="caption">Optional</Typography>
                          //   );
                          // }
                          // if (isStepSkipped(index)) {
                          //   stepProps.completed = false;
                          // }
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                      {activeStep === steps?.length ? (
                        <>
                          <Typography
                            variant="h6"
                            align="left"
                            sx={{ mt: 2, mb: 1 }}
                          >
                            {t(
                              "assetReg.DialogPartialPrintRegistration.text-complete"
                            )}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                            }}
                          >
                            <Box sx={{ flex: "1 1 auto" }} />
                            {/* <Button onClick={handleReset}>Reset</Button> */}
                          </Box>
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="h6"
                            align="left"
                            sx={{ mt: 2, mb: 1 }}
                          >
                            {t(
                              "assetReg.DialogPartialPrintRegistration.text-current"
                            )}{" "}
                            {activeStep + 1}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                            }}
                          >
                            {/* <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                              >
                                Back
                              </Button> */}
                            <Box sx={{ flex: "1 1 auto" }} />
                            {/* <Button onClick={handleNext}>
                                {activeStep === steps.length - 1 ? "Finish" : "Next"}
                              </Button> */}
                          </Box>
                        </>
                      )}
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {!isNext ? (
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                fullWidth={true}
                onClick={nextButtonHandler}
                style={{ color: "white", backgroundColor: "blue" }}
              >
                {t("assetReg.DialogPartialPrintRegistration.button-next")}
              </Button>
            </Grid>
          ) : null}
          {isNext ? (
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                fullWidth={true}
                onClick={() => {
                  if (!serialNum) {
                    alert(
                      t(`assetReg.DialogPartialPrintRegistration.alert-body`)
                    );
                  } else if (serialNum) handleNext();
                }}
                style={{ color: "white", backgroundColor: "blue" }}
                disabled={activeStep === steps.length}
              >
                {t("assetReg.DialogPartialPrintRegistration.button-genNext")}
              </Button>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={handleClose}
              style={{ color: "white", backgroundColor: "red" }}
            >
              {t("assetReg.DialogPartialPrintRegistration.button-close")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      {/* <Alert variant="outlined" severity="warning">
        {message}
      </Alert> */}
    </>
  );
};

export default DialogPartialPrintRegistration;
