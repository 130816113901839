/* eslint-disable array-callback-return */
/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { isInteger } from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Menu,
  MenuItem,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UserService from "../../../service/KeyCloakService";

// eslint-disable-next-line arrow-body-style
const ScannerConfigDialogAdd = ({ open, close, load, selected }) => {
  const [ip, setIP] = useState();
  const [description, setDescription] = useState();
  const { t } = useTranslation(["setting"]);

  // Change input handlers
  const ipInputChangeHandler = (e) => {
    setIP(e.target.value.toUpperCase());
  };

  const descriptionInputChangeHandler = (e) => {
    setDescription(e.target.value.toUpperCase());
  };

  // Button functions
  const handleClose = () => {
    setDescription("");
    setIP("");
    close();
  };

  const modifyRules = async () => {
    const userName = UserService.getUsername();
    if (!ip || !description) {
      alert(t(`setting.ContainerRuleConfigDialogModify.alert-addRules`));
    } else {
      // If they enters the all fields
      const temp = {
        id: selected.id,
        site: selected.site,
        paramName: "READER_IP",
        code: description,
        value: ip,
        maker: userName,
        effectiveDate: selected.effectiveDate,
        cessationDate: selected.cessationDate,
      };
      const obj = JSON.stringify(temp);
      try {
        const data = await fetch(
          `/echoMe/param/updateSystemParam?systemParam=${obj}`,
          {
            method: "POST",
          }
        );
        if (!data.ok) {
          console.log("error message from addRules");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        handleClose();
        load();
      } catch (err) {
        console.error(err);
        alert(err);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          {t("setting.ScannerConfigDialog.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("setting.ScannerConfigDialog2.body")}
          </DialogContentText>
          <Divider />
          <Box variant="div" sx={{ padding: "20px" }}>
            <form>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth={true}
                    id="configName-input"
                    label={t("setting.ContainerRuleConfigDialogAdd.label-ip")}
                    type="text"
                    onChange={ipInputChangeHandler}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth={true}
                    id="configName-input"
                    label={t("setting.ContainerRuleConfigDialogAdd.label-Code")}
                    type="text"
                    onChange={descriptionInputChangeHandler}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "blue " }}
            onClick={modifyRules}
          >
            {t("setting.ContainerRuleConfigDialogModify.button-modify")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red " }}
            onClick={handleClose}
          >
            {t("setting.ContainerRuleConfigDialogModify.button-close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScannerConfigDialogAdd;
