/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-object-spread */
import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import { useSelector, useDispatch } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import "@inovua/reactdatagrid-enterprise/index.css";
import { usePageVisibility } from "react-page-visibility";
import { useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import DateFilter from "@inovua/reactdatagrid-enterprise/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import moment from "moment";
import { navbarContainer } from "examples/Navbars/DashboardNavbar/styles";
import MDButton from "../MDButton";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import DialogFileUpload from "./DialogFileUploadTransout";
import DialogProcess from "./DialogProcessTransout";
import Equipmentdialog from "./DialogEquipmentTransout";
import UserService from "../../service/KeyCloakService";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// React data grid fields
const gridStyle = { marginTop: 10, minHeight: 530 };

const defaultFilterValue = [
  { name: "id", type: "number", operator: "eq", value: "" },
  { name: "deliveryOrderNum", type: "string", operator: "contains", value: "" },
  { name: "toNum", type: "string", operator: "contains", value: "" },
  { name: "shipToDivision", type: "string", operator: "contains", value: "" },
  { name: "shipToLocation", type: "string", operator: "contains", value: "" },
  { name: "shipToLocation", type: "string", operator: "contains", value: "" },
  { name: "status", type: "select", operator: "in", value: "" },
  { name: "shipType", type: "string", operator: "contains", value: "" },
  { name: "createdDate", type: "date", operator: "inrange", value: "" },
  { name: "reason", type: "select", operator: "in", value: "" },
  { name: "remark", type: "string", operator: "contains", value: "" },
];

const STATUS_SELECTION = [
  "IMPORTED",
  "RFID_TAG_PRINTED",
  "TRANSFER_OUT_WIP",
  "COMPLETED",
  "ONHOLD",
  "CANCELLED",
];

const REASON_SELECTION = ["DIRECT_TRANSFER_ORDER", "NORMAL_TRANSFER_ORDER"];

const onRenderRow = (rowProps) => {
  if (rowProps.data.status === "COMPLETED") {
    rowProps.style.color = "#7986cb";
  }
};
// eslint-disable-next-line arrow-body-style
const TransoutAsync = () => {
  const { t } = useTranslation(["transferOut"]);
  const defaultColumns = [
    { name: "id", header: "Id", defaultVisible: false, type: "number" },
    {
      name: "deliveryOrderNum",
      header: t(`transferOut.Async.column-deliveryOrderNum`),
      defaultFlex: 3,
    },
    {
      name: "toNum",
      header: t(`transferOut.Async.column-toNum`),
      defaultFlex: 2,
    },
    {
      name: "shipToDivision",
      header: t(`transferOut.Async.column-shipToDivision`),
      defaultFlex: 2,
    },
    {
      name: "shipToLocation",
      header: t(`transferOut.Async.column-shipToLocation`),
      defaultFlex: 2,
    },
    {
      name: "createdDate",
      header: t(`transferOut.Async.column-createdDate`),
      defaultFlex: 2,
      enableColumnFilterContextMenu: false,
      minWidth: 200,
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) =>
        // for range and notinrange operators, the index is 1 for the after field
        ({
          dateFormat: "YYYY-MM-DD",
          cancelButton: false,
          highlightWeekends: false,
          // placeholder: index === 1 ? "Create date is before..." : "Create date is after...",
        }),
      // eslint-disable-next-line no-unused-vars
      render: ({ value, cellPros }) =>
        value === null ? "None" : moment(value).format("YYYY-MM-DD"),
    },
    {
      name: "status",
      header: t(`transferOut.Async.column-status`),
      defaultFlex: 2,
      enableColumnFilterContextMenu: false,
      filterEditor: SelectFilter,
      render: ({ value, cellPros }) => value?.replaceAll("_", " "),
      filterEditorProps: {
        placeholder: "All",
        multiple: true,
        wrapMultiple: false,
        dataSource: STATUS_SELECTION?.map((c) => ({ id: c, label: c })),
      },
    },
    {
      name: "remark",
      header: t(`transferOut.DialogProcessTransout.input-remark`),
      defaultFlex: 2,
    },
    {
      name: "reason",
      header: t(`transferOut.DialogProcessTransout.input-reason`),
      defaultFlex: 2,
      enableColumnFilterContextMenu: false,
      filterEditor: SelectFilter,
      render: ({ value, cellPros }) => value?.replaceAll("_", " "),
      filterEditorProps: {
        placeholder: "All",
        multiple: true,
        wrapMultiple: false,
        dataSource: REASON_SELECTION?.map((c) => ({ id: c, label: c })),
      },
    },
  ];

  const STORE = {
    columns: defaultColumns,
    limit: 10,
    sortInfo: { name: "docDate", dir: 1, type: undefined },
    filterValue: defaultFilterValue,
    reservedViewportWidth: 0,
    columnOrder: ["deliveryOrderNum", "shipperCode", "createdDate"],
  };
  const initialData = Object.assign({}, STORE, { dataSource: [] });
  const [gridState, setGridState] = useState(initialData);
  const [checked, setChecked] = useState(0);
  const [dialogUploadOpen, setDialogUploadOpen] = useState(false);
  const [newOrUpdate, setNewOrUpdate] = useState("");
  const [openProcess, setOpenProcess] = useState(false);
  const [selectedShipmentCode, setSelectedShipmentCode] = useState();
  const [selectedObject, setSelectedObject] = useState();
  const storedSite = localStorage.getItem("siteCode");
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const reduxSiteCode = useSelector((state) => state.siteCode);
  const isVisible = usePageVisibility();
  const storedLan = localStorage.getItem("i18nextLng");
  const defaultSortInfo = { name: "createdDate", dir: -1 };
  const [openBackDrop, setOpenBackDrop] = useState(false);

  //  Header option for fetch
  let token = keycloak.token;
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  // Dialog Handler
  const dialogProcessClickHandler = () => {
    setOpenProcess(true);
  };

  const dialogProcessCloseHandler = () => {
    setOpenProcess(false);
  };

  const backDrophandleClose = () => {
    setOpenBackDrop(false);
  };

  const backDrophandleOpen = async () => {
    setOpenBackDrop(true);
  };

  // It will trigger refreshing of the web if site is changed from other tab
  useEffect(() => {
    if (storedSite !== reduxSiteCode && reduxSiteCode && storedSite) {
      window.location.reload();
    }
  }, [reduxSiteCode, storedSite]);

  // Fetching data
  const getDataSource =
    (entity) =>
    ({ skip, limit, sortInfo, groupBy, filterValue }) => {
      const queryParams = [
        skip ? `skip=${skip}` : null,
        limit ? `limit=${limit}` : null,
        groupBy && groupBy.length ? `groupBy=${groupBy}` : null,
        filterValue && filterValue.length
          ? `filterBy=${JSON.stringify(
              filterValue
                ?.filter((v) => v.active !== false)
                ?.map((v) => ({
                  value: v.value,
                  name: v.name,
                  operator: v.operator,
                  type: v.type,
                }))
            )}`
          : null,
        sortInfo ? `sortInfo=${JSON.stringify(sortInfo)}` : null,
      ]
        ?.filter((value) => value)
        .join("&");

      return fetch(`/echoMe/to/${entity}?${queryParams}`, requestOptions).then(
        (response) => {
          const totalCount = response.headers.get("X-Total-Count");
          return response
            .json()
            .then((data) => ({ data, count: parseInt(totalCount, 10) }));
        }
      );
    };

  const newDataSource = useCallback(getDataSource("listTransferOutHeader"), [
    dialogUploadOpen,
  ]);

  // Filtering toNum number
  const filterDocumentId = async () => {
    // const data = await fetch(`/echoMe/listDocument`);
    const data = await fetch(`/echoMe/to/listTransferOutHeader`);
    const json = await data.json();
    // console.log(json);
    json
      ?.filter((d) => d.id === checked)
      ?.map((d) => setSelectedShipmentCode(d.toNum));
  };

  // Filterfing selected for process screen
  const filterDocumentObject = async () => {
    // const data = await fetch(`/echoMe/listDocument`);
    const data = await fetch(`/echoMe/to/listTransferOutHeader`);
    const json = await data.json();
    json?.filter((d) => d.id === checked)?.map((d) => setSelectedObject(d));
  };

  useEffect(() => {
    filterDocumentId();
    filterDocumentObject();
  }, [checked]);

  // for changing language
  useEffect(() => {
    gridState.columns = [];
    gridState.columns = defaultColumns;
  }, [storedLan]);

  //  Button functions
  const clearData = () => {
    setGridState(Object.assign({}, gridState, { dataSource: [] }));
  };

  const loadData = () => {
    clearData();
    setTimeout(
      () =>
        setGridState(
          Object.assign({}, gridState, { dataSource: newDataSource })
        ),
      10
    );
  };

  //  Load data first whenever page is loaded
  useEffect(() => {
    if (storedSite) {
      loadData();
    } else {
      alert(t(`transferOut.Async.alert-location`));
      navigate("/dashboard");
    }
  }, [storedSite, reduxSiteCode, isVisible]);

  const uploadFile = () => {
    setNewOrUpdate("new");
    setDialogUploadOpen(true);
  };

  const onDialogFileUploadClose = (event, reason, buttonStr) => {
    if (reason !== "backdropClick") setDialogUploadOpen(false);
    if (buttonStr === "submit") loadData();
  };
  const myFunc = () => {};

  const onLimitChange = (newLimit) => {
    setGridState(Object.assign({}, gridState, { limit: newLimit }));
  };

  const onSortInfoChange = (value) => {
    // const newSortInfo = value ? { type: value.type, name: value.name, dir: value.dir } : value;
    // setGridState(Object.assign({}, gridState, { sortInfo: newSortInfo }));
    setGridState(Object.assign({}, gridState, { sortInfo: value }));
  };

  const onFilterValueChange = (value) => {
    // const newFilterValue = JSON.parse(JSON.stringify(value));
    // const newFilterValue = value ? [...value] : value;
    setGridState(Object.assign({}, gridState, { filterValue: value }));
  };

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setChecked(selected);
  }, []);

  const onColumnOrderChange = (columnOrder) => {
    setGridState(Object.assign({}, gridState, { columnOrder }));
  };

  const onEditComplete = useCallback(() => {});

  const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
    menuProps.autoDismiss = true;
    menuProps.items = [
      {
        label: `myFunc: ${rowProps.data[cellProps.id]}`,
        onClick: () => {
          myFunc({ rowProps, cellProps });
        },
      },
    ];
  };
  const exportData = async () => {
    backDrophandleOpen();
    fetch(
      `/echoMe/to/exportPacking?toNum=${encodeURIComponent(
        selectedShipmentCode
      )}`
    ).then((response) => {
      response.blob().then((blob) => {
        if (response?.ok === true) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${selectedShipmentCode} packing list.xlsx`;
          a.click();
          backDrophandleClose();
        } else if (response?.ok === false) {
          alert("There is something wrong during processing data.");
          backDrophandleClose();
        }
      });
    });
  };

  const cancelOrder = async () => {
    if (selectedObject?.status.toLowerCase() === "completed") {
      alert(t(`transferOut.Async.alert-cancelOrder`));
    } else {
      try {
        const data = await fetch(
          `/echoMe/to/cancelTransferOut?toNum=${encodeURIComponent(
            selectedShipmentCode
          )}`
        );
        if (!data.ok) {
          console.log("error message from cancelOrder");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);
          if (error.status === 500) {
            throw Error("Please try again.");
          }
          throw Error(errorText);
        }
        const json = await data.json();
      } catch (err) {
        console.log(err);
      }
      setChecked(null);
      loadData();
    }
  };

  return (
    <div>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Toolbar
          style={{ minHeight: "10px" }}
          sx={(theme) => navbarContainer(theme)}
        >
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }}>
            <MDTypography variant="h6" color="white">
              {t("transferOut.Async.header-name")}
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDButton
              style={{ marginRight: 10 }}
              size="small"
              onClick={loadData}
            >
              {t("transferOut.Async.button-loadData")}
            </MDButton>
            <MDButton
              style={{ marginRight: 10 }}
              size="small"
              disabled={Array.isArray(gridState.dataSource)}
              onClick={clearData}
            >
              {t("transferOut.Async.button-clearData")}
            </MDButton>
            {(UserService.getClientRole().includes("ROLE_ECHOME_TRANOUT") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={uploadFile}
              >
                {t("transferOut.Async.button-importData")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_TRANOUT") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={dialogProcessClickHandler}
                disabled={checked === null || checked === true || checked === 0}
              >
                {t("transferOut.Async.button-process")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_TRANOUT") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                disabled={checked === null || checked === true || checked === 0}
                onClick={exportData}
              >
                {t("transferOut.Async.button-export")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_TRANOUT") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_TEAMREADER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                disabled={
                  checked === null ||
                  checked === true ||
                  checked === 0 ||
                  selectedObject?.status.toLowerCase() === "completed" ||
                  selectedObject?.status.toLowerCase() === "cancelled" ||
                  selectedObject?.status.toLowerCase() === "registering"
                }
                onClick={cancelOrder}
              >
                {t("transferOut.Async.button-cancel")}
              </MDButton>
            )}
          </MDBox>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={openBackDrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Toolbar>
      </MDBox>
      <MDBox pt={3}>
        <ReactDataGrid
          licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
          idProperty="id"
          style={gridStyle}
          columns={gridState.columns}
          dataSource={gridState.dataSource}
          defaultGroupBy={[]}
          defaultFilterValue={defaultFilterValue}
          enableSelection
          selected={checked}
          multiSelect={false}
          checkboxColumn
          pagination
          pageSizes={[10, 50, 100]}
          limit={gridState.limit}
          onLimitChange={onLimitChange}
          onSelectionChange={onSelectionChange}
          onSortInfoChange={onSortInfoChange}
          onFilterValueChange={onFilterValueChange}
          onColumnOrderChange={onColumnOrderChange}
          onEditComplete={onEditComplete}
          // renderRowContextMenu={renderRowContextMenu}
          onRenderRow={onRenderRow}
          defaultSortInfo={defaultSortInfo}
        />
        <DialogFileUpload
          newOrUpdate={newOrUpdate}
          open={dialogUploadOpen}
          selected={checked}
          onDialogClose={onDialogFileUploadClose}
        />
        <DialogProcess
          // selected={JSON.stringify(selected)}
          open={openProcess}
          close={dialogProcessCloseHandler}
          selected3={selectedObject}
          setChecked={setChecked}
          load={loadData}
          filterDocumentObject={filterDocumentObject}
        />
      </MDBox>
    </div>
  );
};

export default TransoutAsync;
