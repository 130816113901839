/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
import { createSlice, configureStore } from "@reduxjs/toolkit";

//  Redux state management store
const initialState = {
  siteCode: "",
  siteDescription: "",
  scannerIP: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateSiteCode(state, action) {
      state.siteCode = action.payload;
    },
    updateSiteDescription(state, action) {
      state.siteDescription = action.payload;
    },
    updateScannerIP(state, action) {
      state.scannerIP = action.payload;
    },
  },
});

//  Create the store
const store = configureStore({
  reducer: userSlice.reducer,
});

export const usersActions = userSlice.actions;
export default store;
