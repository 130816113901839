/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Typography, Box, Paper } from "@mui/material";
import Button from "@mui/material/Button";
import MDButton from "../MDButton";
import DialogEquipment from "./DialogEquipment";
import UserService from "../../service/KeyCloakService";
import { printZpl } from "../../utility/printUtli";
import { genMultItemRfidTagZpl } from "../../utility/printUtli/itemLabelPrint";
import PropTypes from "prop-types";
//  React Data grid fields
const gridStyle = { minHeight: 400 };

const DialogStock = ({
  newOrUpdate,
  open,
  onDialogClose,
  selected,
  load,
  selectedObject,
  setSelected,
}) => {
  const [invStock, setInvStock] = useState("");
  const [openManage, setOpenManage] = useState(false);
  const [siteCode, setSiteCode] = useState("");
  const [equipID, setEquipID] = useState("");
  const { t } = useTranslation(["inventory"]);
  const columns = [
    {
      name: "id",
      header: "Name",
      type: "number",
      defaultFlex: 1,
      defaultVisible: false,
    },
    { name: "Name", header: t(`inventory.Async.column-Name`), defaultFlex: 1 },
    {
      name: "Value",
      header: t(`inventory.Async.column-Value`),
      defaultFlex: 1,
    },
  ];
  const dataSource = [
    { id: 1, Name: t(`inventory.Async.column-style`), Value: invStock.style },
    { id: 2, Name: t(`inventory.Async.column-color`), Value: invStock.color },
    { id: 3, Name: t(`inventory.Async.column-size`), Value: invStock.size },
    { id: 4, Name: t(`inventory.Async.column-serial`), Value: invStock.serial },
    { id: 5, Name: t(`inventory.Async.column-eanupc`), Value: invStock.eanupc },
    { id: 6, Name: t(`inventory.Async.column-coo`), Value: invStock?.coo },
    // { id: 7, Name: t(`inventory.Async.column-qty`), Value: invStock?.quantity },
  ];
  const onEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...dataSource];
      data[rowId - 1][columnId] = value;
      // console.log(data);
      let temp = {
        ...invStock,
        style: data[0]?.Value,
        color: data[1]?.Value,
        size: data[2]?.Value,
        serial: data[3]?.Value,
        eanupc: data[4]?.Value,
        coo: data[5]?.Value,
        quantity: data[6]?.Value,
      };
      // console.log(temp);
      setInvStock(temp);
    },
    [dataSource]
  );

  // equivalent to componentDidMount()
  useEffect(() => {
    // eslint-disable-next-line no-console, no-unused-expressions
    // open ? console.log("component did mount: open") : console.log("component did mount: close");

    let id = null;
    if (Array.isArray(selected)) {
      const selectedKeys = selected ? Object.keys(selected) : null;
      const firstSelectedKey = selectedKeys ? selectedKeys[0] : null;
      id = firstSelectedKey;
    } else {
      id = selected;
    }
    setInvStock({
      ...invStock,
    });
    if (open && id) {
      // eslint-disable-next-line no-console
      // console.log(`/echoMe/getInvStock?id=${id}`);
      // fetch(`/echoMe/getInvStock?id=${id}`, {
      fetch(`/echoMe/inv/getInventory?id=${id}`, {
        method: "GET",
        mode: "no-cors",
      })
        .then((response) => {
          const promise = response.json().then((data) => ({ data }));
          return promise;
        })
        .then((data) => {
          // eslint-disable-next-line no-console
          // console.log(`Editing id ${data.data.id}`);
          // setInvStock({ ...invStock, ...data.data });
          // eslint-disable-next-line prefer-object-spread
          setInvStock(Object.assign({}, invStock, data.data));

          //  Fetching siteCode
          fetch(`/echoMe/loc/getLocSite?id=${data.data?.site}`)
            .then((r) => {
              return r.json();
            })
            .then((d) => {
              setSiteCode(d.siteCode);
            });

          //  Fetching euipmentID
          fetch(
            `/echoMe/bundle/getBundleByDescendant?descendant=${data.data?.assetCode}`
          )
            .then((r) => {
              return r.json();
            })
            .then((d) => {
              setEquipID(d.ancestor);
            });
        });
    }
  }, [open]);

  // Dialog Handler
  const onDiaLogStockClose = (event, reason) => {
    onDialogClose(event, reason, undefined);
  };

  const handleCancel = () => {
    onDialogClose(undefined, undefined, "cancel");
  };

  const dialogManageClickHandler = () => {
    setOpenManage(true);
  };

  const dialogManageCloseHandler = () => {
    setOpenManage(false);
  };

  // Button functions
  const handleSubmit = () => {
    fetch(
      `/echoMe/inv/${
        newOrUpdate === "new" ? "newInventory" : "updateInventory"
      }?inventory=${JSON.stringify(invStock)}`,
      {
        method: "GET",
        mode: "no-cors",
      }
    )
      .then(async (response) => {
        const responseData = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          console.log("error message from inventoryUpdate");
          let error = await response;
          let errorText = await response.text();
          console.log(errorText);
          throw Error(errorText);
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
    setTimeout(() => {
      load();
    }, 200);
    onDialogClose(undefined, undefined, "submit");
    setSelected({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInvStock({
      ...invStock,
      [name]: value,
    });
  };

  //  Button functions
  const regenerateItemRFIDButtonHandler = async () => {
    const rfidArray = selectedObject?.map((r) => r.rfid)?.join(",");
    try {
      const data = await fetch(
        `/echoMe/reg/regenRfidItem?regNum=NA&rfids=${rfidArray}`
      );
      if (!data.ok) {
        console.log("error message from regenRfidItem");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }

      const json = await data.json();
      printZpl(
        genMultItemRfidTagZpl(
          json?.map((e) => {
            const {
              eanupc = "1111111111",
              description = "NA",
              style = "NA",
              color = "NA",
              size = "NA",
              skuCode = "NA",
              exp = "NA",
              serial = "NA",
              rfid = "SNoRfidFoundData",
              productCode = "NA",
            } = e;
            console.log(e);
            let ssc = `${style}/${size}/${color}`;
            return {
              barCode: eanupc,
              des: description,
              ssc: ssc,
              sku: skuCode,
              exp: exp,
              serial: serial,
              rfid: rfid,
              productCode: productCode,
            };
          })
        )
      );
    } catch (err) {
      console.error(err);
      alert(err);
    }
  };

  const genEquRfidTagZpl = (rfid, barCode) => {
    return `CT~~CD,~CC^~CT~ ^XA ^MD25 ~TA000 ~JSN ^LT0 ^MNW ^MTT ^PON ^PMN ^LH0,0 ^JMA ^PR6,6 ~SD15 ^JUS ^LRN ^CI27 ^PA0,1,1,0 ^RS8,,,3 ^XZ ^XA ^MMT ^PW945 ^LL472 ^LS0 ^FPH,3^FT11,395^A0N,150,150^FB934,1,38,C^FH\\^CI28^FD${barCode}^FS^CI27 ^BY5,3,200^FT139,236^BCN,,N,N ^FH\\^FD>:${barCode}^FS ^RFW,H,1,2,1^FD4000^FS ^RFW,A,2,6,1^FD${rfid}^FS ^PQ1,0,1,Y ^XZ`;
  };

  const regenerateContainerRFIDButtonHandler = async (event) => {
    try {
      const data = await fetch(
        `/echoMe/rfid/regenRfidTagContainer?orgContainerAssetCode=${selectedObject[0]?.containerAssetCode}`,
        {
          method: "POST",
        }
      );
      if (!data.ok) {
        console.log("error message from genRfidContainer");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();

      //  get rfid and barcode from json
      let zpl = json
        ?.map((e) => {
          return genEquRfidTagZpl(e.rfid, e.containerCode);
        })
        .join("");
      console.log(zpl);

      // Print dialog
      setTimeout(() => {
        let printWindow = window.open();
        printWindow.document.open("text/plain");
        printWindow.document.write(zpl);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      });
    } catch (err) {
      console.error(err);
      alert(err);
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={onDiaLogStockClose}
        fullScreen={true}
      >
        <DialogTitle>
          {newOrUpdate === "new"
            ? "Registeration"
            : t("inventory.DialogStockInventory.header-name")}
          {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETIN") ||
            UserService.getClientRole().includes(
              "ROLE_ECHOME_REGEN_RFID_ITEM"
            ) ||
            UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
            UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
            UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER")) && (
            <Button
              color="primary"
              size="medium"
              variant="contained"
              style={{ float: "right", color: "white" }}
              onClick={regenerateContainerRFIDButtonHandler}
            >
              {t(
                "inventory.DialogStockInventory.bodyButton-regenRFIDContainer"
              )}
            </Button>
          )}
          {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETIN") ||
            UserService.getClientRole().includes(
              "ROLE_ECHOME_REGEN_RFID_ITEM"
            ) ||
            UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
            UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
            UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER")) && (
            <Button
              color="primary"
              size="medium"
              variant="contained"
              style={{ float: "right", color: "white", marginRight: "20px" }}
              onClick={regenerateItemRFIDButtonHandler}
            >
              {t("inventory.DialogStockInventory.bodyButton-regenRFIDItem")}
            </Button>
          )}
          <Button
            color="primary"
            size="medium"
            variant="contained"
            style={{ float: "right", color: "white", marginRight: "20px" }}
            onClick={dialogManageClickHandler}
            // disabled={true}
          >
            {t("inventory.DialogStockInventory.bodyButton-equipment")}
          </Button>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("inventory.DialogStockInventory.bodyText")}
            <b style={{ color: "red" }}>{selected}</b>
          </DialogContentText>
          <form onSubmit={handleSubmit}>
            <Box
              variant="div"
              sx={{
                padding: "20px",
                border: "1px solid",
              }}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                sx={{
                  flextDirection: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} md={4}>
                  <Paper
                    elevation={8}
                    sx={{
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      padding: "15px",
                    }}
                  >
                    <Typography variant="h6">
                      {t(`inventory.DialogBatchRegeneInv.column-assetInfo`)}
                    </Typography>
                    <TextField
                      // autoFocus
                      margin="dense"
                      id="status-input"
                      name="status"
                      label={t(`inventory.Async.column-status`)}
                      type="text"
                      // variant="standard"
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          color: "blue",
                        },
                        "& label.Mui-focused": {
                          color: "#353935",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#353935",
                          },
                        },
                      }}
                      focused
                      onChange={handleInputChange}
                      value={invStock?.status ?? ""}
                    />
                    <TextField
                      // autoFocus
                      margin="dense"
                      id="skuCode-input"
                      name="skuCode"
                      label={t(
                        `inventory.DialogBatchRegeneInv.column-productCode`
                      )}
                      type="text"
                      // variant="standard"
                      fullWidth={true}
                      onChange={handleInputChange}
                      value={invStock?.skuCode ?? ""}
                      focused
                      sx={{
                        "& label.Mui-focused": {
                          color: "#353935",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#353935",
                          },
                        },
                      }}
                    />
                    <TextField
                      margin="dense"
                      id="itemDescription-input"
                      name="description"
                      label={t(`inventory.Async.column-description`)}
                      type="text"
                      fullWidth={true}
                      onChange={handleInputChange}
                      value={invStock?.description ?? ""}
                      focused
                      sx={{
                        "& label.Mui-focused": {
                          color: "#353935",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#353935",
                          },
                        },
                      }}
                    />
                    <TextField
                      margin="dense"
                      id="site-input"
                      name="site"
                      label={t(`inventory.Async.column-site`)}
                      type="text"
                      fullWidth={true}
                      onChange={handleInputChange}
                      value={siteCode ?? ""}
                      sx={{
                        input: {
                          color: "blue",
                        },
                        "& label.Mui-focused": {
                          color: "#353935",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#353935",
                          },
                        },
                      }}
                      focused
                    />
                    <TextField
                      margin="dense"
                      id="location-input"
                      name="loc_id"
                      label={t(`inventory.Async.column-locCode`)}
                      type="text"
                      fullWidth={true}
                      sx={{
                        input: {
                          color: "blue",
                        },
                        "& label.Mui-focused": {
                          color: "#353935",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#353935",
                          },
                        },
                      }}
                      onChange={handleInputChange}
                      value={invStock?.locCode ?? ""}
                      focused
                    />
                    <TextField
                      margin="dense"
                      id="assetId-input"
                      name="asset_code"
                      label={t(`inventory.Async.column-assetCode`)}
                      type="text"
                      fullWidth={true}
                      onChange={handleInputChange}
                      value={invStock?.assetCode ?? ""}
                      sx={{
                        input: {
                          color: "blue",
                        },
                        "& label.Mui-focused": {
                          color: "#353935",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#353935",
                          },
                        },
                      }}
                      focused
                    />
                    <TextField
                      margin="dense"
                      id="regNum-input"
                      name="regNum"
                      label={t(`inventory.Async.column-regNum`)}
                      type="text"
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          color: "blue",
                        },
                        "& label.Mui-focused": {
                          color: "#353935",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#353935",
                          },
                        },
                      }}
                      onChange={handleInputChange}
                      value={invStock?.regNum ?? ""}
                      focused
                    />
                    <TextField
                      margin="dense"
                      id="createdDate-input"
                      name="createdDate"
                      label={t(`inventory.Async.column-createdDate`)}
                      type="text"
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          color: "blue",
                        },
                        "& label.Mui-focused": {
                          color: "#353935",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#353935",
                          },
                        },
                      }}
                      onChange={handleInputChange}
                      value={
                        moment(invStock?.createdDate).format(
                          "YYYY-MM-DD HH:mm:ss"
                        ) ?? ""
                      }
                      focused
                    />
                    <TextField
                      margin="dense"
                      id="equipmentId-input"
                      name="equipmentId"
                      label={t(`inventory.Async.column-equipmentId`)}
                      type="text"
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          color: "blue",
                        },
                        "& label.Mui-focused": {
                          color: "#353935",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#353935",
                          },
                        },
                      }}
                      onChange={handleInputChange}
                      value={equipID ?? ""}
                      focused
                    />
                    <TextField
                      margin="dense"
                      id="quantity-input"
                      name="quantity"
                      label={t(`inventory.Async.column-qty`)}
                      type="number"
                      fullWidth={true}
                      sx={{
                        input: {
                          color: "black",
                        },
                        "& label.Mui-focused": {
                          color: "#353935",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#353935",
                          },
                        },
                      }}
                      onChange={handleInputChange}
                      value={invStock?.quantity}
                      focused
                    />
                    <TextField
                      margin="dense"
                      id="lastLocCode-input"
                      name="lastLocCode"
                      label={t(`inventory.Async.column-lastLocCode`)}
                      type="text"
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          color: "blue",
                        },
                        "& label.Mui-focused": {
                          color: "#353935",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#353935",
                          },
                        },
                      }}
                      onChange={handleInputChange}
                      value={invStock?.lastLocCode ?? ""}
                      focused
                    />
                    <TextField
                      margin="dense"
                      id="lastSite-input"
                      name="lastSite"
                      label={t(`inventory.Async.column-lastSite`)}
                      type="text"
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          color: "blue",
                        },
                        "& label.Mui-focused": {
                          color: "#353935",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#353935",
                          },
                        },
                      }}
                      onChange={handleInputChange}
                      value=""
                      focused
                    />
                    <TextField
                      margin="dense"
                      id="lastCheckpointCode-input"
                      name="lastCheckpointCode"
                      label={t(`inventory.Async.column-lastCheckpointCode`)}
                      type="text"
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          color: "blue",
                        },
                        "& label.Mui-focused": {
                          color: "#353935",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#353935",
                          },
                        },
                      }}
                      onChange={handleInputChange}
                      value={invStock?.lastCheckpointCode ?? ""}
                      focused
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} md={0.2}>
                  {}
                </Grid>
                <Grid item xs={12} md={7}>
                  <Paper
                    elevation={8}
                    sx={{
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      padding: "15px",
                    }}
                  >
                    <Typography variant="h6">
                      {t(`inventory.DialogBatchRegeneInv.column-extra`)}
                    </Typography>
                    <ReactDataGrid
                      licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                      idProperty="id"
                      // checkboxColumn
                      // selected={selected}
                      // onSelectionChange={onSelectionChange}
                      style={gridStyle}
                      columns={columns}
                      dataSource={dataSource}
                      rowHeight={60}
                      editable={true}
                      onEditComplete={onEditComplete}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "blue" }}
            onClick={handleSubmit}
          >
            {t("inventory.DialogStockInventory.button-confirm")}
          </MDButton>

          <MDButton
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={handleCancel}
          >
            {t("inventory.DialogStockInventory.button-cancel")}
          </MDButton>
        </DialogActions>
      </Dialog>
      <DialogEquipment
        // selected={JSON.stringify(selected)}
        open={openManage}
        close={dialogManageCloseHandler}
        currentContainerAssetCodes={equipID}
      />
    </div>
  );
};

export default DialogStock;
