/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import { Grid, Typography, Box } from "@mui/material";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { useDispatch, useSelector } from "react-redux";
import { usersActions } from "../../store/userSlice";
import UserService from "../../service/KeyCloakService";

// React data grid fields
const gridStyle = { minHeight: 300 };

const columns = [
  {
    name: "siteCode",
    header: "Location Name",
    defaultFlex: 1,
    type: "string",
    enableColumnFilterContextMenu: false,
  },
  {
    name: "description",
    header: "Description",
    defaultFlex: 1,
    type: "string",
    enableColumnFilterContextMenu: false,
  },
  { name: "id", header: "Id", type: "number", defaultVisible: false },
];

const filterValue = [
  { name: "siteCode", operator: "contains", type: "string", value: "" },
  { name: "description", operator: "contains", type: "string", value: "" },
];

// eslint-disable-next-line arrow-body-style
const DialogLocationDashboard = ({ open, close }) => {
  const { keycloak } = useKeycloak();
  const [selected, setSelected] = useState({});
  const [locationArray, SetLocationArray] = useState([]);
  const [selectedSite, SetSelectedSite] = useState([]);
  const storedSite = localStorage.getItem("siteCode");
  const storedSiteDescription = localStorage.getItem("siteDes");
  const [siteArray, setSiteArray] = useState([]);
  const [userKeyclaokRoles, setUserKeyclaokRoles] = useState([]);
  const reduxSiteCode = useSelector((state) => state.siteCode);
  const reduxSiteDescription = useSelector((state) => state.siteDescription);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common"]);
  //  Header file to exchange token with server
  let token = keycloak.token;
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  // React data-grid functions
  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  //  Loading the location list from server and put into dataSource
  const loadLocation = async () => {
    try {
      const data = await fetch(`/echoMe/loc/listLocSite`);
      if (!data.ok) {
        console.log("error message from loadLocation");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      const temp = [];
      const temp2 = [];
      json?.map((r) => {
        temp.push({
          id: r.id,
          siteCode: r.siteCode,
          description: r.description,
        });
        temp2.push(r);
      });
      SetLocationArray(temp);

      //  filtering site from keycloak
      const temp3 = [];
      const temp4 = [];
      UserService.getClientRole()
        ?.filter((r) => r.includes("ROLE_SITE_"))
        ?.map((r) => temp3.push(r.slice(10)));
      setUserKeyclaokRoles(temp3);

      // if user has role of superuser
      if (temp3.includes("SUPERUSER")) {
        setSiteArray(temp2);
      } else {
        temp2
          ?.filter((r) => temp3.includes(r.siteCode))
          ?.map((r) => temp4.push(r));
        setSiteArray(temp4);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Load location again to fetch data if either of two fields
  useEffect(() => {
    if (!storedSite || !storedSiteDescription) {
      loadLocation();
    }
  }, [storedSite, storedSiteDescription]);

  // Load location again to fetch data if sid nav is open
  useEffect(() => {
    loadLocation();
  }, [open]);

  // Closing dialog
  const handleClose = () => {
    close();
  };

  //  Fetch the location that user select from dialog and save selected location
  //  to local storage and redux for remembering the location
  const selectHandler = async () => {
    if (typeof selected === "number") {
      let tempLocation = locationArray
        ?.filter((r) => {
          return r.id === selected;
        })
        ?.map((r) => r.siteCode);
      SetSelectedSite(tempLocation);
      try {
        const data = await fetch(
          `/echoMe/site?siteCode=${tempLocation}`,
          requestOptions
        );
        if (!data.ok) {
          console.log("error message from loadLocation");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        dispatch(usersActions.updateSiteCode(tempLocation[0]));
        localStorage.setItem("siteCode", tempLocation[0]);
        let temp = siteArray
          ?.filter((r) => r.siteCode === tempLocation[0])
          ?.map((r) => r.description);
        dispatch(usersActions.updateSiteDescription(temp[0]));
        localStorage.setItem("siteDes", temp);
        handleClose();
      } catch (err) {
        console.error(err);
        alert(err);
      }
    } else if (typeof selected !== "number") {
      alert("Please select one location to start echoMe.");
    }
  };

  // Language Handler
  // Set default language as English
  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  const LanguageChangeHandler = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  // console.log(siteArray);
  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        fullWidth={true}
        maxWidth="sm"
        disableEscapeKeyDown={true}
        // onBackdropClick
      >
        <DialogTitle>
          {t("common.DialogLocationDashboard.header-name")}
          {/* <select
            className="nav-link bg-dark border-0 ml-1 mr-2 mt-1"
            value={localStorage.getItem("i18nextLng")}
            style={{ float: "right", marginRight: "20px" }}
            onChange={LanguageChangeHandler}
          >
            <option value="en">English</option>
            <option value="ch">繁字</option>
          </select> */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("common.DialogLocationDashboard.body")}
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <ReactDataGrid
                    licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                    idProperty="id"
                    checkboxColumn
                    selected={selected}
                    defaultFilterValue={filterValue}
                    multiSelect={false}
                    onSelectionChange={onSelectionChange}
                    style={gridStyle}
                    columns={columns}
                    dataSource={siteArray}
                  />
                  <p>Total Site: {siteArray.length}</p>
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red" }}
            onClick={loadLocation}
          >
            {t("common.DialogLocationDashboard.button-refresh")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "blue" }}
            onClick={selectHandler}
          >
            {t("common.DialogLocationDashboard.button-select")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogLocationDashboard;
