/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useSelector, useDispatch } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import {
  Grid,
  TextField,
  Box,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";

// eslint-disable-next-line arrow-body-style
const DialogZoneTypeAdd = ({ open, close, load, site }) => {
  const [typeCode, setTypeCode] = useState("");
  const [description, setDescription] = useState("");

  // Input fields change handler
  const typeCodeChangeHandler = (event) => {
    setTypeCode(event.target.value);
  };

  const descriptionChangeHandler = (event) => {
    setDescription(event.target.value);
  };

  //  Button functions
  const closeHandler = () => {
    setTypeCode("");
    setDescription("");
    load();
    close();
  };

  const submitHandler = async () => {
    // Allow user to enter all fields
    if (!typeCode || !description || !site) {
      alert("Please input all the fields.");
    } else {
      const temp = { site: site, typeCode: typeCode, description: description };
      const obj = JSON.stringify(temp);
      try {
        //  If all fields are entered.
        const data = await fetch(
          `/echoMe/loc/newLocZoneType?locZoneType=${obj}`,
          {
            method: "GET",
            mode: "no-cors",
          }
        );
        if (!data.ok) {
          console.log("error message from addLocation");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        // update the object and clear all fields and load data.
        closeHandler();
      } catch (err) {
        console.error(err);
        alert(err);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="sm">
        <DialogTitle>Add New Zone Type</DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            Please Input the Zone Type information.
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  id="typeCode-input"
                  name="typeCode"
                  label="Type Code"
                  type="text"
                  fullWidth={true}
                  onChange={typeCodeChangeHandler}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  id="description-input"
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth={true}
                  onChange={descriptionChangeHandler}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={submitHandler}
              style={{ color: "white", backgroundColor: "blue" }}
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={closeHandler}
              style={{ color: "white", backgroundColor: "red" }}
            >
              Close
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogZoneTypeAdd;
