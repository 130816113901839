/* eslint-disable no-extra-boolean-cast */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toLength } from "lodash";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { Grid, Typography, Box } from "@mui/material";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import ContainerRuleConfigDialogAdd from "./ScannerConfigDialogAdd";
import UserService from "../../../service/KeyCloakService";
import ContainerRuleConfigDialogModify from "./ScannerConfigDialogModify";

const gridStyle = { minHeight: 200 };

// eslint-disable-next-line arrow-body-style
const ScannerConfigDialog = ({ open, close }) => {
  const [selected, setSelected] = useState({});
  const [openConfigAdd, setOpenConfigAdd] = useState(false);
  const [openConfigModify, setOpenConfigModify] = useState(false);
  const [ableDisabledButtonClicked, setAbleDisabledButtonClicked] =
    useState(false);
  const [rulesArray, setRulesArray] = useState([]);
  const [selectedObject, setSelectedObject] = useState();
  const { t } = useTranslation(["setting"]);
  // React data grid field
  const columns = [
    {
      name: "code",
      header: t(`setting.ScannerConfigDialog.cloumn-code`),
      defaultFlex: 1.5,
      type: "string",
    },
    {
      name: "value",
      header: t(`setting.ContainerRuleConfigDialogAdd.label-ip`),
      type: "number",
    },

    {
      name: "readerStatus",
      header: t(`setting.ScannerConfigDialog.cloumn-readerStatus`),
      defaultFlex: 1,
      type: "Number",
    },
    {
      name: "activeStatus",
      header: t(`setting.ScannerConfigDialog.cloumn-activeStatus`),
      defaultFlex: 1.9,
      type: "Number",
    },
    {
      name: "maker",
      header: t(`setting.ScannerConfigDialog.cloumn-maker`),
      defaultFlex: 1,
      type: "Number",
    },
    { name: "id", header: "Id", type: "number", defaultVisible: false },
  ];

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  //  Filtering selected object which is needed for process screen
  const filterDocumentObject = async () => {
    if (selected) {
      rulesArray
        ?.filter((d) => d.id === selected)
        ?.map((d) => setSelectedObject(d));
    } else if (!selected) {
      setSelectedObject(null);
    }
  };

  useEffect(() => {
    filterDocumentObject();
  }, [selected]);

  // Dialog Handler
  const dialogConfigAddClickHandler = () => {
    setOpenConfigAdd(true);
  };

  const dialogConfigAddCloseHandler = () => {
    setOpenConfigAdd(false);
  };

  const dialogConfigModifyClickHandler = () => {
    setOpenConfigModify(true);
  };

  const dialogConfigModifyCloseHandler = () => {
    setOpenConfigModify(false);
  };

  // Data fetching functions

  // Dialog Handler
  const toggleButtonHandler = () => {
    setAbleDisabledButtonClicked((prevCheck) => !prevCheck);
  };

  const loadData = async () => {
    //  Fetching data
    try {
      const data = await fetch(`/echoMe/param/getAllReaderIp`);
      if (!data.ok) {
        console.log("error message from loadPrefix");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      setRulesArray([]);
      setRulesArray(json);
    } catch (err) {
      console.error(err);
    }
  };

  //  Load data whenever dialog is opened or user press the able/disable button
  useEffect(() => {
    loadData();
  }, [open, ableDisabledButtonClicked]);

  const handleCancel = () => {
    setSelected(null);
    setSelectedObject(null);
    setAbleDisabledButtonClicked(false);
    close();
  };

  // Button functions
  const removeRules = async () => {
    try {
      const data = await fetch(
        `/echoMe/param/removeSystemParam?id=${selected}`,
        {
          method: "DELETE",
        }
      );
      if (!data.ok) {
        console.log("error message from removeRules");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      setSelected(null);
      await loadData();
    } catch (err) {
      console.error(err);
    }
  };

  const enableRules = async () => {
    toggleButtonHandler();
    try {
      const data = await fetch(
        `/echoMe/param/enableSystemParam?id=${selected}`,
        {
          method: "POST",
        }
      );
      if (!data.ok) {
        console.log("error message from removeRules");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      setSelected(null);
      setTimeout(() => {
        loadData();
      }, 10);
    } catch (err) {
      console.error(err);
    }
  };

  const disableRules = async () => {
    toggleButtonHandler();
    try {
      const data = await fetch(`/echoMe/param/cessSystemParam?id=${selected}`, {
        method: "POST",
      });
      if (!data.ok) {
        console.log("error message from removeRules");
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      setSelected(null);
      setTimeout(() => {
        loadData();
      }, 10);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="md">
        <DialogTitle>
          {t("setting.ScannerConfigDialog.header-name")}
        </DialogTitle>
        <DialogContent>
          <Box variant="div" sx={{ padding: "20px" }}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <ReactDataGrid
                    licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                    idProperty="id"
                    checkboxColumn
                    selected={selected}
                    multiSelect={false}
                    onSelectionChange={onSelectionChange}
                    style={gridStyle}
                    columns={columns}
                    dataSource={rulesArray}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red " }}
            // disabled={!selected || selected === null || Object.keys(selected).length === 0}
            onClick={() => {
              if (_.toLength(selected) === 0) {
                alert(t(`setting.ScannerConfigDialog.alert-delete`));
              } else if (_.toLength(selected) > 0) {
                removeRules();
              }
            }}
          >
            {t("setting.ContainerRuleConfigDialog.button-delete")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red " }}
            onClick={() => {
              if (_.toLength(selected) === 0) {
                alert(t(`setting.ScannerConfigDialog.alert-modify`));
              } else if (_.toLength(selected) > 0) {
                dialogConfigModifyClickHandler();
              }
            }}
          >
            {t("setting.ContainerRuleConfigDialog.button-modify")}
          </Button>
          {selectedObject?.activeStatus.toLowerCase() === "enabled" && (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", backgroundColor: "red " }}
              onClick={disableRules}
            >
              {t("setting.ScannerConfigDialog.button-disable")}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "blue " }}
            onClick={dialogConfigAddClickHandler}
          >
            {t("setting.ScannerConfigDialog.button-add")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "blue " }}
            onClick={loadData}
          >
            {t("setting.ScannerConfigDialog.button-refresh")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "blue " }}
            onClick={handleCancel}
          >
            {t("setting.ScannerConfigDialog.button-close")}
          </Button>
          {selectedObject?.activeStatus.toLowerCase() === "disabled" && (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", backgroundColor: "blue " }}
              onClick={enableRules}
            >
              {t("setting.ScannerConfigDialog.button-enable")}
            </Button>
          )}
        </DialogActions>
        <ContainerRuleConfigDialogAdd
          open={openConfigAdd}
          close={dialogConfigAddCloseHandler}
          load={loadData}
        />
        <ContainerRuleConfigDialogModify
          open={openConfigModify}
          close={dialogConfigModifyCloseHandler}
          load={loadData}
          selected={selectedObject}
        />
      </Dialog>
    </>
  );
};

export default ScannerConfigDialog;
