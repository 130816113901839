/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-object-spread */
import React, { useState, useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import { useKeycloak } from "@react-keycloak/web";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";
import DateFilter from "@inovua/reactdatagrid-enterprise/DateFilter";
import { useTranslation } from "react-i18next";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import moment from "moment";
import { navbarContainer } from "examples/Navbars/DashboardNavbar/styles";
import MDButton from "../MDButton";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import DialogFileUpload from "./DialogFileUpload";
import DialogProcess from "./DialogProcessRegistration";
import Equipmentdialog from "./DialogEquipmentRegistration";
import UserService from "../../service/KeyCloakService";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// eslint-disable-next-line arrow-body-style
const RegistrationAsync2 = () => {
  const [checked, setChecked] = useState(0);
  const [dialogUploadOpen, setDialogUploadOpen] = useState(false);
  const [newOrUpdate, setNewOrUpdate] = useState("");
  const [openProcess, setOpenProcess] = useState(false);
  const [openEquipment, setOpenEquipment] = useState(false);
  const [selectedDocumentNum, setSelectedDocumentNum] = useState();
  const [selectedObject, setSelectedObject] = useState();
  const { keycloak } = useKeycloak();
  const storedSite = localStorage.getItem("siteCode");
  const storedLan = localStorage.getItem("i18nextLng");
  const navigate = useNavigate();
  const { t } = useTranslation(["assetRegistration"]);
  const defaultSortInfo = { name: "createdDate", dir: -1 };
  const [openBackDrop, setOpenBackDrop] = useState(false);

  // React data grid fields
  const gridStyle = { marginTop: 10, minHeight: 530 };

  const onRenderRow = (rowProps) => {
    if (rowProps.data.status === "COMPLETED") {
      rowProps.style.color = "#7986cb";
    }
  };

  const STATUS_SELECTION = [
    "IMPORTED",
    "RFID_TAG_PRINTED",
    "REGISTERING",
    "COMPLETED",
    "ONHOLD",
    "CANCELLED",
    "PARTIAL_COMPLETE",
  ];

  const defaultFilterValue = [
    { name: "id", type: "number", operator: "eq", value: "" },
    { name: "regNum", type: "string", operator: "contains", value: "" },
    { name: "status", type: "select", operator: "in", value: "" },
    { name: "maker", type: "string", operator: "contains", value: "" },
    { name: "regDate", type: "date", operator: "inrange", value: "" },
    { name: "shipperCode", type: "string", operator: "contains", value: "" },
    { name: "createdDate", type: "date", operator: "inrange", value: "" },
    { name: "totalCheckinQty", type: "number", operator: "eq", value: "" },
    { name: "totalOrderQty", type: "number", operator: "eq", value: "" },
    { name: "totalSkuCodeQty", type: "number", operator: "eq", value: "" },
    { name: "totalContainerQty", type: "number", operator: "eq", value: "" },
  ];

  const defaultColumns = [
    { name: "id", header: "Id", defaultVisible: false, type: "number" },
    {
      name: "regNum",
      header: t(`assetReg.Async.column-RegNum`),
      defaultFlex: 2,
      type: "string",
    },
    {
      name: "shipperCode",
      header: t(`assetReg.Async.column-supplyCode`),
      defaultFlex: 2,
      type: "string",
    },
    {
      name: "regDate",
      header: t(`assetReg.Async.column-regDate`),
      defaultFlex: 1.5,
      minWidth: 200,
      enableColumnFilterContextMenu: false,
      type: "date",
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) =>
        // for range and notinrange operators, the index is 1 for the after field
        ({
          dateFormat: "YYYY-MM-DD",
          cancelButton: false,
          highlightWeekends: false,
        }),
      // eslint-disable-next-line no-unused-vars
      render: ({ value, cellPros }) =>
        value === null ? "None" : moment(value).format("YYYY-MM-DD"),
    },
    {
      name: "createdDate",
      header: t(`assetReg.Async.column-createdDate`),
      defaultFlex: 1.5,
      enableColumnFilterContextMenu: false,
      minWidth: 200,
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) =>
        // for range and notinrange operators, the index is 1 for the after field
        ({
          dateFormat: "YYYY-MM-DD",
          cancelButton: false,
          highlightWeekends: false,
        }),
      // eslint-disable-next-line no-unused-vars
      render: ({ value, cellPros }) =>
        value === null ? "None" : moment(value).format("YYYY-MM-DD"),
    },
    {
      name: "status",
      header: t("assetReg.Async.column-status"),
      // header: "Status",
      defaultFlex: 2,
      filterEditor: SelectFilter,
      enableColumnFilterContextMenu: false,
      render: ({ value, cellPros }) => value?.replaceAll("_", " "),
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "All",
        dataSource: STATUS_SELECTION?.map((c) => ({ id: c, label: c })),
      },
    },
    {
      name: "maker",
      header: t(`assetReg.Async.column-createdBy`),
      defaultFlex: 1.5,
      type: "string",
    },
    {
      name: "totalCheckinQty",
      header: t(`assetReg.Async.column-totalcheckedQty`),
      defaultFlex: 2,
    },
    {
      name: "totalOrderQty",
      header: t(`assetReg.Async.column-totalOrderQty`),
      defaultFlex: 2,
    },
    {
      name: "totalSkuCodeQty",
      header: t(`assetReg.Async.column-totalOrderSKU`),
      defaultFlex: 2,
    },
    {
      name: "totalContainerQty",
      header: t(`assetReg.Async.column-totalScanContainer`),
      defaultFlex: 2.1,
    },
  ];

  const STORE = {
    columns: defaultColumns,
    limit: 10,
    sortInfo: [{ name: "createdDate", dir: 1, type: undefined }],
    filterValue: defaultFilterValue,
    reservedViewportWidth: 0,
    columnOrder: ["regNum", "regDate", "createdDate", "status,", "maker"],
  };

  const initialData = Object.assign({}, STORE, { dataSource: [] });
  const [gridState, setGridState] = useState(initialData);

  //  Header option for fetch
  let token = keycloak.token;
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  // Dialog Process data logic
  const dialogProcessClickHandler = () => {
    setOpenProcess(true);
  };

  const dialogProcessCloseHandler = () => {
    setOpenProcess(false);
  };

  // Dialog Equipment data logic
  const dialogEquipmentClickHandler = () => {
    setOpenEquipment(true);
  };

  const dialogEquipmentCloseHandler = () => {
    setOpenEquipment(false);
  };

  const backDrophandleClose = () => {
    setOpenBackDrop(false);
  };

  const backDrophandleOpen = async () => {
    setOpenBackDrop(true);
  };

  // Fetching data
  const getDataSource =
    (entity) =>
    ({ skip, limit, sortInfo, groupBy, filterValue }) => {
      const queryParams = [
        skip ? `skip=${skip}` : null,
        limit ? `limit=${limit}` : null,
        groupBy && groupBy.length ? `groupBy=${groupBy}` : null,
        filterValue && filterValue.length
          ? `filterBy=${JSON.stringify(
              filterValue
                ?.filter((v) => v.active !== false)
                ?.map((v) => ({
                  value: v.value,
                  name: v.name,
                  operator: v.operator,
                  type: v.type,
                }))
            )}`
          : null,
        sortInfo ? `sortInfo=${JSON.stringify(sortInfo)}` : null,
      ]
        ?.filter((value) => value)
        .join("&");

      return fetch(`/echoMe/reg/${entity}?${queryParams}`, requestOptions).then(
        (response) => {
          const totalCount = response.headers.get("X-Total-Count");
          return response
            .json()
            .then((data) => ({ data, count: parseInt(totalCount, 10) }));
        }
      );
    };

  // React query responses
  const onSuccess = (data) => {
    console.log("Sucess", data?.data);
    // queryClient.invalidateQueries("assetReg");
  };

  const onError = (error) => {
    console.log(error);
  };

  const newDataSource = useCallback(getDataSource("listRegisterHeader"), [
    dialogUploadOpen,
  ]);

  // Reat query
  const {
    isLoading,
    data: assetRegistration,
    isError,
    error,
    isFetching,
    refetch,
    refetchIntervalInBackground,
  } = useQuery("assetReg-loadRegisterHeader", newDataSource, {
    onSuccess,
    onError,
    refetchInterval: 300000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
  });

  //  Filtering document regNum with selected
  const filterDocumentId = () => {
    assetRegistration?.data
      ?.filter((d) => d.id === checked)
      ?.map((d) => setSelectedDocumentNum(d.regNum));
  };

  //  Filtering selected object which is needed for process screen
  const filterDocumentObject = () => {
    assetRegistration?.data
      ?.filter((d) => d.id === checked)
      ?.map((d) => setSelectedObject(d));
  };

  //  Button functions
  const clearData = () => {
    setGridState(Object?.assign({}, gridState, { dataSource: [] }));
  };

  const loadData = () => {
    clearData();
    refetch();
    setTimeout(
      () =>
        setGridState(
          Object?.assign({}, gridState, { dataSource: newDataSource })
        ),
      10
    );
  };

  useEffect(() => {
    filterDocumentId();
    filterDocumentObject();
  }, [checked, loadData]);

  // for changing language
  useEffect(() => {
    gridState.columns = [];
    gridState.columns = defaultColumns;
  }, [storedLan]);

  //  Load data first whenever page is loaded
  useEffect(() => {
    if (storedSite) {
      loadData();
    } else {
      alert(t(`assetReg.Async.alert-location`));
      navigate("/dashboard");
    }
  }, [storedSite]);

  const uploadFile = () => {
    setNewOrUpdate("new");
    setDialogUploadOpen(true);
  };

  const onDialogFileUploadClose = (event, reason, buttonStr) => {
    if (reason !== "backdropClick") setDialogUploadOpen(false);
    if (buttonStr === "submit") loadData();
  };
  // const myFunc = () => {};

  const onLimitChange = (newLimit) => {
    setGridState(Object.assign({}, gridState, { limit: newLimit }));
  };

  const onSortInfoChange = (value) => {
    // const newSortInfo = value ? { type: value.type, name: value.name, dir: value.dir } : value;
    // setGridState(Object.assign({}, gridState, { sortInfo: newSortInfo }));
    setGridState(Object.assign({}, gridState, { sortInfo: value }));
  };

  const onFilterValueChange = (value) => {
    // const newFilterValue = JSON.parse(JSON.stringify(value));
    // const newFilterValue = value ? [...value] : value;
    setGridState(Object.assign({}, gridState, { filterValue: value }));
  };

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setChecked(selected);
  }, []);

  const onColumnOrderChange = (columnOrder) => {
    setGridState(Object.assign({}, gridState, { columnOrder }));
  };

  const onEditComplete = useCallback(() => {});

  // const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
  //   menuProps.autoDismiss = true;
  //   menuProps.items = [
  //     {
  //       label: `myFunc: ${rowProps.data[cellProps.id]}`,
  //       onClick: () => {
  //         myFunc({ rowProps, cellProps });
  //       },
  //     },
  //   ];
  // };

  const exportData = async () => {
    backDrophandleOpen();
    fetch(
      "/echoMe/reg/exportRegistration" +
        `?sortInfo=${JSON.stringify(
          gridState.sortInfo
        )}&filterBy=${JSON.stringify(gridState.filterValue)}`
    ).then((response) => {
      response.blob().then((blob) => {
        if (response?.ok === true) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Registration.xlsx";
          a.click();
          backDrophandleClose();
        } else if (response?.ok === false) {
          alert("There is something wrong during processing data.");
          backDrophandleClose();
        }
      });
    });
  };

  const cancelOrder = async () => {
    if (selectedObject?.status.toLowerCase() === "completed") {
      alert(t(`assetReg.Async.alert-cancelOrder`));
    } else {
      try {
        const data = await fetch(
          `/echoMe/reg/cancelRegister?regNum=${encodeURIComponent(
            selectedDocumentNum
          )}`
        );
        if (!data.ok) {
          console.log("error message from cancelOrder");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        const json = await data.json();
      } catch (err) {
        console.log(err);
      }
    }
    setChecked(null);
    loadData();
  };

  // Case handling
  // if (isLoading || isFetching) {
  //   return <Skeleton variant="rectangular" width="auto" height="auto" />;
  // }

  if (isError) {
    window.location.reload();
  }

  const partialCompeleteOrder = async () => {
    if (selectedObject?.status.toLowerCase() === "partial_complete") {
      try {
        const data = await fetch(
          `/echoMe/reg/recreatePartialCmpOrder?regNum=${selectedDocumentNum}`
        );
        if (!data.ok) {
          console.log("error message from cancelOrder");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        const json = await data.json();
      } catch (err) {
        console.log(err);
      }
      setChecked(null);
      loadData();
    } else {
      alert(t(`assetReg.Async.alert-partialCompete`));
    }
  };

  return (
    <div>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Toolbar
          style={{ minHeight: "10px" }}
          sx={(theme) => navbarContainer(theme)}
        >
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }}>
            <MDTypography variant="h6" color="white">
              {t("assetReg.Async.header-name")}
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDButton
              style={{ marginRight: 10 }}
              size="small"
              onClick={loadData}
            >
              {t("assetReg.Async.button-loadData")}
            </MDButton>
            <MDButton
              style={{ marginRight: 10 }}
              size="small"
              disabled={Array.isArray(gridState.dataSource)}
              onClick={clearData}
            >
              {t("assetReg.Async.button-clearData")}
            </MDButton>
            {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETREG") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={uploadFile}
              >
                {t("assetReg.Async.button-importData")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETREG") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={dialogEquipmentClickHandler}
                disabled={selectedObject?.status.toLowerCase() === "completed"}
              >
                {t("assetReg.Async.button-equipment")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETREG") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                onClick={dialogProcessClickHandler}
                disabled={checked === null || checked === true || checked === 0}
              >
                {t("assetReg.Async.button-process")}
              </MDButton>
            )}
            {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETREG") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes("ROLE_ECHOME_TEAMREADER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_PICKER1")) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                disabled={Array?.isArray(gridState.dataSource)}
                onClick={exportData}
              >
                {t("assetReg.Async.button-export")}
              </MDButton>
            )}
            {/* {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETREG") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_TEAMREADER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                disabled={
                  checked === null ||
                  checked === true ||
                  checked === 0 ||
                  selectedObject?.status.toLowerCase() === "completed" ||
                  selectedObject?.status.toLowerCase() === "cancelled" ||
                  selectedObject?.status.toLowerCase() === "registering"
                }
                onClick={partialCompeleteOrder}
              >
                {t("assetReg.Async.button-partialComplete")}
              </MDButton>
            )} */}
            {(UserService.getClientRole().includes("ROLE_ECHOME_ASSETREG") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERUSER") ||
              UserService.getClientRole().includes("ROLE_ECHOME_SUPERVISORS") ||
              UserService.getClientRole().includes(
                "ROLE_ECHOME_TEAMREADER"
              )) && (
              <MDButton
                style={{ marginRight: 10 }}
                size="small"
                disabled={
                  checked === null ||
                  checked === true ||
                  checked === 0 ||
                  selectedObject?.status.toLowerCase() === "completed" ||
                  selectedObject?.status.toLowerCase() === "cancelled" ||
                  selectedObject?.status.toLowerCase() === "registering"
                }
                onClick={cancelOrder}
              >
                {t("assetReg.Async.button-cancel")}
              </MDButton>
            )}
          </MDBox>
        </Toolbar>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openBackDrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </MDBox>
      <MDBox pt={3}>
        <ReactDataGrid
          licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
          idProperty="id"
          style={gridStyle}
          columns={gridState.columns}
          dataSource={gridState.dataSource}
          defaultGroupBy={[]}
          defaultFilterValue={defaultFilterValue}
          enableSelection
          selected={checked}
          multiSelect={false}
          checkboxColumn
          pagination
          pageSizes={[10, 50, 100]}
          limit={gridState.limit}
          onLimitChange={onLimitChange}
          onSelectionChange={onSelectionChange}
          onSortInfoChange={onSortInfoChange}
          onFilterValueChange={onFilterValueChange}
          onColumnOrderChange={onColumnOrderChange}
          onEditComplete={onEditComplete}
          // renderRowContextMenu={renderRowContextMenu}
          onRenderRow={onRenderRow}
          defaultSortInfo={defaultSortInfo}
        />
        <DialogFileUpload
          newOrUpdate={newOrUpdate}
          open={dialogUploadOpen}
          selected={checked}
          onDialogClose={onDialogFileUploadClose}
          load={refetch}
        />
        <DialogProcess
          // selected={JSON.stringify(selected)}
          open={openProcess}
          close={dialogProcessCloseHandler}
          selected2={selectedDocumentNum}
          selected3={selectedObject}
          setChecked={setChecked}
          load={loadData}
        />
        <Equipmentdialog
          // selected={JSON.stringify(selected)}
          open={openEquipment}
          close={dialogEquipmentCloseHandler}
        />
      </MDBox>
    </div>
  );
};

export default RegistrationAsync2;
