/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable prefer-const */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable no-return-assign */
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Grid,
  Typography,
  TextField,
  Box,
  MenuItem,
  Paper,
} from "@mui/material";

import DialogStockTakeRecount from "./DialogStockTakeRecount";
import StockTakeListData1 from "./StockTakeListData1";

// // eslint-disable-next-line import/no-absolute-path
// eslint-disable-next-line arrow-body-style

// React data grid
const gridStyle = { minHeight: 370 };
const gridStyle2 = { minHeight: 330 };

const STATUS_SELECTION = [
  "OUTSTANDING",
  "SCANNED",
  "IN_RANGE_EXCEPTIONAL",
  "OUT_RANGE_EXCEPTIONAL",
];

const defaultFilterValue = [
  { name: "id", type: "string", operator: "eq", value: "" },
  { name: "stNum", type: "string", operator: "contains", value: "" },
  { name: "containerCode", type: "string", operator: "contains", value: "" },
  { name: "itemAssetCode", type: "string", operator: "contains", value: "" },
  { name: "skuCode", type: "string", operator: "contains", value: "" },
  { name: "locCode", type: "string", operator: "contains", value: "" },
  // { name: "lineStatus", type: "select", operator: "eq", value: "" },
  { name: "lineStatus", type: "string", operator: "contains", value: "" },
];

const DialogProcessStockOut = ({
  open,
  close,
  selected2,
  load,
  filterDocumentObject,
  setChecked,
}) => {
  const [isInitiated, setIsInitiated] = useState(true);
  const [isStarted, setIsStarted] = useState(true);
  const [selected, setSelected] = useState({});
  const [dataArray, setdataArray] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("Scanned");
  const [locCode, setLocCode] = useState([]);
  const [selectedLocCode, setSelectedLocCode] = useState("");
  const openMenu = Boolean(anchorEl);
  const [value, setValue] = useState(3);
  const { t } = useTranslation(["stockTake"]);
  const [openRecount, setOpenRecount] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  // const [scannerIP, setScannerIP] = useState("localhost");
  const [scannerIP, setScannerIP] = useState("");
  const [containerCode, setContainerCode] = useState("");
  const [equipmentRFID, setEquipmentRFID] = useState("");
  const [isScanning, setIsScanning] = useState(false);
  const [isCleared, setIsCleared] = useState(true);
  const [isScanned, setIsScanned] = useState(false);
  const [isOneTimeReg, setIsOneTimeReg] = useState(false);
  const [containerAssetCode, setContainerAssetCode] = useState("");
  const [selectedContainerRFID, setSelectedContainerRFID] = useState([]);
  const [containerRfidList, setContainerRfidList] = useState([]);
  const [regList, setRegList] = useState([]);
  const [scannerArray, setScannerArray] = useState([]);
  const [timerId, setTimerId] = useState("");
  const [isM2Scanning, setIsM2Scanning] = useState(false);
  const columns = [
    { name: "id", header: "Id", type: "number", defaultVisible: false },
    {
      name: "containerCode",
      header: t(`stockTake.DialogCreateStockTake.column-siteCode`),
      defaultFlex: 2,
      type: "string",
    },
    {
      name: "itemAssetCode",
      header: t(`stockTake.DialogProcessStockOut.column-itemAssetCode`),
      defaultFlex: 3,
      type: "string",
    },
    {
      name: "skuCode",
      header: t(`stockTake.DialogProcessStockOut.column-skuCode`),
      defaultFlex: 2,
      type: "string",
    },
    {
      name: "locCode",
      header: t(`stockTake.DialogProcessStockOut.column-locCode`),
      defaultFlex: 2,
      type: "string",
    },
    {
      name: "lineStatus",
      header: t(`stockTake.DialogProcessStockOut.column-description`),
      defaultFlex: 2.2,
      type: "string",
    },
    {
      name: "reason",
      header: t(`stockTake.DialogProcessStockOut.column-reason`),
      defaultFlex: 2,
      type: "string",
    },
  ];

  const columns2 = [
    {
      name: "rfid",
      header: t(`stockTake.DialogProcessStockOut.column-RFID`),
      defaultFlex: 1,
      type: "string",
    },
    {
      name: "rsku",
      header: t(`stockTake.DialogProcessStockOut.column-RSKU`),
      defaultFlex: 1,
      type: "string",
    },
  ];

  // React query responses
  const onSuccess = (data) => {
    console.log("Sucess", data);
  };

  const onError = (error) => {
    console.log(error);
  };

  // Fetching scanner List with reg num
  const getScannerList = async () => {
    const data = await fetch(
      `/echoMe/param/findAllActiveParamByCode?paramName=READER_IP`,
      {
        mode: "cors",
      }
    );
    const json = await data.json();
    setScannerArray(json);
    return json;
  };

  // Reat query -scanner list
  const {
    data: scannerListProcessStockTake,
    isFetching: isFetchingScannerListProcessStockTake,
    refetch: refetchScannerListProcessStockTake,
    isError: isErrorScannerListProcessStockTake,
  } = useQuery("stockTake-getScannerList", getScannerList, {
    onSuccess,
    onError,
    // refetchInterval: 1000,
    // refetchIntervalInBackground: true,
    // refetchOnWindowFocus: true,
    enabled: false,
  });

  //  Dialog Handler
  const dialogRecountClickHandler = () => {
    setOpenRecount(true);
  };

  const dialogRecountCloseHandler = () => {
    setOpenRecount(false);
  };

  const dialogLocationClickHandler = () => {
    setOpenLocation(true);
  };

  const dialogLocationCloseHandler = () => {
    setOpenLocation(false);
  };

  const backDrophandleClose = () => {
    setOpenBackDrop(false);
  };

  const backDrophandleOpen = () => {
    setOpenBackDrop(true);
  };

  // Button functions
  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  // Fetching scanner list whenever dialog is opened
  useEffect(() => {
    if (selected2?.stNum) {
      refetchScannerListProcessStockTake();
      setStatus(selected2?.status);
    }
  }, [open]);

  const itemRFIDDeleteButtonHandler = async () => {
    const temp = _.difference(regList, Object?.values(selected));
    setRegList(temp);
    setSelected({});
  };

  const getData = (arrayData) => {
    setSelectedContainerRFID(
      arrayData === null || arrayData === undefined ? {} : arrayData
    );
  };

  const stopScanButtonHandler = async () => {
    if (scannerIP) {
      setIsScanning(false);
      clearTimeout(timerId); // clear the timeoutID
      setTimerId("");
    }
  };

  const startScanM2 = async () => {
    if (scannerIP) {
      setIsM2Scanning(true);
      backDrophandleOpen();
      try {
        const data = await fetch(
          // `http://192.168.1.141:8080/echoMe/rfid/getReaderData?ipAdd=${scannerIP}`,
          `echoMe/rfid/getReaderData?ipAdd=${scannerIP}`,
          // `/echoMe/rfid/getReaderData?ipAdd=dummyData`,
          {
            mode: "cors",
          }
        );
        if (!data.ok) {
          console.log("error message from submitHandler");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        setIsM2Scanning(false);
        const json = await data.json();

        // Filtering RFID for Display
        const filteredRegData = [];
        json
          ?.filter((d) => d?.rfid.includes("SAT"))
          .map((d) => filteredRegData.push(d));
        // If first time scanning
        if (_.isEmpty(regList)) {
          setRegList(filteredRegData);
        } else if (!_.isEmpty(regList)) {
          // If more than one time then accumulate result
          const temp = _.differenceBy(filteredRegData, regList, "rfid");
          const removeDupReg = _.uniqBy([...temp, ...regList], "rfid");
          setRegList(removeDupReg);
        }

        // Filtering Container RFID for Display
        const filteredContainerData = [];
        json
          ?.filter((d) => d?.rfid.includes("CAT"))
          .map((d) => filteredContainerData.push(d));
        // If first time scanning
        if (_.isEmpty(containerRfidList)) {
          setContainerRfidList(filteredContainerData);
        } else if (!_.isEmpty(containerRfidList)) {
          // If more than one time then accumulate result
          const temp = _.differenceBy(
            filteredContainerData,
            containerRfidList,
            "rfid"
          );
          const removeDupCon = _.uniqBy(
            [...temp, ...containerRfidList],
            "rfid"
          );
          setContainerRfidList(removeDupCon);
        }

        // console.log({ filteredRegData, filteredContainerData });
        setIsScanning(false);
        setIsScanned(true);
        setIsCleared(false);
        backDrophandleClose();
      } catch (err) {
        console.error(err);
        backDrophandleClose();
        alert(err);
      }
    }
  };

  const clearButtonHandler = async () => {
    if (scannerIP) {
      setRegList([]);
      setRegList([]);
      setEquipmentRFID("");
      setIsScanned(false);
      setContainerCode("");
      setIsOneTimeReg(false);
      setIsScanning(false);
      setContainerRfidList([]);
      setSelectedContainerRFID("");
      setSelected(null);
      setIsCleared(true);
      setIsM2Scanning(false);
    }
  };

  const handleCancel = () => {
    setAnchorEl(null);
    setIsInitiated(true);
    load();
    setContainerCode("");
    setEquipmentRFID("");
    setTimerId("");
    setContainerAssetCode("");
    setIsScanned(false);
    setIsOneTimeReg(false);
    setLocCode([]);
    setIsScanning(false);
    setRegList([]);
    setScannerArray([]);
    setScannerIP("");
    setSelectedLocCode("");
    setContainerRfidList([]);
    setSelectedContainerRFID("");
    setSelected(null);
    setIsCleared(true);
    setChecked(null);
    setIsM2Scanning(false);
    close();
  };

  const closeButtonHandler = async () => {
    backDrophandleOpen();
    const rfids = regList.map((d) => d?.rfid);
    if (_.isEmpty(regList) || !selectedLocCode) {
      alert(t(`stockTake.DialogProcessStockOut.alert-closeButtonHandler`));
      backDrophandleClose();
    } else if (rfids.length > 1 && selectedLocCode) {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const data = await fetch(`/echoMe/stocktake/checkInItems`, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            locCode: selectedLocCode,
            rfids,
            stNum: encodeURIComponent(selected2?.stNum),
          }),
        });
        if (!data.ok) {
          console.log("error message from registerItems");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        alert(t(`stockTake.DialogProcessStockOut.alert-closeButtonHandler2`));
        setIsOneTimeReg(false);
        load();
        clearButtonHandler();
        filterDocumentObject();
        backDrophandleClose();
      } catch (err) {
        alert(err);
        setIsOneTimeReg(false);
        backDrophandleClose();
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleIPChange = async (event) => {
    setScannerIP(event.target.value);
    let tempLocCodeArray = selected2?.ranges.split(",");
    setLocCode(tempLocCodeArray);
  };

  const handleLocationChange = (event) => {
    setSelectedLocCode(event.target.value);
  };

  const inRangeExceptionHandler = async () => {
    setTitle("In-Range Exceptional");
    backDrophandleOpen();
    try {
      const data = await fetch(
        `/echoMe/stocktake/listStocktakeLine?stNum=${encodeURIComponent(
          selected2?.stNum
        )}&filterBy=[{"value":"IN_RANGE_EXCEPTIONAL","name":"status","operator":"eq","type":"select"}]`
      );
      if (!data.ok) {
        console.log("error message from exceptionHandler");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      setdataArray(json);
      backDrophandleClose();
    } catch (err) {
      alert(err);
    }
    backDrophandleClose();
  };

  const outRangeExceptionHandler = async () => {
    setTitle("Out-Range Exceptional");
    backDrophandleOpen();
    try {
      const data = await fetch(
        `/echoMe/stocktake/listStocktakeLine?stNum=${encodeURIComponent(
          selected2?.stNum
        )}&filterBy=[{"value":"OUT_RANGE_EXCEPTIONAL","name":"status","operator":"eq","type":"select"}]`
      );
      if (!data.ok) {
        console.log("error message from exceptionHandler");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      setdataArray(json);
      backDrophandleClose();
    } catch (err) {
      alert(err);
    }
    backDrophandleClose();
  };

  const scannednHandler = async () => {
    setTitle("Scanned");
    backDrophandleOpen();
    try {
      const data = await fetch(
        `/echoMe/stocktake/listStocktakeLine?stNum=${encodeURIComponent(
          selected2?.stNum
        )}&filterBy=[{"value":"SCANNED","name":"status","operator":"eq","type":"select"}]`
      );
      if (!data.ok) {
        console.log("error message from exceptionHandler");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      setdataArray(json);
      backDrophandleClose();
    } catch (err) {
      alert(err);
    }
    backDrophandleClose();
  };

  const outstandingHandler = async () => {
    setTitle("Outstanding");
    backDrophandleOpen();
    try {
      const data = await fetch(
        `/echoMe/stocktake/listStocktakeLine?stNum=${encodeURIComponent(
          selected2?.stNum
        )}&filterBy=[{"value":"OUTSTANDING","name":"status","operator":"eq","type":"select"}]`
      );
      if (!data.ok) {
        console.log("error message from exceptionHandler");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);
        throw Error(errorText);
      }
      const json = await data.json();
      setdataArray(json);
      backDrophandleClose();
    } catch (err) {
      alert(err);
    }
    backDrophandleClose();
  };

  // Set initated to different state according to corresponding status
  useEffect(() => {
    outstandingHandler();
    if (selected2?.status === "DRAFT") {
      setIsInitiated(false);
      setIsStarted(false);
    } else if (selected2?.status === "INITIATED") {
      setIsInitiated(true);
      setIsStarted(false);
    } else if (
      selected2?.status === "STOCKTAKING" ||
      selected2?.status === "CANCEL" ||
      selected2?.status === "COMPLETE"
    ) {
      setIsStarted(true);
      setIsInitiated(true);
    }
  }, [open]);

  const initiateButtonHandler = async () => {
    backDrophandleOpen();

    try {
      const data = await fetch(
        `/echoMe/stocktake/stocktakeInitiate?stNum=${encodeURIComponent(
          selected2?.stNum
        )}`
      );
      if (!data.ok) {
        console.log("error message from initiateButtonHandler");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);

        throw Error(errorText);
      }
      const json = await data.json();
      setIsInitiated(true);
      setStatus("INITIATED");
      backDrophandleClose();
    } catch (err) {
      alert(err);
    }
  };

  const startButtonHandler = async () => {
    backDrophandleOpen();

    try {
      const data = await fetch(
        `/echoMe/stocktake/stocktakeStart?stNum=${encodeURIComponent(
          selected2?.stNum
        )}`
      );
      if (!data.ok) {
        console.log("error message from startButtonHandler");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);

        throw Error(errorText);
      }
      const json = await data.json();
      setIsStarted(true);
      setStatus("STOCKTAKING");
      backDrophandleClose();
      handleCancel();
    } catch (err) {
      alert(err);
    }
  };

  const completeButtonHandler = async () => {
    try {
      const data = await fetch(
        `/echoMe/stocktake/stocktakeComplete?stNum=${encodeURIComponent(
          selected2?.stNum
        )}`
      );
      if (!data.ok) {
        console.log("error message from completeButtonHandler");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);

        throw Error(errorText);
      }
      const json = await data.json();
      // console.log(json);
      setChecked(null);
      handleCancel();
    } catch (err) {
      alert(err);
    }
  };

  const cancelButtonHandler = async () => {
    try {
      const data = await fetch(
        `/echoMe/stocktake/stocktakeCancel?stNum=${encodeURIComponent(
          selected2?.stNum
        )}`
      );
      if (!data.ok) {
        console.log("error message from cancelButtonHandler");
        let error = await data;
        let errorText = await data.text();
        console.log(errorText);

        throw Error(errorText);
      }
      const json = await data.json();
      // console.log(json);
      setChecked(null);
      handleCancel();
    } catch (err) {
      alert(err);
    }
  };

  const exportData = () => {
    backDrophandleOpen();
    fetch(
      `/echoMe/stocktake/exportStocktakeCntLst?stNum=${encodeURIComponent(
        selected2?.stNum
      )}`
    ).then((response) => {
      response.blob().then((blob) => {
        if (response?.ok === true) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${selected2?.stNum} Counting Result.xlsx`;
          a.click();
          backDrophandleClose();
        } else if (response?.ok === false) {
          alert(err);
          backDrophandleClose();
        }
      });
    });
  };

  const scanButtonHandler = async () => {
    startScanM2();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        fullWidth={true}
        maxWidth="xl"
        fullScreen={true}
      >
        <DialogTitle>
          {t("stockTake.DialogProcessStockOut.header-name")}
        </DialogTitle>
        <DialogContent>
          {t("stockTake.DialogProcessStockOut.bodytext-documentNumber")}
          <b style={{ color: "red" }}>{selected2?.stNum}</b>
          <Typography variant="h6" sx={{ float: "right", color: "orange" }}>
            {selected2?.status.toLowerCase() === "draft" ||
            selected2?.status.toLowerCase() === "initiated" ||
            selected2?.status.toLowerCase() === "stocktaking"
              ? status
              : selected2?.status?.replaceAll("_", " ")}
          </Typography>
          <Box
            variant="div"
            sx={{ padding: "20px", border: "1px solid", borderRadius: "8px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6">
                        {t("stockTake.DialogProcessStockOut.button-equip")}
                      </Typography>
                    </Grid>{" "}
                    <Grid item xs={12} sm={3}>
                      {}
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      {}
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      {}
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      {}
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        fullWidth={true}
                        style={{ color: "white" }}
                        onClick={closeButtonHandler}
                        disabled={
                          selected2?.status.toLowerCase() === "complete" ||
                          selected2?.status.toLowerCase() === "cancel" ||
                          isScanning ||
                          !isScanned ||
                          !scannerIP ||
                          isM2Scanning ||
                          !selectedLocCode
                        }
                      >
                        {t("stockTake.DialogProcessStockOut.button-regItem")}
                      </Button>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{ marginTop: "10px", marginLeft: "15px" }}
                      >
                        <StockTakeListData1
                          filteredData={containerRfidList}
                          getData={getData}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "row",
                    padding: "15px",
                  }}
                >
                  <Grid container spacing={2} mb="10px">
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h6">
                        {t(
                          "stockTake.DialogProcessStockOut.sectionHeader-equipment"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        margin="dense"
                        id="range-input"
                        name="range"
                        label={t("stockTake.DialogProcessStockOut.input-range")}
                        type="text"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth={true}
                        value={selected2?.ranges ?? "None"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          margin="dense"
                          id="start-input"
                          name="start"
                          label={t(
                            "stockTake.DialogProcessStockOut.input-startDate"
                          )}
                          type="text"
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={true}
                          value={
                            selected2?.startDate === null
                              ? "None"
                              : moment(selected2?.startDate).format(
                                  "YYYY-MM-DD"
                                )
                          }
                        />
                        <TextField
                          margin="dense"
                          id="totalContainerQty-input"
                          name="totalContainerQty"
                          label={t(
                            "stockTake.DialogProcessStockOut.input-totalContainerQty"
                          )}
                          type="text"
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={true}
                          value={selected2?.totalContainerQty ?? "None"}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid item xs={12} sm={12}>
                        {/* <TextField
                            margin="dense"
                            id="maker-input"
                            name="maker"
                            label={t("stockTake.DialogProcessStockOut.input-maker")}
                            type="text"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth={true}
                            value={selected2?.maker ?? "None"}
                          /> */}
                        <TextField
                          margin="dense"
                          id="endDate-input"
                          name="endDate"
                          label={t(
                            "stockTake.DialogProcessStockOut.input-endDate"
                          )}
                          type="text"
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={true}
                          value={
                            selected2?.startDate === null
                              ? "None"
                              : moment(selected2?.endDate).format("YYYY-MM-DD")
                          }
                        />
                        <TextField
                          margin="dense"
                          id="totalExceptionQty-input"
                          name="totalExceptionQty"
                          label={t(
                            "stockTake.DialogProcessStockOut.input-totalExceptionQty"
                          )}
                          type="text"
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={true}
                          value={selected2?.totalExceptionQty ?? "None"}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid item xs={12} sm={12}>
                        {/* <TextField
                            margin="dense"
                            id="taker-input"
                            name="taker"
                            label={t("stockTake.DialogProcessStockOut.input-taker")}
                            type="text"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth={true}
                            value={selected2?.taker ?? "None"}
                          /> */}
                        <TextField
                          margin="dense"
                          id="totalAssetQty-input"
                          name="totalAssetQty"
                          label={t(
                            "stockTake.DialogProcessStockOut.input-totalAssetQty"
                          )}
                          type="text"
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={true}
                          value={selected2?.totalAssetQty ?? "None"}
                        />
                        <TextField
                          margin="dense"
                          id="totalStocktakeQty-input"
                          name="totalStocktakeQty"
                          label={t(
                            "stockTake.DialogProcessStockOut.input-totalStocktakeQty"
                          )}
                          type="text"
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={true}
                          value={selected2?.totalStocktakeQty ?? "None"}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "row",
                    padding: "15px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Typography variant="h6">
                          {t(
                            "stockTake.DialogProcessStockOut.sectionHeader-regList"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Box sx={{ marginLeft: "3px" }}>
                          <FormControl fullWidth variant="standard">
                            <InputLabel id="ip-select">
                              {t("stockTake.DialogProcessStockOut.dropDown-IP")}
                            </InputLabel>
                            <Select
                              labelId="ip-label"
                              id="ip-select"
                              label={t(
                                "stockTake.DialogProcessStockOut.dropDown-IP"
                              )}
                              onChange={handleIPChange}
                              value={scannerIP}
                              disabled={
                                selected2?.status.toLowerCase() ===
                                  "complete" ||
                                selected2?.status.toLowerCase() === "cancel" ||
                                isScanning ||
                                isOneTimeReg ||
                                isM2Scanning
                              }
                            >
                              {scannerArray?.map((scanner) => (
                                <MenuItem
                                  key={scanner.id}
                                  value={scanner.value}
                                >
                                  {`${scanner.code} - ${scanner.value}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={2.5}>
                        <Box>
                          <FormControl fullWidth variant="standard">
                            <InputLabel id="location-label">
                              {t(
                                "stockTake.DialogChooseLocationStockTake.headerName"
                              )}
                            </InputLabel>
                            <Select
                              labelId="location-label"
                              id="location-select"
                              label={t(
                                "stockTake.DialogChooseLocationStockTake.headerName"
                              )}
                              onChange={handleLocationChange}
                              value={selectedLocCode}
                              disabled={
                                selected2?.status.toLowerCase() ===
                                  "complete" ||
                                selected2?.status.toLowerCase() === "cancel" ||
                                isScanning ||
                                isOneTimeReg ||
                                isM2Scanning ||
                                !scannerIP
                              }
                            >
                              {locCode?.map((location) => (
                                <MenuItem key={location} value={location}>
                                  {`${location}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={1}>
                        {}
                      </Grid>
                      <Grid item xs={12} sm={1.5}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          disabled={
                            selected2?.status.toLowerCase() === "complete" ||
                            selected2?.status.toLowerCase() === "cancel" ||
                            selected2?.status.toLowerCase() === "draft" ||
                            selected2?.status.toLowerCase() === "initiated" ||
                            isScanning ||
                            !scannerIP ||
                            isM2Scanning ||
                            !selectedLocCode
                          }
                          style={{ color: "white" }}
                          onClick={scanButtonHandler}
                        >
                          {t("stockTake.DialogProcessStockOut.button-scan")}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={1.5}>
                        <Button
                          variant="contained"
                          fullWidth={true}
                          onClick={clearButtonHandler}
                          style={{
                            color: "white",
                            backgroundColor: "red",
                            marginRight: "-100px",
                          }}
                          disabled={
                            selected2?.status.toLowerCase() === "complete" ||
                            selected2?.status.toLowerCase() === "cancel" ||
                            !scannerIP ||
                            !selectedLocCode
                          }
                        >
                          {t("stockTake.DialogProcessStockOut.button-clear")}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={1.5}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ color: "white", backgroundColor: "red" }}
                          fullWidth={true}
                          onClick={itemRFIDDeleteButtonHandler}
                          disabled={
                            selected2?.status.toLowerCase() === "complete" ||
                            selected2?.status.toLowerCase() === "cancel" ||
                            isScanning ||
                            !isScanned ||
                            isOneTimeReg ||
                            !scannerIP ||
                            Object?.values(selected).length < 1 ||
                            regList.length < 1 ||
                            isM2Scanning ||
                            !selectedLocCode
                          }
                        >
                          {t("stockTake.DialogProcessStockOut.button-delete")}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <ReactDataGrid
                          licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                          idProperty="rfid"
                          checkboxColumn
                          // multiSelect={false}
                          selected={selected}
                          onSelectionChange={onSelectionChange}
                          style={gridStyle2}
                          columns={columns2}
                          dataSource={regList}
                        />
                        <p>
                          {t(`stockTake.total`)}: {regList.length}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px",
                  }}
                >
                  <Grid container spacing={2} mt="5px">
                    <Grid item xs={12} sm={7}>
                      <Box sx={{ width: "100%" }}>
                        <Tabs value={value} onChange={handleChange}>
                          <Tab
                            label="In-range Exceptional"
                            onClick={inRangeExceptionHandler}
                          />
                          <Tab
                            label="Out-range Exceptional"
                            onClick={outRangeExceptionHandler}
                          />
                          <Tab label="Scanned" onClick={scannednHandler} />
                          <Tab
                            label="Outstanding"
                            onClick={outstandingHandler}
                          />
                        </Tabs>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                      {}
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <Button
                        variant="contained"
                        fullWidth={true}
                        style={{ color: "white" }}
                        onClick={dialogRecountClickHandler}
                        disabled={
                          selected2?.status.toLowerCase() === "cancel" ||
                          selected2?.status.toLowerCase() === "draft" ||
                          selected2?.status.toLowerCase() === "initiated" ||
                          isScanning ||
                          !isCleared ||
                          isM2Scanning
                        }
                      >
                        {t("stockTake.DialogProcessStockOut.button-recount")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <Button
                        variant="contained"
                        fullWidth={true}
                        style={{ color: "white" }}
                        onClick={exportData}
                        disabled={
                          !isInitiated ||
                          isScanning ||
                          !isCleared ||
                          selected2?.status.toLowerCase() === "draft" ||
                          selected2?.status.toLowerCase() === "initiated" ||
                          isM2Scanning
                        }
                      >
                        {t("stockTake.DialogProcessStockOut.button-export")}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} mt="10px">
                      <ReactDataGrid
                        licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                        idProperty="rfid"
                        style={gridStyle}
                        columns={columns}
                        dataSource={dataArray}
                        defaultFilterValue={defaultFilterValue}
                        pagination={true}
                        limit={10}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={openBackDrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </DialogContent>
        <DialogActions>
          {!isInitiated ? (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={initiateButtonHandler}
            >
              {t("stockTake.DialogProcessStockOut.button-initiate")}
            </Button>
          ) : !isStarted ? (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={startButtonHandler}
            >
              {t("stockTake.DialogProcessStockOut.button-start")}
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                style={{ color: "white" }}
                onClick={completeButtonHandler}
                disabled={
                  selected2?.status.toLowerCase() === "complete" ||
                  selected2?.status.toLowerCase() === "cancel" ||
                  isScanning ||
                  isM2Scanning
                }
              >
                {t("stockTake.DialogProcessStockOut.button-complete")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ color: "white", backgroundColor: "red" }}
                onClick={cancelButtonHandler}
                disabled={
                  selected2?.status.toLowerCase() === "complete" ||
                  selected2?.status.toLowerCase() === "cancel" ||
                  isScanning ||
                  isM2Scanning
                }
              >
                {t("stockTake.DialogProcessStockOut.button-cancel")}
              </Button>
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red" }}
            disabled={isScanning || isM2Scanning}
            onClick={handleCancel}
          >
            {t("stockTake.DialogProcessStockOut.button-close")}
          </Button>
        </DialogActions>
      </Dialog>
      <DialogStockTakeRecount
        open={openRecount}
        close={dialogRecountCloseHandler}
        selected2={selected2}
      />
    </>
  );
};

export default DialogProcessStockOut;
