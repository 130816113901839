/* eslint-disable spaced-comment */
/* eslint-disable no-undef */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable arrow-body-style */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Button, Box, Paper } from "@mui/material";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import UserService from "../../service/KeyCloakService";

// React Data-grid fields
const gridStyle = { minHeight: 600 };

// eslint-disable-next-line arrow-body-style
const DialogRegisteredEquipment = ({ open, close, regNum, selected3 }) => {
  const [arrayData, setArrayData] = useState([]);
  const [selected, setSelected] = useState({});
  const [pending, setPending] = useState({});
  const [confirmed, setConfirmed] = useState({});
  const { t } = useTranslation(["assetRegistration"]);

  const columns = [
    { name: "id", header: "Id", type: "number", defaultVisible: false },
    {
      name: "ancestor",
      header: t(`assetReg.DialogRegisteredEquipment.column-ancestor`),
      type: "string",
      defaultFlex: 1,
    },
    {
      name: "equipmentRfid",
      header: t(`assetReg.Async.DialogProcessRegistration-container`),
      type: "string",
      defaultFlex: 1,
    },
    {
      name: "totalSKU",
      header: t(`assetReg.DialogRegisteredEquipment.column-totalSKU`),
      defaultFlex: 1,
    },
    {
      name: "totalQty",
      header: t(`assetReg.DialogRegisteredEquipment.column-totalQty`),
      type: "string",
      defaultFlex: 1,
    },
    {
      name: "status",
      header: t(`assetReg.DialogRegisteredEquipment.column-status`),
      type: "string",
      defaultFlex: 1,
    },
  ];

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  // Load data
  const loadData = async () => {
    const data = await fetch(
      `/echoMe/bundle/getBundleSummaryByRegNum?regNum=${encodeURIComponent(
        regNum
      )}`
    );
    const json = await data.json();
    setArrayData(json);
    return json;
  };

  // React query responses
  const onSuccess = (data) => {
    console.log("Sucess", data);
  };

  const onError = (error) => {
    console.log(error);
  };

  // Reat query
  const {
    data: assetRegRegisteredEquipment,
    isFetching,
    refetch: refetchRegisteredEquipment,
  } = useQuery("assetReg-loadRegisteredEquipment", loadData, {
    onSuccess,
    onError,
    // refetchInterval: 300000,
    // refetchIntervalInBackground: true,
    // refetchOnWindowFocus: true,
    enabled: false,
  });

  const getStatus = () => {
    //  defining number of pending
    let temp = [];
    assetRegRegisteredEquipment
      ?.filter((r) => r.status.toLowerCase() === "pending")
      ?.map((r) => temp.push(r));
    setPending(temp.length);

    //  defining number of pending
    let temp2 = [];
    assetRegRegisteredEquipment
      ?.filter((r) => r.status.toLowerCase() === "confirmed")
      ?.map((r) => temp2.push(r));
    setConfirmed(temp2.length);
  };

  useEffect(() => {
    if (regNum) {
      refetchRegisteredEquipment();
      getStatus();
    }
  }, [open, regNum]);

  // Button functions
  const confirmButtonHandler = async () => {
    // Getting arrays of status
    const statusArray = [];
    Object?.values(selected)
      ?.filter((r) => r.status.toLowerCase() === "confirmed")
      ?.map((r) => statusArray.push(r.status));
    // Getting equipment ids.
    const rfidArray = Object?.values(selected)
      ?.map((r) => r.ancestor)
      ?.join(",");

    // If array has elements of "Confirmed"
    if (statusArray?.includes("CONFIRMED")) {
      alert(
        t(`assetReg.DialogRegisteredEquipment.alert-confirmButtonHandler1`)
      );
      setSelected(null);
    } else if (!rfidArray) {
      alert(
        t(`assetReg.DialogRegisteredEquipment.alert-confirmButtonHandler2`)
      );
    } else {
      // If array does not have elements of "Confirmed"
      // console.log(rfidArray);
      try {
        const data = await fetch(
          `/echoMe/rfid/confirmRegisteredItem?regNum=${encodeURIComponent(
            regNum
          )}&assetContainerCodes=${rfidArray}`
        );
        if (!data.ok) {
          console.log("error message from confirm equipment");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);

          throw Error(errorText);
        }
        setSelected(null);
        loadData();
      } catch (err) {
        console.error(err);
        alert(err);
        setSelected(null);
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        fullWidth={true}
        maxWidth="xl"
        fullScreen={true}
      >
        <DialogTitle>
          {t("assetReg.DialogRegisteredEquipment.header-name")}
        </DialogTitle>
        <DialogContent>
          <Box
            variant="div"
            sx={{ padding: "15px", border: "1px solid", borderRadius: "8px" }}
          >
            <Paper
              elevation={8}
              sx={{
                borderRadius: "8px",
                display: "flex",
                flexDirection: "row",
                padding: "25px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {}
                </Grid>

                <Grid item xs={12} sm={2}>
                  {}
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    onClick={refetchRegisteredEquipment}
                    style={{ color: "white" }}
                  >
                    {t("assetReg.DialogRegisteredEquipment.button-refresh")}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                  {(UserService.getClientRole().includes(
                    "ROLE_ECHOME_ASSETREG"
                  ) ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_SUPERUSER"
                    ) ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_SUPERVISORS"
                    ) ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_TEAMREADER"
                    ) ||
                    UserService.getClientRole().includes(
                      "ROLE_ECHOME_PICKER1"
                    )) && (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth={true}
                      onClick={confirmButtonHandler}
                      style={{ color: "white" }}
                      disabled={
                        selected3?.status.toLowerCase() === "completed" ||
                        selected3?.status.toLowerCase() === "cancelled"
                      }
                    >
                      {t("assetReg.DialogRegisteredEquipment.button-confirm")}
                    </Button>
                  )}
                </Grid>
                <Grid container spacing={2} mt="5px">
                  <Grid item xs={12} sm={12}>
                    <ReactDataGrid
                      licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                      idProperty="id"
                      checkboxColumn
                      selected={selected}
                      onSelectionChange={onSelectionChange}
                      style={gridStyle}
                      columns={columns}
                      // editable
                      dataSource={arrayData}
                    />
                    <p>
                      {t(
                        "assetReg.DialogRegisteredEquipment.text-totalRegEquip"
                      )}
                      {arrayData.length}
                      <br />
                      {t("assetReg.DialogRegisteredEquipment.text-pending")}
                      {pending}
                      <br />
                      {t("assetReg.DialogRegisteredEquipment.text-confirmed")}
                      {confirmed}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red" }}
            onClick={close}
          >
            {t("assetReg.DialogRegisteredEquipment.button-close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogRegisteredEquipment;
