/* eslint-disable prefer-const */
const printZpl = (txt) => {
  setTimeout(() => {
    let printWindow = window.open();
    printWindow.document.open("text/plain");
    printWindow.document.write(txt);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  });
};

export default printZpl;
