/* eslint-disable no-else-return */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-const */
import { useState, useEffect, useCallback, Suspense, useRef } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { uniqBy, uniqWith } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
// Images
import brandWhite from "assets/images/logo-whalespod.png";
import brandDark from "assets/images/logo-whalespod-dark.png";
import EchoMe from "components/EchoMe";
import DebugDisplay from "components/DebugDisplay";
// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SignOutB from "pages/authentication/sign-outbeta";
import Inventory from "pages/Inventory";
import TrackAndTrace from "pages/TrackAndTrace";
import Location from "pages/Location";
import LocProfile from "pages/LocProfile";
import Registration2 from "pages/Registration2";
import AssetReturn from "pages/Return";
import StockOut from "pages/StockOut";
import TransferIn from "pages/TransferIn";
import TransferOut from "pages/TransferOut";
import Notifications from "pages/notifications";
import Dashboard from "pages/dashboard";
import Account from "pages/Account";
import Setting from "pages/SystemSetting";

import "./App.css";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import { Typography } from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
// Material Dashboard 2 React themes
import theme from "assets/theme";
// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import PropTypes from "prop-types";
// React routes
import routes from "routes/routes";
// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
// Importing Keycloak components
import LoginGate from "./components/LoginGate";
import routeHander from "./service/routeService";
import UserService from "../src/service/KeyCloakService";

const ConfigsButton = ({ onClick }) => (
  <MDBox
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="3.25rem"
    height="3.25rem"
    bgColor="white"
    shadow="sm"
    borderRadius="50%"
    position="fixed"
    right="2rem"
    bottom="2rem"
    zIndex={99}
    color="dark"
    sx={{ cursor: "pointer" }}
    onClick={() => onClick()}
  >
    <Icon fontSize="small" color="inherit">
      Settings
    </Icon>
  </MDBox>
);

ConfigsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

//  Getting route service for side nav bar
const getRoutes = (allRoutes) =>
  allRoutes?.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }
    if (route.route) {
      return (
        <Route
          exact
          path={route.route}
          element={route.component}
          key={route.key}
        />
      );
    }
    return null;
  });

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const { t } = useTranslation(["common"]);
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isSiteChanged, setIsSiteChanged] = useState(false);
  const [temp, setTemp] = useState();
  const [site, setSite] = useState();
  const { pathname } = useLocation();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const storedLan = localStorage.getItem("i18nextLng");
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // routeHandler
  const routeHandler = () => {
    //  Getting basic route
    let route = [
      {
        type: "collapse",
        name: t("common.App.dashboard"),
        key: "dashboard",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "/dashboard",
        component: <Dashboard />,
      },
      {
        key: "divider1",
        type: "divider",
      },
      {
        key: "divider2",
        type: "divider",
      },
      {
        type: "collapse",
        name: t("common.App.profile"),
        key: "profile",
        icon: <Icon fontSize="small">person</Icon>,
        route: "/profile",
        component: <Account />,
      },
      {
        type: "collapse",
        name: t("common.App.setting"),
        key: "Setting",
        icon: <Icon fontSize="small">settings_applications</Icon>,
        route: "/setting",
        component: <Setting />,
      },
      // {
      //   type: "collapse",
      //   name: t("common.App.notification"),
      //   key: "notifications",
      //   icon: <Icon fontSize="small">notifications</Icon>,
      //   route: "/notifications",
      //   component: <Notifications />,
      // },
      {
        type: "collapse",
        name: t("common.App.logout"),
        key: "log-out",
        icon: <Icon fontSize="small">logout</Icon>,
        route: "/authentication/sign-outbeta",
        component: <SignOutB />,
      },
    ];

    //  Route sequence handling
    let profileIndex = route?.map((object) => object.key).indexOf("profile");
    if (keycloak.authenticated) {
      // console.log(UserService?.getClientRole());
      if (
        UserService?.getClientRole()?.includes("ROLE_ECHOME_LOCMANAGE") ||
        UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERUSER")
      ) {
        route.splice(profileIndex + 1, 0, {
          type: "collapse",
          name: t("common.App.location"),
          key: "Location",
          icon: <Icon fontSize="small">location_searching</Icon>,
          route: "/location",
          component: <LocProfile />,
        });
      }
    }
    if (UserService.isLoggedIn()) {
      if (
        UserService?.getClientRole()?.includes("ROLE_ECHOME_TRACT") ||
        UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERUSER")
      ) {
        route.splice(2, 0, {
          type: "collapse",
          name: t("common.App.trackandtrace"),
          key: "trackandtrace",
          icon: <Icon fontSize="small">add_circle</Icon>,
          route: "/trackandtrace",
          component: <TrackAndTrace />,
        });
      }
      if (
        UserService?.getClientRole()?.includes("ROLE_ECHOME_STOCKTAKE") ||
        UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERUSER")
      ) {
        route.splice(2, 0, {
          type: "collapse",
          name: t("common.App.stocktake"),
          key: "stockTake",
          icon: <Icon fontSize="small">shopping_cart</Icon>,
          route: "/stockTake",
          component: <StockOut />,
        });
      }
      if (
        UserService?.getClientRole()?.includes("ROLE_ECHOME_ASSETRETURN") ||
        UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERUSER")
      ) {
        route.splice(2, 0, {
          type: "collapse",
          name: t("common.App.assetRe"),
          key: "assetReturn",
          icon: <Icon fontSize="small">keyboard_return</Icon>,
          route: "/assetReturn",
          component: <AssetReturn />,
        });
      }
      if (
        UserService?.getClientRole()?.includes("ROLE_ECHOME_TRANIN") ||
        UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERUSER")
      ) {
        route.splice(2, 0, {
          type: "collapse",
          name: t("common.App.tranIn"),
          key: "transferIn",
          icon: <Icon fontSize="small">zoom_in_map</Icon>,
          route: "/transferIn",
          component: <TransferIn />,
        });
      }
      if (
        UserService?.getClientRole()?.includes("ROLE_ECHOME_TRANOUT") ||
        UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERUSER")
      ) {
        route.splice(2, 0, {
          type: "collapse",
          name: t("common.App.tranOut"),
          key: "transferOut",
          icon: <Icon fontSize="small">zoom_out_map</Icon>,
          route: "/transferOut",
          component: <TransferOut />,
        });
      }
      if (
        UserService?.getClientRole()?.includes("ROLE_ECHOME_ASSETIN") ||
        UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERUSER")
      ) {
        route.splice(2, 0, {
          type: "collapse",
          name: t("common.App.assetInv"),
          key: "assetInventory",
          icon: <Icon fontSize="small">inventory</Icon>,
          route: "/assetInventory",
          component: <Inventory />,
        });
      }
      if (
        UserService?.getClientRole()?.includes("ROLE_ECHOME_ASSETREG") ||
        UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERUSER")
      ) {
        route.splice(2, 0, {
          type: "collapse",
          name: t("common.App.assetReg"),
          key: "assetRegistration",
          icon: <Icon fontSize="small">edit_note</Icon>,
          route: "/assetRegistration",
          component: <Registration2 />,
        });
      }

      let finalRoute = _.uniqBy(route, "key");
      return finalRoute;
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    keycloak.onAuthSuccess = () => {
      console.log("Auth Success");
      let x = routeHandler();
      setTemp(x);
    };
  }, [storedLan]);

  useEffect(() => {
    let x = routeHandler();
    setTemp(x);
  }, [storedLan]);

  // Redicrecting user to login page when token is expired
  useEffect(() => {
    keycloak.onTokenExpired = () => {
      console.log(keycloak?.token);
      console.log("expired " + new Date());
      alert(t("common.DialogLocationDashboard.alert-token"));
      const url =
        process.env.REACT_APP_HOST_ENV === "DEV"
          ? "http://echome.protek-asia.tech/"
          : "http://echome.dfs.com/";
      keycloak.logout({ redirectUri: url });
      window.localStorage.clear();
    };
  }, [keycloak?.token]);

  //  Login with Local Keycloak
  return (
    <Suspense fallback="Loading">
      <LoginGate>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={
                  (transparentSidenav && !darkMode) || whiteSidenav
                    ? brandDark
                    : brandWhite
                }
                brandName={<EchoMe />}
                routes={temp ?? []}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              {/* {process.env.REACT_APP_HOST_ENV === "DEV" ? <DebugDisplay /> : null} */}
              {/* <Configurator />
              <ConfigsButton onClick={handleConfiguratorOpen} />
              <Configurator />
              {ConfigsButton} */}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </ThemeProvider>
      </LoginGate>
    </Suspense>
  );
}
