import Card from "@mui/material/Card";
import BasicLayout from "pages/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useKeycloak } from "@react-keycloak/web";

//  using keycloak logout option and direct to our qa server
function Basic() {
  const { keycloak } = useKeycloak();
  const url =
    process.env.REACT_APP_HOST_ENV === "DEV"
      ? "http://echome.protek-asia.tech/"
      : "http://echome.dfs.com/";
  // const navigate = useNavigate();

  return (
    <BasicLayout image={bgImage}>
      <Card>
        {keycloak.authenticated && (
          <Card>
            {keycloak.authenticated && (
              <p>
                Logout
                {keycloak.logout({ redirectUri: url })}
                {window.localStorage.clear()}
              </p>
            )}
          </Card>
        )}
        {/* {alert("This function is not supported yet")} */}
        {/* <a href="http://qa-echome.ddns.net/echoMe/oscar">Link in new tab</a> */}
      </Card>
    </BasicLayout>
  );
}

export default Basic;
