/* eslint-disable no-use-before-define */
/* eslint-disable arrow-body-style */
/* eslint-disable no-useless-escape */
const genMultItemRfidTagZpl = (array) => {
  return array
    ?.map((e) => {
      return genItemRfidTagZpl(
        e.barCode,
        e.des,
        e.ssc,
        e.sku,
        e.exp,
        e.serial,
        e.rfid,
        e.productCode
      );
    })
    .join("");
};

// const genItemRfidTagZpl = (barCode, des, scs, sku, exp, sn, rfid) => {
//   return `^XA ^MMT ^PW909 ^LL425 ^LS0 ^FT35,54^A0N,54,53^FH\^CI28^FDUpper Left^FS^CI27 ^FT35,411^A0N,54,53^FH\^CI28^FDLower Left^FS^CI27 ^BY2,3,23^FT808,181^BCI,,Y,N ^FH\^FD>;${barCode}^FS ^FT846,141^A0I,17,18^FH\^CI28^FD${des}^FS^CI27 ^FT847,88^A0I,17,18^FH\^CI28^FD${scs}^FS^CI27 ^FT662,88^A0I,17,18^FH\^CI28^FD${sku}^FS^CI27 ^FT662,115^A0I,17,18^FH\^CI28^FD${exp}^FS^CI27 ^FT847,115^A0I,17,18^FH\^CI28^FD${sn}^FS^CI27 ^RFW,H,1,2,1^FD4000^FS ^RFW,A,2,16,1^FD${rfid}^FS ^PQ1,0,1,Y ^XZ`;
// };

const genItemRfidTagZpl = (barCode, des, ssc, sku, exp, serial, rfid, gdc) => {
  return `CT~~CD,~CC^~CT~ ^XA ^PW1010 ^MD25 ~TA000 ~JSN ^LT24 ^MNW ^MTT ^PON ^PMN ^LH0,0 ^JMA ^PR6,6 ~SD15 ^JUS ^LRN ^CI27 ^PA0,1,1,0 ^RS8,,,3 ^XZ ^XA ^MMT ^PW1000 ^LL425 ^LS0 ^FT35,54^A0N,54,53^FH\^CI28^FDUpper Left^FS^CI27 ^FT35,411^A0N,54,53^FH\^CI28^FDLower Left^FS^CI27 ^FT639,411^A0N,54,53^FH\^CI28^FDLower Right^FS^CI27 ^FPH,3^FT551,276^ADN,54,30^FB358,1,0,C^FH\^FD${gdc}^FS ^FPH,3^FT554,328^A0N,18,18^FB355,1,5,C^FH\^CI28^FD${rfid}^FS^CI27 ^BY2,3,23^FT851,174^BCI,,N,N,,D ^FH\^FD${barCode}^FS ^FPH,3^FT871,129^A0I,17,18^FH\^CI28^FD${des}^FS^CI27 ^FPH,2^FT871,74^A0I,17,18^FH\^CI28^FD${ssc}^FS^CI27 ^FPH,1^FT909,153^ADI,18,10^FB349,1,0,R^FH\^FD${sku}^FS ^FPH,3^FT909,100^A0I,17,18^FB349,1,4,R^FH\^CI28^FD${exp}^FS^CI27 ^FPH,3^FT871,100^A0I,17,18^FH\^CI28^FD${serial}^FS^CI27 ^FT871,152^A0I,17,18^FH\^CI28^FD${barCode}^FS^CI27 ^RFW,H,1,2,1^FD4000^FS ^RFW,A,2,16,1^FD${rfid}^FS ^PQ1,0,1,Y ^XZ`;
};

export { genMultItemRfidTagZpl, genItemRfidTagZpl };
