/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import UserService from "../../service/KeyCloakService";
import LocationAsync from "components/LocationProfile/LocationAsync";
import ZoneAsync from "components/LocationProfile/ZoneAsync";
import ZonetypeAsync from "../../components/LocationProfile/ZoneTypeAsync";
import LocationLocAsync from "../../components/LocationProfile/LocationLocAsync";
//  Getting arrays of roles from keycloak and if user does not have then it will not grant access
function LocProfile() {
  let contents;
  const navigate = useNavigate();
  if (
    UserService?.getClientRole()?.includes("ROLE_ECHOME_LOCMANAGE") ||
    UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERUSER") ||
    UserService?.getClientRole()?.includes("ROLE_ECHOME_SUPERVISORS") ||
    UserService?.getClientRole()?.includes("ROLE_ECHOME_TEAMREADER")
  ) {
    contents = (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={6}>
                <LocationAsync />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <LocationLocAsync />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={6}>
                <ZonetypeAsync />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <ZoneAsync />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  } else {
    navigate("/dashboard");
  }
  return <>{contents}</>;
}

export default LocProfile;
