/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, TextField, Box, MenuItem } from "@mui/material";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";

// eslint-disable-next-line arrow-body-style
const DialogBatchUpdateInv = ({ open, close, selected, load, setSelected }) => {
  const [upcField, setUpcField] = useState();
  const [value, setValue] = useState();
  const [assetCode, setAssetCode] = useState();
  const [fieldsArray, setFieldsArray] = useState(["EANUPC"]);
  const { t } = useTranslation(["inventory"]);

  // Input change Handler
  const upcFieldChangeHandler = (event) => {
    setUpcField(event.target.value.toLowerCase());
  };

  const valueChangeHandler = (event) => {
    setValue(event.target.value);
  };

  //  Button Functions
  const handleClose = () => {
    //  Resetting the values
    setUpcField("");
    setAssetCode("");
    setValue("");
    setSelected("");
    load();
    close();
  };

  //  Button functions
  const submitHandler = async (event) => {
    event.preventDefault();
    const assetCodeArray = selected?.map((r) => r.assetCode).join(",");
    setAssetCode(assetCodeArray);
    if (!upcField || !value) {
      alert(t(`inventory.DialogBatchUpdateInv.alert-submitHandler2`));
    } else if (upcField) {
      //  fetching data
      try {
        const data = await fetch(
          `/echoMe/inv/massUpdate?type=${upcField}&value=${value}&assetCodes=${assetCodeArray}`
        );
        if (!data.ok) {
          console.log("error message from genRfidContainer");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        //  Resetting the values and close
        handleClose();
      } catch (err) {
        console.error(err);
        alert(err);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth="sm" fullWidth={true}>
        <DialogTitle>
          {t("inventory.DialogBatchUpdateInv.header-name")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            {t("inventory.DialogBatchUpdateInv.body")}
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <form onSubmit={submitHandler}>
              <Grid container spacing={2} mt="5px">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2}>
                    {}
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Box>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="simple-select-label">
                          {t("inventory.DialogBatchRegeneInv.input-fields")}
                        </InputLabel>
                        <Select
                          labelId="simple-select-label"
                          id="demo-simple-select"
                          label="fields"
                          defaultValue=""
                          onChange={upcFieldChangeHandler}
                        >
                          {fieldsArray?.map((fields) => (
                            <MenuItem key={fields} value={fields}>
                              {fields}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {}
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      margin="dense"
                      id="value-input"
                      name="value"
                      label={t("inventory.DialogBatchRegeneInv.input-value")}
                      type="string"
                      fullWidth={true}
                      onChange={valueChangeHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              type="submit"
              style={{ color: "white", backgroundColor: "blue" }}
              onClick={submitHandler}
            >
              {t("inventory.DialogBatchUpdateInv.button-confirm")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={handleClose}
              style={{ color: "white", backgroundColor: "red" }}
            >
              {t("inventory.DialogBatchUpdateInv.button-close")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogBatchUpdateInv;
