/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useSelector, useDispatch } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import {
  Grid,
  TextField,
  Box,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";

// eslint-disable-next-line arrow-body-style
const DialogLocationAdd = ({ open, close, load }) => {
  const [locCode, setLocCode] = useState("");
  const [description, setDescription] = useState("");
  const [address, SetAddress] = useState("");

  // Input fields change handler
  const locCodeChangeHandler = (event) => {
    setLocCode(event.target.value);
  };

  const descriptionChangeHandler = (event) => {
    setDescription(event.target.value);
  };

  const addressChangeHandler = (event) => {
    SetAddress(event.target.value);
  };

  //  Button functions
  const closeHandler = () => {
    setLocCode("");
    setDescription("");
    SetAddress("");
    load();
    close();
  };

  const submitHandler = async () => {
    // Allow user to enter all fields
    if (!locCode || !description || !address) {
      alert("Please input all the fields.");
    } else {
      //  If all fields are entered.
      const temp = {
        siteCode: locCode,
        address: address,
        description: description,
      };
      const obj = JSON.stringify(temp);
      // console.log(obj);
      try {
        const data = await fetch(`/echoMe/loc/newLocSite?locSite=${obj}`, {
          method: "GET",
          mode: "no-cors",
        });
        if (!data.ok) {
          console.log("error message from addLocation");
          let errorText = await data.text();
          console.log(errorText);
          throw Error(errorText);
        }
        // update the object and clear all fields and load data.
        closeHandler();
      } catch (err) {
        console.error(err);
        alert(err);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="sm">
        <DialogTitle>Add New Location</DialogTitle>
        <DialogContent>
          <DialogContentText align="left">
            Please Input the location information.
          </DialogContentText>
          <Box variant="div" sx={{ padding: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  id="locationCode-input"
                  name="locationCode"
                  label="Location Code"
                  type="text"
                  fullWidth={true}
                  onChange={locCodeChangeHandler}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  id="description-input"
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth={true}
                  onChange={descriptionChangeHandler}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  margin="dense"
                  id="address-input"
                  name="address"
                  label="Address"
                  type="text"
                  fullWidth={true}
                  onChange={addressChangeHandler}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={submitHandler}
              style={{ color: "white", backgroundColor: "blue" }}
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={closeHandler}
              style={{ color: "white", backgroundColor: "red" }}
            >
              Close
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogLocationAdd;
