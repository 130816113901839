/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "@inovua/reactdatagrid-enterprise/index.css";
import PropTypes from "prop-types";
// react data grid
const gridStyle = { minHeight: 560 };

const toArray = (selected) => Object.values(selected)?.map((id) => id);

// eslint-disable-next-line arrow-body-style
const RegisterManageListData = ({
  data,
  getTargetContainerAssetCode,
  isNew,
  isMoved,
}) => {
  const [selected, setSelected] = useState({});
  const { t } = useTranslation(["inventory"]);
  const columns = [
    {
      name: "productCode",
      header: t(`inventory.RegisterManageListData.column-productCode`),
      type: "string",
      defaultFlex: 1.5,
    },
    {
      name: "skuCode",
      header: t(`inventory.RegisterManageListData.column-skuCode`),
      type: "string",
      defaultFlex: 1.5,
    },
    {
      name: "description",
      header: t(`inventory.RegisterManageListData.column-description`),
      type: "string",
      defaultFlex: 2,
    },
    {
      name: "assetCode",
      header: t(`inventory.RegisterManageListData.column-assetCode`),
      type: "string",
      defaultFlex: 1.5,
    },
    { name: "id", header: "Id", type: "number", defaultVisible: false },
  ];
  // Remove all selected if isNew is false
  useEffect(() => {
    if (!isNew || isMoved) {
      setSelected({});
    }
  }, [isNew, isMoved]);

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  // filter assetCode for moving record
  const filterAssetCode = () => {
    const temp = toArray(selected)
      ?.map((d) => d.assetCode)
      .join(",");
    getTargetContainerAssetCode(temp, setSelected);
  };

  useEffect(() => {
    filterAssetCode();
  }, [selected]);

  return (
    <div>
      <ReactDataGrid
        licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
        idProperty="id"
        checkboxColumn
        selected={selected}
        onSelectionChange={onSelectionChange}
        style={gridStyle}
        columns={columns}
        dataSource={data}
      />
      <p>
        {t(`inventory.total`)}: {data.length}
      </p>
    </div>
  );
};

export default RegisterManageListData;
