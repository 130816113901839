/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-useless-path-segments */
import { useCallback } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import UserService from "../service/KeyCloakService";
import TransferIn from "pages/TransferIn";
import TransferOut from "pages/TransferOut";
import Notifications from "pages/notifications";

// import Profile from "pages/profile";
import SignOutB from "pages/authentication/sign-outbeta";
import Inventory from "pages/Inventory";
import TrackAndTrace from "pages/TrackAndTrace";
import Location from "pages/Location";
import LocProfile from "pages/LocProfile";
import Registration2 from "pages/Registration2";
import AssetReturn from "pages/Return";
import StockOut from "pages/StockOut";
import routesNA from "../routes/routesNA";

// @mui icons
import Icon from "@mui/material/Icon";
import { uniqBy, uniqWith } from "lodash";

// const { t } = useTranslation(["assetReturn"]);

function routeHander(keycloak) {
  //  Getting basic route
  let route = routesNA;
  //  Route sequence handling
  let profileIndex = route?.map((object) => object.name).indexOf("Profile");
  if (keycloak.authenticated) {
    console.log(UserService.getClientRole());
    if (UserService.getClientRole().includes("ROLE_ECHOME_LOCMANAGE")) {
      route.splice(profileIndex + 1, 0, {
        type: "collapse",
        name: "Location",
        key: "Location",
        icon: <Icon fontSize="small">location_searching</Icon>,
        route: "/location",
        component: <LocProfile />,
      });
    }
  }
  if (UserService.isLoggedIn()) {
    if (UserService.getClientRole().includes("ROLE_ECHOME_TRACT")) {
      route.splice(2, 0, {
        type: "collapse",
        name: "Track and Trace",
        key: "trackandtrace",
        icon: <Icon fontSize="small">add_circle</Icon>,
        route: "/trackandtrace",
        component: <TrackAndTrace />,
      });
    }
    if (UserService.getClientRole().includes("ROLE_ECHOME_STOCKTAKE")) {
      route.splice(2, 0, {
        type: "collapse",
        name: "Stock Take",
        key: "stockTake",
        icon: <Icon fontSize="small">shopping_cart</Icon>,
        route: "/stockTake",
        component: <StockOut />,
      });
    }
    if (UserService.getClientRole().includes("ROLE_ECHOME_ASSETRETURN")) {
      route.splice(2, 0, {
        type: "collapse",
        name: "Asset Return",
        key: "assetReturn",
        icon: <Icon fontSize="small">keyboard_return</Icon>,
        route: "/assetReturn",
        component: <AssetReturn />,
      });
    }
    if (UserService.getClientRole().includes("ROLE_ECHOME_TRANIN")) {
      route.splice(2, 0, {
        type: "collapse",
        name: "Transfer In",
        key: "transferIn",
        icon: <Icon fontSize="small">zoom_in_map</Icon>,
        route: "/transferIn",
        component: <TransferIn />,
      });
    }
    if (UserService.getClientRole().includes("ROLE_ECHOME_TRANOUT")) {
      route.splice(2, 0, {
        type: "collapse",
        name: "Transfer Out",
        key: "transferOut",
        icon: <Icon fontSize="small">zoom_out_map</Icon>,
        route: "/transferOut",
        component: <TransferOut />,
      });
    }
    if (UserService.getClientRole().includes("ROLE_ECHOME_ASSETIN")) {
      route.splice(2, 0, {
        type: "collapse",
        name: "Asset Inventory",
        key: "assetInventory",
        icon: <Icon fontSize="small">inventory</Icon>,
        route: "/assetInventory",
        component: <Inventory />,
      });
    }
    if (UserService.getClientRole().includes("ROLE_ECHOME_ASSETREG")) {
      route.splice(2, 0, {
        type: "collapse",
        name: "Asset Registration",
        key: "assetRegistration",
        icon: <Icon fontSize="small">edit_note</Icon>,
        route: "/assetRegistration",
        component: <Registration2 />,
      });
    }

    let finalRoute = _.uniqBy(route, "key");
    return finalRoute;
  }
}

export default routeHander;
