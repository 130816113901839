import i18n from "i18next";
import Backend from "i18next-http-backend";
import LangaugeDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(LangaugeDetector)
  .use(initReactI18next)
  .init({
    backend: {
      //  translation file path
      loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
    },
    fallbackLng: "en",
    //  diable in production
    debug: false,
    //  can have multiple namespace, in case you want to divide a translation
    //  into smaller pieces and load them on demand
    ns: [
      "common",
      "assetRegistration",
      "assetReturn",
      "stockTake",
      "transferIn",
      "transferOut",
      "trackTrace",
      "inventory",
      "setting",
    ],
    interpolation: {
      espaceValue: false,
      formatSeparator: ",",
    },
    react: { useSuspense: false },
  });
export default i18n;
