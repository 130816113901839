/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable yoda */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from "react";
import { uniqBy, uniqWith } from "lodash";
import MDBox from "../MDBox";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { Grid, Typography, Box } from "@mui/material";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserService from "../../service/KeyCloakService";
import VersionSteps from "./VersionSteps";

const gridStyle = { minHeight: 160 };

// eslint-disable-next-line arrow-body-style
const ChangeLog = ({ open, close }) => {
  return (
    <>
      <Dialog open={open} onClose={close} fullWidth={true} maxWidth="sm">
        <DialogTitle>Change Log</DialogTitle>
        <DialogContent>
          <Box variant="div" sx={{ padding: "20px" }}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6">Current Version: </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <VersionSteps />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6">Change Log: </Typography>
                  <Grid item xs={12} sm={12}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                      sx={{ padding: "20px" }}
                    >
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>0.0.4</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" display="inline">
                            * Fixed Import document issue for M1,M2,M3.
                            <br />* Fixed Cancel/Complete order error.
                            <br />* Side nivigation bar will show site
                            description instead.
                            <br />* Whenever site code and description is
                            missing, it will forcebly redirect to dashboard to
                            select the site.
                            <br />* Asset Return is completed.
                            <br />* Other unimplemented functions are disabled.
                            <br />* Location management is ready except (Site
                            Location add, zonetype add).
                            <br />* Scaned equipment is completed for asset
                            reg/return.
                            <br />* echoMe web will refetch the data whenever
                            user's browser tab is on focus.
                            <br />* Fixed Date issues in asset reg/return.
                            <br />* Dialog ui modification.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ mt: "10px" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>0.0.5</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" display="inline">
                            * Batch update UI.
                            <strong style={{ color: "blue" }}>
                              {" "}
                              API needed
                            </strong>
                            <br />* Batch re-generate item RFID UI.{" "}
                            <strong style={{ color: "blue" }}>
                              API needed
                            </strong>
                            <br />* Asset reg filtering testing.
                            <br />* Show scanned Equipment/ Confrim on Asset
                            Reg/return/ Transfer Out.
                            <br />* Asset return TO selection UI <br />* All
                            sections' status filtering error fixed.{" "}
                            <strong style={{ color: "red" }}>
                              Except Inventory
                            </strong>
                            <br />* Production site logout redirect url problem
                            <br />* New item label applied.
                            <br />* Asset reg/return generate inbound prefix
                            error fixed.
                            <br />* Partial Print for Asset Registration UI.{" "}
                            <strong style={{ color: "blue" }}>
                              API needed
                            </strong>
                            <br />* Reg Equipment will be available all status
                            but gerate rfid, regerate rfid, confirm order is
                            restricted on cancel, confirm, registering.
                            <br />* Tuned the inventory UI.
                            <br />* System Configuration UI.
                            <br />* React Data Query.
                            <br />* echoMe will fetch site every rerendering and
                            if site is 0 then it will redirect to login page
                            <br />* Keycloak token will be saved on user's
                            localstorage and used for update for certain
                            lifespan right after token is expired
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ mt: "10px" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>0.0.6</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" display="inline">
                            * Container rules setting.
                            <br />* Batch regeration With Keycloak role
                            [ROLE_ECHOME_BATCH_REGEN].
                            <br />* Re-generation is only allowed on status of
                            [PRINTED] in Management screen for Asset Return,
                            Registration, Transfer Out.
                            <br />* All field are added in Asset Inventory with
                            vertical scrolling/ scroll to first button.
                            <br />* Batch update With Keycloak role
                            [ROLE_ECHOME_BATCH_UPDATE].
                            <br />* TO selection in asset Return
                            <br />* React-Query is applied to asset registration
                            <br />* Re-generate asset RFID & Management UI in
                            Transfer in{" "}
                            <strong style={{ color: "blue" }}>
                              API needed
                            </strong>
                            <br />* Generate RFID UI in Transfer in{" "}
                            <strong style={{ color: "blue" }}>
                              API needed
                            </strong>
                            <br />* SKU Grouping is added on Asset Reg, Return,
                            Transfer Out/In{" "}
                            <strong style={{ color: "blue" }}>
                              Default Collapse
                            </strong>
                            <br />* Uploading file issue for m1,m2,m3. Submit
                            button will be disabled until successfully uploaded
                            after user click it once.
                            <br />* Asset Registration(3 time fails do refresh
                            -React Query Only-)
                            <br />* Token expired then redirect to login page
                            <br />* Partial Print UI{" "}
                            <strong style={{ color: "blue" }}>
                              API needed
                            </strong>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ mt: "10px" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>0.0.7</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" display="inline">
                            * UI of Process screen, management screen in Asset
                            Reg, Return, Transfer In/out are changed.
                            <br />* Asset Inventory Adjustment Screen UI is
                            changed.
                            <br />* Modification of Dialog UI.
                            <br />* Change UI of System setting.
                            <br />* Batch Update, Regen restriction is removed.
                            <br />* Partial Printing in Asset Reg.
                            <br />* Modification of Selected To in Asset Return.
                            <br />* Asset Registration process screen data
                            loading issue after uploading m1 file.
                            <br />* Add optional chaining to transfer in
                            replaceAll issue.
                            <br />* Add validation, keycloak roles to container
                            rule configuration.
                            <br />* Add validation to Prefix setting.
                            <br />* Generate equipment RFID in Transfer In.
                            <br />* Re-generate asset RFID in Transfer In.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ mt: "10px" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>0.0.8</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" display="inline">
                            * Add more validation to partial print in asset-reg.
                            <br />* Add filters to site selection dialog and
                            enlarge the table size to height of 400.
                            <br />* Real time scanning on register list on asset
                            reg.
                            <br />* Real time scanning on register list on asset
                            return.
                            <br />* Real time scanning on register list on asset
                            transferout.
                            <br />* Inventory Equipment.
                            <br />* Site selection logic is changed on side nav
                            bar.
                            <br />* Configuration UI for setting time duation
                            for scanner <strong>API needed</strong>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ mt: "10px" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>0.0.9~</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" display="inline">
                            * RFID generation ( Equipment and Item )
                            <br />* Enhance the register process
                            <br />* Equipment Management Enhancement
                            <br />* Create the stock count order
                            <br />* Open, search and view the stock take
                            <br />* Count sheet export
                            <br />* Counting Result Export
                            <br />* One Time regstration function in asset
                            reg,return,transout
                            <br />* Multi-Language (Asset-return,reg, TransIn,
                            Stocktake, dashboard, site selection)
                            <br />* Modification of Transfer In UI
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ mt: "10px" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>0.0.10</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" display="inline">
                            * Asset Inventory Movement
                            <br />* Stock Take Process screen modification
                            <br /> * Added Private routing for stocktake with
                            corresponding keycloak roles
                            <br /> * Track And Trace
                            <br /> * Invalid Date issue fixed
                            <br /> * One Time register UI/UX Enhancement
                            <br /> * Printer Selection dropdown menu before
                            scanning in trans out, asset reg/return
                            <br /> * Stock take recount
                            <br /> * Multiple language support
                            <br /> * Avatar function is added
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ mt: "10px" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>0.0.11</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" display="inline">
                            * Stock Take Recount Modification with selecting
                            location
                            {/* <br /> * Batch Extional Handling in Track And Trace */}
                            <br /> * Asset Registration Process Screen
                            modification
                            <br /> * Asset Return Process Screen modification
                            <br /> * Transfer Out Process Screen modification
                            <br /> * New Scanning logic for Asset
                            Registration(User can modify the scanning records)
                            <br /> * New Scanning logic for Asset Return(User
                            can modify the scanning records)
                            <br /> * New Scanning logic Transfer Out(User can
                            modify the scanning records)
                            <br /> * Asset Inventory Adjstment function issue
                            fixed
                            <br /> * Allows user set the start number when
                            create a new prefix
                            <br /> * Track and Trace UI and new field is added
                            <br /> * Added boolean flag filter to track and
                            trace
                            <br /> * Added line status to stock take line
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ mt: "10px" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>0.0.12</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" display="inline">
                            * In Track and trace, color will be applied
                            according to different flag
                            <br /> Asset Inventory container rfid regeneration
                            <br /> Date Filter is added
                            <br /> Container RFID REGEN
                            <br /> Remove Auto Reload function on Asset Inv,
                            Track and Trace
                            <br /> Track and trace default filtering added
                            exception flag = true, handled flag = false
                            <br /> Added filter on stock take process screen
                            <br /> Scanning null, undefined type error fixed
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ mt: "10px" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>0.0.13</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" display="inline">
                            * Stock Take Scanning and UI modification
                            <br /> * Transfer Out supporting new scanner
                            <br /> * Multiple language support
                            <br /> * Support New Scanning method for Asset
                            reg/return, Transfer Out, Stock Take
                            <br /> * Stock take filter added
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ mt: "10px" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>0.0.14</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" display="inline">
                            * Keycloak Final design is applied
                            <br /> * BiLingual Support
                            <br /> * Transfer In Scanning
                            <br /> * Process Screen UI modification
                            <br /> * Old scanning support is removed
                            <br /> * Added required fields
                            <br /> * Scanner Configuration
                            <br /> * Inventory search by RFID/Equipment
                            <br /> * Asset registration partial complete
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ mt: "10px" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>0.0.15</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" display="inline">
                            * Adding client side pagination to Transfer IN/OUT -
                            Transfer List
                            <br /> * Adding RFID Filtering logic to Transfer In
                            - Transfer List
                            <br /> * Load function issue in Asset Registration
                            <br /> * Filtering option issue of status in
                            Transfer out
                            <br /> * Adding Refresh button to process screen
                            which has scan function
                            <br /> * Adding sorting criterion (createdDate with
                            Ascending)
                            <br /> * Adding pagination to all list grid
                            <br /> * New scanning logic applied on Transfer Out
                            <br /> * Upload file error fixed
                            <br /> * Asseg reg, return generating rfid issue fix
                            <br /> * New partial print script applied
                            <br /> * Add backdrop for initiating and starting
                            backdrop
                            <br /> * Fixed issues from Location Profile issues
                            <br /> * Inventory Adjustment UI and changing logic
                            revamped
                            <br /> * Fixed Inventory Refreshing issue after
                            saving
                            <br /> * Location change logic enchanced
                            <br /> * TO selection from Asset Return issue fixed
                            <br /> * Added Saving TO information function
                            <br /> * Remove redirection from avatar icon
                            <br /> * TO selection issue fixed
                            <br /> * Manage RFID Screen add default 'PRINTED'
                            Filter
                            <br /> * Disabled filtering of status in invetory
                            <br /> * Fixed urgent scanning function issue
                            <br /> * Add refreshing function to able/disable
                            scanner
                            <br /> * fixed checkin container api for transfer
                            out
                            <br /> * UI/UX enhancement of genratring rfid button
                            of Asset Reg/Return
                            <br /> * Asset Reg delete rfid issue fixed
                            <br /> * Enhanced the behaviour of cancel button on
                            stock take header
                            <br /> * Stock take issue fixed
                            <br /> * New scanning logic applied on Transfer In/
                            Asset Return/ Stock Take
                            <br /> * Stock UI Enhancement on process screen
                            <br /> * Asset Reg Partial print api issue fix
                            <br /> * Asset Reg Partial print expiry date
                            overlapping issue fixed
                            <br /> * Asset Return partial print parameter
                            checking according to new api
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ mt: "10px" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>2.0.1</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" display="inline">
                            * New release version
                            <br /> * Keyclaok compatibility issue fix
                            <br /> * manage rfid change limit to 9999
                            <br /> * Add user feedback for exporting documents
                            <br /> * Add user feedback for Scanning
                            <br /> * New scanning logic applied
                            <br /> * Changed get to post for checking in items
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ mt: "10px" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>2.0.2</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" display="inline">
                            * Fixed Tranfer In scanning logic
                            <br /> * Fixed naming in stock-take
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red" }}
            onClick={close}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangeLog;
