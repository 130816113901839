/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable arrow-body-style */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { useEffect, useState, useRef, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { Grid, Button, Box, TextField, Paper } from "@mui/material";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import moment from "moment";
import { printZpl } from "utility/printUtli";
import { genMultItemRfidTagZpl } from "utility/printUtli/itemLabelPrint";

const gridStyle = { minHeight: 600 };

const defaultFilterValue = [
  { name: "id", type: "string", operator: "eq", value: "" },
  { name: "regNum", type: "string", operator: "contains", value: "" },
  { name: "rSkuCode", type: "string", operator: "contains", value: "" },
  { name: "skuCode", type: "string", operator: "contains", value: "" },
  { name: "rfid", type: "string", operator: "contains", value: "" },
  { name: "status", type: "string", operator: "contains", value: "PRINTED" },
  { name: "version", type: "number", operator: "gte", value: 1 },
];

// eslint-disable-next-line arrow-body-style
const DialogManageReturn = ({ open, close, rtnNum, selected3 }) => {
  const [arrayData, setArrayData] = useState([]);
  const [selected, setSelected] = useState({});
  const { t } = useTranslation(["assetReturn"]);
  const columns = [
    { name: "id", header: "Id", type: "number", defaultVisible: false },
    {
      name: "regNum",
      header: t(`assetReturn.DialogManageReturn.column-regNum`),
      type: "string",
      defaultFlex: 1,
    },
    {
      name: "skuCode",
      header: t(`assetReturn.DialogManageReturn.column-skuCode`),
      defaultFlex: 1,
    },
    {
      name: "rSkuCode",
      header: t(`assetReturn.DialogManageReturn.column-rskuCode`),
      defaultFlex: 1,
    },
    {
      name: "rfid",
      header: t(`assetReturn.DialogManageReturn.column-rfid`),
      type: "string",
    },
    {
      name: "status",
      header: t(`assetReturn.DialogManageReturn.column-status`),
      type: "string",
    },
    {
      name: "version",
      header: t(`assetReturn.DialogManageReturn.column-version`),
      type: "number",
    },
    {
      name: "createdDate",
      header: t(`assetReturn.DialogManageReturn.column-createdDate`),
      defaultFlex: 1,
      minWidth: 200,
      filterEditorProps: (props, { index }) =>
        // for range and notinrange operators, the index is 1 for the after field
        ({
          dateFormat: "YYYY-MM-DD",
          cancelButton: false,
          highlightWeekends: false,
          placeholder:
            index === 1 ? "Order date is before..." : "Order date is after...",
        }),
      // eslint-disable-next-line no-unused-vars
      render: ({ value, cellPros }) =>
        value === null ? "None" : moment(value).format("YYYY-MM-DD"),
    },
  ];

  // eslint-disable-next-line no-shadow
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const loadData = async () => {
    const data = await fetch(
      `/echoMe/rfid/listRfidItemByOrderNum?limit=9999&orderNum=${encodeURIComponent(
        rtnNum
      )}&orderType=ASSET_RETURN`
    );

    const json = await data.json();
    // console.log(json);
    setArrayData(json);
  };

  useEffect(() => {
    if (rtnNum) {
      loadData();
    }
  }, [rtnNum, open]);

  const regenerateButtonHandler = async () => {
    const statusArray = Object?.values(selected)?.map((r) => r.status);
    const rfidArray = Object?.values(selected)
      ?.map((r) => r.rfid)
      ?.join(",");
    if (
      !statusArray?.includes("CANCELLED") &&
      !statusArray?.includes("ACTIVE")
    ) {
      try {
        const data = await fetch(
          `/echoMe/assetReturn/regenRfidItem?rtnNum=${encodeURIComponent(
            rtnNum
          )}&rfids=${rfidArray}`
        );
        if (!data.ok) {
          console.log("error message from regenRfidItem");
          let error = await data;
          let errorText = await data.text();
          console.log(errorText);

          throw Error(errorText);
        }
        const json = await data.json();
        printZpl(
          genMultItemRfidTagZpl(
            json?.map((e) => {
              const {
                eanupc = "1111111111",
                description = "NA",
                style = "NA",
                color = "NA",
                size = "NA",
                skuCode = "NA",
                exp = "NA",
                serial = "NA",
                rfid = "SNoRfidFoundData",
                productCode = "NA",
              } = e;
              console.log(e);
              let ssc = `${style}/${size}/${color}`;
              return {
                barCode: eanupc,
                des: description,
                ssc: ssc,
                sku: skuCode,
                exp: exp,
                serial: serial,
                rfid: rfid,
                productCode: productCode,
              };
            })
          )
        );
        setSelected(null);
        loadData();
      } catch (err) {
        console.error(err);
        alert(err);
        setSelected(null);
      }
    } else {
      alert(t(`assetReturn.DialogManageReturn.alert-regenerateButtonHandler`));
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        fullWidth={true}
        maxWidth="xl"
        fullScreen={true}
      >
        <DialogTitle>
          {t("assetReturn.DialogManageReturn.header-name")}
        </DialogTitle>
        <DialogContent>
          {t("assetReturn.DialogManageReturn.bodytext-documentNumber")}
          <b style={{ color: "red" }}>{rtnNum}</b>
          <Box
            variant="div"
            sx={{ padding: "20px", border: "1px solid", borderRadius: "8px" }}
          >
            <Paper
              elevation={8}
              sx={{
                borderRadius: "8px",
                display: "flex",
                flexDirection: "row",
                padding: "25px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {}
                </Grid>
                <Grid item xs={12} sm={2}>
                  {}
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    onClick={loadData}
                    style={{ color: "white" }}
                  >
                    {t("assetReturn.DialogManageReturn.button-refresh")}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    onClick={regenerateButtonHandler}
                    style={{ color: "white" }}
                    // disabled={
                    //   selected3?.status.toLowerCase() === "completed" ||
                    //   selected3?.status.toLowerCase() === "cancelled" ||
                    //   selected3?.status.toLowerCase() === "registering"
                    // }
                  >
                    {t(
                      "assetReturn.DialogRegisteredEquipmentReturn.button-regen"
                    )}
                  </Button>
                </Grid>
                <Grid container spacing={2} mt="5px">
                  <Grid item xs={12} sm={12}>
                    <ReactDataGrid
                      licenseKey="AppName=ProtekIntelligenceAsiaApp,Company=ProtekIntelligenceAsia,ExpiryDate=2024-04-28,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ProtekIntelligenceAsiaLicenseRef,Z=75645547416661968631719684595-2080444437-10293263181668125359"
                      idProperty="id"
                      checkboxColumn
                      // multiSelect={false}
                      selected={selected}
                      onSelectionChange={onSelectionChange}
                      style={gridStyle}
                      columns={columns}
                      // editable
                      dataSource={arrayData}
                      defaultFilterValue={defaultFilterValue}
                    />
                    <p>
                      {t(`assetReturn.total`)}: {arrayData.length}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", backgroundColor: "red" }}
            onClick={close}
          >
            {t("assetReturn.DialogManageReturn.button-close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogManageReturn;
